import React, { FC, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row, UncontrolledCarousel } from 'reactstrap';
import { noImage, ProductEligibility, ProductModel } from '../../../shared/orders';
import {
  AddedToCartCheck,
  Breadcrumb,
  InlineHelp,
  LazyMarkdown,
  PriceBreakdown,
  ProductBadges,
} from '../../components';
import { MaterialIconText } from '../../components/MaterialIconText';
import { displayName } from '../../utils';
import { ProductConfigurator } from './ProductConfigurator';

interface ProductPageProps {
  readonly product: ProductModel;
  readonly eligibility: ProductEligibility;
  onBackToCart(): void;
}

export const ProductPage: FC<ProductPageProps> = ({ product, eligibility, onBackToCart }) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const includedAddons = (product.addons ?? []).filter(({ isFree }) => isFree);

  useEffect(() => {
    setAddedToCart(false);
  }, [product]);

  const breadcrumb = (
    <Col style={{ marginTop: '.15em', marginBottom: '2em' }} xs={12}>
      <Breadcrumb
        items={[
          { text: 'Event Store', url: '/store' },
          { text: product.category.name, url: `/store?category=${product.category.id}` },
          { active: true, text: displayName(product) },
        ]}
      />
    </Col>
  );

  if (addedToCart) {
    return (
      <Row className="justify-content-center">
        {breadcrumb}
        <Col lg={10} xs={12}>
          <AddedToCartCheck
            product={product}
            secondaryAction={{
              onClick: () => {
                setQuantity(1);
                onBackToCart();
                setAddedToCart(false);
              },
              text: 'Back to Product',
            }}
          />
        </Col>
      </Row>
    );
  }

  const images = product.images
    .filter((t) => t.type === 'primary' || t.type === 'image')
    .sort((a, b) => (a.type === b.type ? 0 : a.type === 'primary' ? -1 : 1));

  return (
    <Row className="justify-content-center product-info" id={`productPage${product.id}`}>
      {breadcrumb}
      <Col className="justify-content-center margin-bottom-10" md={3} xl={2} xs={12}>
        {product.images.length <= 1 ? (
          <img
            src={images.length > 0 ? `/api/products/${product.id}/images/${images[0].id}` : noImage}
            style={{ maxWidth: '100%', maxHeight: 'auto', borderRadius: '10px', margin: '0 auto' }}
          />
        ) : (
          <UncontrolledCarousel
            interval={15_000}
            items={images.map(({ id }) => ({ src: `/api/products/${product.id}/images/${id}` }))}
          />
        )}
      </Col>
      <Col md={8} xl={8} xs={12}>
        <h3>{displayName(product)}</h3>
        <div className="margin-bottom-10">
          <ProductBadges includeCategory product={product} />
        </div>
        <GrantRestrictedAttribute eligibility={eligibility} product={product} />
        <TicketedProductAttribute product={product} />
        <strong>Price</strong>
        <h4>
          <PriceBreakdown product={product} />
        </h4>
        {includedAddons.length > 0 && (
          <>
            <hr />
            <FormGroup>
              <Label>Includes</Label>
              <p id="addons">
                {includedAddons.map((addon) => (
                  <strong key={addon.id}>{displayName(addon)}</strong>
                ))}
              </p>
            </FormGroup>
          </>
        )}
        <hr />
        <Row>
          <ProductConfigurator
            eligibility={eligibility}
            onAddedToCart={setAddedToCart}
            onQuantityChange={setQuantity}
            product={product}
            quantity={quantity}
          />
        </Row>
      </Col>
      <Col lg={9} xs={12}>
        <hr />
        <h5>Description</h5>
        <LazyMarkdown source={product.description ?? ''} />
      </Col>
    </Row>
  );
};

interface AttributeProps {
  readonly product: ProductModel;
}

const TicketedProductAttribute: FC<AttributeProps> = ({ product }) => {
  if (!product.isTicketed) {
    return null;
  }

  return (
    <p id="attributeTicketedProduct">
      <MaterialIconText name="confirmation_number" type="primary">
        This is a Ticketed Product.
        <InlineHelp>
          <p>
            A Ticketed Product is a one-per-person purchasable product for accessing special or
            limited events.
          </p>
          <p>
            <strong>
              You will need to print the ticket, or have it ready on your phone, and present it to
              the event organizer at the door.
            </strong>
          </p>
          <p className="mb-0">
            Please check your ticket after purchase for information on your entry group, how to
            access the event, and other details or restrictions.
          </p>
        </InlineHelp>
      </MaterialIconText>
    </p>
  );
};

interface GrantRestrictedAttributeProps {
  readonly product: ProductModel;
  readonly eligibility: ProductEligibility;
}

const GrantRestrictedAttribute: FC<GrantRestrictedAttributeProps> = ({ product, eligibility }) => {
  if (product.digitalGrantRestricted && product.requiredGrants.length > 0) {
    if (!eligibility) {
      return (
        <p>
          <MaterialIconText name="pending" type="muted">
            Checking purchase eligibility...
          </MaterialIconText>
        </p>
      );
    }

    if (eligibility.canPurchase && eligibility.requiredProduct) {
      return (
        <p id="attributeGrantRestrictedOK">
          <MaterialIconText name="done" type="success">
            You are eligible to purchase this product because you have purchased the following
            product: <strong>{displayName(eligibility.requiredProduct)}</strong>.
          </MaterialIconText>
        </p>
      );
    }

    const productNames = product.requiredGrants.map((grant) => displayName(grant)).join(', ');

    return (
      <p id="attributeGrantRestricted">
        <MaterialIconText name="warning" type="warning">
          To purchase this product, you must have already purchased one of the following:{' '}
          <strong>{productNames}</strong>.
          <InlineHelp>
            <p>
              The purchase of this product is restricted to users who have already purchased one of
              the listed products.
            </p>
            <p className="mb-0">
              You will need to purchase a listed product before you can add this product to your
              cart.
            </p>
          </InlineHelp>
        </MaterialIconText>
      </p>
    );
  }

  if (product.maxGrants) {
    if (eligibility.grantLeft === 0) {
      return (
        <p id="noGrantsLeft">
          <MaterialIconText name="warning" type="warning">
            You have already reached the purchase limit for this product.
          </MaterialIconText>
        </p>
      );
    }

    return (
      <p id="grantsLimit">
        <MaterialIconText name="warning" type="warning">
          There is a limit of {product.maxGrants} per customer for this product.
        </MaterialIconText>
      </p>
    );
  }

  return null;
};
