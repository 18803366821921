import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { isLogicError } from '../../utils';
import { LogicError } from '../../utils/errorHandling';

interface VoucherComponentProps {
  readonly orderId: number;
  onVoucherApplied(): void;
}

export const VoucherComponent: FC<VoucherComponentProps> = ({ orderId, onVoucherApplied }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleError = useCallback((err: Error) => {
    if (isLogicError(err)) {
      const error = err.apiResponse.errors.logic!.find(
        ({ code }) =>
          LogicError.CannotRedeemVoucherNegative === code ||
          LogicError.InvalidVoucher === code ||
          LogicError.VoucherCannotBeUsedWithOtherVouchers === code,
      );

      if (error) {
        setErrorMessage(error.message);
        return true;
      }
    }

    return false;
  }, []);

  const onSuccess = useCallback(() => {
    onVoucherApplied();
    setErrorMessage(undefined);
  }, []);

  return (
    <Card className="margin-bottom-10">
      <CardHeader>Have a voucher to redeem?</CardHeader>
      <CardBody>
        <JSONForm
          method="post"
          onFailure={handleError}
          onSuccess={onSuccess}
          path={`/api/orders/${orderId}/vouchers/apply`}
        >
          <InputGroup>
            <Input id="voucherCode" invalid={!!errorMessage} name="code" />
            <InputGroupAddon addonType="prepend">
              <Button id="voucherRedeem">Redeem Voucher</Button>
            </InputGroupAddon>
            {errorMessage && <FormFeedback invalid>{errorMessage}</FormFeedback>}
          </InputGroup>
        </JSONForm>
      </CardBody>
    </Card>
  );
};
