import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Fetcher, useFetcher } from '../../utils';
import { LinkButton } from '../LinkButton';
import { IconColor } from '../MaterialIcon';

export const CartComplete: FC = () => {
  const params = useParams<{ id: string }>();

  const fetcher = useFetcher(async () => {
    return await api.getOrderStatus(Number.parseInt(params.id, 10));
  }, [params]);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  const order = fetcher.data!;

  if (order.status === 'paid') {
    return (
      <OrderStatusCard
        icon="check_circle"
        iconType="success"
        subText={[
          'Your order is completed.',
          'You can view your order history and manage your orders from the Order History page.',
        ]}
        title="Payment success!"
      >
        <Col className="margin-bottom-10" md={3} xs={12}>
          <LinkButton block color="secondary" outline to="/">
            Go home
          </LinkButton>
        </Col>
      </OrderStatusCard>
    );
  }

  if (!order.paymentError) {
    return (
      <OrderStatusCard
        icon="sync_problem"
        iconType="warning"
        subText={[
          'The payment is not complete, but there are no payment erros on record.',
          `Try to refresh the page, or contact support. (Order#${params.id})`,
        ]}
        title="Payment error"
      >
        <Col className="margin-bottom-10" md={3} xs={12}>
          <LinkButton block color="secondary" outline to="/cart">
            Go back
          </LinkButton>
        </Col>
      </OrderStatusCard>
    );
  }

  return (
    <OrderStatusCard
      icon="error"
      iconType="danger"
      subText={[
        'Your payment could not completed.',
        `${order.paymentError.error} (${order.paymentError.code})`,
        `Go back to the checkout page to try again.`,
      ]}
      title="Payment error"
    >
      <Col className="margin-bottom-10" md={3} xs={12}>
        <LinkButton block color="secondary" outline to="/cart">
          Go back
        </LinkButton>
      </Col>
    </OrderStatusCard>
  );
};

interface OrderStatusCardProps {
  readonly icon: string;
  readonly iconType: IconColor;
  readonly title: string;
  readonly subText: string[];
}

const OrderStatusCard: FC<OrderStatusCardProps> = ({
  icon,
  iconType,
  title,
  subText,
  children,
}) => {
  return (
    <Row className="justify-content-center">
      <Col className="margin-bottom-10" id="cartComplete" lg={10} xs={12}>
        <Card className="margin-bottom-10">
          <CardBody className="text-center">
            <MaterialIcon large name={icon} type={iconType} />
            <h3 className={iconType === 'success' ? 'text-success' : ''}>{title}</h3>
            {subText.map((t) => (
              <p key={t}>{t}</p>
            ))}
            <Row className="justify-content-center">{children}</Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
