/**
 * Calculates the difference in years of 2 dates
 */
export function yearDiff(from: Date, to: Date): number {
  return Math.abs(new Date(to.getTime() - from.getTime()).getUTCFullYear() - 1970);
}

export function timeAdjust(seconds: number): Date {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  return date;
}
