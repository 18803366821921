import React from 'react';

interface ProfilePictureProps {
  readonly url: string;
}

export const UserProfilePicture = ({ url }: ProfilePictureProps): JSX.Element => {
  return (
    <img
      referrerPolicy="no-referrer"
      src={url}
      style={{ float: 'left', paddingRight: '10px', maxWidth: '64px' }}
    />
  );
};
