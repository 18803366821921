import { randInt, randomChance, randomSample } from './';

const memeSuffix = [
  '420yoloswag',
  'xXx',
  'lololol',
  ' (in Spanish)',
  'topia',
  ', esq.',
  '420',
  " )'(",
  ' :3',
  '69',
  ' <3',
  '~~~~',
  ' McGee',
  '.com',
  '.xxx',
  ', Inc.',
  ', LLC',
  ', GmBH',
  randInt(10, 100).toString(),
  randInt(100, 1000).toString(),
  randInt(1000, 10_000).toString(),
];

const adjective = [
  '[adjective]',
  '[adjective]',
  '[REDACTED]',
  // Colors
  'Black',
  'Blue',
  'Bronze',
  'Gold',
  'Green',
  'Orange',
  'Purple',
  'Red',
  'Silver',
  'Yellow',
  // Flavors
  'Apple',
  'Cherry',
  'Chocolate',
  'Coffee',
  'Covfefe',
  'Grape',
  'Mango',
  'Peach',
  'Raspberry',
  'Vanilla',
  'Watermelon',
  // Uncategorized
  'Blaze',
  'Callous',
  'Callypigian',
  'Chance',
  'Charming',
  'Chewy',
  'Cocky',
  'Complex',
  'Confused',
  'Corn-like',
  'Cranky',
  'Creeper',
  'Cynical',
  'Dancing',
  'Defiant',
  'Derping',
  'Dogmatic',
  'Dynamic',
  'Eastern',
  'Emo',
  'Energetic',
  'Flaming',
  'Flightless',
  'Fluffy',
  'Freckled',
  'Frilly',
  'FullConsent',
  'Furious',
  'Furry',
  'Fuzzy',
  'Gassy',
  'Generic',
  'Heroic',
  'Hippy',
  'Hoarse',
  'Hollow',
  'Huff',
  'Hungry',
  'Husky',
  'Hygenic',
  'Irritable',
  'Judgemental',
  'Large',
  'Lycan',
  'Magnificent',
  'Malevolent',
  'Massive',
  'Mechanical',
  'Meta',
  'Mighty',
  'Miniature',
  'Moist',
  'Mountain',
  'Mysterious',
  'Northern',
  'Perky',
  'Plastered',
  'Playful',
  'Raw',
  'Reflective',
  'Regal',
  'Round',
  'Rowdy',
  'Scaly',
  'Shy',
  'Sinister',
  'Singing',
  'Singing',
  'Singing',
  'Sleepy',
  'Slick',
  'Slimy',
  'Slinking',
  'Slinky',
  'Slippery',
  'Smol',
  'Snuggle',
  'Soft',
  'Southern',
  'Spoopy',
  'Stinky',
  'Stoic',
  'SWAG',
  'Swol',
  'Thicc',
  'Thick',
  'Ticklish',
  'Trash',
  'Underground',
  'Wannabe',
  'Western',
  'Wild',
];

const species = [
  '[REDACTED]',
  '[species]',
  '[species]',
  'Alligator',
  'Anthropomorphic Airplane',
  'Anthropomorphic Canteloupe',
  'Anthropomorphic Car',
  'Anthropomorphic Sandwich',
  'Arctic Fox',
  'Basilisk',
  'Bat',
  'Bear',
  'Birb',
  'Biscuit',
  'Blue-Footed Booby',
  'Bunny',
  'Capybara',
  'Caracal',
  'Caribou',
  'Catfrog',
  'Cheetah',
  'Civet',
  'Collie',
  'Cow',
  'Coyote',
  'Creeper',
  'Crow',
  'Crumpet',
  'Crux',
  'Deer',
  'Demon',
  'Dingo',
  'Dog',
  'Doggo',
  'Dragon',
  'Eagle',
  'Emu',
  'Falcon',
  'Fennec',
  'Ferret',
  'Folf',
  'Fox',
  'Fox',
  'Fur',
  'Ghost',
  'Giant Panda',
  'Gorilla',
  'Gryphon',
  'Hamster',
  'Hawk',
  'Hippo',
  'Horse',
  'Husky',
  'Jaguar',
  'Kingfisher',
  'Kitsune',
  'Kitty',
  'Leopard',
  'Liger',
  'Lizard',
  'Lobster',
  'Magpie',
  'Minotaur',
  'Monolith',
  'Muskrat',
  'Ocelot',
  'Octopus',
  'Otter',
  'Otter',
  'Owl',
  'Pangolin',
  'Penguin',
  'Pigeon',
  'Polecat',
  'Pronghorn',
  'Rabbit',
  'Raccoon',
  'Raven',
  'Red Panda',
  'Rhino',
  'Saber',
  'Salmon',
  'Sergal',
  'Shag Carpet',
  'Shep',
  'Skunk',
  'Slider',
  'Snake',
  'Sparkle Dog',
  'Squirrel',
  'Thylacine',
  'Titmouse',
  'Turkey',
  'Turtle',
  'Vicuna',
  'Were Orca',
  'Werewolf',
  'Whooping Crane',
  'Wolf',
  'Wolf',
  'Woodpecker',
];

// wolf-specific names
const wolfNames = [
  'Black',
  'Blaze',
  'Blood',
  'Bronze',
  'Chaos',
  'Crooked',
  'Dancing',
  'Dawn',
  'Demon',
  'Drift',
  'Dusk',
  'Echo',
  'Fast',
  'Fire',
  'Forest',
  'Frost',
  'Ghost',
  'Gold',
  'Graceful',
  'Grey',
  'Howl',
  'Lone',
  'Majestic',
  'Moon',
  'Moon',
  'Moon',
  'Moon',
  'Mountain',
  'Mystic',
  'Rain',
  'Red',
  'River',
  'Rogue',
  'Shade',
  'Shadow',
  'Sharp',
  'Shy',
  'Silver',
  'Singing',
  'Singing',
  'Singing',
  'Snow',
  'Snuggle',
  'Stalking',
  'Sun',
  'White',
  'Wild',
  'Wild',
  'Wolf',
  'Wuff',
];

const wolfSubSpecies = [
  'alpha',
  'arrow',
  'blade',
  'claw',
  'dawn',
  'dusk',
  'fang',
  'feather',
  'fire',
  'frost',
  'fur',
  'howl',
  'moon',
  'moon',
  'moon',
  'omega',
  'paw',
  'pup',
  'rain',
  'shadow',
  'shine',
  'snow',
  'song',
  'song',
  'stone',
  'storm',
  'tail',
  'tear',
  'thorn',
  'tooth',
  'wolf',
  'wuff',
];

interface BadgeLabels {
  badgeName: string;
  species: string;
}

function generateRandomWolfName(): BadgeLabels {
  return {
    badgeName: `${randomSample(wolfNames)}${randomSample(
      wolfSubSpecies,
    ).toLowerCase()} ${randomSample(wolfNames)}${randomSample(wolfSubSpecies).toLowerCase()}`,
    species: 'Wolf',
  };
}

export function generateRandomName(data: BadgeLabels): BadgeLabels {
  let output = { ...data };
  let rndSpecies = randomSample(species);
  if (data.species.toUpperCase().includes('WOLF') && randomChance(0.85)) {
    output = generateRandomWolfName();
  } else {
    output.species = rndSpecies;
    // split the species into an array of words
    const words = rndSpecies.split(' ');
    // and fetch the last word from that array
    rndSpecies = words.at(-1)!;
    output.badgeName = randomSample(adjective) + rndSpecies.toLowerCase();

    if (Math.random() > 0.8) {
      output.badgeName += randomSample(memeSuffix);
    }
  }

  return output;
}
