import WebFont from 'webfontloader';
import { NodeMap } from '../../../../modules/badgerenderer/BadgeRender';

/**
 * Make sure font files are not loaded more than once
 */
const loadingFonts: Record<string, Promise<void> | undefined> = {};

export async function loadFonts(nodes: NodeMap): Promise<void> {
  const fontsToLoad: Promise<void>[] = [];

  for (const node of Object.values(nodes)) {
    if (!node || node.type !== 'text' || node.fontFamily !== 'custom') {
      continue;
    }

    const { customFontFamily } = node;

    if (!customFontFamily) {
      continue;
    }

    fontsToLoad.push(
      (loadingFonts[customFontFamily] ??= new Promise((resolve, reject) => {
        WebFont.load({
          fontactive: () => {
            resolve();
          },
          fontinactive: () => {
            reject(new Error(`Could not load font ${customFontFamily}`));
          },
          google: {
            families: [customFontFamily],
          },
        });
      })),
    );
  }

  await Promise.all(fontsToLoad);
}
