import React, { FC, useCallback, useState } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { useConfig, useConvention } from '../utils';
import { MaterialIcon, TermsLoader } from '.';

export enum AgeCheckResult {
  Adult,
  Teen,
  Underage,
}

interface AgeCheckProps {
  onSelect(res: AgeCheckResult): void;
}

function yearsAgo(date: Date, years: number): Date {
  const ret = new Date(date);
  ret.setFullYear(date.getFullYear() - years);
  return ret;
}

export const AgeCheck: FC<AgeCheckProps> = ({ onSelect }) => {
  const convention = useConvention();

  if (convention.identityVerification !== 'none') {
    return <AgeRestrictionBarrier onSelect={onSelect} />;
  }

  return <AllAgeCon onSelect={onSelect} />;
};

const AgeRestrictionBarrier: FC<AgeCheckProps> = ({ onSelect }) => {
  const [underage, setUnderage] = useState(false);
  const selectAdult = useCallback(() => {
    onSelect(AgeCheckResult.Adult);
  }, []);

  const selectUnderage = useCallback(() => {
    setUnderage(true);
    onSelect(AgeCheckResult.Underage);
  }, [onSelect]);

  const { startAt, minorAgeThreshold } = useConvention();
  const config = useConfig();
  const date = startAt.getUTCDate();
  const month = startAt.toLocaleDateString('en-US', { month: 'long', timeZone: 'UTC' });
  const year = startAt.getUTCFullYear();

  if (underage) {
    return (
      <Card>
        <CardBody className="text-center">
          <MaterialIcon large name="error" type="danger" />
          <CardTitle>We're Sorry</CardTitle>
          <CardText>
            <p>This is a {minorAgeThreshold}+ convention.</p>
            <p>
              If you are going to be {minorAgeThreshold}+ during the time of the convnetion, please
              contant <strong>{config.contact.email.registration}</strong>.
            </p>
          </CardText>
          <Button
            block
            color="primary"
            onClick={() => {
              setUnderage(false);
            }}
            style={{ marginTop: '20px' }}
          >
            Go back
          </Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody className="text-center">
        <CardTitle>Age Check</CardTitle>
        <CardText>
          We just need to confirm you are {minorAgeThreshold}+ years old before you can register an
          account.
        </CardText>
        <Row className="justify-content-center">
          <Col className="margin-bottom-10" lg={8} onClick={selectAdult} xs={12}>
            <Button block color="primary" id="age-option-adult">
              I was born before {month} {date}, {year - minorAgeThreshold} (Over {minorAgeThreshold}
              )
            </Button>
          </Col>
          <Col className="margin-bottom-10" lg={8} onClick={selectUnderage} xs={12}>
            <Button block color="primary" id="age-option-teen">
              I was born after {month} {date}, {year - minorAgeThreshold} ({minorAgeThreshold - 1}{' '}
              years old or under)
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const AllAgeCon: FC<AgeCheckProps> = ({ onSelect }) => {
  const [underage, setUnderage] = useState(false);
  const selectAdult = useCallback(() => {
    onSelect(AgeCheckResult.Adult);
  }, []);

  const selectTeen = useCallback(() => {
    onSelect(AgeCheckResult.Teen);
  }, []);

  const selectUnderage = useCallback(() => {
    setUnderage(true);
    onSelect(AgeCheckResult.Underage);
  }, [onSelect]);

  const { startAt, timeZone, childAgeThreshold, minorAgeThreshold } = useConvention();

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'long',
    timeZone,
    year: 'numeric',
  });

  if (underage) {
    return <UnderAgeWarning />;
  }

  let cutoffDate = yearsAgo(startAt, childAgeThreshold);
  if (cutoffDate > yearsAgo(new Date(), 13)) {
    cutoffDate = yearsAgo(new Date(), 13);
  }

  const cutoffDateStr = dateFormatter.format(cutoffDate);
  return (
    <Card>
      <CardBody className="text-center">
        <CardTitle>Age Check</CardTitle>
        <CardText>We just need to confirm your age before you can register an account.</CardText>
        <Row className="justify-content-center">
          <Col className="margin-bottom-10" lg={8} onClick={selectAdult} xs={12}>
            <Button block color="primary" id="age-option-adult">
              I was born before {dateFormatter.format(yearsAgo(startAt, minorAgeThreshold))} (Over{' '}
              {minorAgeThreshold})
            </Button>
          </Col>
          {childAgeThreshold < minorAgeThreshold && (
            <Col className="margin-bottom-10" lg={8} onClick={selectTeen} xs={12}>
              <Button block color="primary" id="age-option-teen">
                I was born <strong>before</strong> {cutoffDateStr} ({childAgeThreshold} to{' '}
                {minorAgeThreshold} years old)
              </Button>
            </Col>
          )}
          <Col className="margin-bottom-10" lg={8} onClick={selectUnderage} xs={12}>
            <Button block color="primary" id="age-option-underage">
              I was born <strong>after</strong> {cutoffDateStr} (Under {childAgeThreshold})
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const UnderAgeWarning: FC = () => {
  return (
    <Card>
      <CardBody className="text-center">
        <MaterialIcon large name="error" type="danger" />
        <CardTitle>We're Sorry</CardTitle>
        <CardText>
          <TermsLoader name="childRegistrationError" />
        </CardText>
      </CardBody>
    </Card>
  );
};
