import React, { FC, ReactNode } from 'react';
import { CardBody, CardFooter, CardHeader } from 'reactstrap';

export interface CardGroupProps {
  readonly header?: ReactNode;
  readonly footer?: ReactNode;
}

/**
 * Simple way of construction the reactstrap card.
 * https://reactstrap.github.io/components/card/
 *   <Card>                     <Card>
 *      <CardGroup                 <CardHeader/>
 *         header=""               <CardBody/>
 *         footer="">    --->      <CardFooter/>
 *         body                  </Card>
 *      </CardGroup>
 *    </Card>
 */
export const CardGroup: FC<CardGroupProps> = ({ header, children, footer }) => (
  <>
    {header && <CardHeader>{header}</CardHeader>}
    {children && <CardBody>{children}</CardBody>}
    {footer && <CardFooter>{footer}</CardFooter>}
  </>
);
