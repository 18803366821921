import React, { Fragment } from 'react';
import { Button, Card, CardBody, CardText, Col, FormText, Row } from 'reactstrap';
import { MaterialIcon } from '.';

export interface ElementListItem {
  id: number;
  name: string;
}

interface ElementListProps {
  readonly elements: ElementListItem[];
  onDelete?(id: number): void;
}

export const ElementList = ({ elements, onDelete }: ElementListProps): JSX.Element => {
  if (elements.length === 0) {
    return (
      <Card>
        <CardBody>
          <FormText color="muted">No results found.</FormText>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardText>
          <Row>
            {elements.map((element) => (
              <Fragment key={element.id}>
                {onDelete && (
                  <Col lg={2} xs={12}>
                    <Button
                      block
                      color="danger"
                      id={`deleteElement${element.id}`}
                      onClick={() => {
                        onDelete(element.id);
                      }}
                      outline
                      type="submit"
                    >
                      <MaterialIcon name="delete" />
                    </Button>
                  </Col>
                )}
                <Col lg={onDelete ? 10 : 12} style={{ marginTop: '6px' }} xs={12}>
                  {element.name}
                </Col>
                <Col xs={12}>
                  <hr />
                </Col>
              </Fragment>
            ))}
          </Row>
        </CardText>
      </CardBody>
    </Card>
  );
};
