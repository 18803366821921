import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { SupportAgentBanner, UserRoute } from '../../components';
import { checkPermission, useTypeSequence, useUser } from '../../utils';
import {
  CashierContext,
  CashierContextProps,
  CashierSettingsModel,
  defaultContext,
  getBadgePrinterManagers,
  getReceiptPrinterManagers,
  loadCashierSettings,
  saveCashierSettings,
} from './cashiercontext';
import { Header } from './header';
import { ScannerContext, useCreateScannerContext } from './scanner';
import { CashierHome, CashierProfile } from '.';

export const CashierComponent: FC = () => {
  const history = useHistory();
  const user = useUser();
  const printerManagers = useMemo(() => getBadgePrinterManagers(), []);
  const receiptPrinterManagers = useMemo(() => getReceiptPrinterManagers(), []);

  const [cashierSettings, setCashierSettings] = useState<CashierSettingsModel>(() => {
    return loadCashierSettings(defaultContext);
  });

  useEffect(() => {
    saveCashierSettings(cashierSettings);
  }, [cashierSettings]);

  useTypeSequence(
    '@ANSI',
    'Enter',
    (value) => {
      if (!user || !checkPermission(user, 'registration:cashier')) {
        return;
      }

      history.push(`/housekeeping/cashier?term=${encodeURIComponent(value)}`);
    },
    [user, history],
  );

  const updateCashierSettings = useCallback(
    (settings: CashierSettingsModel) => {
      setCashierSettings({
        ...cashierSettings,
        ...settings,
      });
    },
    [setCashierSettings, cashierSettings],
  );

  const settings = useMemo<CashierContextProps>(
    () => ({
      printerManagers,
      receiptPrinterManagers,
      config: cashierSettings,
      updateCashierSettings,
    }),
    [cashierSettings, updateCashierSettings],
  );

  const scannerContext = useCreateScannerContext();

  return (
    <CashierContext.Provider value={settings}>
      <ScannerContext.Provider value={scannerContext}>
        <div>
          <Header />
          <SupportAgentBanner />
          <Switch>
            <UserRoute
              component={CashierHome}
              exact
              path="/housekeeping/cashier"
              requirePermissions={['registration:cashier']}
            />
            <UserRoute
              component={CashierProfile}
              exact
              path="/housekeeping/cashier/:id"
              requirePermissions={['registration:cashier']}
            />
          </Switch>
        </div>
      </ScannerContext.Provider>
    </CashierContext.Provider>
  );
};
