import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { EventRegistration, UserStateComponent } from '../../components';
import { RegistrationInfo } from '../../models';
import { fthrow, isResourceError, useUser } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { registrationClosedHandler } from './RegistrationClosed';

export const NewRegistration: FC = () => {
  const user = useUser();
  return (
    <UserStateComponent>
      <LoadingWrapper<RegistrationInfo | undefined, number>
        dataFetcher={async (id) =>
          await api
            .getUserActiveRegistration(id)
            .catch((error: Error) =>
              isResourceError(error, 'Registration') ? undefined : fthrow(error),
            )
        }
        errorDisplay={registrationClosedHandler}
        inline
        passback={user?.id}
      >
        {(data) => (data?.id ? <Redirect to="/event/register/edit" /> : <EventRegistration />)}
      </LoadingWrapper>
    </UserStateComponent>
  );
};
