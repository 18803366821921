import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import * as Reactstrap from 'reactstrap';

interface BreadcrumbProps {
  readonly items: BreadcrumbItemProps[];
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ items }) => {
  return (
    <Reactstrap.Breadcrumb>
      {items.map((item) => (
        <BreadcrumbItem key={`${String(item.active)}${item.text}${item.url ?? ''}`} {...item} />
      ))}
    </Reactstrap.Breadcrumb>
  );
};

export interface BreadcrumbItemProps {
  readonly active?: boolean;
  readonly text: string;
  readonly url?: string;
}

export const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ active, text, url }) => {
  if (active || !url) {
    return <Reactstrap.BreadcrumbItem active>{text}</Reactstrap.BreadcrumbItem>;
  }

  return (
    <Reactstrap.BreadcrumbItem>
      <Link to={url}>{text}</Link>
    </Reactstrap.BreadcrumbItem>
  );
};
