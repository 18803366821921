import React, { FC } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { MaterialIcon } from '.';

export const AccessDenied: FC = () => {
  return (
    <Row className="justify-content-center" id="accessDenied">
      <Col lg={6} xs={12}>
        <Card className="danger">
          <CardBody className="text-center">
            <MaterialIcon large name="block" type="danger" />
            <CardText>You do not have the required permissions to view this page.</CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
