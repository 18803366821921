import React, { FC } from 'react';
import { UserStateComponent } from '../../../components';
import { useUser } from '../../../utils';
import { VolunteerFormComponent } from './form';

export const VolunteerComponent: FC = () => {
  return (
    <UserStateComponent>
      <VolunteerFormComponent isAdmin={false} user={useUser()!} />
    </UserStateComponent>
  );
};
