import { CurrentUser } from '../../../shared/user/base';
import { PaymentGatewayConfig } from '../../APIClient';

export const enum PaymentState {
  Pending = 0,
  Processing,
  Success,
  Failed,
  Declined,
  Cancelled,
}

export interface PaymentProps {
  total: number;
  conventionName: string;
  user: CurrentUser;
  paymentConfig: PaymentGatewayConfig;
  onUpdate(state: PaymentState, message?: string): void;
}
