import React, { FC } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PageHeader, UserStateComponent } from '../../components';
import { NeosVRLoginButton } from './sociallink/neosvr';

import './sociallinks.scss';

export const SocialLinks: FC = () => {
  return (
    <UserStateComponent>
      <PageHeader>Social Links</PageHeader>
      <Row className="justify-content-center">
        <Col className="text-center" lg={8} xs={12}>
          <p>
            Connect your external accounts to enable access to additional features and benefits
            offered by the event.
          </p>
        </Col>
        <Col xs={12} />
        <Col lg={3} xs={6}>
          <Card>
            <CardBody className="text-center social-link" id="social-link-neos">
              <Row className="justify-content-center">
                <Col className="social-link-logo" lg={8} xs={12}>
                  <img src="https://cdn.concat.app/logo/neos.png" />
                </Col>
                <Col className="social-link-name" lg={8} xs={12}>
                  Neos
                </Col>
                <Col lg={8} xs={12}>
                  <NeosVRLoginButton fullText={false} target="neosvr" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
