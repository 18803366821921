import React, { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ProductEligibility, ProductModel } from '../../../shared/orders/product';
import { UserStateComponent } from '../../components';
import { isResourceError, useUser } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { ProductPage } from './productPage';

interface RouterParams {
  id: string;
}
interface ProductLoad {
  product: ProductModel;
  eligibility: ProductEligibility;
}

async function loadProduct(userId: number, productId: number) {
  const [product, eligibility] = await Promise.all([
    api.getPublicProductById(productId),
    api.getProductEligibility(userId, productId),
  ]);

  return { product, eligibility };
}

export const ProductPageHOC: FC<RouteComponentProps<RouterParams>> = ({ match: { params } }) => {
  const user = useUser()!;
  return (
    <UserStateComponent>
      <LoadingWrapper<ProductLoad, number>
        dataFetcher={async (id) => await loadProduct(user.id, id)}
        errorDisplay={(err) => (isResourceError(err, 'Product') ? <Redirect to="/store" /> : false)}
        inline
        passback={Number.parseInt(params.id, 10)}
      >
        {({ product, eligibility }, refresh) =>
          product.category.isStore ? (
            <ProductPage eligibility={eligibility} onBackToCart={refresh} product={product} />
          ) : (
            <Redirect to="/store" />
          )
        }
      </LoadingWrapper>
    </UserStateComponent>
  );
};
