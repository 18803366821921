/* eslint-disable import/no-unused-modules */
import React, { FC, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useBoolState } from '../utils';
import { captureError } from '../utils/errorHandling';

interface ActionButtonProps {
  readonly id: string;
  readonly title: string;
  readonly children: React.ReactNode;
  // Button inside of the modal
  readonly actionContent: React.ReactNode;
  // Button the user has to click
  readonly buttonContent: React.ReactNode;
  readonly color: 'danger' | 'primary' | 'secondary' | 'success';
  readonly block?: boolean;
  readonly outline?: boolean;
  readonly className?: string | undefined;
  onComplete(): Promise<void>;
}

export const ActionButtonModal: FC<ActionButtonProps> = (props) => {
  const [isOpen, openModal, closeModal] = useBoolState(false);

  const complete = useCallback(async () => {
    try {
      await props.onComplete();
      closeModal();
    } catch (error) {
      captureError(error as Error);
    }
  }, [props.onComplete, closeModal]);

  return (
    <>
      <Button
        block={props.block}
        className={props.className}
        color={props.color}
        id={props.id}
        onClick={openModal}
        outline={props.outline}
      >
        {props.buttonContent}
      </Button>
      <Modal id={`${props.id}Modal`} isOpen={isOpen}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>
          <Button color={props.color} id="complete" onClick={complete}>
            {props.actionContent}
          </Button>{' '}
          <Button color="secondary" id="cancel" onClick={closeModal} outline>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
