import { History } from 'history';
import React, { Component, FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import * as OrderSummary from '../../../shared/orders/model';
import { ProductCategoryModel } from '../../../shared/orders/product';
import { CurrentUser } from '../../../shared/user/base';
import { PaymentGatewayConfig } from '../../APIClient';
import {
  ActionButton,
  Loading,
  LoadingError,
  MaterialIcon,
  PageHeader,
  UserStateComponent,
} from '../../components';
import { paymentGateways } from '../../components/PaymentGateway';
import { PaymentState } from '../../components/payment';
import { GlobalState } from '../../services';
import { classNames, isResourceError, sumBy, useConvention, useUser } from '../../utils';
import { LoadingState } from '../../utils/LoadingState';
import { cToUsdStrPref } from '../../utils/cToUsdStr';
import { captureError } from '../../utils/errorHandling';
import { OrderItemProduct, renderOrderSummary, VoucherComponent } from '.';

import './cart.scss';

interface CartState {
  gateway?: PaymentGatewayConfig;
  error?: string;
  categories?: ProductCategoryModel[];
  order?: OrderSummary.Order;
  status: LoadingState;
  paymentState: PaymentState;
  paymentStateMessage?: string;
  failureCount: number;
}

interface CartStateProps {
  user: CurrentUser | null;
}

interface CartProps {
  history?: History;
  onProcessing?(): void;
  onSuccess?(): void;
  onError?(): void;
}

class Cart extends Component<CartProps & CartStateProps, CartState> {
  public override state: CartState = {
    paymentState: PaymentState.Pending,
    status: LoadingState.Loading,
    failureCount: 0,
  };

  public override async componentDidMount(): Promise<void> {
    await this.performAPICalls();
  }

  public override render(): JSX.Element {
    const { error, status } = this.state;
    return (
      <UserStateComponent>
        {status === LoadingState.Loading && <Loading inline />}
        {status === LoadingState.Error && <LoadingError errorText={error} />}
        {status === LoadingState.Done && this.renderPage()}
      </UserStateComponent>
    );
  }

  private async performAPICalls(keepOldOrder?: boolean): Promise<void> {
    try {
      const { order: oldOrder } = this.state;

      const [categories, order, gateway] = await Promise.all([
        api.getProductCategories(),
        oldOrder && keepOldOrder
          ? api.getOrderById(oldOrder.id)
          : api.getActiveOrder(this.props.user!.id),
        api.getPaymentGateways(),
      ]);

      this.setState({
        categories,
        gateway,
        order,
        status: LoadingState.Done,
      });

      if (oldOrder && keepOldOrder && order.status === 'paid') {
        this.updateOrderState(PaymentState.Success, 'Your payment has been successful! Thank you!');
      }
    } catch (error) {
      if (isResourceError(error, 'Order')) {
        this.setState({
          status: LoadingState.Done,
        });

        return;
      }

      this.setState({
        error: (error as Error).message,
        status: LoadingState.Error,
      });
    }
  }

  private renderPage(): JSX.Element {
    const { order } = this.state;
    const hasOrder =
      order && (order.orderItems.length > 0 || (order.fees && order.fees.length > 0));

    return (
      <>
        <PageHeader>Checkout</PageHeader>
        <Row className="justify-content-center">
          {hasOrder ? this.renderOrder() : this.renderEmptyCart()}
        </Row>
      </>
    );
  }

  private renderOrder(): JSX.Element {
    const { categories, paymentState } = this.state;
    const order = this.state.order!;
    const items = order.orderItems;
    const isUnpaid = paymentState !== PaymentState.Success && order.status !== 'paid';
    return (
      <>
        <Col className="margin-bottom-10 order" lg={6} xs={12}>
          <Card>
            <CardBody>
              <h4>
                {items.length} Item{items.length === 1 ? '' : 's'}
              </h4>
              <hr />
              {order.orderItems.map((item) => (
                <OrderItemProduct
                  item={item}
                  key={item.id}
                  onUpdate={async () => await this.performAPICalls(true)}
                  order={order}
                  readOnly={!isUnpaid}
                />
              ))}
              {order.fees && order.fees.length > 0 && (
                <Fragment key="orderFees">
                  <div className="order-line">
                    <div className="order-item">
                      <div className="order-item-description">Fees, Charges, and Fines</div>
                      <div className="order-item-price">
                        {cToUsdStrPref(sumBy(order.fees, (fee) => fee.amount))}
                      </div>
                    </div>
                  </div>
                  <hr />
                </Fragment>
              )}
            </CardBody>
          </Card>
          <Alert className="margin-top-10" color="warning">
            <strong>Important</strong>
            <br />
            When making payments, please do not use the refresh or back buttons on your browser.
          </Alert>
          <Card className="margin-top-10" id="transactionSecurity">
            <CardBody>
              <h2>Transaction Security</h2>
              <hr />
              <p>
                Our payment processor implements Verified by Visa and MasterCard SecureCode security
                measures to protect against unauthorized use of your cards.
              </p>
              <p>
                If your financial institution participates in either of these programs, you may see
                a dialog asking you to verify your identity to complete the transaction. This dialog
                will vary depending on your financial institution.
              </p>
              <p>
                If you have any concerns or issues, please contact your financial institution using
                the customer service number on the back of your card.
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} xs={12}>
          <Card className="margin-bottom-10">
            <CardBody>
              <h4>Order Summary</h4>
              <hr />
              {renderOrderSummary({
                categories: categories!,
                isCustomer: true,
                order,
                paymentState,
              })}
            </CardBody>
          </Card>
          {this.renderPaymentState()}
          {isUnpaid && this.showPaymentOptions()}
        </Col>
      </>
    );
  }

  private showPaymentOptions(): JSX.Element | null {
    const { breakdown, ...order } = this.state.order!;
    const total = breakdown.total - breakdown.paid;
    const isFreeOrder = breakdown.subtotal === 0;

    if (this.state.paymentState === PaymentState.Cancelled) {
      return null;
    }

    if (total === 0) {
      return (
        <Card className="margin-bottom-10">
          <CardHeader>{isFreeOrder ? 'Confirm Order' : 'Pay with Voucher Balance'}</CardHeader>
          <CardBody>
            <ActionButton
              action={`/api/orders/${order.id}/settle`}
              block
              color="primary"
              id="confirmVoucherOrder"
              method="post"
              onFailure={captureError}
              onSuccess={async () => await this.performAPICalls(true)}
              payload={{}}
            >
              Confirm Order
            </ActionButton>
          </CardBody>
        </Card>
      );
    }

    return (
      <>
        <VoucherComponent
          onVoucherApplied={async () => await this.performAPICalls(true)}
          orderId={order.id}
        />
        <PaymentCard
          gatewayConfig={this.state.gateway}
          paymentState={this.state.paymentState}
          total={total}
          updateOrderState={(state, message) => this.updateOrderState(state, message)}
        />
      </>
    );
  }

  private updateOrderState(paymentState: PaymentState, paymentStateMessage?: string): void {
    let failureCount = this.state.failureCount;
    const { onProcessing, onSuccess, onError } = this.props;

    switch (paymentState) {
      case PaymentState.Processing: {
        if (onProcessing) {
          onProcessing();
        }

        break;
      }

      case PaymentState.Success: {
        if (onSuccess) {
          onSuccess();
        }

        break;
      }

      case PaymentState.Cancelled:
      case PaymentState.Failed:
      case PaymentState.Declined: {
        if (onError) {
          onError();
        }

        failureCount++;

        break;
      }

      case PaymentState.Pending: {
        break;
      }
    }

    this.setState({
      paymentState,
      paymentStateMessage,
      failureCount,
    });
  }

  private renderPaymentState(): JSX.Element | null {
    const { failureCount, paymentState, paymentStateMessage } = this.state;

    if ([PaymentState.Processing, PaymentState.Pending].includes(paymentState)) {
      return null;
    }

    const success = paymentState === PaymentState.Success;

    return (
      <Card
        className={classNames(
          {
            'bg-danger': !success,
            'bg-success': success,
          },
          'text-white margin-bottom-10 payment-status-card',
        )}
        id={success ? 'paymentComplete' : 'paymentFailed'}
      >
        <CardBody>
          {paymentState === PaymentState.Declined && (
            <p>
              <strong>Your payment was declined</strong> by our payment processor:
            </p>
          )}
          {paymentStateMessage}
          {failureCount >= 3 && (
            <div className="payment-status-repeated-failures">
              <hr />
              <p>
                <small>
                  If you repeatedly see this message, you may want to contact the event and your
                  financial institution for assistance.
                </small>
              </p>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  private renderEmptyCart(): JSX.Element {
    return (
      <Col className="text-center" id="cartEmpty" lg={8} xs={12}>
        <MaterialIcon large name="shopping_cart" />
        <h4>Nothing in your cart... yet!</h4>
        {history ? (
          <>
            <p>Let's find you an event ticket or some swag!</p>
            <Row className="justify-content-center">
              <Col lg={5} xs={12}>
                <Button block color="primary" outline tag={Link} to="/event/register">
                  Go to Registrations
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <p>
            If you were expecting something to be here, try refreshing the page and starting again.
          </p>
        )}
      </Col>
    );
  }
}

const connectedCart = connect<CartStateProps, {}, CartProps, GlobalState>(({ user }, ownProps) => ({
  ...ownProps,
  user,
}))(Cart);

export { connectedCart as Cart };

interface PaymentCardProps {
  readonly gatewayConfig?: PaymentGatewayConfig;
  readonly paymentState: PaymentState;
  readonly total: number;
  updateOrderState(paymentState: PaymentState, paymentStateMessage?: string): void;
}

const PaymentCard: FC<PaymentCardProps> = (props) => {
  const { updateOrderState, total, paymentState, gatewayConfig } = props;
  const user = useUser();
  const convention = useConvention();

  if (!user) {
    return null;
  }

  for (const gateway of paymentGateways) {
    if (gateway.type === gatewayConfig?.type) {
      return (
        <Card id="paymentCard">
          <CardHeader>Pay by Credit / Debit Card with {gateway.name}</CardHeader>
          {paymentState === PaymentState.Processing && (
            <Loading>
              <p>Processing Payment...</p>
            </Loading>
          )}
          <CardBody>
            <gateway.lazyComponent
              conventionName={convention.longName}
              onUpdate={(state, message) => updateOrderState(state, message)}
              paymentConfig={gatewayConfig}
              total={total}
              user={user}
            />
          </CardBody>
        </Card>
      );
    }
  }

  return (
    <Card>
      <CardHeader>Credit card payment is not configured</CardHeader>
      <CardBody>Credit card payment is not configured</CardBody>
    </Card>
  );
};
