/* eslint-disable max-lines */
import {
  APIClient as BaseAPIClient,
  ListResponse,
  RequestOptions,
} from '@conventioncatcorp/common-fe/dist/APIClient';
import { deepMerge } from '@conventioncatcorp/common-fe/dist/deepMerge';
import { Convention, ConventionListItem } from '../shared/convention';
import {
  DealerArea,
  DealerAssistance,
  DealerForm,
  DealerFullModel,
  DealerModel,
  SeatingPreference,
} from '../shared/dealer';
import { EmailTargets, MailQueueInfo, SendEmailPayload, Senders } from '../shared/email';
import { ExportListItem } from '../shared/export';
import { FormLayout, FormPageModel } from '../shared/kiosk/models';
import { NewSecretModel, OAuthApplicationModel } from '../shared/oauth';
import { Option, OptionInternal, OptionSavePayload, OptionSource } from '../shared/options';
import { OrderSearch } from '../shared/orders';
import { Order, OrderRefund, PaymentGateway } from '../shared/orders/model';
import { OrderPaymentStatus, PrepareChargeResult } from '../shared/orders/payment';
import { ProductCategoryModel, ProductEligibility, ProductModel } from '../shared/orders/product';
import { Raffle, RaffleTicket, RaffleWithUserDetails } from '../shared/orders/raffle';
import { CreateVoucherModel, VoucherListItem, VoucherWithDetails } from '../shared/orders/voucher';
import { PermissionName } from '../shared/permissions';
import { Policy, PolicyCreate, PolicyDelete } from '../shared/policies';
import {
  ConRegistrationForm,
  RegistrationFlagModel,
  RegistrationFlagUser,
  RegistrationUpsert,
} from '../shared/registration/model';
import { SettingTermKeys } from '../shared/settings';
import {
  AttendeeStats,
  DealerStats,
  IdentityStats,
  RegistrationStats,
  SalesReport,
} from '../shared/stats';
import { BadgeData, BadgeDataRequest, BadgeDesign, DesignerNode } from '../shared/user/badgeDesign';
import { CurrentUser } from '../shared/user/base';
import { CashierCheck } from '../shared/user/cashier';
import { ExtendedUser } from '../shared/user/extended';
import { NewIdentitySession, StripeIdentityUser } from '../shared/user/identity';
import { RoleUserListItem, RoleWithPermissions } from '../shared/user/roles';
import { UserSearchUser } from '../shared/user/search';
import { UpdateUserPayload } from '../shared/user/update';
import {
  DepartmentWithLeads,
  VolunteerForm,
  VolunteerLoadModel,
  VolunteerModel,
  VolunteerUpsertModel,
} from '../shared/volunteer';
import { VolunteerConfig } from '../shared/volunteer/config';
import { StaffRegistrationInfo } from '../shared/volunteer/staff';
import { VolunteerStats } from '../shared/volunteer/statst';
import {
  BadgeArt,
  Ban,
  ChildRegistration,
  Config,
  ExportRequest,
  Fee,
  LiveSettings,
  LogResult,
  MenuLink,
  Permission,
  RegistrationInfo,
  Role,
  SocialLink,
  SocialLinkEndpoint,
  Surcharge,
  SurchargeOrderItem,
  SurchargeProduct,
  UserLocation,
  UserNote,
  UserPermissionVerbose,
} from './models';
import { Ticket } from './models/ticket';
import { isAuthError, isResourceError } from './utils';
import { AuthErrorCodes } from './utils/errorHandling';

export interface PaymentGatewayConfig {
  type: 'stripe' | 'stripeConnect';
  publishableKey: string;
  accountId?: string;
}

interface Terms {
  text: string;
}

export type UniqueAttribute = 'email' | 'username';

type BanType = 'dealer' | 'other' | 'registration' | 'staff';

interface BanDetails {
  reason: string;
  type: BanType;
}

export interface BannedUser {
  firstName: string;
  lastName: string;
  preferredName: string;
  username: string;
}

export interface IssuerUser {
  username: string;
}

export interface BanResult extends Ban {
  user: BannedUser;
  issuer: IssuerUser;
}

export interface AttendeeEmails {
  productIds: number[];
  regStatus: { paid: boolean; unpaid: boolean };
  withChildren: boolean;
}

interface OAuthParams {
  access_token: string;
  client_id: string;
}

export interface DataPoint {
  count: number;
  time: number;
}

export interface RegsOverTime {
  perDay: DataPoint[];
  perHour: DataPoint[];
  perQuarterHour: DataPoint[];
}

export interface ScopeInfo {
  header: string;
  icon: string;
  description: string;
  name: string;
}

export interface OAuthApp {
  clientId: number;
  description?: string;
  name: string;
  scopeInfo?: ScopeInfo[];
  userAlreadyAuthorized: boolean;
}

export interface OAuthAuthorization {
  redirectUri: string;
  token: string;
}

interface EmailQueueStatus {
  remaining: number;
}

interface EmailPreview {
  preview: string;
}

interface EmailEstimate {
  count: number;
}

interface ProductCartDeleteParams {
  orderItemId?: number;
  productId?: number;
  referenceId?: number;
}

interface AssetCreateResult {
  assetId: string;
}

export interface Webhook {
  id: number;
  name: string;
  url: string[];
  events: string[];
}

interface PrepareOTPResult {
  url: string;
  image: string;
}

interface PrepareBackupResult {
  codes: string[];
}

export const enum SecondFactorType {
  Otp = 'otp',
  Backup = 'backup',
}
export interface MinimalSecondFactor {
  id: number;
  type: SecondFactorType;
  data: string;
  updatedAt: Date;
}

export type RoleScope = 'convention' | 'global';

export class APIClient {
  public constructor(private readonly base: BaseAPIClient) {}

  public async getSecondFactors(userId: number): Promise<MinimalSecondFactor[]> {
    return await this.request<MinimalSecondFactor[]>(`/api/users/${userId}/2fa`);
  }

  public async deleteSecondFactor(factorId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/2fa/${factorId}`,
    });
  }

  public async prepareSecondFactor(type: SecondFactorType.Otp): Promise<PrepareOTPResult>;
  public async prepareSecondFactor(type: SecondFactorType.Backup): Promise<PrepareBackupResult>;
  public async prepareSecondFactor(type: SecondFactorType): Promise<object> {
    return await this.request<object>({
      method: 'post',
      payload: {
        type,
      },
      url: '/api/2fa',
    });
  }

  public async getWebhooks(): Promise<Webhook[]> {
    return await this.request<Webhook[]>('/api/webhooks');
  }

  public async getWebhookTypes(): Promise<string[]> {
    return await this.request<string[]>('/api/webhooks/types');
  }

  public async getWebhookKey(): Promise<string> {
    return (await this.request<{ pem: string }>('/api/webhooks/key')).pem;
  }

  public async login(usernameOrMail: string, password: string): Promise<void> {
    await this.request({
      method: 'post',
      payload: {
        password,
        usernameOrMail,
      },
      url: '/api/login',
    });
  }

  public async logout(oauth?: OAuthParams): Promise<void> {
    await this.request({ url: '/api/users/logout', query: { ...oauth } });
  }

  public async getActiveUser(): Promise<CurrentUser> {
    return await this.request<CurrentUser>('/api/users/current');
  }

  public async updateUser(
    userId: number,
    user: UpdateUserPayload,
  ): Promise<{ profilePictureUrl: string }> {
    return await this.request({
      url: `/api/users/${userId}`,
      payload: user,
      method: 'patch',
    });
  }

  public async searchUsers(search: string): Promise<UserSearchUser[]> {
    return await this.request<UserSearchUser[]>({
      query: {
        search,
      },
      url: '/api/users',
    });
  }

  public async getUsersByIds(ids: number[]): Promise<UserSearchUser[]> {
    return await this.request<UserSearchUser[]>({
      query: {
        ids: ids.join(','),
      },
      url: '/api/users',
    });
  }

  public async createRegistration(userId: number, payload: RegistrationUpsert): Promise<void> {
    await this.request({
      method: 'post',
      payload,
      url: `/api/users/${userId}/registration`,
    });
  }

  public async updateRegistration(
    regId: number,
    payload: Partial<RegistrationUpsert>,
  ): Promise<void> {
    await this.request({
      method: 'patch',
      payload,
      url: `/api/registrations/${regId}`,
    });
  }

  public async setActiveRegistrationPayment(orderItemId: number): Promise<void> {
    await this.request({
      method: 'patch',
      payload: {},
      url: `/api/orders/items/${orderItemId}/activate`,
    });
  }

  public async setOrderItemsAsFulfilled(orderId: number, orderItemIds: number[]): Promise<void> {
    return await this.request({
      method: 'patch',
      payload: { orderItemIds },
      url: `/api/orders/${orderId}/fulfill`,
    });
  }

  public async deleteRegistrationPayment(userId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/users/${userId}/registration/payment`,
    });
  }

  public async retrieveStripePaymentInfo(): Promise<PrepareChargeResult> {
    return await this.request<PrepareChargeResult>('/api/pay/gateways/external');
  }

  public async getOrderStatus(orderId: number): Promise<OrderPaymentStatus> {
    return await this.request<OrderPaymentStatus>(`/api/orders/${orderId}/status`);
  }

  public async settleOrder(orderId: number): Promise<void> {
    await this.request({
      method: 'post',
      payload: {},
      url: `/api/orders/${orderId}/settle`,
    });
  }

  public async pay(gateway: PaymentGateway, id: string, targetUserId?: number): Promise<void> {
    await this.request({
      method: 'post',
      payload: {
        targetUserId,
        token: id,
      },
      url: `/api/pay/gateways/${gateway}`,
    });
  }

  public async getPaymentGateways(): Promise<PaymentGatewayConfig> {
    return await this.request<PaymentGatewayConfig>('/api/pay/gateways');
  }

  public async retrieveTermsOfAttendance(type: SettingTermKeys): Promise<Terms> {
    return await this.request<Terms>(`/api/policies/${type}`);
  }

  public async getConvention(id: number): Promise<Convention> {
    return await this.request<Convention>(`/api/conventions/${id}`);
  }

  public async getConventionList(): Promise<ConventionListItem[]> {
    return await this.request<ConventionListItem[]>(`/api/conventions`);
  }

  public async updateConvention(id: number, payload: Omit<Convention, 'id'>): Promise<Convention> {
    return await this.request({
      method: 'patch',
      payload,
      url: `/api/conventions/${id}`,
    });
  }

  public async getConfig(): Promise<Config> {
    const [config, userConfig] = await Promise.all([
      this.request<Config>('/api/config'),
      this.request<Partial<Config>>('/api/config/user'),
    ]);

    return deepMerge(config, userConfig) as Config;
  }

  public async getUserDealer(userId: number): Promise<DealerFullModel> {
    return await this.request<DealerFullModel>(`/api/users/${userId}/dealer`);
  }

  public async getUserPreviousDealer(userId: number): Promise<DealerFullModel> {
    return await this.request<DealerFullModel>(`/api/users/${userId}/dealer/previous`);
  }

  public async confirmAccount(code: string): Promise<void> {
    await this.request({
      method: 'patch',
      payload: { code },
      url: '/api/registrations/confirm',
    });
  }

  public async confirmChangeEmail(code: string): Promise<void> {
    await this.request({
      method: 'patch',
      payload: { code },
      url: '/api/registrations/email',
    });
  }

  public async pendingEmailChange(userId: number): Promise<{ pending: boolean }> {
    return await this.request(`/api/users/${userId}/email/pending`);
  }

  public async checkUserAttributeUnique(value: string, field: UniqueAttribute): Promise<boolean> {
    try {
      // If exists, 200. If not found, 404.
      await this.request(`/api/users/${field}/${encodeURIComponent(value)}`);
      return false;
    } catch (error) {
      if (!isResourceError(error)) {
        throw error;
      }

      return true;
    }
  }

  public async getVouchers(): Promise<VoucherListItem[]> {
    return await this.request('/api/vouchers');
  }

  public async getVoucher(voucherId: number): Promise<VoucherWithDetails> {
    return await this.request(`/api/vouchers/${voucherId}`);
  }

  public async createVoucher(payload: CreateVoucherModel): Promise<{ id: number }> {
    return await this.request<{ id: number }>({
      method: 'post',
      payload,
      url: `/api/vouchers`,
    });
  }

  public async getProductEligibility(
    userId: number,
    productId: number,
  ): Promise<ProductEligibility> {
    return await this.request(`/api/users/${userId}/eligibility/${productId}`);
  }

  public async addProductToCart(productId: number, referenceId?: number): Promise<void> {
    await this.request({
      method: 'post',
      payload: { referenceId },
      url: `/api/products/${productId}/add`,
    });
  }

  public async removeProductFromCart(
    orderId: number,
    payload: ProductCartDeleteParams,
  ): Promise<void> {
    await this.request({
      method: 'delete',
      payload,
      url: `/api/orders/${orderId}/product`,
    });
  }

  public async updateProductQuantity(
    orderId: number,
    orderItemId: number,
    quantity: number,
  ): Promise<void> {
    await this.request({
      method: 'patch',
      payload: { quantity },
      url: `/api/orders/${orderId}/item/${orderItemId}`,
    });
  }

  public async getOrdersByUserId(userId: number): Promise<Order[]> {
    return await this.request(`/api/users/${userId}/orders`);
  }

  public async getOptions(source: OptionSource): Promise<Option[]> {
    return await this.request(`/api/options/${source}`);
  }

  public async getOption(source: OptionSource, optionId: number): Promise<OptionInternal> {
    return await this.request(`/api/options/${source}/${optionId}`);
  }

  public async createOption(
    source: OptionSource,
    option: OptionSavePayload,
  ): Promise<OptionInternal> {
    return await this.request({
      method: 'post',
      payload: option,
      url: `/api/options/${source}`,
    });
  }

  public async saveOption(
    source: OptionSource,
    optionId: number,
    option: OptionSavePayload,
  ): Promise<Option> {
    return await this.request({
      method: 'patch',
      payload: option,
      url: `/api/options/${source}/${optionId}`,
    });
  }

  public async deleteOption(source: OptionSource, id: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/options/${source}/${id}`,
    });
  }

  public async getProducts(): Promise<ProductModel[]> {
    return await this.request('/api/products');
  }

  public async getProductById(id: number): Promise<ProductModel> {
    return await this.request(`/api/products/${id}`);
  }

  public async deleteProductById(id: number): Promise<ProductModel[]> {
    return await this.request({
      method: 'delete',
      url: `/api/products/${id}`,
    });
  }

  public async clearProductFromCarts(id: number): Promise<void> {
    return await this.request({
      method: 'post',
      url: `/api/products/${id}/clear`,
    });
  }

  public async getPublicProductById(id: number): Promise<ProductModel> {
    return await this.request(`/api/products/${id}/public`);
  }

  public async getUserActiveRegistration(userId: number): Promise<RegistrationInfo> {
    return await this.request<RegistrationInfo>(`/api/users/${userId}/registration`);
  }

  public async getRegistrationLimitReached(): Promise<{ limitReached: boolean }> {
    return await this.request<{ limitReached: boolean }>('/api/registrations/limit');
  }

  public async getPublicProducts(): Promise<ProductModel[]> {
    return await this.request<ProductModel[]>('/api/products/public');
  }

  public async deleteProductCategory(
    categoryId: number,
    deleteItems?: boolean,
    replacementCategoryId?: number,
  ): Promise<void> {
    await this.request({
      payload: {
        deleteItems,
        replacementCategoryId,
      },
      url: `/api/products/categories/${categoryId}`,
    });
  }

  public async getProductCategoryById(categoryId: number): Promise<ProductCategoryModel> {
    return await this.request<ProductCategoryModel>(`/api/products/categories/${categoryId}`);
  }

  public async getProductCategories(): Promise<ProductCategoryModel[]> {
    return await this.request<ProductCategoryModel[]>('/api/products/categories');
  }

  public async getActiveOrder(userId: number, create = false): Promise<Order> {
    return await this.request<Order>({
      query: {
        create,
      },
      url: `/api/orders/user/${userId}`,
    });
  }

  public async getOrderById(orderId: number): Promise<Order> {
    return await this.request<Order>(`/api/orders/${orderId}`);
  }

  public async getOrders(payload: OrderSearch): Promise<ListResponse<Order>> {
    return await this.request({
      method: 'post',
      payload,
      url: `/api/orders`,
    });
  }

  public async getOrdersForUser(userId: number): Promise<Order[]> {
    return await this.request(`/api/users/${userId}/orders`);
  }

  public async getTicketById(id: string): Promise<Ticket> {
    return await this.request<Ticket>(`/api/tickets/${id}`);
  }

  public async getSurcharges(): Promise<Surcharge[]> {
    return await this.request<Surcharge[]>('/api/surcharges');
  }

  public async getCategorySurcharges(categoryId: number): Promise<Surcharge[]> {
    return await this.request<Surcharge[]>(`/api/products/categories/${categoryId}/surcharges`);
  }

  public async getPlatformFees(): Promise<Surcharge[]> {
    return await this.request<Surcharge[]>('/api/surcharges/platform');
  }

  public async getSurchargeById(id: number): Promise<Surcharge> {
    return await this.request<Surcharge>(`/api/surcharges/${id}`);
  }

  public async getSurchargeProductsById(
    id: number,
    page: number,
  ): Promise<ListResponse<SurchargeProduct>> {
    return await this.base.listRequest<SurchargeProduct>(`/api/surcharges/${id}/products`, page);
  }

  public async getSurchargeOrderItemsById(
    id: number,
    page: number,
  ): Promise<ListResponse<SurchargeOrderItem>> {
    return await this.base.listRequest<SurchargeOrderItem>(
      `/api/surcharges/${id}/orderItems`,
      page,
    );
  }

  public async deleteSurchargeProduct(productId: number, surchargeId: number): Promise<void> {
    await this.base.request({
      method: 'delete',
      url: `/api/products/${productId}/surcharges/${surchargeId}`,
    });
  }

  public async getProductsByCategory(
    categoryId: number,
    currentProductId?: number,
  ): Promise<ProductModel[]> {
    return await this.request<ProductModel[]>({
      query: currentProductId ? { currentProductId } : undefined,
      url: `/api/products/categories/${categoryId}/public`,
    });
  }

  public async updateCategory(categoryId: number, eventStore: boolean): Promise<void> {
    await this.request({
      method: 'patch',
      payload: { eventStore },
      url: `/api/products/categories/${categoryId}`,
    });
  }

  public async getFullProductsByCategory(
    categoryId: number,
    onlyActive = false,
  ): Promise<ProductModel[]> {
    return await this.request<ProductModel[]>({
      url: `/api/products/categories/${categoryId}/full`,
      query: { onlyActive },
    });
  }

  public async getDealerForm(): Promise<DealerForm> {
    return await this.request<DealerForm>('/api/dealers/form');
  }

  public async getBadgeArt(ignoreSettings = false): Promise<BadgeArt[]> {
    return await this.request<BadgeArt[]>({ url: '/api/badgeart', query: { ignoreSettings } });
  }

  public async getDepartments(): Promise<DepartmentWithLeads[]> {
    return await this.request<DepartmentWithLeads[]>('/api/departments');
  }

  public async getDealerAssistants(dealerId: number): Promise<DealerAssistance[]> {
    return await this.request<DealerAssistance[]>(`/api/dealers/${dealerId}/assistants`);
  }

  public async updateDealer(userId: number, payload: DealerModel): Promise<void> {
    await this.request({
      method: 'post',
      url: `/api/users/${userId}/dealer`,
      payload,
    });
  }

  public async deleteChildRegistration(childId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/children/${childId}`,
    });
  }

  public async getCurrentUserChildren(): Promise<ChildRegistration[]> {
    return await this.request<ChildRegistration[]>('/api/children');
  }

  public async getMenuLinks(): Promise<MenuLink[]> {
    return await this.request<MenuLink[]>('/api/menulinks');
  }

  public async getRaffleAddressLocation(
    addressCity: string,
    addressCountry: string,
    addressZipcode: string,
  ): Promise<UserLocation> {
    return await this.request<UserLocation>({
      query: { addressCity, addressCountry, addressZipcode },
      url: '/api/raffles/locations',
    });
  }

  /**
   * Get all fees
   */
  public async getAllFees(): Promise<Fee[]> {
    return await this.request<Fee[]>('/api/fees');
  }

  /**
   * Get all the bans! Every last one, no pagination currently exists.
   */
  public async getAllBans(): Promise<BanResult[]> {
    return await this.request<BanResult[]>('/api/bans');
  }

  /**
   * Add a ban for a particular user!
   * (Note! Most adds are done through JSONForms)
   */
  public async addBan(userId: number, banDetails: BanDetails): Promise<void> {
    await this.request({
      method: 'post',
      url: `/api/users/${userId}/bans`,
      payload: banDetails,
    });
  }

  /**
   * Delete a ban for a particular user!
   */
  public async deleteBan(userId: number, banId: number): Promise<void> {
    await this.request({ method: 'delete', url: `/api/users/${userId}/bans/${banId}` });
  }

  /**
   * Get a single Ban for a particular user
   * (Note! Untested!)
   */
  public async getBan(userId: number): Promise<Ban> {
    return await this.request<Ban>(`/api/users/${userId}/bans`);
  }

  public async getActiveVolunteers(): Promise<VolunteerModel[]> {
    return await this.request<VolunteerModel[]>('/api/volunteers');
  }

  public async getVolunteerStats(): Promise<VolunteerStats> {
    return await this.request('/api/volunteers/stats');
  }

  public async getUserVolunteerApplications(userId: number): Promise<VolunteerLoadModel> {
    return await this.request<VolunteerLoadModel>(`/api/users/${userId}/volunteers`);
  }

  public async getEmailTargets(): Promise<EmailTargets> {
    return await this.request<EmailTargets>('/api/emails/targets');
  }

  public async getEmailSenders(): Promise<Senders> {
    return await this.request<Senders>('/api/emails/senders');
  }

  public async getAttendeeEmails(payload: AttendeeEmails): Promise<{ userIds: number[] }> {
    return await this.request<{ userIds: number[] }>({
      method: 'post',
      payload,
      url: '/api/emails/list/attendees',
    });
  }

  public async sendMassEmail(payload: SendEmailPayload): Promise<MailQueueInfo[]> {
    return await this.request({
      method: 'post',
      payload,
      url: '/api/emails',
    });
  }

  public async deleteRegistration(id: number): Promise<void> {
    await this.request({ method: 'delete', url: `/api/registrations/${id}` });
  }

  public async transferRegistration(userId: number, targetUserId: number): Promise<void> {
    await this.request({
      method: 'post',
      payload: { targetUserId },
      url: `/api/registrations/${userId}/transfer/admin`,
    });
  }

  public async getRegistrationFlags(): Promise<RegistrationFlagModel[]> {
    return await this.request<RegistrationFlagModel[]>('/api/registrations/flags');
  }
  public async getRegistrationFlagUsers(flagId: number): Promise<RegistrationFlagUser[]> {
    return await this.request<RegistrationFlagUser[]>(`/api/registrations/flags/${flagId}/users`);
  }

  public async getRegistrationForm(): Promise<ConRegistrationForm> {
    return await this.request<ConRegistrationForm>('/api/registrations/form');
  }

  public async getChildRegistration(registrationId: number): Promise<ChildRegistration[]> {
    return await this.request<ChildRegistration[]>(`/api/registrations/${registrationId}/children`);
  }

  public async getExtendedUser(userId: number): Promise<ExtendedUser> {
    return await this.request<ExtendedUser>(`/api/users/${userId}`);
  }

  public async getVolunteerConfig(): Promise<VolunteerConfig> {
    return await this.request<VolunteerConfig>('/api/volunteers/config');
  }

  public async updateVolunteerConfig(payload: Partial<VolunteerConfig>): Promise<void> {
    return await this.request({
      method: 'patch',
      payload,
      url: '/api/volunteers/config',
    });
  }

  public async getDepartmentFromLead(): Promise<VolunteerModel[]> {
    return await this.request<VolunteerModel[]>('/api/volunteers/lead');
  }

  public async upsertVolunteer(userId: number, payload: VolunteerUpsertModel): Promise<void> {
    await this.request({ method: 'post', payload, url: `api/users/${userId}/volunteer` });
  }

  public async deleteVolunteer(volunteerId: number): Promise<void> {
    await this.request({ method: 'delete', url: `api/volunteers/${volunteerId}` });
  }

  public async getDealerSeatingPreferences(dealerId: number): Promise<SeatingPreference[]> {
    return await this.request<SeatingPreference[]>(`/api/dealers/${dealerId}/seatingPreferences`);
  }

  public async getUserVolunteer(userId: number): Promise<VolunteerModel> {
    return await this.request<VolunteerModel>(`/api/users/${userId}/volunteer`);
  }

  public async getVolunteerForm(): Promise<VolunteerForm> {
    return await this.request<VolunteerForm>('/api/volunteers/form');
  }

  public async getUserVolunteerStaff(volunteerId: number): Promise<StaffRegistrationInfo> {
    return await this.request(`api/volunteers/${volunteerId}/staff`);
  }

  public async getDealerAreas(): Promise<DealerArea[]> {
    return await this.request({ url: '/api/dealers/areas' });
  }

  public async getAllDealers(areaIds: number[]): Promise<DealerFullModel[]> {
    return await this.request({
      method: 'post',
      payload: {
        areaIds,
      },
      url: '/api/dealers/search',
    });
  }

  public async getDealer(dealerId: number): Promise<DealerFullModel> {
    return await this.request(`/api/dealers/${dealerId}`);
  }

  public async getUserNotes(userId: number): Promise<UserNote[]> {
    return await this.request<UserNote[]>(`/api/users/${userId}/notes`);
  }

  public async deleteUserNote(userId: number, noteId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/users/${userId}/notes/${noteId}`,
    });
  }

  public async deleteActiveDealerApplicationPayment(
    dealerId: number,
    areaId: number,
  ): Promise<void> {
    await this.request({ method: 'delete', url: `api/dealers/${dealerId}/area/${areaId}/payment` });
  }

  public async deleteActiveDealerApplication(dealerId: number, areaId: number): Promise<void> {
    await this.request({ method: 'delete', url: `api/dealers/${dealerId}/area/${areaId}` });
  }

  public async getGeneralSettings(): Promise<LiveSettings> {
    return await this.request<LiveSettings>('/api/settings');
  }

  public async updateSetting<K extends keyof LiveSettings>(
    section: K,
    value: LiveSettings[K],
  ): Promise<void> {
    await this.request({
      method: 'put',
      payload: {
        key: section,
        value,
      },
      url: '/api/settings',
    });
  }

  public async getPolicies(): Promise<Policy[]> {
    return await this.request<Policy[]>('/api/policies');
  }

  public async upsertPolicy(payload: PolicyCreate): Promise<void> {
    await this.request({
      method: 'put',
      payload,
      url: '/api/policies',
    });
  }

  public async deletePolicy(payload: PolicyDelete): Promise<void> {
    await this.request({
      method: 'delete',
      payload,
      url: `/api/policies`,
    });
  }

  public async updateUserRole(
    userId: number,
    roleId: number,
    action: 'add' | 'remove',
    scope: RoleScope,
  ): Promise<void> {
    await this.request({
      method: 'patch',
      payload: { roleId, action, scope },
      url: `/api/users/${userId}/roles`,
    });
  }

  public async getUserRoles(): Promise<RoleWithPermissions[]> {
    return await this.request<RoleWithPermissions[]>('/api/users/roles');
  }

  public async getRoles(): Promise<Role[]> {
    return await this.request<Role[]>('/api/roles');
  }

  public async updatePermissionRole(
    roleId: number,
    permissionId: number,
    invert: boolean,
  ): Promise<void> {
    await this.request({
      method: 'patch',
      payload: {
        invert,
      },
      url: `/api/roles/${roleId}/permissions/${permissionId}`,
    });
  }

  public async deletePermissionRole(roleId: number, permissionId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/roles/${roleId}/permissions/${permissionId}`,
    });
  }

  public async getRoleUsers(roleId: number): Promise<RoleUserListItem[]> {
    return await this.request<RoleUserListItem[]>(`/api/roles/${roleId}/users`);
  }

  public async getPermissions(): Promise<Permission[]> {
    return await this.request<Permission[]>('/api/permissions');
  }

  public async getUserPermissions(userId: number): Promise<UserPermissionVerbose[]> {
    return await this.request<UserPermissionVerbose[]>(`/api/users/${userId}/permissions`);
  }

  public async deleteUserPermission(userId: number, permissionId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/users/${userId}/permissions/${permissionId}`,
    });
  }

  public async updateUserPermission(
    userId: number,
    permissionId: number,
    invert: boolean,
  ): Promise<void> {
    await this.request({
      method: 'patch',
      payload: {
        invert,
      },
      url: `/api/users/${userId}/permissions/${permissionId}`,
    });
  }

  public async getUserCashierProfile(userId: number, barcode?: string): Promise<CashierCheck> {
    return await this.request<CashierCheck>({
      query: barcode
        ? {
            barcode,
          }
        : {},
      url: `/api/users/${userId}/cashier`,
    });
  }

  public async markRegistrationBadgeAsPrinted(
    regId: number,
    checkin: boolean | null,
    barcode?: string,
  ): Promise<void> {
    await this.request({
      method: 'patch',
      payload: {
        barcode,
        checkin,
      },
      url: `/api/registrations/${regId}/print`,
    });
  }

  public async getRefunds(): Promise<OrderRefund[]> {
    return await this.request<OrderRefund[]>('/api/refunds');
  }

  public async refundUser(userId: number, refundId: number, amount: number): Promise<void> {
    await this.request({
      method: 'post',
      payload: { amount },
      url: `/api/users/${userId}/refund/${refundId}`,
    });
  }

  public async getAttendeeStats(): Promise<AttendeeStats> {
    return await this.request<AttendeeStats>(`/api/stats/attendeeStats`);
  }

  public async getIdentityStats(): Promise<IdentityStats> {
    return await this.request<IdentityStats>(`/api/stats/identityStats`);
  }

  public async confirmAssistant(userId: number, code: string): Promise<void> {
    await this.request({
      method: 'patch',
      payload: {
        code,
      },
      url: `/api/users/${userId}/assistant`,
    });
  }

  public async getRegistrationsOverTime(): Promise<RegsOverTime> {
    return await this.request<RegsOverTime>(`/api/stats/registrationsOverTime`);
  }

  public async getRegistrationsProductStats(): Promise<RegistrationStats> {
    return await this.request<RegistrationStats>(`/api/stats/registrationStats`);
  }

  public async getSalesStats(): Promise<SalesReport> {
    return await this.request<SalesReport>(`/api/stats/sales`);
  }

  public async getDealerStats(): Promise<DealerStats> {
    return await this.request<DealerStats>(`/api/dealers/stats`);
  }

  public async getOAuthApplicationAuthorizationInfo(
    clientId: string,
    scope: string,
    redirectUri: string,
  ): Promise<OAuthApp> {
    return await this.request<OAuthApp>({
      query: {
        redirect_uri: redirectUri,
        scope,
      },
      url: `/api/oauth/${clientId}`,
    });
  }

  public async authorizeOAuthApplication(
    clientId: number,
    redirectUri: string,
    responseType: string,
    scope?: string,
  ): Promise<OAuthAuthorization> {
    return await this.request<OAuthAuthorization>({
      method: 'post',
      payload: {
        redirect_uri: redirectUri,
        response_type: responseType,
        scope,
      },
      url: `/api/oauth/${clientId}/authorize`,
    });
  }

  public async checkEmailJobStatus(id: string): Promise<EmailQueueStatus> {
    return await this.request<EmailQueueStatus>({
      query: { id },
      url: '/api/emails/status',
    });
  }

  public async previewEmail(input: string): Promise<EmailPreview> {
    return await this.request<EmailPreview>({
      method: 'post',
      payload: {
        body: input,
      },
      url: '/api/emails/preview',
    });
  }

  public async estimateEmailRecipients(recipients: string): Promise<EmailEstimate> {
    return await this.request<EmailEstimate>({
      query: {
        recipients,
      },
      url: '/api/emails/estimate',
    });
  }

  public async createAsset(category: string, file: Blob): Promise<AssetCreateResult> {
    return await this.base.sendFile<AssetCreateResult>({
      method: 'post',
      payload: this.fixupImageBlob(file),
      progressor: () => undefined,
      url: `/api/assets/${category}`,
    });
  }

  public async createBadgeArt(assetId: string): Promise<void> {
    await this.request({
      method: 'post',
      payload: {
        assetId,
      },
      url: '/api/badgeart',
    });
  }

  /* Badge Designer Endpoints */
  public async createBadge(name: string, content: DesignerNode[]): Promise<{ id: number }> {
    return await this.request({
      method: 'post',
      payload: { content, name },
      url: '/api/badge/designs',
    });
  }

  public async updateBadge(id: number, name: string, content: DesignerNode[]): Promise<void> {
    await this.request({
      method: 'patch',
      payload: { content, name },
      url: `/api/badge/designs/${id}`,
    });
  }

  public async getBadgeDesignList(): Promise<BadgeDesign[]> {
    return await this.request('/api/badge/designs');
  }

  public async getBadgeDesign(id: number | 'default'): Promise<BadgeDesign> {
    return await this.request(`/api/badge/designs/${id}`);
  }

  public async deleteBadge(id: number): Promise<void> {
    await this.request({ method: 'delete', url: `/api/badge/designs/${id}` });
  }

  public async setDefaultBadgeDesign(id: number): Promise<BadgeDesign> {
    return await this.request({
      method: 'post',
      payload: { badgeDesignId: id },
      url: '/api/badge/designs/default',
    });
  }

  public async getBadgeData(payload: BadgeDataRequest): Promise<BadgeData[]> {
    return await this.request({
      method: 'post',
      payload,
      url: '/api/badge/data',
    });
  }

  public async setLogo(file: File): Promise<AssetCreateResult> {
    return await this.base.sendFile<AssetCreateResult>({
      method: 'post',
      payload: this.fixupImageBlob(file),
      progressor: () => undefined,
      url: '/api/logo',
    });
  }

  public async createProductImage(productId: number, assetId: string): Promise<void> {
    await this.request({
      method: 'post',
      payload: {
        assetId,
      },
      url: `/api/products/${productId}/images`,
    });
  }

  public async getAuditLogs(payload: {
    entityId: string;
    objectId?: string;
    time?: number;
    reverse?: boolean;
    ignoreTypes: string[];
    pageSize: number;
  }): Promise<LogResult> {
    return await this.request({
      method: 'post',
      payload,
      url: '/api/auditlogs',
    });
  }

  public async getAuditEntities(): Promise<{ name: string }[]> {
    return await this.request('/api/auditlogs/entities');
  }

  public async getRaffleById(id: number): Promise<RaffleWithUserDetails> {
    return await this.request(`/api/raffles/${id}`);
  }

  public async getRaffleTicketsById(
    id: number,
    page: number,
    search: string,
    status: string,
  ): Promise<ListResponse<RaffleTicket>> {
    return await this.base.listRequest<RaffleTicket>({
      query: { search, page, status },
      url: `/api/raffles/${id}/tickets`,
    });
  }

  public async getFullRaffleById(id: number): Promise<Raffle> {
    return await this.request(`/api/raffles/${id}/full`);
  }

  public async getRaffles(): Promise<Raffle[]> {
    return await this.request('/api/raffles');
  }

  public async getSocialLink(name: string): Promise<SocialLinkEndpoint> {
    return await this.request(`/api/sociallink/provider/${name}`);
  }

  public async getSocialLinkUser(userId: number, name: string): Promise<SocialLink> {
    return await this.request(`/api/user/${userId}/sociallink/${name}`);
  }

  public async loginSocialLink(code: string): Promise<{ id: string; name: string }> {
    return await this.request({
      method: 'post',
      payload: { code },
      url: '/api/sociallink',
    });
  }

  public async deleteSocialLink(id: string): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/sociallink/${id}`,
    });
  }

  public async setRaffleTicket(
    raffleId: number,
    userId: number,
    status: string,
    points: number,
  ): Promise<void> {
    await this.request({
      method: 'post',
      payload: { status, userId, points },
      url: `/api/raffles/${raffleId}/ticket`,
    });
  }

  public async getRedirectRequest(conventionId: number): Promise<{ token: string }> {
    return await this.request<{ token: string }>({
      method: 'post',
      payload: { conventionId },
      url: '/api/login/redirect_token',
    });
  }

  public async loginFromToken(token: string, userId: number): Promise<void> {
    await this.request({
      method: 'post',
      payload: { token, userId },
      url: '/api/login/from_redirect',
    });
  }

  public async getStripeVerifyKey(): Promise<{
    publishableKey: string;
    remainingIdentityVerifications: number;
  }> {
    return await this.request<{ publishableKey: string; remainingIdentityVerifications: number }>({
      method: 'get',
      url: '/api/identity/key',
    });
  }

  public async fetchStripeVerifyToken(): Promise<NewIdentitySession> {
    return await this.request<NewIdentitySession>({
      method: 'post',
      url: '/api/identity/session',
    });
  }

  public async updateKiosk(id: number, layout: FormLayout): Promise<void> {
    return await this.request({
      method: 'patch',
      payload: { layout },
      url: `/api/kiosk/${id}`,
    });
  }

  public async getKioskLayout(id: number): Promise<{ layout: FormLayout }> {
    return await this.request<{ layout: FormLayout }>({
      method: 'get',
      url: `/api/kiosk/${id}`,
    });
  }

  public async getKioskList(): Promise<{ id: string }> {
    return await this.request<{ id: string }>({
      method: 'get',
      url: '/api/kiosk',
    });
  }

  public async deleteRegistrationFormLayout(): Promise<void> {
    return await this.request({
      method: 'delete',
      url: '/api/convention/registration/form',
    });
  }

  public async updateRegistrationFormLayout(registrationForm: FormPageModel): Promise<void> {
    return await this.request({
      method: 'patch',
      payload: { registrationForm },
      url: '/api/convention/registration/form',
    });
  }

  public async getRegistrationFormLayout(force?: boolean): Promise<FormPageModel> {
    return await this.request<FormPageModel>({
      method: 'get',
      query: { force: force ? 'true' : 'false' },
      url: '/api/convention/registration/form',
    });
  }

  public async fetchStripeIdentityInfo(id: string): Promise<StripeIdentityUser> {
    return await this.request<StripeIdentityUser>({
      method: 'get',
      url: `/api/identity/session/${id}`,
    });
  }

  public async fetchPendingExportRequests(): Promise<ExportListItem[]> {
    return await this.request<ExportListItem[]>({
      method: 'get',
      url: '/api/exports/pending',
    });
  }

  public async fetchExportRequestStatus(create: boolean): Promise<ExportRequest | undefined> {
    return await this.request<ExportRequest>({
      method: 'post',
      payload: { create },
      url: '/api/export/request',
    });
  }

  public async approveExportRequest(id: string): Promise<void> {
    await this.request({
      method: 'post',
      url: `/api/exports/${id}/approve`,
    });
  }

  public async rejectExportRequest(id: string): Promise<void> {
    await this.request({
      method: 'post',
      url: `/api/exports/${id}/reject`,
    });
  }

  public async deleteExportRequest(id: string): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/exports/${id}`,
    });
  }

  public async getOAuthApplications(): Promise<OAuthApplicationModel[]> {
    return await this.request<OAuthApplicationModel[]>({
      method: 'get',
      url: `/api/oauth`,
    });
  }

  public async createOAuthApplication(
    app: Omit<OAuthApplicationModel, 'id'>,
  ): Promise<NewSecretModel> {
    return await this.request<NewSecretModel>({
      method: 'post',
      payload: app,
      url: `/api/oauth`,
    });
  }

  public async updateOAuthApplication(
    id: number,
    app: Omit<OAuthApplicationModel, 'id'>,
  ): Promise<NewSecretModel> {
    return await this.request<NewSecretModel>({
      method: 'patch',
      payload: app,
      url: `/api/oauth/${id}`,
    });
  }

  public async deleteOAuthApplication(id: number): Promise<NewSecretModel> {
    return await this.request<NewSecretModel>({
      method: 'delete',
      url: `/api/oauth/${id}`,
    });
  }

  public async getOAuthPermissions(): Promise<PermissionName[]> {
    return await this.request<PermissionName[]>({
      method: 'get',
      url: `/api/oauth/permissions`,
    });
  }

  public async approveFelonyException(id: number): Promise<void> {
    return await this.request({
      method: 'post',
      url: `/api/volunteers/${id}/felony`,
      payload: {},
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  public async request<T extends object | void = void>(
    opts: RequestOptions<object | undefined> | string,
  ): Promise<T> {
    try {
      return await this.base.request<T>(opts);
    } catch (error) {
      const url = opts instanceof Object ? opts.url : opts;
      const noRedirect = url === '/api/users/current' || url === '/api/users/logout';

      // If we're not authenticated, redirect to the login page
      if (isAuthError(error as Error, AuthErrorCodes.NotAuthenticated) && !noRedirect) {
        window.location.replace(`/login?redirect=${encodeURIComponent(window.location.pathname)}`);
      }

      throw error;
    }
  }

  /**
   * Required until https://github.com/puppeteer/puppeteer/commit/532ae573d29063ffd081ed9ee340d71b25320dec is
   * in puppeteers latest release.
   * See https://github.com/puppeteer/puppeteer/pull/5358 for explanation.
   */
  private fixupImageBlob(blob: Blob): Blob {
    if (blob.type === '') {
      return blob.slice(0, blob.size, 'image/png');
    }

    return blob;
  }
}
