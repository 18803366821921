import React, { FC, useCallback } from 'react';
import { makeLazyComponent } from '../utils/lazy';

const PhoneInputInner = makeLazyComponent(
  async () => await import(/* webpackChunkName: "phoneinput" */ 'react-phone-input-2'),
);

interface PhoneInputProps {
  readonly name?: string;
  readonly disabled?: boolean;
  readonly value: string | null;
  onChange?(newValue: string | null): void;
}

export const PhoneInput: FC<PhoneInputProps> = ({ name, disabled, value, onChange }) => {
  const onPhoneChange = useCallback(
    (_v: string, _country: unknown, _e: unknown, formatted: string) => {
      onChange?.(
        !formatted || formatted.length === 0 || formatted === '+1'
          ? ''
          : formatted.replaceAll(/[ ()-]/g, ''),
      );
    },
    [onChange],
  );

  return (
    <PhoneInputInner
      country="us"
      disabled={disabled}
      inputProps={{ name }}
      onChange={onPhoneChange}
      value={value}
    />
  );
};
