import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownItem as BSDropdownItem } from 'reactstrap';
import { classNames } from '../utils';

export interface DropdownItemProps {
  readonly className: string;
  readonly isDangerous?: boolean;
  readonly to?: string;
  readonly onClick?: () => void;
  readonly text: string;
}

export const DropdownItem: FC<DropdownItemProps> = ({
  className,
  isDangerous,
  to,
  onClick,
  text,
}) => {
  const history = useHistory();
  const onClickRedirect = useCallback(() => {
    history.push(to!);
  }, [to]);

  if (!to && !onClick) {
    return null;
  }

  return (
    <BSDropdownItem
      className={classNames(
        {
          'text-danger': !!isDangerous,
        },
        className,
      )}
      onClick={onClick ?? onClickRedirect}
    >
      {text}
    </BSDropdownItem>
  );
};
