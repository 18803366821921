import { makeReporter } from '@conventioncatcorp/common-fe/dist/errorHandling';
import React from 'react';
import { toast } from 'react-toastify';

export { LogicError } from '../../shared/error';

export const enum AuthErrorCodes {
  NotAuthenticated = -1,
  IncorrectLogin,
  NotVerified,
  UnderReview,
  PasswordResetRequired,
  Banned,
  MaintenanceMode,

  // 2FA
  SecondFactorMissing = 1000,
  SecondFactorInvalid = 1001,

  // OAuth
  Invalid = 2000,
  Expired,
  Revoked,
}

export const captureError = makeReporter({
  errorMessages: {
    [AuthErrorCodes.IncorrectLogin]: <>Invalid Username or Password.</>,
    [AuthErrorCodes.NotVerified]: (
      <>
        Please check your email to confirm your account and complete registration.
        <br />
        Be sure to check your spam / junk folder!
      </>
    ),
    [AuthErrorCodes.UnderReview]: (
      <>
        Your account requires review by the registration team before you can complete registration.
        <br />
        We'll email you as soon as possible and apologize for the inconvenience.
      </>
    ),
    [AuthErrorCodes.PasswordResetRequired]: (
      <>
        As part of the data migration to the new system, we require all users to set a new password.
        <br />
        Please click Forgot Password? and follow the instructions to reset your password.
        <br />
        We apologize for the inconvenience.
      </>
    ),
    [AuthErrorCodes.Banned]: (
      <>
        You have been banned from attending the convention.
        <br />
        If you think this was in error, contact the Registration team.
      </>
    ),
  },
  showError: (content) => {
    toast.error(() => content);
  },
});
