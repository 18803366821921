import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export const AutoLink: FC<{
  readonly children: ReactNode;
  readonly link: string;
  readonly onClick: () => void;
}> = ({ link, onClick, children }) => {
  const regex = /^https?:\/\//;
  if (regex.test(link)) {
    return (
      <a href={link} onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <NavLink activeClassName="active" exact onClick={onClick} to={link}>
      {children}
    </NavLink>
  );
};
