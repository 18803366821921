import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { OAuthApp, OAuthAuthorization } from '../../../../APIClient';

interface AuthorizedProps {
  readonly app: OAuthApp;
  readonly authorization: OAuthAuthorization;
}

export const Authorized: React.FC<AuthorizedProps> = ({ app, authorization }) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const { redirectUri, token } = authorization;
      const params = new URLSearchParams(location.search);

      window.location.replace(
        `${redirectUri}?code=${encodeURIComponent(token)}&state=${params.get('state')!}`,
      );
    }, 1000);
  }, []);

  return (
    <Row>
      <Col className="margin-center margin-top-10 oauth" lg={8} md={10} s={12} xl={6}>
        <div className="appicon">
          <img src="/api/logo" style={{ maxWidth: '128px' }} />
        </div>
        <h4 className="text-center">Authorize {app.name}</h4>
        {app.description && <p className="text-center">{app.description}</p>}
        <Card>
          <CardBody className="text-center">
            <MaterialIcon className="override" large name="check_circle" type="success" />
            <h4>Authorized!</h4>
            <div>
              Just a moment while we redirect you back to <strong>{app.name}</strong>.
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
