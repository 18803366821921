import { APIError } from '@conventioncatcorp/common-fe/dist/APIClient';
import React, { Component, FormEvent } from 'react';
import { FormFeedback, Input } from 'reactstrap';
import { UniqueAttribute } from '../APIClient';
import { delayPromise, randInt } from '../utils';

export enum UniqueInputState {
  INVALID = -2,
  INUSE = -1,
  UNCHECKED = 0,
  AVAILABLE = 1,
}

export interface UniqueAttributeProps {
  readonly type: UniqueAttribute;
  readonly onChange?: (val: string) => void;
}
export interface UniqueAttributeState {
  state: UniqueInputState;
}

export class UniqueAttributeInput extends Component<UniqueAttributeProps, UniqueAttributeState> {
  public override state = {
    state: UniqueInputState.UNCHECKED,
  };

  public override render(): JSX.Element {
    const { type } = this.props;
    const { state } = this.state;
    return (
      <>
        <Input
          autoComplete={type === 'username' ? 'username' : 'email'}
          id={type}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          invalid={state < 0}
          name={type}
          onBlur={async (el) => await this.checkUnique(el, type)}
          placeholder={`Example: ${type === 'email' ? 'bobcat@concat.app' : 'bobcat18'}`}
          type={type === 'email' ? 'email' : 'text'}
        />
        {state !== UniqueInputState.UNCHECKED && (
          <FormFeedback
            invalid={state === UniqueInputState.INVALID ? 'true' : undefined}
            valid={state === UniqueInputState.AVAILABLE}
          >
            {this.getStateMessage()}
          </FormFeedback>
        )}
      </>
    );
  }

  private async checkUnique(
    event: FormEvent<HTMLInputElement>,
    field: UniqueAttribute,
  ): Promise<void> {
    let state = UniqueInputState.INVALID;
    const { value } = event.currentTarget;
    try {
      // If exists, 200. If not found, 404.
      state = (await api.checkUserAttributeUnique(value, field))
        ? UniqueInputState.AVAILABLE
        : UniqueInputState.INUSE;
    } catch (error) {
      if (!(error instanceof APIError) || error.apiResponse.errors.rateLimit) {
        throw error;
      }

      await delayPromise(randInt(100, 250));
    }

    this.setState({
      state,
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  private getStateMessage(): string | null {
    const { type } = this.props;
    switch (this.state.state) {
      case UniqueInputState.INVALID: {
        if (type === 'username') {
          return 'That username is invalid. Usernames must be between 4 and 64 characters.';
        }

        return `This ${type} is invalid`;
      }

      case UniqueInputState.INUSE: {
        return `This ${type} is already in use.`;
      }

      case UniqueInputState.AVAILABLE: {
        return `Great! This ${type} is available`;
      }

      default: {
        return null;
      }
    }
  }
}
