import React, { ChangeEvent, DragEvent, FC, FormEvent, useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { captureError } from '../utils/errorHandling';
import { MaterialIcon } from '.';

interface AssetUploadProps {
  readonly category: 'badge-art' | 'product';
  onComplete(): void;
  onFileUploaded(assetId: string): Promise<void>;
}

const stopEvent = (e: DragEvent<HTMLInputElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

export const AssetUpload: FC<AssetUploadProps> = ({ category, onComplete, onFileUploaded }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const uploadFiles = useCallback(
    async (files: FileList) => {
      setIsUploading(true);

      try {
        await Promise.all(
          Array.from(files).map(async (file: Blob) => {
            const { assetId } = await api.createAsset(category, file);
            await onFileUploaded(assetId);
          }),
        );

        toast.success('Upload Complete!');
      } catch (error) {
        captureError(error as Error);
      }

      onComplete();
      setIsUploading(false);
    },
    [onComplete, category, onFileUploaded],
  );

  const onChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      await uploadFiles(e.target.files);
    }
  }, []);

  const onDrop = useCallback(async (e: DragEvent<HTMLInputElement>) => {
    await uploadFiles(e.dataTransfer.files);
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const uploadElem = uploadInputRef.current;
      if (uploadElem?.files) {
        await uploadFiles(uploadElem.files);
      }
    },
    [uploadInputRef],
  );

  const openFileUpload = useCallback(() => {
    if (uploadInputRef.current && !isUploading) {
      uploadInputRef.current.click();
    }
  }, [uploadInputRef, isUploading]);

  return (
    <form id="fileUploadForm" onSubmit={onSubmit}>
      <input
        accept="image/png"
        className="d-none"
        data-multiple-caption="{count} files selected"
        disabled={isUploading}
        id="fileUpload"
        multiple
        name="files[]"
        onChange={onChange}
        onDrag={stopEvent}
        onDragEnd={stopEvent}
        onDragEnter={stopEvent}
        onDragLeave={stopEvent}
        onDragOver={stopEvent}
        onDragStart={stopEvent}
        onDrop={onDrop}
        ref={uploadInputRef}
        type="file"
      />
      <div
        className="cardbtn"
        onClick={openFileUpload}
        style={{ height: '200px', textAlign: 'center', paddingTop: '4em' }}
      >
        <MaterialIcon name="cloud_upload" style={{ fontSize: '24px' }} />
        <h4>{isUploading ? 'Uploading...' : 'Upload File(s)'}</h4>
        <small>(Only PNG Files Supported)</small>
      </div>
    </form>
  );
};
