import { BadgeData, BadgeDesign } from '../../../../shared/user/badgeDesign';

export type PrinterConfig = Record<string, unknown>;

export enum PrinterManagerStatus {
  Ready = 'ready',
  Scanning = 'scanning',
  Error = 'error',
}

export enum PrinterStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

/** The interface type for communicating with a printer */
export enum PrinterType {
  /** Use the operating system print APIs and dialog. */
  OS = 'os',
  /** Use the WebZLP library to communicate with label printers. */
  WebLabel = 'weblabel',
  /** Use the Web-ReceiptLine-Printer library to communicate with receipt printers. */
  WebReceipt = 'webreceipt',
}

export interface ConfigurablePrinter {
  setConfig(config: PrinterConfig): Promise<void>;
}

interface PrinterBase {
  name: string;
  serial: string;
  type: PrinterType;
  canConfigure: boolean;
  uid: string;

  get status(): PrinterStatus;

  getConfig(): PrinterConfig;
  testPrint(): Promise<boolean>;
}

export interface ReceiptPrinterBase extends PrinterBase {
  /** Print a receipt document. */
  print(receiptline: string): Promise<boolean>;

  /** Kick open a cash drawer, assuming one is connected. */
  drawerKick(): Promise<boolean>;
}

export interface BadgePrinterBase extends PrinterBase {
  /** Print a badge */
  print(badgeData: BadgeData, badgeDesign: BadgeDesign): Promise<boolean>;
}

export interface PrinterInfoBase {
  name: string;
  serial: string;
  type: PrinterType;
  config: PrinterConfig;
  uid: string;
}

export interface PrinterManagerBase {
  name: string;
  type: PrinterType;
  status: PrinterManagerStatus;
  supportsManualConnect: boolean;

  startManualConnect?(): Promise<void>;
  getAvailablePrinters(): Promise<PrinterInfoBase[]>;
  usePrinter(name: string, config: PrinterConfig): PrinterBase | undefined;
}

/** Get a unique ID for a given printer. */
export function getPrinterUid(type: PrinterType, serial: string): string {
  return `${type}:${serial}`;
}

/** Decode a unique ID for a printer. */
export function parsePrinterUid(str?: string):
  | {
      type: PrinterType;
      serial: string;
    }
  | undefined {
  if (str === undefined) {
    return;
  }

  const [typeRaw, serial] = str.split(':');
  if (serial === undefined || serial.length === 0) {
    return;
  }

  let type: PrinterType | undefined;
  switch (typeRaw.toUpperCase()) {
    case 'OS': {
      type = PrinterType.OS;
      break;
    }

    case 'WEBLABEL': {
      type = PrinterType.WebLabel;
      break;
    }

    case 'WEBRECEIPT': {
      type = PrinterType.WebReceipt;
      break;
    }
  }

  if (type === undefined) {
    return;
  }

  return { type, serial };
}
