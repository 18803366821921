import React, { useCallback, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { MaterialIcon } from './MaterialIcon';

import './InputMultiSelect.scss';

export enum MultiSelectDirection {
  Left,
  Right,
}

export interface MultiSelectItem {
  id: string;
  value: string;
}

interface InputMultiSelectProps {
  readonly leftItems: MultiSelectItem[];
  readonly leftLabel: string;
  readonly rightItems: MultiSelectItem[];
  readonly rightLabel: string;
  readonly onMove: (items: MultiSelectItem[], direction: MultiSelectDirection) => void;
}

export const InputMultiSelect: React.FC<InputMultiSelectProps> = ({
  leftItems,
  leftLabel,
  rightItems,
  rightLabel,
  onMove,
}) => {
  const [leftSelectedItems, setLeftSelectedItems] = useState([] as MultiSelectItem[]);
  const [rightSelectedItems, setRightSelectedItems] = useState([] as MultiSelectItem[]);

  const onSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Get the selected items, filter any that are not in the items list, and set the state
      const event = e as unknown as React.ChangeEvent<HTMLSelectElement>;

      const left = e.target.name === 'left-select';

      const selectedItems: MultiSelectItem[] = Array.from(event.target.selectedOptions)
        .map((option) => {
          const item = (left ? leftItems : rightItems).find((i) => i.id === option.value);
          return item ?? { id: '', value: '' };
        })
        .filter((item) => item.id !== '');

      if (left) {
        setLeftSelectedItems(selectedItems);
      } else {
        setRightSelectedItems(selectedItems);
      }
    },
    [leftItems],
  );

  const moveLeft = useCallback(() => {
    onMove(rightSelectedItems, MultiSelectDirection.Left);
  }, [rightSelectedItems]);

  const moveRight = useCallback(() => {
    onMove(leftSelectedItems, MultiSelectDirection.Right);
  }, [leftSelectedItems]);

  return (
    <div className="multi-select">
      <div className="multi-select-left">
        <label>{leftLabel}</label>
        <Input multiple name="left-select" onChange={onSelectChange} type="select">
          {leftItems.map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))}
        </Input>
      </div>
      <div className="multi-select-buttons">
        <Button color="primary" onClick={moveLeft} outline type="button">
          <MaterialIcon name="arrow_left" />
        </Button>
        <Button color="primary" onClick={moveRight} outline type="button">
          <MaterialIcon name="arrow_right" />
        </Button>
      </div>
      <div className="multi-select-right">
        <label>{rightLabel}</label>
        <Input multiple name="right-select" onChange={onSelectChange} type="select">
          {rightItems.map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
};
