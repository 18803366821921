import React, { FunctionComponent, useCallback } from 'react';

import './EditableText.scss';

interface Props {
  readonly text: string;
  readonly onChange: (text: string) => void;
}

export const EditableText: FunctionComponent<Props> = ({ text, onChange }) => {
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return <input className="editable-text" onChange={onInputChange} type="text" value={text} />;
};
