import { JSONForm } from '@conventioncatcorp/common-fe';
import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { DealerApplication, DealerFullModel } from '../../../../shared/dealer';
import { ProductModel } from '../../../../shared/orders';
import { MessageCard } from '../../../components';
import { displayName, useConfig, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError } from '../../../utils/errorHandling';
import { getProductPrice } from '../../../utils/product';

interface DealerApplicationFormProps {
  readonly dealer: DealerFullModel;
  readonly dealerAreaId: number;
  readonly callback: () => void;
}

export const SpaceApplicationForm: FC<DealerApplicationFormProps> = ({
  dealer,
  dealerAreaId,
  callback,
}) => {
  const config = useConfig();
  const area = config.dealersAreas.find((t) => t.id === dealerAreaId)!;
  const application = dealer.applications.find((t) => t.areaId === dealerAreaId);
  const disabled = !!application && application.status !== 'pending';

  function onSuccess(): void {
    callback();
    toast.success('Your application has been updated.');
  }

  if (!area.open) {
    return (
      <Col xs={12}>
        <Alert color="danger">{area.name} is closed.</Alert>
      </Col>
    );
  }

  return (
    <JSONForm
      method="patch"
      onSuccess={onSuccess}
      path={`/api/dealers/${dealer.id}/area/${dealerAreaId}`}
    >
      <LoadingWrapper
        dataFetcher={async () =>
          await api.getProductsByCategory(area.categoryId, application?.tableType.id)
        }
        inline
      >
        {(tableTypes) => (
          <Row className="justify-content-center" id="areaApplicationForm">
            <Col lg={8} sm={12}>
              {area.full && (
                <Alert color="warning">
                  The {area.name} is full. Applications can still be made in case a seat becomes
                  available.
                </Alert>
              )}
              {disabled && (
                <Alert color="warning">
                  The application is {application.status} and can not be modified.
                </Alert>
              )}
            </Col>
            <Col className="margin-bottom-10" lg={8} sm={12}>
              <ApplicationBody
                application={application}
                disabled={disabled}
                tableTypes={tableTypes}
              />
            </Col>
            {tableTypes.length > 0 && (
              <Col className="margin-top-10" lg={6} sm={12}>
                <Button
                  block
                  color="primary"
                  disabled={disabled}
                  id="submitAreaApplication"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            )}
            <Col sm={12} />
            <Col className="margin-top-10" lg={6} sm={12}>
              <DeleteApplicationButton
                application={application}
                dealerAreaId={dealerAreaId}
                dealerId={dealer.id}
              />
            </Col>
          </Row>
        )}
      </LoadingWrapper>
    </JSONForm>
  );
};

interface ApplicationBodyProps {
  readonly application: DealerApplication | undefined;
  readonly disabled: boolean;
  readonly tableTypes: ProductModel[];
}

const ApplicationBody: FC<ApplicationBodyProps> = (props) => {
  const { application, disabled, tableTypes } = props;

  if (tableTypes.length === 0) {
    return (
      <Col lg={12} xs={12}>
        <MessageCard icon="block" id="noTablesFailed" level="danger">
          <CardTitle>No table types available</CardTitle>
          No table types available to create an application.
        </MessageCard>
      </Col>
    );
  }

  if (!application) {
    return (
      <ApplicationCard application={application} disabled={disabled} tableTypes={tableTypes} />
    );
  }

  return (
    <Row className="justify-content-center">
      <Col lg={6} sm={12}>
        <ApplicationCard application={application} disabled={disabled} tableTypes={tableTypes} />
      </Col>
      <Col lg={6} sm={12}>
        <Card id="nextStepsCard">
          <CardBody>
            <CardTitle>Next Steps</CardTitle>
            <NextStepsCard application={application} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const NextStepsCard: FC<{ readonly application: DealerApplication }> = ({ application }) => {
  const config = useConfig();

  if (application.status === 'pending') {
    return (
      <ul>
        <li>
          Your application has been submitted and is pending approval by the event. No action is
          required at this time.
        </li>
      </ul>
    );
  }

  if (application.status === 'rejected') {
    return (
      <ul>
        <li>
          Thank you for taking the time to submit a vendor application, but unfortunately your
          application has been rejected by the event at this time.
        </li>
      </ul>
    );
  }

  if (application.status === 'waitlisted') {
    return (
      <ul>
        <li>
          Your application is waitlisted and is pending approval by the event. No action is required
          at this time.
        </li>
        <li>You will be contacted if your application status changes.</li>
      </ul>
    );
  }

  if (!application.paidOrderItemId) {
    // TODO: Add payment amount and link.
    return (
      <ul>
        <li>Your application was approved and is now pending payment.</li>
        <li>
          If you have any assistants that will be helping you at the event, please add them via the{' '}
          <Link to="/vendor/assistants">Vendor Assistants</Link> page.
        </li>
        <li>
          If you need to edit or cancel your application, please contact{' '}
          {config.contact.email.dealers}.
        </li>
      </ul>
    );
  }

  return (
    <ul>
      <li>
        Your application was approved and has been paid. You're ready for the event and we look
        forward to welcoming you!
      </li>
      <li>
        If you have any assistants that will be helping you at the event, please add them via the{' '}
        <Link to="/vendor/assistants">Vendor Assistants</Link> page.
      </li>
      <li>
        If you need to edit or cancel your application, please contact{' '}
        {config.contact.email.dealers}.
      </li>
    </ul>
  );
};

const ApplicationCard: FC<ApplicationBodyProps> = (props) => {
  const { application, disabled, tableTypes } = props;
  const [selectedTableTypeId, setSelectedTableTypeId] = useState<number>(
    application?.tableType.id ?? tableTypes[0].id,
  );

  return (
    <Card>
      <CardBody>
        <CardTitle>Space Information</CardTitle>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="specialRequests">Special Requests</Label>
              <Input
                defaultValue={application?.specialRequests}
                disabled={disabled}
                id="specialRequests"
                name="specialRequests"
                type="textarea"
              />
            </FormGroup>
            <TableTypeSelect
              disabled={disabled}
              selectedTableTypeId={selectedTableTypeId}
              setSelectedTableTypeId={setSelectedTableTypeId}
              tableTypes={tableTypes}
            />
          </Col>
          <Col xs={12}>
            <ApplicationAddons
              application={application}
              disabled={disabled}
              selectedTableType={tableTypes.find((t) => selectedTableTypeId === t.id)}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

interface TableTypeSelectProps {
  readonly tableTypes: ProductModel[];
  readonly selectedTableTypeId: number;
  readonly setSelectedTableTypeId: (value: number) => void;
  readonly disabled: boolean;
}

const TableTypeSelect: FC<TableTypeSelectProps> = (props) => {
  const { tableTypes, selectedTableTypeId, setSelectedTableTypeId, disabled } = props;

  return (
    <FormGroup>
      <Label for="tableTypeId">Table Type</Label>
      <Input
        disabled={disabled}
        id="tableTypeId"
        name="tableTypeId"
        onChange={(e) => {
          setSelectedTableTypeId(Number.parseInt(e.target.value, 10));
        }}
        type="select"
        value={selectedTableTypeId.toString()}
      >
        {tableTypes.map((tableType) => {
          return (
            <option key={tableType.id} value={tableType.id}>
              {displayName(tableType)} (${getProductPrice(tableType).price})
            </option>
          );
        })}
      </Input>
      <small>{tableTypes.find((t) => selectedTableTypeId === t.id)?.description ?? ''}</small>
    </FormGroup>
  );
};

interface ApplicationAddonsProps {
  readonly selectedTableType?: ProductModel;
  readonly application?: DealerApplication;
  readonly disabled: boolean;
}

const ApplicationAddons: FC<ApplicationAddonsProps> = (props) => {
  const { application, selectedTableType, disabled } = props;
  const dealerRequestTypes = application?.dealerRequestTypes.map((t) => t.id) ?? [];

  if (!selectedTableType) {
    return null;
  }

  return (
    <>
      {selectedTableType.addons.map((addon) => {
        const key = `requests${addon.id}`;
        return (
          <div className="custom-control custom-checkbox margin-top-10" key={key}>
            <Input
              className="custom-control-input"
              defaultChecked={dealerRequestTypes.includes(addon.id)}
              disabled={disabled}
              id={key}
              name="requests[]"
              type="checkbox"
              value={addon.id}
            />
            <Label className="custom-control-label" for={key}>
              {displayName(addon)} {!addon.isFree && `($${getProductPrice(addon).price})`}
            </Label>
          </div>
        );
      })}
    </>
  );
};

const DeleteApplicationButton: FC<{
  readonly dealerId: number;
  readonly dealerAreaId: number;
  readonly application?: DealerApplication;
}> = ({ dealerAreaId, application, dealerId }) => {
  const history = useHistory();
  const user = useUser()!;

  if (!application || application.status !== 'pending') {
    return null;
  }

  async function deleteApplication(): Promise<void> {
    try {
      await api.deleteActiveDealerApplication(dealerId, dealerAreaId);
      let dealer = null;
      try {
        dealer = await api.getUserDealer(user.id);
      } catch {
        /* NO-OP */
      }

      toast.success('Your vendor application has been deleted.');
      history.push(dealer ? '/vendor' : '/');
    } catch (error) {
      captureError(error as Error);
    }
  }

  return (
    <Button block color="danger" id="deleteApplication" onClick={deleteApplication} type="button">
      Delete Application
    </Button>
  );
};
