import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { OrderItemProduct } from '..';
import * as OrderSummary from '../../../shared/orders/model';
import { ProductCategoryModel } from '../../../shared/orders/product';
import { CurrentUser } from '../../../shared/user/base';
import {
  MaterialIcon,
  OrderItemElement,
  OrderItemSelectOption,
  RefundRequestModal,
  UserStateComponent,
} from '../../components';
import { PageHeader } from '../../components/PageHeader';
import { GlobalState } from '../../services';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { cToUsdStrPref } from '../../utils/cToUsdStr';

interface OrderHistoryComponentState {
  refundOrder?: OrderSummary.Order;
  modalOption?: OrderItemSelectOption;
}

interface OrderHistoryComponentProps {
  readonly user: CurrentUser | null;
}

interface LoadingData {
  categories: ProductCategoryModel[];
  orders: OrderSummary.Order[];
}

class OrderHistoryComponent extends Component<
  OrderHistoryComponentProps,
  OrderHistoryComponentState
> {
  public override state: OrderHistoryComponentState = {};

  public override render(): JSX.Element {
    return (
      <UserStateComponent>
        <PageHeader>Order History</PageHeader>
        <Row className="justify-content-center" id="orderHistory">
          <LoadingWrapper<LoadingData, void>
            dataFetcher={async () => await this.fetchData()}
            inline
          >
            {({ orders, categories }, refresh) =>
              orders.length === 0
                ? this.showNoHistory()
                : this.showOrderHistory(orders, categories, refresh)
            }
          </LoadingWrapper>
        </Row>
      </UserStateComponent>
    );
  }

  private showOrderHistory(
    orders: OrderSummary.Order[],
    categories: ProductCategoryModel[],
    refresh: () => void,
  ): JSX.Element {
    const { refundOrder, modalOption } = this.state;
    return (
      <>
        {orders
          .filter(({ orderItems }) => orderItems && orderItems.length > 0)
          .map((order) => (
            <Col key={order.id} lg={8} xs={12}>
              <OrderItemElement
                categories={categories}
                hasRefunds={order.refunds && order.refunds.length > 0}
                id={`order_${order.id}`}
                isCustomer
                onSelect={(opt) => {
                  this.handleItemSelect(opt, order);
                }}
                order={order}
              />
            </Col>
          ))}
        {refundOrder && modalOption === 'requestRefund' && (
          <RefundRequestModal
            onCancel={() => {
              this.hideRefundModal();
            }}
            onSuccess={() => {
              this.hideRefundModal(refresh);
            }}
            order={refundOrder}
          />
        )}
        {refundOrder && modalOption === 'viewRefundHistory' && this.showHistoryModal()}
      </>
    );
  }

  private handleItemSelect(opt: OrderItemSelectOption, order: OrderSummary.Order): void {
    this.setState({
      modalOption: opt,
      refundOrder: order,
    });
  }

  private hideRefundModal(refresh?: () => void): void {
    this.setState({
      modalOption: undefined,
      refundOrder: undefined,
    });

    if (refresh) {
      refresh();
    }
  }

  private showNoHistory(): JSX.Element {
    return (
      <Col className="text-center" id="noOrderHistory" lg={8} xs={12}>
        <MaterialIcon large name="sentiment_dissatisfied" />
        <h4>You've got no previous orders.</h4>
        <p>Once you pay for an order, you can view and manage it here.</p>
      </Col>
    );
  }

  private async fetchData(): Promise<LoadingData> {
    const { user } = this.props;

    const [categories, orders] = await Promise.all([
      api.getProductCategories(),
      api.getOrdersByUserId(user!.id).then((data) => data.sort((a, b) => b.id - a.id)),
    ]);

    return {
      categories,
      orders,
    };
  }

  private showHistoryModal(): JSX.Element {
    const { refundOrder: order } = this.state;

    return (
      <Modal className="modal-large" id="refundModal" isOpen>
        <ModalHeader>Refund History: Order #{order!.id}</ModalHeader>
        <ModalBody className="table">
          {order!.refunds.map((refund) => (
            <div className="table-item" key={refund.id}>
              <h4>Refund #{refund.id}</h4>
              {refund.status === 'pending' && <Badge color="warning">Pending Approval</Badge>}
              {refund.status === 'denied' && <Badge color="danger">Denied</Badge>}
              {refund.status === 'approved' && (
                <>
                  <Badge color="success">Approved</Badge>
                  <small> &mdash; {cToUsdStrPref(refund.amount)} Refunded</small>
                </>
              )}
              <br />
              <br />
              <hr />
              {refund.items.map(({ itemId }) => {
                const item = order!.orderItems.find(({ id }) => id === itemId)!;
                return <OrderItemProduct item={item} key={item.id} order={order!} readOnly />;
              })}
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              this.hideRefundModal();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedOHC = connect<OrderHistoryComponentProps, {}, {}, GlobalState>(({ user }) => ({
  user,
}))(OrderHistoryComponent);

export { connectedOHC as OrderHistoryComponent };
