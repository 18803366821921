import React, { FC } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { useConfig } from '../../../utils';

export const DealerBan: FC = () => {
  const config = useConfig();
  return (
    <Row className="justify-content-center" id="dealerBanned">
      <Col lg={6} xs={12}>
        <Card className="danger">
          <CardBody className="text-center">
            <MaterialIcon large name="block" type="danger" />
            <CardText tag="div">
              <p>You are currently banned from being a vendor at the convention.</p>
              <p>
                Please contact <strong>{config.contact.email.dealers}</strong> if you think this is
                in error.
              </p>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
