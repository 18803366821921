import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { LazyMarkdown, MaterialIcon, PageHeader } from '../../components';
import { UserStateComponent } from '../../components/UserState';
import {
  DateRange,
  useBoolState,
  useConfig,
  useConvention,
  useQuery,
  useToggle,
} from '../../utils';
import { LoginForm } from './LoginForm';

export const LoginComponent: FC = () => {
  const {
    contact: { email },
    activeConventionId,
    conventions,
    organization,
  } = useConfig();

  let selectedConvention = useConvention();
  const isMaintenance = organization.maintenanceMode || selectedConvention.maintenanceMode;
  const [showLogin, enableShowLogin] = useBoolState(!isMaintenance);

  const [resetModalOpen, toggleResetModal] = useToggle(false);
  const params = useQuery();
  const conventionId = Number.parseInt(params.get('conventionId') ?? '', 10);

  function onResetResult(): void {
    toast.info(() => (
      <p>
        Your password reset request has been submitted.
        <br />
        If your email is registered to an account, you should receive an email shortly.
      </p>
    ));

    toggleResetModal();
  }

  for (const c of conventions) {
    // Only redirect if defaultKiosk is true, a layout is provided, and redirect isn't specified or is /
    const shouldKioskRedirect =
      c.defaultKiosk && !!c.defaultKioskLayoutId && (params.get('redirect') ?? '/') === '/';

    // If we're on the correct host for c (convention)
    if (window.location.host === c.domain) {
      // Check to see if we should redirect to kiosk mode FIRST
      if (shouldKioskRedirect) {
        window.location.replace(`https://${c.domain}/kiosk`);
        return null;
      }

      // If not, check to see if this is the primary convention, if not, redirect for login
      if (c.id !== activeConventionId) {
        return (
          <UserStateComponent requireLoggedOut>
            <Redirect to="/" />
          </UserStateComponent>
        );
      }
    }

    // Display the information about the correct convention if we are being redirected
    if (conventionId === c.id) {
      if (shouldKioskRedirect) {
        window.location.replace(`https://${c.domain}/kiosk`);
        return null;
      }

      selectedConvention = c;
    }
  }

  return (
    <UserStateComponent requireLoggedOut>
      <PageHeader className={['mt-3']}>Welcome</PageHeader>
      <Row className="justify-content-center">
        <Col className="margin-bottom-10" lg={3} md={6} xs={12}>
          <Card className="margin-bottom-10">
            <CardBody className="text-center">
              <CardTitle>
                <img src="/api/logo" style={{ maxWidth: '128px' }} />
              </CardTitle>
              <CardTitle>{selectedConvention.longName}</CardTitle>
              <CardText>
                <DateRange
                  end={selectedConvention.endAt}
                  start={selectedConvention.startAt}
                  timeZone={selectedConvention.timeZone}
                />
              </CardText>
              <CardText>{selectedConvention.venue}</CardText>
            </CardBody>
          </Card>
          {selectedConvention.loginAnnouncement && (
            <Card>
              <CardBody>
                <LazyMarkdown source={selectedConvention.loginAnnouncement} />
              </CardBody>
            </Card>
          )}
        </Col>
        {showLogin && (
          <LoginForm onForgotPassword={toggleResetModal} showRegister={!isMaintenance} />
        )}
        {!showLogin && (
          <Col className="margin-bottom-10" id="maintenanceMode" lg={3} md={6} xs={12}>
            <Card>
              <CardBody className="text-center">
                <MaterialIcon large name="offline_bolt" type="danger" />
                <h4>Maintenance Mode</h4>
                <hr />
                <p>We are currently performing maintenance on the registration system.</p>
                <p>Please check back later.</p>
              </CardBody>
              <CardFooter>
                <small>
                  <Button color="secondary" id="navLogin" onClick={enableShowLogin} outline>
                    <MaterialIcon name="supervised_user_circle" /> Admin Login
                  </Button>
                </small>
              </CardFooter>
            </Card>
          </Col>
        )}
      </Row>
      <Modal isOpen={resetModalOpen} toggle={toggleResetModal}>
        <JSONForm id="resetForm" method="post" onSuccess={onResetResult} path="/api/login/reset">
          <ModalHeader toggle={toggleResetModal}>Reset Password</ModalHeader>
          <ModalBody>
            <p>
              If you have forgotten your password, simply enter your email address and we will send
              you a link to reset your password.
            </p>
            <p>
              If you don't receive an email within 30 minutes, you may not have registered an
              account with that email. Contact <strong>{email.registration}</strong> for assistance.
            </p>
            <hr />
            <FormGroup row>
              <Label for="resetusernameOrMail" sm={4}>
                Email
              </Label>
              <Col sm={8}>
                <Input
                  id="resetusernameOrMail"
                  name="usernameOrMail"
                  placeholder="example@concat.app"
                  required
                  type="email"
                />
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" id="resetPassword" type="submit">
              Reset Password
            </Button>{' '}
            <Button color="secondary" onClick={toggleResetModal}>
              Cancel
            </Button>
          </ModalFooter>
        </JSONForm>
      </Modal>
    </UserStateComponent>
  );
};
