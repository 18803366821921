import React, { FC } from 'react';
import { ProductModel } from '../../shared/orders';
import { cToUsdStrPref } from '../utils/cToUsdStr';

interface PriceBreakdownProps {
  readonly product: ProductModel;
}

export const PriceBreakdown: FC<PriceBreakdownProps> = ({ product }: PriceBreakdownProps) => {
  if (product.price === 0) {
    return <span className="text-success">Free!</span>;
  }

  return <span>{cToUsdStrPref(product.price)}</span>;
};
