import React, { FC } from 'react';
import { MessageCard, PageHeader, UserStateComponent } from '../../components';
import { ChildRegistrations } from '../../components/ChildRegistrations';
import { ChildRegistration } from '../../models';
import { isLogicError, isResourceError, useUser } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { LogicError } from '../../utils/errorHandling';

type LoadingData = [ChildRegistration[], number];

const NotRegistered: FC = () => (
  <MessageCard icon="error_outline" level="danger" title="You have not registered yet">
    In order to register children to your account, you need to register for the convention first.
  </MessageCard>
);

const ChildrenNotAllowed: FC = () => (
  <MessageCard icon="remove_circle" level="danger" title="Child registrations not allowed">
    The event policy currently disallows child registrations to be created.
  </MessageCard>
);

const ParentCannotVolunteer: FC = () => (
  <MessageCard icon="face" id="parentCanNotVolunteer" level="warning">
    No human being can possibly be capable of volunteering and chaperoning minors at the same time.
  </MessageCard>
);

const errorDisplayHandler = (err: Error) => {
  if (isResourceError(err, 'Registration')) {
    return <NotRegistered />;
  }

  if (isLogicError(err, LogicError.ChildrenNotAllowed)) {
    return <ChildrenNotAllowed />;
  }

  if (isLogicError(err, LogicError.ParentCannotVolunteer)) {
    return <ParentCannotVolunteer />;
  }

  return false;
};

const fetchData = async (userId: number): Promise<LoadingData> => {
  return await Promise.all([
    api.getCurrentUserChildren(),
    api.getUserActiveRegistration(userId).then((d) => d.id),
  ]);
};

export const ChildRegistrationComponent: FC = () => {
  const userId = useUser()!.id;
  return (
    <LoadingWrapper<LoadingData, number>
      dataFetcher={fetchData}
      errorDisplay={errorDisplayHandler}
      inline
      passback={userId}
    >
      {([children, registrationId], refresh) => (
        <UserStateComponent>
          <PageHeader>Register Children</PageHeader>
          <ChildRegistrations items={children} onUpdate={refresh} registrationId={registrationId} />
        </UserStateComponent>
      )}
    </LoadingWrapper>
  );
};
