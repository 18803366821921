import React from 'react';
import { render } from 'react-dom';
import { toast } from 'react-toastify';
import {
  IUsbDeviceManagerEventMap,
  LabelPrinter,
  Percent,
  ReadyToPrintDocuments,
  UsbDeviceManager,
  // eslint-disable-next-line import/no-unresolved
} from 'webzlp';
import { BadgeData, BadgeDesign, DesignerNode } from '../../../../shared/user/badgeDesign';
import { BadgePageRender, transformNodeMap } from '../../../modules/badgerenderer/BadgeRender';
import { delayPromise } from '../../../utils';
import {
  BadgePrinterBase,
  ConfigurablePrinter,
  getPrinterUid,
  PrinterConfig as PrinterConfigBase,
  PrinterInfoBase,
  PrinterManagerBase,
  PrinterManagerStatus,
  PrinterStatus,
  PrinterType,
} from './PrinterBase';

interface LabelPrinterConfig extends PrinterConfigBase {
  serial: string;
  width: number;
  speed: number;
  autosense: boolean;
  darkness: number;
}

async function readFont(blob: Blob): Promise<string> {
  return await new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = function onloadend() {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(blob);
  });
}

interface FontBlob {
  name: string;
  blob: string;
}

async function getFonts(name: string): Promise<FontBlob[]> {
  const fetch1 = await fetch(`https://fonts.googleapis.com/css?family=${name}`);
  const fetchText = await fetch1.text();
  const fontLocations = fetchText.match(/https:\/\/[^)]+/g);

  if (!fontLocations) {
    return [];
  }

  const result: FontBlob[] = [];

  for (const loc of fontLocations) {
    const fetch2 = await fetch(loc);
    const blob = await fetch2.blob();
    const font = await readFont(blob);
    result.push({ blob: font, name });
  }

  return result;
}

async function getSVGFontStyle(nodes: DesignerNode[]) {
  const fonts: FontBlob[] = [];

  for (const node of nodes) {
    if (node.type !== 'text') {
      continue;
    }

    try {
      const fontName = node.fontFamily === 'custom' ? node.customFontFamily! : node.fontFamily;
      const fontBlobs = await getFonts(fontName);
      for (const font of fontBlobs) {
        fonts.push(font);
      }
    } catch (error) {
      // Just a warning. Do not block drawing the badge.
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  }

  const fontFaces = fonts.map((f) => {
    return `@font-face {
      font-family: "${f.name}";
      src: url("${f.blob}") format("woff2");
    }`;
  });

  return `<style type="text/css">${fontFaces.join('\n')}</style>`;
}

// eslint-disable-next-line import/no-unused-modules
export class ZebraPrinter implements BadgePrinterBase, ConfigurablePrinter {
  public serial = '';
  public readonly canConfigure = true;
  public readonly type = PrinterType.WebLabel;
  public readonly uid;

  public constructor(
    public readonly name: string,
    private readonly printer: LabelPrinter,
    private config: LabelPrinterConfig,
  ) {
    this.serial = printer.printerSerial;
    this.uid = getPrinterUid(this.type, this.serial);
  }

  public get status(): PrinterStatus {
    return this.printer.connected ? PrinterStatus.Connected : PrinterStatus.Disconnected;
  }

  public getConfig(): LabelPrinterConfig {
    return this.config;
  }

  public async setConfig(newConfig: LabelPrinterConfig): Promise<void> {
    this.config = {
      ...this.config,
      ...newConfig,
    };

    const configDoc = this.printer
      .getConfigDocument()
      .setPrintDirection()
      .setLabelHomeOffsetDots(0, 0)
      .setPrintSpeed(this.config.speed)
      .setDarknessConfig(this.config.darkness as Percent)
      .setLabelDimensions(this.config.width);

    const doc = this.config.autosense ? configDoc.autosenseLabelLength() : configDoc.finalize();

    // And send the whole shebang to the printer!
    await this.printer.sendDocument(doc);
  }

  public async print(badge: BadgeData, design: BadgeDesign): Promise<boolean> {
    const printArea = document.getElementById('printArea')!;

    render(<BadgePageRender labels={[badge]} nodes={transformNodeMap(design)} />, printArea);

    // Allows time for OccamyText to find best font size
    await delayPromise(200);

    const rect = printArea.getElementsByClassName('badgeLabel')[0].getBoundingClientRect();

    // Add a small margin as printer alignment is not exact.
    const width = this.printer.printerOptions.labelWidthDots - 2;
    const height = this.printer.printerOptions.labelHeightDots - 2;
    const scale = Math.min(width / rect.width, height / rect.height);

    const topDiv = printArea.firstElementChild!.cloneNode(true) as Element;
    topDiv.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');

    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    foreignObject.setAttribute('width', '100%');
    foreignObject.setAttribute('height', '100%');
    foreignObject.setAttribute('transform', `scale(${scale})`);
    foreignObject.append(topDiv);

    const defsStyle = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    defsStyle.innerHTML = await getSVGFontStyle(design.content);
    foreignObject.append(topDiv);

    const svg = document.createElement('svg');
    svg.setAttribute('width', width.toString());
    svg.setAttribute('height', height.toString());
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.append(defsStyle);
    svg.append(foreignObject);

    const builder = this.printer.getLabelDocument();
    const fromSvg = await builder.addImageFromSVG(svg.outerHTML, width, height);
    const doc = fromSvg.addPrintCmd().finalize();

    await this.printer.sendDocument(doc);

    if (badge.registrationId) {
      await api.markRegistrationBadgeAsPrinted(
        badge.registrationId,
        null,
        new URLSearchParams(location.search).get('barcode') ?? undefined,
      );
    }

    return true;
  }

  public async testPrint(): Promise<boolean> {
    const doc = ReadyToPrintDocuments.printTestLabelDocument(
      this.printer.printerOptions.labelWidthDots,
    );

    await this.printer.sendDocument(doc);

    return true;
  }
}

interface LabelPrinterInfo extends PrinterInfoBase {
  serial: string;
  config: LabelPrinterConfig;
}

export class LabelPrinterManager implements PrinterManagerBase {
  public name = 'Badge Printer with WebUSB';
  public type = PrinterType.WebLabel;
  public supportsManualConnect = true;
  public status: PrinterManagerStatus = PrinterManagerStatus.Scanning;

  private readonly mgr?: UsbDeviceManager<LabelPrinter>;

  public constructor() {
    if (!window.navigator.usb) {
      // TODO: Better handling for non-WebUSB browsers
      this.mgr = undefined;
      this.status = PrinterManagerStatus.Error;
      return;
    }

    if (window.zebraPrinterManager) {
      this.mgr = window.zebraPrinterManager;
      return;
    }

    this.mgr = new UsbDeviceManager<LabelPrinter>(
      window.navigator.usb,
      LabelPrinter.fromUSBDevice,
      {
        requestOptions: {
          filters: [
            {
              // Zebra
              vendorId: 0x0a_5f,
            },
          ],
        },
        /*
         * Print debug messages to the console
         * TODO: This is very chatty!
         */
        debug: true,
      },
    );

    this.mgr
      .forceReconnect()
      .then(() => {
        this.status = PrinterManagerStatus.Ready;
      })
      .catch((error: Error) => {
        toast.error(error.message);
        this.status = PrinterManagerStatus.Error;
      });

    window.zebraPrinterManager = this.mgr;
  }

  public addEventListener<T extends keyof IUsbDeviceManagerEventMap<LabelPrinter>>(
    type: T,
    listener:
      | EventListenerObject
      | ((
          this: UsbDeviceManager<LabelPrinter>,
          ev: IUsbDeviceManagerEventMap<LabelPrinter>[T],
        ) => void)
      | null,
    options?: AddEventListenerOptions | boolean,
  ): void {
    this.mgr?.addEventListener(type, listener, options);
  }

  public removeEventListener<T extends keyof IUsbDeviceManagerEventMap<LabelPrinter>>(
    type: T,
    listener:
      | EventListenerObject
      | ((
          this: UsbDeviceManager<LabelPrinter>,
          ev: IUsbDeviceManagerEventMap<LabelPrinter>[T],
        ) => void)
      | null,
    options?: AddEventListenerOptions | boolean,
  ): void {
    this.mgr?.removeEventListener(type, listener, options);
  }

  public async startManualConnect(): Promise<void> {
    await this.mgr?.promptForNewDevice();
  }

  public async getAvailablePrinters(): Promise<LabelPrinterInfo[]> {
    return this.mgr?.devices.map((d) => this.toPrinterInfo(d)) ?? [];
  }

  public usePrinter(serial: string, config: LabelPrinterConfig): ZebraPrinter | undefined {
    const printer = this.mgr?.devices.find((p) => p.printerSerial === serial);
    if (printer !== undefined && this.mgr !== undefined) {
      return new ZebraPrinter(this.getPrinterName(printer, true), printer, config);
    }

    return undefined;
  }

  private toPrinterInfo(printer: LabelPrinter): LabelPrinterInfo {
    const o = printer.printerOptions;
    const serial = o.serialNumber;

    return {
      name: this.getPrinterName(printer, true),
      type: this.type,
      serial,
      uid: getPrinterUid(this.type, serial),
      config: {
        serial,
        speed: o.speed.printSpeed,
        darkness: o.darknessPercent,
        width: o.labelWidthDots,
        autosense: false,
      },
    };
  }

  private getPrinterName(p: LabelPrinter, short = false): string {
    return short
      ? `Label (#${p.printerSerial})`
      : `${p.printerManufacturer} ${p.printerModel.model} (S/N: ${p.printerSerial})`;
  }
}
