import React, { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PageHeader } from '../../components';

export const NotFoundError: FC = () => (
  <Container className="justify-content-center">
    <Row>
      <Col xs={12}>
        <PageHeader>
          Page Not Found <small>(HTTP 404)</small>
        </PageHeader>
        <p>The page you were looking for doesn't exist. Sorry about that!</p>
      </Col>
    </Row>
  </Container>
);
