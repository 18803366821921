import React, { FC, useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { Order, ProductModel, ProductVisibility } from '../../shared/orders';
import { ConRegistrationForm } from '../../shared/registration/model';
import { RegistrationInfo } from '../models/RegistrationInfo';
import { RegistrationDynamicInfoForm } from '../modules/registration/DynamicRegForm';
import { useFetcher, useUser } from '../utils';
import { LoadingState } from '../utils/LoadingState';
import { captureError } from '../utils/errorHandling';
import { EventRegistrationComplete } from './EventRegistrationComplete';
import {
  Loading,
  LoadingError,
  MaterialIcon,
  PageHeader,
  RegistrationInfoForm,
  RegTypeSelectList,
  WizardComponent,
  WizardStatus,
} from '.';

async function loadRegistration(
  userId: number,
  registration?: RegistrationInfo,
): Promise<{ form: ConRegistrationForm; registrationTypes: ProductModel[]; order: Order }> {
  const category = (await api.getProductCategories()).find(({ isRegistration }) => isRegistration);
  const orderPromise = api.getActiveOrder(userId, true);

  if (!category) {
    return {
      form: {
        flags: [],
        options: [],
        terms: '',
      },
      registrationTypes: [],
      order: await orderPromise,
    };
  }

  const [registrationTypes, form] = await Promise.all([
    api.getProductsByCategory(category.id, registration?.attendanceTypeId),
    api.getRegistrationForm(),
  ]);

  return {
    order: await orderPromise,
    form,
    registrationTypes,
  };
}

export const EventRegistration: FC<{ readonly registration?: RegistrationInfo }> = ({
  registration: initReg,
}) => {
  const user = useUser()!;
  const [registration, setRegistration] = useState(initReg);
  const [regTypeState, setRegTypeState] = useState<WizardStatus>(WizardStatus.InProgress);
  const [reviewState, setReviewState] = useState<WizardStatus>(WizardStatus.NotStarted);
  const [selectedRegistrationType, setSelectedRegistrationType] = useState<number | undefined>();
  const [additionalInfoState, setAdditionalInfoState] = useState<WizardStatus>(
    WizardStatus.NotStarted,
  );

  const fetcher = useFetcher(async () => {
    return await loadRegistration(user.id, registration);
  });

  useEffect(() => {
    if (fetcher.data && registration) {
      const { order } = fetcher.data;
      const orderItem = order.orderItems.find(
        (oi) =>
          oi.referenceId === registration.id &&
          registrationTypes.find(({ id }) => id === oi.product.id),
      );

      const type = registrationTypes.find(
        ({ id }) => id === (orderItem ? orderItem.product.id : registration.attendanceTypeId),
      );

      if (type) {
        selectRegistrationType(type.id);
      }
    }
  }, [fetcher.state]);

  const resetRegSelect = useCallback(() => {
    setRegTypeState(WizardStatus.InProgress);
    if (additionalInfoState === WizardStatus.InProgress) {
      setAdditionalInfoState(WizardStatus.NotStarted);
    } else {
      setAdditionalInfoState(WizardStatus.NotStarted);
      setReviewState(WizardStatus.NotStarted);
    }
  }, [additionalInfoState]);

  const selectRegistrationType = useCallback(
    (productId: number) => {
      setSelectedRegistrationType(productId);
      setRegTypeState(WizardStatus.Completed);

      if (additionalInfoState === WizardStatus.Completed) {
        setReviewState(WizardStatus.InProgress);
      } else {
        setAdditionalInfoState(WizardStatus.InProgress);
      }
    },
    [additionalInfoState],
  );

  const completeRegInfo = useCallback(async () => {
    try {
      const reg = await api.getUserActiveRegistration(user.id);
      setRegistration(reg);
      setAdditionalInfoState(WizardStatus.Completed);
      setReviewState(WizardStatus.InProgress);
    } catch (error) {
      captureError(error as Error);
    }
  }, [user]);

  if (fetcher.state === LoadingState.Loading) {
    return <Loading inline />;
  }

  if (fetcher.state === LoadingState.Error) {
    return <LoadingError errorText={(fetcher.error as Error).message} />;
  }

  const { registrationTypes, form } = fetcher.data!;
  if (!registrationTypes || registrationTypes.length === 0) {
    return <RegistrationClosed />;
  }

  const registrationType = registrationTypes.find((t) => t.id === selectedRegistrationType);
  const validRegistrationTypes = registrationTypes.filter(
    (rt) => selectedRegistrationType === rt.id || rt.visibility === ProductVisibility.PUBLIC,
  );

  return (
    <>
      <PageHeader>Event Registration</PageHeader>
      <Row className="justify-content-center">
        <Col lg={8} xs={12}>
          <WizardComponent
            className="justify-content-center"
            items={[
              {
                status: regTypeState,
                text: 'Registration Type',
              },
              {
                status: additionalInfoState,
                text: 'Additional Information',
              },
              {
                status: reviewState,
                text: 'Review',
              },
            ]}
          />
        </Col>
      </Row>
      <br />
      {regTypeState === WizardStatus.InProgress && (
        <RegTypeSelectList
          attendanceType={registrationType}
          attendanceTypes={validRegistrationTypes}
          onSelect={selectRegistrationType}
          paidAttendanceType={
            registration?.paidOrderItem &&
            validRegistrationTypes.find(({ id }) => id === registration.paidOrderItem!.productId)
          }
          raffle={registration?.raffle}
        />
      )}
      {additionalInfoState === WizardStatus.InProgress &&
        (form.formLayout ? (
          <RegistrationDynamicInfoForm
            attendanceType={registrationType!}
            onReset={resetRegSelect}
            onSuccess={completeRegInfo}
            regForm={form}
            registration={registration}
            user={user}
          />
        ) : (
          <RegistrationInfoForm
            attendanceType={registrationType!}
            onReset={resetRegSelect}
            onSuccess={completeRegInfo}
            regForm={form}
            registration={registration}
            user={user}
          />
        ))}
      {reviewState === WizardStatus.InProgress && (
        <EventRegistrationComplete
          modifyRegistration={resetRegSelect}
          product={registrationType!}
          registration={registration}
        />
      )}
    </>
  );
};

const RegistrationClosed: FC = () => {
  return (
    <>
      <PageHeader>Event Registration</PageHeader>
      <Row className="justify-content-center" id="registrationEmpty">
        <Col lg={6} xs={12}>
          <Card className="danger">
            <CardBody className="text-center">
              <MaterialIcon large name="remove_circle" type="danger" />
              <CardText tag="div">
                <p>There are no products available for registration.</p>
                <p>Please check back later.</p>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
