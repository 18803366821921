import React, { Component, FC, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Carousel,
  CarouselCaption,
  CarouselIndicators,
  CarouselItem,
  Col,
  FormText,
  Input,
  Row,
} from 'reactstrap';
import { BadgeArt } from '../models';
import { isMobile } from '../services';
import { Fetcher, isLogicError, useConvention, useFetcher } from '../utils';
import { captureError, LogicError } from '../utils/errorHandling';
import { MaterialIcon } from './MaterialIcon';

interface BadgeArtRowProps {
  readonly registrationId?: number;
  // Previously selected option
  readonly defaultBadgeArtId?: number;
  readonly badgeArtId: number | undefined;
  readonly setBadgeArtId: (value: number) => void;
}

export const BadgeArtSelectorCard: FC<BadgeArtRowProps> = ({
  registrationId,
  setBadgeArtId,
  badgeArtId,
  defaultBadgeArtId,
}) => {
  const { enableBadgeArt } = useConvention();
  const [refreshId, setRefreshId] = useState(0);

  if (!enableBadgeArt) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const badgeArts = useFetcher(async () => {
    const arts = await api.getBadgeArt();

    if (!badgeArtId && arts.length > 0) {
      const randomArts = arts.filter((t) => t.limit === null || t.count < t.limit);

      if (randomArts.length > 0) {
        // Get random art
        setBadgeArtId(randomArts[Math.floor(Math.random() * randomArts.length)].id);
      }
    }

    return arts;
  }, [refreshId]);

  const art: BadgeArt[] = (badgeArts.data ?? []).filter((t) => {
    if (t.limit === null) {
      return true;
    }

    if (defaultBadgeArtId === t.id) {
      return true;
    }

    return t.count < t.limit;
  });

  return (
    <Card>
      <CardHeader>Badge Art</CardHeader>
      <CardBody>
        <Fetcher inline result={badgeArts}>
          <>
            <BadgeArtSelector
              art={art.map((t) => t.id)}
              artId={badgeArtId}
              onChange={setBadgeArtId}
              refresh={() => {
                setRefreshId(refreshId + 1);
              }}
              regId={registrationId}
            />
            <Input id="badgeArtId" name="badgeArtId" type="hidden" value={badgeArtId} />
          </>
        </Fetcher>
      </CardBody>
    </Card>
  );
};

interface BadgeArtSelectorProps {
  readonly art: number[];
  readonly artId?: number;
  readonly regId?: number;
  onChange(artId: number): void;
  refresh(): void;
}

interface BadgeArtSelectorState {
  animating: boolean;
  loading: boolean;
}

class BadgeArtSelector extends Component<BadgeArtSelectorProps, BadgeArtSelectorState> {
  public constructor(props: BadgeArtSelectorProps) {
    super(props);
    this.state = {
      animating: false,
      loading: false,
    };
  }

  public override render(): JSX.Element {
    const { art } = this.props;
    const { loading } = this.state;
    if (art.length === 0) {
      return (
        <FormText color="muted">
          There is currently no badge art to choose. Check back closer to the convention.
        </FormText>
      );
    }

    if (isMobile) {
      return this.renderMobile();
    }

    return (
      <Row className="justify-content-center">
        {art.map((artId, index) => {
          const isSelected = this.props.artId === artId;
          return (
            <Col key={artId} style={{ marginBottom: '1em' }} xl={3} xs={4}>
              <img
                src={`/api/badgeart/${artId}/image`}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              {isSelected ? (
                <Button
                  block
                  className={loading ? 'saving' : 'selected'}
                  color="secondary"
                  disabled
                  id={`badge${artId}`}
                  outline
                  type="button"
                >
                  {loading ? 'Saving...' : 'Selected'}
                </Button>
              ) : (
                <Button
                  block
                  color="primary"
                  disabled={loading}
                  id={`badge${artId}`}
                  onClick={() => {
                    this.goToIndex(index);
                  }}
                  type="button"
                >
                  Select
                </Button>
              )}
            </Col>
          );
        })}
      </Row>
    );
  }

  private getArtIndex(): number {
    const { artId, art } = this.props;

    if (!artId || art.length === 0) {
      return 0;
    }

    const index = art.indexOf(artId);

    return index === -1 ? 0 : index;
  }

  private moveIndex(indexMod: number): void {
    const { art } = this.props;
    this.goToIndex((this.getArtIndex() + indexMod) % art.length);
  }

  private goToIndex(newIndex: number): void {
    const { art } = this.props;
    const { animating } = this.state;
    const currentArtIndex = this.getArtIndex();

    if (animating || currentArtIndex === newIndex) {
      return;
    }

    this.props.onChange(art[newIndex]);

    this.quickUpdateBadge(art[newIndex]).catch(captureError);
  }

  private async quickUpdateBadge(index: number): Promise<void> {
    if (this.props.regId === undefined) {
      return;
    }

    this.setState({ loading: true });

    try {
      await api.updateRegistration(this.props.regId, {
        badgeArtId: index,
      });
    } catch (error) {
      captureError(error as Error);
      if (isLogicError(error, LogicError.BadgeArtLimitReached)) {
        this.props.refresh();
      }
    }

    this.setState({ loading: false });
  }

  private onExiting(): void {
    this.setState({
      animating: true,
    });
  }

  private onExited(): void {
    this.setState({
      animating: false,
    });
  }

  private renderMobile(): JSX.Element {
    const { art } = this.props;
    const currentArtIndex = this.getArtIndex();
    const items = art.map((artId) => ({
      altText: `Badge ${artId}`,
      caption: `Badge ${artId}`,
      src: `/api/badgeart/${artId}/image`,
    }));

    const slides = items.map((item) => (
      <CarouselItem
        key={item.src}
        onExited={() => {
          this.onExited();
        }}
        onExiting={() => {
          this.onExiting();
        }}
      >
        <img alt={item.altText} src={item.src} />
        <CarouselCaption captionHeader={item.caption} captionText={item.caption} />
      </CarouselItem>
    ));

    return (
      <Carousel
        activeIndex={currentArtIndex}
        interval={false}
        next={() => {
          this.moveIndex(1);
        }}
        previous={() => {
          this.moveIndex(-1);
        }}
      >
        <CarouselIndicators
          activeIndex={currentArtIndex}
          items={items}
          onClickHandler={(idx) => {
            this.goToIndex(idx);
          }}
        />
        {slides}
        <a
          className="carousel-control-prev"
          onClick={() => {
            this.moveIndex(-1);
          }}
        >
          <MaterialIcon large name="keyboard_arrow_left" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          onClick={() => {
            this.moveIndex(1);
          }}
        >
          <MaterialIcon large name="keyboard_arrow_right" />
          <span className="sr-only">Next</span>
        </a>
      </Carousel>
    );
  }
}
