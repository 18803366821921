import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { FilterOptions } from '.';

export type SelectedOptions<T> = Record<string, FilterOption<T>[]>;

export interface FilterOption<T> {
  name: string;
  value: string;
  map?(data: T): T;
  filter(data: T, options: SelectedOptions<T>): boolean;
}

export interface Filter<T> {
  name: string;
  displayName?: string;
  options: FilterOption<T>[];
}

interface FilterTableProps<T> {
  readonly data: T[];
  readonly filters: Filter<T>[];
  readonly infoUrl?: string;
  renderer(data: T): JSX.Element;
  infoRenderer?(data?: T): JSX.Element;
}

interface FilterTableState<T> {
  visibleData: T[];
  selectedDataId?: number;
}

interface Identifiable {
  id: number;
}

export class FilterTable<T extends Identifiable> extends Component<
  FilterTableProps<T>,
  FilterTableState<T>
> {
  public override state: FilterTableState<T> = {
    visibleData: [],
  };
  private filterFunc?: (data: T[]) => T[];

  public override componentDidMount(): void {
    this.updateData();
  }

  public override componentDidUpdate(prevProps: FilterTableProps<T>): void {
    if (prevProps.data !== this.props.data) {
      this.updateData(false);
    }
  }

  public override render(): JSX.Element {
    const { data, filters, renderer, infoRenderer, infoUrl } = this.props;
    const { selectedDataId, visibleData } = this.state;
    const isFiltered = visibleData.length !== data.length;
    const selectedData = data.find(({ id }) => id === selectedDataId);
    return (
      <Row className={selectedData && 'hasSelectedRow'}>
        <Col xs={12}>
          <FilterOptions<T>
            filters={filters}
            onFilterInit={(f) => {
              this.onFilterInit(f);
            }}
            onUpdate={() => {
              this.updateData();
            }}
          />
        </Col>
        <Col xs={12}>
          <br />
        </Col>
        <Col className="resultTable" lg={infoRenderer ? 8 : 12} xs={12}>
          <Card className="table">
            <CardHeader>
              {visibleData.length} Result(s)
              {isFiltered && ` (Filtered from ${data.length})`}
            </CardHeader>
            {visibleData.map((d) => {
              const id = `row${d.id}`;
              if (!infoRenderer) {
                return (
                  <Link
                    id={id}
                    key={d.id}
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                    to={`${infoUrl!}/${d.id}`}
                  >
                    {renderer(d)}
                  </Link>
                );
              }

              return (
                <div
                  id={id}
                  key={d.id}
                  onClick={() => {
                    this.selectData(d.id);
                  }}
                >
                  {renderer(d)}
                </div>
              );
            })}
          </Card>
        </Col>
        {infoRenderer && (
          <Col className="resultInfo" lg={4} xs={12}>
            <Card>{this.renderSelectedData(selectedData)}</Card>
          </Col>
        )}
      </Row>
    );
  }

  private renderSelectedData(data: T | undefined): JSX.Element {
    const { infoRenderer } = this.props;
    const cardFooter =
      data === undefined ? null : (
        <CardFooter>
          <Button
            block
            color="secondary"
            onClick={() => {
              this.selectData();
            }}
            outline
            type="button"
          >
            Close Info
          </Button>
        </CardFooter>
      );

    return (
      <>
        {infoRenderer!(data)}
        {cardFooter}
      </>
    );
  }

  private selectData(selectedDataId?: number): void {
    this.setState({
      selectedDataId,
    });
  }

  private updateData(resetId = true): void {
    if (!this.filterFunc) {
      return;
    }

    this.setState({
      selectedDataId: resetId ? undefined : this.state.selectedDataId,
      visibleData: this.filterFunc(this.props.data),
    });
  }

  private onFilterInit(filterFunc: (data: T[]) => T[]): void {
    this.filterFunc = filterFunc;
    this.updateData();
  }
}
