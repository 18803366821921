import React, { FC, useState } from 'react';
import { Col, FormGroup, FormText, Input, Label } from 'reactstrap';
import { StripeIdentityUser } from '../../shared/user/identity';
import { CountrySelector } from './CountrySelector';
import { PhoneInput, RegionSelector } from '.';

interface RegisterUserInfoProps {
  readonly forceValues?: StripeIdentityUser;
}

function getDob(input: StripeIdentityUser): string {
  const utcDate = input.bornAt.getUTCDate();
  const utcMonth = input.bornAt.getUTCMonth() + 1;

  const month = utcMonth < 10 ? `0${utcMonth}` : `${utcMonth}`;
  const day = utcDate < 10 ? `0${utcDate}` : `${utcDate}`;

  return `${input.bornAt.getUTCFullYear()}-${month}-${day}`;
}

export const RegisterUserInfo: FC<RegisterUserInfoProps> = ({ forceValues }) => {
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState<string | null>(null);
  const [region, setRegion] = useState('');

  return (
    <>
      <FormGroup row>
        <Label for="firstName" sm={4}>
          First Name
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="given-name"
            defaultValue={forceValues?.firstName}
            disabled={!!forceValues}
            id="firstName"
            name="firstName"
            placeholder="Example: John"
          />
          {forceValues?.lastName ? (
            <FormText color="info">
              This field has been prepopulated with the legal first name from your verified ID
              document.
            </FormText>
          ) : (
            <FormText color="muted">
              This must be your legal first name, as it appears on your government issued ID.
            </FormText>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="lastName" sm={4}>
          Last Name
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="family-name"
            defaultValue={forceValues?.lastName}
            disabled={!!forceValues}
            id="lastName"
            name="lastName"
            placeholder="Example: Smith"
          />
          {forceValues?.lastName ? (
            <FormText color="info">
              This field has been prepopulated with the legal last name from your verified ID
              document.
            </FormText>
          ) : (
            <FormText color="muted">
              This must be your legal last name, as it appears on your government issued ID.
            </FormText>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="preferredName" sm={4}>
          Preferred Name <small>(Optional)</small>
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="username"
            id="preferredName"
            name="preferredName"
            placeholder="Example: Bobcat"
          />
          <FormText color="muted">
            If you have a name that you would prefer we use, please let us know here.
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="bornAt" sm={4}>
          Date of Birth
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="bday"
            defaultValue={forceValues ? getDob(forceValues) : ''}
            disabled={!!forceValues}
            id="bornAt"
            name="bornAt"
            type="date"
          />
          {forceValues?.lastName && (
            <FormText color="info">
              This field has been prepopulated with the legal date of birth from your verified ID
              document.
            </FormText>
          )}
        </Col>
      </FormGroup>
      <hr />
      <FormGroup row>
        <Label for="addressLine1" sm={4}>
          Address Line 1
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="address-line1"
            id="addressLine1"
            name="addressLine1"
            placeholder="Example: 1 Magical Lane"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressLine2" sm={4}>
          Address Line 2 <small>(Optional)</small>
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="address-line2"
            id="addressLine2"
            name="addressLine2"
            placeholder="Example: Apt 42"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressCity" sm={4}>
          City
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="address-level2"
            id="addressCity"
            name="addressCity"
            placeholder="Example: Reno"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressZipcode" sm={4}>
          Zip / Postal Code
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="postal-code"
            id="addressZipcode"
            name="addressZipcode"
            placeholder="Example: 89595"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressCountry" sm={4}>
          Country
        </Label>
        <Col sm={8}>
          <CountrySelector
            autoComplete="country-name"
            classes="form-control"
            id="addressCountry"
            name="addressCountry"
            onChange={setCountry}
            value={country}
            valueType="short"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressState" sm={4}>
          State / Province
        </Label>
        <Col sm={8}>
          <RegionSelector
            autoComplete="address-level1"
            classes="form-control"
            country={country}
            countryValueType="short"
            data-coerce={false}
            disableWhenEmpty
            id="addressState"
            name="addressState"
            onChange={setRegion}
            value={region}
            valueType="short"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="phone" sm={4}>
          Phone Number
        </Label>
        <Col sm={8}>
          <PhoneInput name="phone" onChange={setPhone} value={phone ?? ''} />
        </Col>
      </FormGroup>
    </>
  );
};
