import React, { FC } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DropdownItem, DropdownItemProps } from './DropdownItem';
import { MaterialIcon } from '.';

interface Props {
  readonly button?: boolean;
  readonly items: DropdownItemProps[];
}

export const Dropdown: FC<Props> = ({ button, items }) => (
  <UncontrolledDropdown>
    <DropdownToggle
      className={button ? 'btn btn-outline-secondary' : ''}
      tag={button ? 'button' : 'a'}
      type="button"
    >
      <MaterialIcon className="action-more-options clickable" name="more_horiz" />
    </DropdownToggle>
    <DropdownMenu right>
      {items.map((item) => (
        <DropdownItem key={item.text} {...item} />
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);
