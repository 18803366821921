import React, { FC } from 'react';
import { Dropdown, DropdownItemProps } from '.';

export interface DataInfoViewItemProps {
  readonly rightElement?: JSX.Element | JSX.Element[] | string;
  readonly id: string;
  readonly title: JSX.Element | string;
  readonly subtitle?: string;
  readonly content?: JSX.Element | JSX.Element[];
  readonly dropdownItems?: DropdownItemProps[];
}

export const DataViewItem: FC<DataInfoViewItemProps> = ({
  content,
  dropdownItems,
  id,
  rightElement,
  title,
  subtitle,
}) => (
  <div className="data-item" id={id}>
    <div className="data-item-right">
      <span className="data-item-right-text text-secondary">{rightElement}</span>
      {dropdownItems && <Dropdown button items={dropdownItems} />}
    </div>
    <div>
      {title}
      {subtitle && <small>&nbsp;({subtitle})</small>}
    </div>
    {content && <div>{content}</div>}
  </div>
);
