import React, { FC } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { MaterialIcon } from '.';

interface LoadingErrorProps {
  readonly retryCallback?: () => void;
  readonly errorText?: string;
}

export const LoadingError: FC<LoadingErrorProps> = ({
  errorText = 'Unknown error, contact support.',
  retryCallback,
}): JSX.Element => {
  return (
    <Row className="justify-content-center" id="loadingError">
      <Col lg={6} xs={12}>
        <Card className="warning">
          <CardBody className="text-center">
            <MaterialIcon large name="sync_problem" type="warning" />
            <CardTitle>Something Went Wrong</CardTitle>
            <CardText tag="div">
              <p>
                We encountered an error while trying to load the required content for this page.
              </p>
              <p>Try reloading the page, or contact support if this continues to occur.</p>
              <p>
                <small>{errorText}</small>
              </p>
              {retryCallback && (
                <Button block color="primary" onClick={retryCallback} outline>
                  Retry
                </Button>
              )}
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
