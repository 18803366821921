import React, { FC, useEffect } from 'react';
import { useQuery } from '../utils';

export const ExternalOauthLoginComponent: FC = () => {
  const code = useQuery().get('code');

  useEffect(() => {
    if (!code) {
      return;
    }

    (window.opener as Window).postMessage({ code }, window.location.origin);
  });

  if (!code) {
    return <>Could not find 'code' in the url parameters.</>;
  }

  return <>Logging in...</>;
};
