import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ProductModel } from '../../shared/orders/product';
import { ConRegistrationForm, RegistrationUpsert } from '../../shared/registration/model';
import { CurrentUser } from '../../shared/user/base';
import { mapProductAddons } from '../containers/store/ProductConfigurator';
import { RegistrationInfo } from '../models';
import { AdditionalRegOptions } from '../modules/registration/AdditionalRegOptions';
import { BadgeInfo } from '../modules/registration/BadgeInfo';
import { ConventionPolicy } from '../modules/registration/ConventionPolicy';
import { EmergencyContact } from '../modules/registration/EmergencyContactInfo';
import { ProductOptionViewerList } from '../modules/registration/ProductOptionViewerList';
import { displayName, useForm } from '../utils';
import { cleanupOptions } from '../utils/options';
import { getProductPrice, renderPrice } from '../utils/product';
import { BadgeArtSelectorCard, LazyMarkdown } from '.';

interface ConventionFormProps {
  readonly attendanceType: ProductModel;
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly regForm: ConRegistrationForm;
  onSuccess(): void;
  onReset(): void;
}

export const RegistrationInfoForm: FC<ConventionFormProps> = ({
  registration: conreg,
  attendanceType,
  user,
  regForm,
  onSuccess,
  onReset,
}) => {
  const isPaidAttendance = !!conreg?.paidOrderItem;
  const productPrice = getProductPrice(attendanceType);
  const [policyChecked, setPolicyChecked] = useState(!!conreg);
  const options = mapProductAddons(attendanceType.options, attendanceType.addons);

  const [registration, setRegistration] = useState<RegistrationUpsert>(() => ({
    attendanceTypeId: attendanceType.id,
    badgeName: conreg?.badgeName ?? '',
    emergencyContactName1: conreg?.emergencyContactName1 ?? null,
    emergencyContactName2: conreg?.emergencyContactName2 ?? null,
    emergencyContactPhone1: conreg?.emergencyContactPhone1 ?? null,
    emergencyContactPhone2: conreg?.emergencyContactPhone2 ?? null,
    flags: conreg?.flags ?? [],
    options: cleanupOptions([...regForm.options, ...attendanceType.options], conreg?.options ?? {}),
    badgeArtId: conreg?.badgeArtId,
  }));

  const form = useForm(async () => {
    if (regForm.terms && !policyChecked) {
      toast.error("You must accept the convention's policy");
      return;
    }

    if (conreg) {
      await api.updateRegistration(conreg.id, registration);
    } else {
      await api.createRegistration(user.id, registration);
    }

    onSuccess();
  }, [conreg, user.id, registration, onSuccess, regForm, policyChecked]);

  return (
    <form onSubmit={form.onSubmit}>
      <Row className="justify-content-center">
        <Col lg={10} xs={12}>
          <Row id="registrationForm">
            <Col className="margin-bottom-10" lg={12} xs={12}>
              <Card className="productItem markdown-container">
                <div className="productInfo">
                  <h5>{displayName(attendanceType)}</h5>
                  <LazyMarkdown
                    source={attendanceType.description ?? '*No Description Provided*'}
                  />
                </div>
                <div className="productPurchaseBar" id="productChangeBar">
                  {!isPaidAttendance && renderPrice(productPrice)}
                  <Button color="white" onClick={onReset} outline type="button">
                    Change
                  </Button>
                </div>
              </Card>
            </Col>
            <Col xs={12} />
            <BadgeInfo
              attendanceType={attendanceType}
              badgeDesign={regForm.badgeDesign}
              regOptions={regForm.options}
              registration={registration}
              setRegistration={setRegistration}
              user={user}
            />
            <AdditionalRegOptions
              flags={regForm.flags}
              regOptions={regForm.options}
              registration={registration}
              setRegistration={setRegistration}
            />
            {options.length > 0 && (
              <Col className="margin-bottom-10" lg={12} xs={12}>
                <Card className="additional-options">
                  <CardHeader>Product Options</CardHeader>
                  <CardBody>
                    <ProductOptionViewerList
                      isPaidAttendance={isPaidAttendance}
                      options={options}
                      setRegistration={setRegistration}
                      values={registration.options}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col className="margin-bottom-10" lg={12} xs={12}>
              <BadgeArtSelectorCard
                badgeArtId={registration.badgeArtId}
                defaultBadgeArtId={conreg?.badgeArtId}
                registrationId={conreg?.id}
                setBadgeArtId={(badgeArtId) => setRegistration((old) => ({ ...old, badgeArtId }))}
              />
            </Col>
            <EmergencyContact registration={registration} setRegistration={setRegistration} />
            {regForm.terms && (
              <ConventionPolicy
                checked={policyChecked}
                disabled={!!conreg}
                onChange={setPolicyChecked}
                policy={regForm.terms}
              />
            )}
          </Row>
        </Col>
        <Col className="margin-top-10" lg={6} xs={12}>
          <Button block color="primary" id="submitForm" type="submit">
            {conreg ? 'Update Registration' : 'Register'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};
