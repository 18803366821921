import React, { FC } from 'react';
import { EventRegistration, UserStateComponent } from '../../components';
import { RegistrationInfo } from '../../models';
import { useUser } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { registrationClosedHandler } from './RegistrationClosed';

interface EditRegistrationState {
  error?: string;
}

export const EditRegistration: FC<EditRegistrationState> = () => {
  const user = useUser();

  return (
    <UserStateComponent>
      <LoadingWrapper<RegistrationInfo, number>
        dataFetcher={async (uid) => await api.getUserActiveRegistration(uid)}
        errorDisplay={registrationClosedHandler}
        inline
        passback={user?.id}
      >
        {(registration) => <EventRegistration registration={registration} />}
      </LoadingWrapper>
    </UserStateComponent>
  );
};
