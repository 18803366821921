import React, { FC } from 'react';
import { ProductModel } from '../../../shared/orders';
import { RegistrationOptionInput, RegistrationUpsert } from '../../../shared/registration/model';
import { BadgeDesign } from '../../../shared/user/badgeDesign';
import { useUser } from '../../utils';
import {
  BadgePreviewRenderer,
  getPlaceholderData,
  transformNodeMap,
} from '../badgerenderer/BadgeRender';

interface BadgePreviewRendererProps {
  readonly options: RegistrationOptionInput[];
  readonly badgeDesign?: BadgeDesign;
  readonly registration: RegistrationUpsert;
  readonly attendanceType: ProductModel;
}

export const BadgePreview: FC<BadgePreviewRendererProps> = ({
  attendanceType,
  options,
  badgeDesign,
  registration,
}) => {
  const user = useUser()!;
  const speciesOption = options.find((t) => t.fromRegistration && t.type === 'text');
  const badgeArtId = registration.badgeArtId;
  const species =
    (speciesOption && (registration.options[speciesOption.id.toString()] as string)) ?? '';

  const label = getPlaceholderData({
    attendanceType,
    badgeArtId: registration.badgeArtId,
    badgeName: registration.badgeName,
    species,
    user,
  });

  if (!badgeDesign) {
    return null;
  }

  return (
    <BadgePreviewRenderer
      image={badgeArtId ? `/api/badgeart/${badgeArtId}/image` : ''}
      label={label}
      nodes={transformNodeMap(badgeDesign)}
    />
  );
};
