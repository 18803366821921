import { MaterialIconText } from '@conventioncatcorp/common-fe/dist/components/MaterialIcon';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { OptionDataPayload } from '../../shared/options';
import { ProductEligibility, ProductModel } from '../../shared/orders';
import { useConvention, useFetcher, useUser } from '../utils';
import { LoadingState } from '../utils/LoadingState';
import { captureError } from '../utils/errorHandling';
import { ActionButton } from './ActionButton';

import './AddToCartButton.scss';

interface Props {
  readonly product: ProductModel;
  readonly eligibility: ProductEligibility;
  readonly quantity: number;
  readonly productOptions: OptionDataPayload;
  onAddedToCart(success: boolean): void;
}

interface CartStatus {
  canAutoFulfill?: boolean;

  orderId: number;
}

export const AddToCartButton: FC<Props> = ({
  product,
  quantity,
  eligibility,
  productOptions,
  onAddedToCart,
}) => {
  const { id: conventionId } = useConvention();
  const user = useUser();
  const history = useHistory();

  const cartStatus = useFetcher<CartStatus>(async () => {
    if (product.price > 0) {
      // If the product is free, we can't auto-fulfill it.
      return { eligibility: 'eligible', canAutoFulfill: false, orderId: 0 };
    }

    // Check for an active order
    const order = await api.getActiveOrder(user!.id, true);

    if (order.breakdown.total > 0) {
      // If the order total is greater than zero, we can't auto-fulfill it.
      return { eligibility: 'eligible', canAutoFulfill: false, orderId: order.id };
    }

    // Otherwise, we can auto-fulfill it!
    return { eligibility: 'eligible', canAutoFulfill: true, orderId: order.id };
  }, [product, eligibility, user, conventionId]);

  const onSuccess = useCallback(async () => {
    if (cartStatus.data?.canAutoFulfill && cartStatus.data.orderId > 0) {
      // Auto-fulfill the order
      try {
        await api.settleOrder(cartStatus.data.orderId);
        onAddedToCart(true);
      } catch (error) {
        captureError(error as Error);
        history.push('/cart');
      }
    } else {
      onAddedToCart(true);
    }
  }, [onAddedToCart, cartStatus, history]);

  if (cartStatus.state === LoadingState.Loading) {
    return (
      <Button block color="secondary" disabled outline>
        Checking Order Status...
      </Button>
    );
  }

  if (!eligibility.canPurchase && eligibility.alreadyHasTicket) {
    return (
      <p className="add-to-cart-blocked" id="addToCartBlocked">
        <MaterialIconText name="block" small type="danger">
          You've already purchased one of this product.
        </MaterialIconText>
      </p>
    );
  }

  return (
    <ActionButton
      action={`/api/products/${product.id}/add`}
      block
      color="success"
      disabled={!eligibility.canPurchase}
      id="addToCart"
      method="post"
      onSuccess={onSuccess}
      payload={{ quantity, options: productOptions }}
    >
      {cartStatus.data!.canAutoFulfill ? 'Complete Order' : 'Add to Cart'}
    </ActionButton>
  );
};
