import React, { useCallback, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { MaterialIconText } from './MaterialIconText';

interface Props {
  readonly name?: string;
  readonly id: string;
  readonly defaultSelected?: string;
  readonly options: Option[];
  readonly onChange?: (value: string) => void;
}

interface Option {
  readonly icon: string;
  readonly label: string;
  readonly value: string;
  readonly disabled?: boolean;
}

export const RadioButtonGroup: React.FC<Props> = ({
  name,
  id,
  defaultSelected,
  options,
  onChange,
}) => {
  const [selected, setSelected] = useState(defaultSelected ?? options[0].value);
  const handleChange = useCallback(
    (value: string) => {
      setSelected(value);

      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <div id={`radioButtonGroup-${id}`}>
      <ButtonGroup>
        {options.map((option) => (
          <RadioButtonElement
            {...option}
            id={id}
            key={option.value}
            onSelect={handleChange}
            selected={selected === option.value}
          />
        ))}
      </ButtonGroup>
      {name && <input name={name} type="hidden" value={selected} />}
    </div>
  );
};

interface ElementProps extends Option {
  readonly id: string;
  readonly selected?: boolean;
  readonly onSelect: (value: string) => void;
}

const RadioButtonElement: React.FC<ElementProps> = ({
  icon,
  id,
  label,
  value,
  selected,
  onSelect,
  disabled,
}) => {
  const handleClick = useCallback(() => onSelect(value), [onSelect, value]);

  return (
    <Button
      color={selected ? 'primary' : 'secondary'}
      disabled={disabled}
      id={`radioButtonGroup-${id}-${value}`}
      onClick={handleClick}
      outline={!selected}
    >
      <MaterialIconText name={icon} small>
        {label}
      </MaterialIconText>
    </Button>
  );
};
