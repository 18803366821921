import React from 'react';
import { render } from 'react-dom';
import { BadgeData, BadgeDesign } from '../../../../shared/user/badgeDesign';
import { BadgePageRender, transformNodeMap } from '../../../modules/badgerenderer/BadgeRender';
import { captureError } from '../../../utils/errorHandling';
import {
  BadgePrinterBase,
  getPrinterUid,
  PrinterConfig,
  PrinterInfoBase,
  PrinterManagerBase,
  PrinterManagerStatus,
  PrinterStatus,
  PrinterType,
} from './PrinterBase';

class OSPrinter implements BadgePrinterBase {
  public readonly name = 'OS Controlled';
  public readonly serial = 'os-print';
  public readonly canConfigure = false;
  public readonly type = PrinterType.OS;
  public readonly uid = getPrinterUid(PrinterType.OS, 'os-print');

  public status: PrinterStatus = PrinterStatus.Connected;

  public getConfig(): PrinterConfig {
    return {};
  }

  public async print(badge: BadgeData, design: BadgeDesign): Promise<boolean> {
    try {
      if (!badge) {
        return false;
      }

      if (badge.registrationId) {
        await api.markRegistrationBadgeAsPrinted(
          badge.registrationId,
          null,
          new URLSearchParams(location.search).get('barcode') ?? undefined,
        );
      }

      render(
        <BadgePageRender labels={[badge]} nodes={transformNodeMap(design)} />,
        document.getElementById('printArea'),
      );

      setTimeout(() => {
        window.print();
      }, 200);

      return true;
    } catch (error) {
      captureError(error as Error);
      return false;
    }
  }

  public async testPrint(): Promise<boolean> {
    render(
      <div>
        <h1>Test Print</h1>
        <p>This is a test print.</p>
      </div>,
      document.getElementById('printArea'),
    );

    setTimeout(() => {
      window.print();
    }, 200);

    return true;
  }
}

export class OSPrinterManager implements PrinterManagerBase {
  public name = 'OS Printer';
  public readonly type = PrinterType.OS;
  public supportsManualConnect = false;
  public status = PrinterManagerStatus.Ready;

  public async getAvailablePrinters(): Promise<PrinterInfoBase[]> {
    const serial = 'os-print';
    return [
      {
        name: 'OS Printer',
        serial,
        type: this.type,
        config: {},
        uid: getPrinterUid(this.type, serial),
      },
    ];
  }

  public usePrinter(): BadgePrinterBase {
    return new OSPrinter();
  }
}
