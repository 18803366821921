import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { v4 } from 'uuid';

import './CollapseInfo.scss';

interface CollapseContext {
  openId: string | undefined;
  toggleCollapse: (id: string) => void;
}
export const CurrentlyOpenCollapse = createContext<CollapseContext>({
  openId: undefined,
  toggleCollapse: () => {
    /* noop */
  },
});

export const CollapseContainer: FC = ({ children }) => {
  const [openId, setOpenId] = useState<string | undefined>(undefined);

  const toggleCollapse = useCallback(
    (ref: string) => {
      if (openId === ref) {
        setOpenId(undefined);
      } else {
        setOpenId(ref);
      }
    },
    [openId],
  );

  const providerValue = useMemo(() => ({ openId, toggleCollapse }), [toggleCollapse, openId]);
  return (
    <CurrentlyOpenCollapse.Provider value={providerValue}>
      {children}
    </CurrentlyOpenCollapse.Provider>
  );
};

interface Props {
  readonly title: string;
  readonly isDefault?: boolean;
}

export const CollapseInfo: FC<Props> = ({ children, title, isDefault }) => {
  const ref = useMemo(() => v4(), []);
  const { openId, toggleCollapse: ctxToggleCollapse } = useContext(CurrentlyOpenCollapse);

  const toggleCollapse = useCallback(() => {
    ctxToggleCollapse(ref);
  }, [ctxToggleCollapse]);

  useEffect(() => {
    if (isDefault) {
      ctxToggleCollapse(ref);
    }
  }, []);

  return (
    <div className="collapse-info mb-3">
      <Card>
        <CardHeader onClick={toggleCollapse}>{title}</CardHeader>
        {openId === ref && <CardBody>{children}</CardBody>}
      </Card>
    </div>
  );
};
