import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PermissionName } from '../../shared/permissions';
import { ActionType } from '../services';
import { checkPermission, useUser } from '../utils';
import { AutoLink } from './AutoLink';
import { MaterialIcon } from './MaterialIcon';

interface SidenavItemProps {
  readonly id?: string;
  readonly link?: string;
  readonly text?: string;
  readonly icon?: string;
  readonly header?: boolean;
  readonly requiredPermissions?: PermissionName | PermissionName[];
  readonly matchAny?: boolean;
  readonly requireLoggedIn?: boolean;
}

export const SidenavItem: FC<SidenavItemProps> = ({
  requiredPermissions,
  requireLoggedIn,
  text,
  link,
  id,
  icon,
  header,
  matchAny,
}) => {
  const user = useUser();

  const dispatch = useDispatch();
  const sideNavClicked = useCallback(
    () => dispatch({ type: ActionType.SideNavLinkClicked }),
    [dispatch],
  );

  if (!user && (requireLoggedIn || requiredPermissions)) {
    return null;
  }

  if (requiredPermissions && user && !checkPermission(user, requiredPermissions, matchAny)) {
    return null;
  }

  if (text) {
    if (link) {
      return (
        <li id={id}>
          <AutoLink link={link} onClick={sideNavClicked}>
            {icon && <MaterialIcon name={icon} />}
            <div>{text}</div>
          </AutoLink>
        </li>
      );
    }

    if (header) {
      return <li className="sidenav-header">{text}</li>;
    }
  }

  return <li className="sidenav-spacer" />;
};
