import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { SecondFactorType } from '../../../APIClient';
import { MaterialIcon } from '../../../components';
import { useConstantSetters } from '../../../utils';

interface SecondFactorState {
  onCancel(): void;
  onSubmit(): void;
}

export const SecondFactorPrompt: FC<SecondFactorState> = ({ onCancel, onSubmit }) => {
  const [type, setType] = useState(SecondFactorType.Otp);
  const [secondFactorCode, setSecondFactorCode] = useState('');

  const isOtp = type === SecondFactorType.Otp;
  const length = isOtp ? 6 : 10;

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSecondFactorCode(e.currentTarget.value);
  }, []);

  const toggle2FAType = useConstantSetters(
    setType,
    isOtp ? SecondFactorType.Backup : SecondFactorType.Otp,
  );

  const onSubmitForm = useCallback((e: React.UIEvent) => {
    e.preventDefault();
    onSubmit();
  }, []);

  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }, []);

  return (
    <>
      <Input defaultValue={type} id="secondFactorType" name="secondFactor[type]" type="hidden" />
      <Input name="secondFactor[code]" required type="hidden" value={secondFactorCode} />
      <Modal isOpen>
        <div>
          <ModalBody className="twofa-modal">
            <div className="text-center">
              <MaterialIcon large name="security" type="primary" />
              <h4>Two-Factor Authentication</h4>
            </div>
            <hr />
            <p className="text-secondary text-center">
              {isOtp
                ? 'Enter the 6-digit code generated by your two-factor authentication app '
                : 'Enter one of your alphanumeric backup codes '}
              to verify it's really you.
            </p>
            <FormGroup className="text-center">
              <Label for="secondFactor[code]">Second Factor Code</Label>
              <Input
                id="secondFactor"
                maxLength={length}
                minLength={length}
                onChange={onChange}
                onKeyUp={onKeyUp}
                placeholder={isOtp ? '123456' : 'ADSKJBKASF'}
                required
              />
            </FormGroup>
            <hr />
          </ModalBody>
          <ModalFooter>
            <Link
              className="btn btn-link btn-block"
              id="secondFactorChangeType"
              onClick={toggle2FAType}
              to="#"
            >
              {isOtp ? 'Use Backup Code Instead' : 'Use Second Factor Code'}
            </Link>
            <Button color="primary" id="login2FA" onClick={onSubmitForm}>
              Login
            </Button>
            <Button color="secondary" onClick={onCancel} outline>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};
