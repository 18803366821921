import { DealerFullModel, DealerModel } from '../../shared/dealer';

export function convertDealerToUpsert(dealer: DealerFullModel): DealerModel {
  return {
    name: dealer.name,
    email: dealer.email,
    addressLine1: dealer.addressLine1,
    addressLine2: dealer.addressLine2,
    addressCity: dealer.addressCity,
    addressZipcode: dealer.addressZipcode,
    addressCountry: dealer.addressCountry,
    addressState: dealer.addressState,
    options: dealer.options,
  };
}
