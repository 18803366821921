import React, { Component, FormEvent } from 'react';
import isEqual from 'react-fast-compare';
import { Badge, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { capitalize } from '../utils';
import { Filter, FilterOption, MaterialIcon, SelectedOptions } from '.';

interface FilterOptionsProps<T> {
  readonly filters: Filter<T>[];
  onFilterInit(filterFunc: (data: T[]) => T[]): void;
  onUpdate(): void;
}

interface FilterOptionsState<T> {
  searchText: string;
  showOptions: boolean;
  selectedOptions: SelectedOptions<T>;
}

interface TextSearchNode {
  [key: string]: TextSearchNode | number | string;
}

export class FilterOptions<T> extends Component<FilterOptionsProps<T>, FilterOptionsState<T>> {
  public override state: FilterOptionsState<T> = {
    searchText: '',
    selectedOptions: {},
    showOptions: false,
  };

  public override componentDidMount(): void {
    this.props.onFilterInit((data) => this.runFilters(data));
    this.props.onUpdate();
  }

  public override componentDidUpdate(
    _: FilterOptionsProps<T>,
    prevState: FilterOptionsState<T>,
  ): void {
    if (!isEqual(prevState, this.state)) {
      this.props.onUpdate();
    }
  }

  public override render(): JSX.Element {
    const { filters } = this.props;
    const { showOptions } = this.state;
    return (
      <Card className="filter-options noselect">
        <CardHeader
          onClick={() => {
            this.toggleOptions();
          }}
        >
          <span>
            <strong>Filter:</strong> {this.describeFilter()}
          </span>
          <div>
            <MaterialIcon name={showOptions ? 'expand_less' : 'expand_more'} />
          </div>
        </CardHeader>
        {showOptions && (
          <CardBody>
            <Row>
              <Col lg={4} xs={12}>
                <FormGroup>
                  <Label>Search</Label>
                  <Input
                    id="searchText"
                    onChange={(el) => {
                      this.searchTextUpdate(el);
                    }}
                  />
                </FormGroup>
              </Col>
              {filters.map((filter) => this.renderFilter(filter))}
            </Row>
          </CardBody>
        )}
      </Card>
    );
  }

  private describeFilter(): string {
    const { searchText, selectedOptions } = this.state;
    const descriptionArr = Object.keys(selectedOptions).map((key) => {
      const optValue = selectedOptions[key];

      if (!optValue || optValue.length === 0) {
        return '';
      }

      const values = optValue
        .sort((a, b) => a.value.localeCompare(b.value))
        .map(({ name }) => name)
        .join(' OR ');

      return `${key} = ${values}`;
    });

    if (searchText.length > 0) {
      descriptionArr.push(`searchText = ${searchText}`);
    }

    const description = descriptionArr.filter(Boolean).join(' and ');

    if (description.length === 0) {
      return 'Showing all results';
    }

    return `Showing results where ${description}`;
  }

  private searchTextUpdate({
    currentTarget: { value: searchText },
  }: FormEvent<HTMLInputElement>): void {
    this.setState({
      searchText,
    });
  }

  private toggleOptions(): void {
    this.setState({
      showOptions: !this.state.showOptions,
    });
  }

  private addSelection(key: string, value: FilterOption<T>): void {
    const { selectedOptions } = this.state;
    const keyValues = selectedOptions[key] || [];

    this.setState({
      selectedOptions: {
        ...selectedOptions,
        [key]: keyValues.concat(value),
      },
    });
  }

  private removeSelection(key: string, value: string): void {
    const { selectedOptions } = this.state;
    const newKeyValues = selectedOptions[key].filter((o) => o.value !== value);

    this.setState({
      selectedOptions: {
        ...selectedOptions,
        [key]: newKeyValues,
      },
    });
  }

  private renderFilter({ displayName, name, options }: Filter<T>): JSX.Element {
    const { selectedOptions } = this.state;
    return (
      <Col key={name} lg={4} xs={12}>
        <FormGroup>
          <Label for={name}>{displayName}</Label>
          <div className="badge-list">
            {options
              .sort((a, b) => a.value.localeCompare(b.value))
              .map((option) => {
                const isSelected = selectedOptions[name]?.some((o) => o.value === option.value);

                const key = `option${capitalize(option.value.replace(/[^A-Za-z]/, ''))}`;
                return (
                  <Badge
                    color={isSelected ? 'primary' : 'secondary'}
                    id={key}
                    key={key}
                    onClick={() => {
                      if (isSelected) {
                        this.removeSelection(name, option.value);
                        return;
                      }

                      this.addSelection(name, option);
                    }}
                  >
                    {option.name}
                  </Badge>
                );
              })}
          </div>
        </FormGroup>
      </Col>
    );
  }

  private checkFilterText(data: TextSearchNode | number | string, searchText: string): boolean {
    if (!data) {
      return false;
    }

    if (searchText.length === 0) {
      return true;
    }

    if (typeof data === 'object') {
      const keys = Object.keys(data);

      return keys.some((k) => this.checkFilterText(data[k], searchText));
    }

    return data.toString().toLowerCase().includes(searchText.toLowerCase());
  }

  private runFilters(data: T[]): T[] {
    const { searchText, selectedOptions } = this.state;
    const optKeys = Object.keys(selectedOptions).filter((k) => selectedOptions[k].length > 0);

    // Run map-reduce
    const mappedData: T[] = data.map((d) => {
      // Flat map from "Array<Group<Option>>" to just "Array<Option>"
      const options = optKeys.flatMap((t) => selectedOptions[t]);

      // Find if anyone wants to change the data before being filtered
      return options.reduce<T>((value, option) => {
        return option.map ? option.map(value) : value;
      }, d);
    });

    const filteredData = mappedData.filter((d) => {
      if (optKeys.length === 0) {
        return true;
      }

      return optKeys.every((key) => {
        return selectedOptions[key].some(({ filter }) => filter(d, selectedOptions));
      });
    });

    return filteredData.filter((fd) =>
      this.checkFilterText(fd as unknown as TextSearchNode, searchText),
    );
  }
}
