import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { SearchComponent, UserStateComponent } from '../../components';
import { CashierContext } from './cashiercontext';

// eslint-disable-next-line import/no-unused-modules -- misidentified as unused
export const CashierHome: FC = () => {
  const {
    config: { primaryPrinter, secondaryPrinter },
  } = useContext(CashierContext);

  return (
    <UserStateComponent>
      <Row>
        {!primaryPrinter && !secondaryPrinter && (
          <Col xs={12}>
            <Alert color="warning">
              <strong>Warning:</strong> No printers are configured for this registration station.
            </Alert>
          </Col>
        )}
        <Col xs={8}>
          <SearchComponent
            hideHelp
            redirectOnSingle
            redirectUrl="/housekeeping/cashier"
            showBornAt
          />
        </Col>
        <Col xs={4}>
          <Card>
            <CardBody>
              <Link className="btn btn-outline-primary btn-block" to="/housekeeping">
                Exit Registration Station
              </Link>
              <hr />
              <h4>Search Help</h4>
              <p>
                You can search for an attendee by{' '}
                <strong>scanning the barcode on the back of their ID</strong>, or by using one of
                the following:
              </p>
              <ul>
                <li>Badge ID</li>
                <li>Username</li>
                <li>First name, last name, and / or preferred name</li>
                <li>Phone number</li>
                <li>Email address</li>
              </ul>
              <p>
                When "<strong>Enable auto navigate</strong>" is selected, the system will redirect
                to the attendee's profile page when only one result is found for the search.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
