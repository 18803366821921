import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { Loading, MaterialIcon, UserStateComponent } from '../../components';
import { ActionType, store } from '../../services';
import { LoadingState } from '../../utils/LoadingState';

interface OAuthLogoutComponentState {
  status: LoadingState;
  next: string | null;
}

class OAuthLogoutComponent extends Component<RouteComponentProps, OAuthLogoutComponentState> {
  public override state: OAuthLogoutComponentState = {
    next: null,
    status: LoadingState.Loading,
  };

  public override async componentDidMount(): Promise<void> {
    const params = new URLSearchParams(this.props.location.search);
    try {
      await this.logout();

      this.setState({
        next: params.get('next'),
        status: LoadingState.Done,
      });
    } catch {
      this.setState({
        next: params.get('next'),
        status: LoadingState.Error,
      });
    }
  }

  public override render(): JSX.Element {
    const { next, status } = this.state;
    return (
      <UserStateComponent>
        {status === LoadingState.Loading && <Loading inline>Logging you out...</Loading>}
        {status === LoadingState.Done && (
          <Loading inline>Redirecting you {next && `to ${next}`}</Loading>
        )}
        {status === LoadingState.Error && (
          <Row className="justify-content-center">
            <Col lg={6} xs={12}>
              <Row className="justify-content-center" id="oauthLogoutError">
                <Col xs={12}>
                  <Card className="warning">
                    <CardBody className="text-center">
                      <MaterialIcon large name="warning" type="warning" />
                      <CardTitle>Logout Attempt</CardTitle>
                      <CardText>
                        {next ?? 'A website'} is trying to log you out, but we could not verify
                        their OAuth credentials.
                      </CardText>
                      <CardText>
                        If you trust this website, you can click 'Allow Logout'.&nbsp;
                        {next && `You will then be redirected to ${next}. `}
                        Otherwise, click 'Cancel' to return to the homepage.
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="margin-top-10" lg={6} xs={12}>
                  <Button block color="danger" onClick={async () => await this.logout(true)}>
                    Allow Logout
                  </Button>
                </Col>
                <Col className="margin-top-10" lg={6} xs={12}>
                  <Button
                    block
                    color="secondary"
                    onClick={() => {
                      this.props.history.push('/');
                    }}
                    outline
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </UserStateComponent>
    );
  }

  private async logout(force = false): Promise<void> {
    const params = new URLSearchParams(this.props.location.search);

    // TODO: This is bad...
    await api.logout(
      force
        ? {
            access_token: params.get('access_token') ?? 'notprovided',
            client_id: params.get('client_id') ?? 'notprovided',
          }
        : undefined,
    );

    const next = params.get('next');

    if (next) {
      window.location.replace(next);
    } else {
      store.dispatch({
        type: ActionType.Logout,
      });
    }
  }
}

const routedOAuthLogoutComponent = withRouter(OAuthLogoutComponent);
export { routedOAuthLogoutComponent as OAuthLogoutComponent };
