import { Option } from '../options';
import { SurchargeAmountType, SurchargeType } from './model';
import { RaffleWithUserDetails } from './raffle';

export interface ProductOptionInput extends Option {
  required: boolean;
}

export enum ProductVisibility {
  PUBLIC = 'public',
  UNLISTED = 'unlisted',
  HIDDEN = 'hidden',
}

export interface ProductReference {
  id: number;
  name: string;
  displayName: string | null;
}

export type ProductImageType = 'badgeArt' | 'hidden' | 'image' | 'primary';

export interface ProductModel {
  id: number;
  conventionId: number;
  name: string;
  displayName: string | null;
  description?: string;
  availableFrom?: Date;
  availableTo?: Date;
  deletedAt?: Date;
  digitalGrantRestricted: boolean;
  maxGrants?: number;

  stockAvailable?: number;
  stockMaximumQuantity: number | null;

  maxAge?: number;
  minAge?: number;
  visibility: ProductVisibility;
  price: number;
  isTicketed: boolean;

  roleRestriction: number[] | null;

  options: ProductOptionInput[];

  upgrades: ProductUpgradeModel[];

  discounts: ProductDiscount[];

  category: ProductCategoryModel;

  images: ProductImage[];

  addons: ProductAddonModel[];

  requiredGrants: {
    productId: number;
    name: string;
    displayName: string | null;
  }[];

  surcharges: SurchargeModel[];

  raffle?: Pick<
    RaffleWithUserDetails,
    'enteredSubtext' | 'enteredTitle' | 'id' | 'overflowEntries' | 'roleOverride' | 'status'
  >;
}

export interface ProductImage {
  id: string;
  type: ProductImageType;
}

export interface ProductCategoryModel {
  id: number;
  name: string;
  isStore: boolean;
  isRegistration: boolean;
  isVendor: boolean;
}

export interface ProductUpgradeModel {
  id: number;
  name: string;
  displayName: string | null;
}

export interface ProductAddonModel {
  isFree: boolean;
  id: number;
  name: string;
  description: string | null;
  price: number;
  visibility: ProductVisibility;
  displayName: string | null;
  imageId?: string;
  options: ProductOptionInput[];
  discounts: ProductDiscount[];
}

export interface SurchargeModel {
  id: number;
  internal: boolean;
  name: string;
  deletedAt?: Date;
  amountType: SurchargeAmountType;
  amount: number;
  type: SurchargeType;
}

export interface ProductDiscount {
  availableFrom: Date;
  availableTo: Date;
  deletedAt?: Date;
  id: number;
  price: number;
  roleId?: number;
}

export interface ProductEligibility {
  canPurchase: boolean;
  alreadyHasTicket?: boolean;
  requireDigitalGrant?: boolean;
  grantLeft?: number;
  requiredProduct?: {
    name: string;
    displayName: string | null;
  };
}

export const noImage = 'https://cdn.concat.app/static/no-image.jpg';
