/*
 * Get a conventionId param to put on the URL
 * Use 'first' to autoamtically put the "?" on the front for the URL
 */
export function getConventionIdParam(first = true): string {
  const conventionId = getConventionId();

  if (!Number.isNaN(conventionId)) {
    return `${first ? '?' : ''}conventionId=${conventionId}`;
  }

  return '';
}

export function getConventionId(): number {
  const params = new URLSearchParams(window.location.search);
  return Number.parseInt(params.get('conventionId') ?? '', 10);
}
