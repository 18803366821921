import { toast } from 'react-toastify';
import {
  IUsbDeviceManagerEventMap,
  parseReceiptLineToDocument,
  PulseCommand,
  ReceiptPrinter,
  TestPrint,
  UsbDeviceManager,
  // eslint-disable-next-line import/no-unresolved
} from 'web-receiptline-printer';
import {
  getPrinterUid,
  PrinterConfig as PrinterConfigBase,
  PrinterInfoBase,
  PrinterManagerBase,
  PrinterManagerStatus,
  PrinterStatus,
  PrinterType,
  ReceiptPrinterBase,
} from './PrinterBase';

interface ReceiptPrinterConfig extends PrinterConfigBase {
  serial?: string;
  width?: number;
}

interface ReceiptPrinterInfo extends PrinterInfoBase {
  serial: string;
  config: ReceiptPrinterConfig;
}

export class ReceiptPrinterWeb implements ReceiptPrinterBase {
  public serial = '';
  public readonly canConfigure = false;
  public readonly type = PrinterType.WebReceipt;
  public readonly uid;

  public constructor(
    public readonly name: string,
    private readonly printer: ReceiptPrinter,
  ) {
    this.serial = printer.printerOptions.serialNumber;
    this.uid = getPrinterUid(this.type, this.serial);
  }

  public get status(): PrinterStatus {
    return this.printer.connected ? PrinterStatus.Connected : PrinterStatus.Disconnected;
  }

  public getConfig(): ReceiptPrinterConfig {
    return {
      serial: this.printer.printerSerial,
      width: this.printer.printerOptions.charactersPerLine,
    };
  }

  public async print(receiptline: string): Promise<boolean> {
    const doc = parseReceiptLineToDocument(receiptline, this.printer.printerOptions);

    await this.printer.sendDocument(doc);

    return true;
  }

  public async testPrint(): Promise<boolean> {
    await this.printer.sendDocument({
      commands: [new TestPrint('rolling')],
    });

    return true;
  }

  public async drawerKick(): Promise<boolean> {
    await this.printer.sendDocument({
      commands: [new PulseCommand()],
    });

    return true;
  }
}

export class ReceiptPrinterManager implements PrinterManagerBase {
  public name = 'Receipt Printer with WebUSB';
  public type = PrinterType.WebReceipt;
  public supportsManualConnect = true;
  public status: PrinterManagerStatus = PrinterManagerStatus.Scanning;

  private readonly mgr?: UsbDeviceManager<ReceiptPrinter>;

  public constructor() {
    if (!window.navigator.usb) {
      // TODO: Better handling for non-WebUSB browsers
      this.mgr = undefined;
      this.status = PrinterManagerStatus.Error;
      return;
    }

    if (window.receiptPrinterManager) {
      this.mgr = window.receiptPrinterManager;
      return;
    }

    this.mgr = new UsbDeviceManager<ReceiptPrinter>(
      window.navigator.usb,
      ReceiptPrinter.fromUSBDevice,
      {
        requestOptions: {
          filters: [
            {
              // Epson
              vendorId: 0x04_b8,
            },
          ],
        },
        /*
         * Print debug messages to the console
         * TODO: This is very chatty!
         */
        debug: true,
      },
    );

    this.mgr
      .forceReconnect()
      .then(() => {
        this.status = PrinterManagerStatus.Ready;
      })
      .catch((error: Error) => {
        toast.error(error.message);
        this.status = PrinterManagerStatus.Error;
      });

    window.receiptPrinterManager = this.mgr;
  }

  public addEventListener<T extends keyof IUsbDeviceManagerEventMap<ReceiptPrinter>>(
    type: T,
    listener:
      | EventListenerObject
      | ((
          this: UsbDeviceManager<ReceiptPrinter>,
          ev: IUsbDeviceManagerEventMap<ReceiptPrinter>[T],
        ) => void)
      | null,
    options?: AddEventListenerOptions | boolean,
  ): void {
    this.mgr?.addEventListener(type, listener, options);
  }

  public removeEventListener<T extends keyof IUsbDeviceManagerEventMap<ReceiptPrinter>>(
    type: T,
    listener:
      | EventListenerObject
      | ((
          this: UsbDeviceManager<ReceiptPrinter>,
          ev: IUsbDeviceManagerEventMap<ReceiptPrinter>[T],
        ) => void)
      | null,
    options?: AddEventListenerOptions | boolean,
  ): void {
    this.mgr?.removeEventListener(type, listener, options);
  }

  public async startManualConnect?(): Promise<void> {
    await this.mgr?.promptForNewDevice();
  }

  public async getAvailablePrinters(): Promise<ReceiptPrinterInfo[]> {
    return this.mgr?.devices.map((d) => this.toPrinterInfo(d)) ?? [];
  }

  public getPrinters(): ReceiptPrinterWeb[] {
    return (
      this.mgr?.devices.map((d) => new ReceiptPrinterWeb(this.getPrinterName(d, true), d)) ?? []
    );
  }

  public usePrinter(serial: string, _: ReceiptPrinterConfig): ReceiptPrinterBase | undefined {
    const printer = this.mgr?.devices.find((d) => d.printerOptions.serialNumber === serial);
    if (printer !== undefined && this.mgr !== undefined) {
      return new ReceiptPrinterWeb(this.getPrinterName(printer, true), printer);
    }

    return undefined;
  }

  private toPrinterInfo(printer: ReceiptPrinter): ReceiptPrinterInfo {
    const o = printer.printerOptions;
    const serial = o.serialNumber;

    return {
      name: this.getPrinterName(printer, true),
      type: this.type,
      serial,
      uid: getPrinterUid(this.type, serial),
      config: {
        serial,
        width: o.charactersPerLine,
      },
    };
  }

  private getPrinterName(printer: ReceiptPrinter, short = false): string {
    const o = printer.printerOptions;
    return short
      ? `Receipt (#${o.serialNumber})`
      : `${o.manufacturer} ${o.model} (S/N: ${o.serialNumber})`;
  }
}
