import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { DealerForm, DealerFullModel } from '../../../../shared/dealer';
import { LinkButton, PageHeader, UserRoute, UserStateComponent } from '../../../components';
import { fthrow, isAccessError, useConfig, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { AreaSelection } from './AreaSelection';
import { DealerBan } from './DealerBan';
import { DealerButtons } from './DealerButtons';
import { DealerFormComponent } from './DealerForm';
import { DealersClosed } from './DealersClosed';
import { SpaceApplicationForm } from './SpaceApplicationForm';
import { VendorAssistantComponent } from './VendorAssistant';
import { VendorAssistantConfirmComponent } from './VendorAssistantConfirm';
import { VendorSeatingComponent } from './VendorSeating';

interface LoadingData {
  readonly dealer: DealerFullModel | undefined;
  readonly prevDealer: DealerFullModel | undefined;
  readonly form: DealerForm;
}

type ULoadingData = LoadingData | undefined;

export const ApplyDealerArea: FC = () => {
  const user = useUser()!;
  const config = useConfig();

  if (!config.dealersAreas.some((t) => t.enabled)) {
    return <DealersClosed />;
  }

  return (
    <UserStateComponent>
      <LoadingWrapper<ULoadingData, number>
        dataFetcher={async (userId) => await fetchData(userId)}
        inline
        passback={user.id}
      >
        {(data, refresh) => {
          // TODO: How do we check ban?
          if (!data) {
            return <DealerBan />;
          }

          const { dealer, form, prevDealer } = data;
          if (config.dealersAreas.length === 0) {
            return <DealersClosed />;
          }

          return (
            <>
              <PageHeader>Dealer Application</PageHeader>
              <DealerRouter dealer={dealer} form={form} prevDealer={prevDealer} refresh={refresh} />
            </>
          );
        }}
      </LoadingWrapper>
    </UserStateComponent>
  );
};

export interface DealerLoadYears {
  prevDealer?: DealerFullModel;
  dealer?: DealerFullModel;
}

async function loadDealer(userId: number): Promise<DealerLoadYears> {
  let dealer: DealerFullModel | undefined;
  let prevDealer: DealerFullModel | undefined;
  try {
    dealer = await api.getUserDealer(userId);
  } catch {
    /*
     * Ignore, we don't /need/ a dealer
     * TODO: check if we should escalate error
     */
  }

  if (!dealer) {
    try {
      prevDealer = await api.getUserPreviousDealer(userId);
    } catch {
      // Do nothing
    }
  }

  return { dealer, prevDealer };
}

async function fetchData(userId: number): Promise<LoadingData | undefined> {
  // This API call is to just check for bans...
  const dealerAreas = await api
    .getDealerAreas()
    .catch((error: Error) => (isAccessError(error) ? undefined : fthrow(error)));

  if (!dealerAreas) {
    return undefined;
  }

  const { dealer, prevDealer } = await loadDealer(userId);
  const form = await api.getDealerForm();

  return {
    dealer,
    prevDealer,
    form,
  };
}

interface DealerRouterProps extends LoadingData {
  readonly refresh: () => void;
}

const DealerRouter: FC<DealerRouterProps> = (props) => {
  const config = useConfig();
  const { dealer, form, prevDealer, refresh } = props;
  const { dealersAreas } = config;

  return (
    <Switch>
      <Route
        exact
        path="/vendor/edit"
        render={() => (
          <DealerFormComponent
            callback={refresh}
            dealer={dealer}
            form={form}
            prevDealer={prevDealer}
          />
        )}
      />
      <Route
        exact
        path="/vendor"
        render={() => {
          if (!dealer) {
            return <DealerFormComponent callback={refresh} form={form} prevDealer={prevDealer} />;
          }

          if (config.dealersAreas.length > 1) {
            return <AreaSelection dealer={dealer} form={form} />;
          }

          return (
            <>
              <DealerButtons dealer={dealer} enableDealerHelpers={form.enableDealerHelpers} />
              <SpaceApplicationForm
                callback={refresh}
                dealer={dealer}
                dealerAreaId={dealersAreas[0].id}
              />
            </>
          );
        }}
      />
      {form.enableDealerHelpers && (
        <UserRoute
          exact
          path="/vendor/assistants"
          render={() => <VendorAssistantComponent dealer={dealer} />}
        />
      )}
      {form.enableDealerHelpers && (
        <UserRoute
          component={VendorAssistantConfirmComponent}
          exact
          path="/vendor/assistants/confirm"
        />
      )}
      <UserRoute
        exact
        path="/vendor/seating"
        render={() => <VendorSeatingComponent dealer={dealer} />}
        requirePermissions={['system:vendor']}
      />
      {dealer && (
        <Route
          exact
          path="/vendor/space/:id"
          render={(route) => (
            <>
              <LinkButton color="secondary" to="/vendor">
                Go back
              </LinkButton>
              <hr />
              <SpaceApplicationForm
                callback={refresh}
                dealer={dealer}
                dealerAreaId={Number.parseInt((route.match.params as { id: string }).id, 10)}
              />
            </>
          )}
        />
      )}
    </Switch>
  );
};
