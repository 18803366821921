import { MaterialIcon } from '@conventioncatcorp/common-fe/dist/components/MaterialIcon';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Option, OptionDataValueInput, OptionFile, OptionFileUnion } from '../../../shared/options';
import { classNames, formatBytes } from '../../utils';

import './OptionFileComponent.scss';

interface OptionFileProps {
  readonly option: Option;
  readonly defaultValue?: OptionDataValueInput;
  onChange?(id: number, option: OptionDataValueInput): void;
}

export const OptionFileComponent: FC<OptionFileProps> = ({ option, onChange, defaultValue }) => {
  const [files, setFiles] = useState<OptionFileUnion[]>((defaultValue as OptionFile[]) ?? []);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const maxFiles = option.max ?? 4;
  const name = `options[${option.id}]`;
  // 5 MB file size limit
  const maxFileSize = 5 * 1024 * 1024;

  useEffect(() => {
    if (onChange) {
      onChange(option.id, files);
    }
  }, [files]);

  const handleFileUpload = useCallback(
    (eventFiles: FileList) => {
      if (files.length + eventFiles.length > maxFiles) {
        toast.error(`Max ${maxFiles} files allowed`);
        // Remove any selected values
        if (inputRef.current) {
          inputRef.current.value = '';
        }

        return;
      }

      for (let i = 0; i < eventFiles.length; i++) {
        const file = eventFiles.item(i)!;

        if (file.size > maxFileSize) {
          toast.error(`File ${file.name} is too large. Max ${formatBytes(maxFileSize)}`);
          continue;
        }

        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          const result = fileReader.result as string;
          const data = result.slice(result.indexOf('base64') + 7);

          // Add the uploaded file to the list
          setFiles((old) => [
            ...old,
            {
              contentType: file.type,
              fileName: file.name,
              size: file.size,
              data,
            },
          ]);
        };

        fileReader.readAsDataURL(file);
      }

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [setFiles, files, maxFiles],
  );

  const onChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        handleFileUpload(e.target.files);
      }
    },
    [handleFileUpload],
  );

  const remove = useCallback((file: OptionFileUnion) => {
    setFiles((old) =>
      old.filter((oldFile) => {
        if (file.id && oldFile.id) {
          return file.id !== oldFile.id;
        }

        if (file.id === undefined && oldFile.id === undefined) {
          return file.data !== oldFile.data;
        }

        return true;
      }),
    );
  }, []);

  const onClickUpload = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  return (
    <div>
      <div className="file-upload">
        <input
          accept="image/*,video/*,application/pdf"
          className="file-upload-input"
          multiple
          name={name}
          onChange={onChangeFile}
          ref={inputRef}
          type="file"
        />
        <FileUploadDroparea
          fileCount={files.length}
          maxFileSize={maxFileSize}
          maxFiles={maxFiles}
          onClickUpload={onClickUpload}
          onDropUpload={handleFileUpload}
        />
      </div>
      {files.map((file) => (
        <FileUploadItem
          file={file}
          key={typeof file === 'string' ? file : file.fileName}
          onClickRemove={() => remove(file)}
        />
      ))}
    </div>
  );
};

interface DropareaProps {
  readonly fileCount: number;
  readonly maxFileSize: number;
  readonly maxFiles: number;
  onClickUpload(): void;
  onDropUpload(f: FileList): void;
}

const FileUploadDroparea: FC<DropareaProps> = ({
  fileCount,
  maxFileSize,
  maxFiles,
  onClickUpload,
  onDropUpload,
}) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else {
        setDragActive(false);
      }
    },
    [setDragActive],
  );

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      setDragActive(false);
      onDropUpload(e.dataTransfer.files);
    },
    [setDragActive],
  );

  if (fileCount >= maxFiles) {
    return (
      <div className="file-upload-droparea disabled">
        <div className="file-upload-icon">
          <MaterialIcon large name="warning" type="warning" />
        </div>
        <div>Max files reached</div>
        <p>
          A maximum of {maxFiles} file{maxFiles > 1 ? 's' : ''} can be uploaded.
        </p>
      </div>
    );
  }

  return (
    <div
      className={classNames({ 'drag-active': dragActive }, 'file-upload-droparea')}
      onClick={onClickUpload}
      onDragEnter={handleDrag}
      onDragExit={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <div className="file-upload-icon">
        <MaterialIcon large name="upload_file" />
      </div>
      {dragActive ? (
        <div>Drop file to upload!</div>
      ) : (
        <>
          <div>Select a file to upload</div>
          <p>or drag and drop it here!</p>
        </>
      )}
      <p>
        {maxFiles === 1 ? (
          <>(Max {formatBytes(maxFileSize)})</>
        ) : (
          <>
            (Max {maxFiles} files, max {formatBytes(maxFileSize)} each)
          </>
        )}
      </p>
    </div>
  );
};

interface ItemProps {
  readonly file: OptionFileUnion;
  onClickRemove(): void;
}

const FileUploadItem: FC<ItemProps> = ({ file, onClickRemove }) => {
  if (file.id) {
    return (
      <div className="file-upload-item">
        <div className="file-upload-item-name">
          <MaterialIcon className="cloud" name="cloud" small />
          <a href={file.url} rel="noreferrer" target="_blank">
            {file.fileName}
          </a>
        </div>
        <div className="dont-shrink">{formatBytes(file.size)}</div>
        <div className="dont-shrink">
          <MaterialIcon className="delete" name="delete" onClick={onClickRemove} small />
        </div>
      </div>
    );
  }

  return (
    <div className="file-upload-item pending">
      <div className="file-upload-item-name">{file.fileName}</div>
      <div className="dont-shrink">{formatBytes(file.size)}</div>
      <div className="dont-shrink">
        <MaterialIcon className="delete" name="delete" onClick={onClickRemove} small />
      </div>
    </div>
  );
};
