import React, { FC, useCallback } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FormNodeLinkTermsAgreementModel } from '../../../../shared/kiosk';
import { LazyMarkdown } from '../../LazyMarkdown';

interface FormNodeLinkTermsAgreementProps extends FormNodeLinkTermsAgreementModel {
  readonly checked: boolean;
  setChecked(checked: boolean): void;
}

export const FormNodeLinkTermsAgreement: FC<FormNodeLinkTermsAgreementProps> = ({
  id,
  markdown,
  checked,
  setChecked,
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
    },
    [setChecked],
  );

  const inputId = `agreement-checkbox-${id}`;
  return (
    <FormGroup>
      <div
        className="custom-control custom-checkbox form-node form-node-link-terms-agreement"
        id={`agreement-${id}`}
      >
        <Input
          checked={checked}
          className="custom-control-input"
          id={inputId}
          onChange={onChange}
          required
          type="checkbox"
        />
        <Label className="custom-control-label" for={inputId}>
          <LazyMarkdown source={markdown} />
        </Label>
      </div>
    </FormGroup>
  );
};
