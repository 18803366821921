import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LicenseWarnings } from '../../../shared/user/cashier';
import { PermissionBoundary } from '../../components';

interface RenderMessagesProps {
  readonly type: string;
  readonly title: string;
  readonly messages: string[];
  readonly licenseWarnings?: LicenseWarnings[];
  openUpdateFromScannerModal?(): void;
}

export const RenderMessages: FC<RenderMessagesProps> = ({
  licenseWarnings,
  type,
  title,
  messages,
  openUpdateFromScannerModal,
}) => {
  const openScannerModal = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      openUpdateFromScannerModal?.();
    },
    [openUpdateFromScannerModal],
  );

  if (messages.length === 0 && (licenseWarnings?.length ?? 0) === 0) {
    return null;
  }

  return (
    <div className={`cashier-status-message cashier-status-message-${type}`}>
      <p className={`text-${type}`} style={{ marginBottom: 0 }}>
        {title}
      </p>
      <ul>
        {messages.map((message) => (
          // eslint-disable-next-line react/no-danger
          <li dangerouslySetInnerHTML={{ __html: message }} key={message} />
        ))}
        {licenseWarnings?.includes('dobMismatch') && (
          <li>Date of Birth does not match scanned ID.</li>
        )}
        {licenseWarnings?.includes('nameMismatch') && (
          <li>
            Name does not match scanned ID.{' '}
            {openUpdateFromScannerModal && (
              <PermissionBoundary inline requiredPermissions={['user:update']}>
                &#40;
                <Link id="openUpdateInfoFromScan" onClick={openScannerModal} to="">
                  Update
                </Link>
                &#41;
              </PermissionBoundary>
            )}
          </li>
        )}
        {licenseWarnings?.includes('expired') && (
          <li>Scanned ID has an expiration date in the past.</li>
        )}
      </ul>
    </div>
  );
};
