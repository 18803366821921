import React, { FC } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useConfig, useConvention } from '../../../utils';

interface FelonyDialogProps {
  onClose(accepted: boolean): void;
}

export const FelonyDialog: FC<FelonyDialogProps> = ({ onClose }) => {
  const { longName, shortName } = useConvention();
  const { contact } = useConfig();

  return (
    <Modal id="felonyRequirementExceptionModal" isOpen toggle={() => onClose(false)}>
      <ModalHeader>{shortName} Felony Requirement Exception Required</ModalHeader>
      <ModalBody>
        <p>
          {longName} requires that all volunteers certify that they have not been convicted of a
          felony, or request an exception from the HR team.
        </p>
        <p>
          If you have been convicted of a felony, please contact the HR team at {contact.email.hr}{' '}
          to request an exception before submitting your application.
        </p>
        <p>
          If you <strong>have not</strong> been convicted of a felony, please click{' '}
          <strong>Cancel</strong> and check the certification under Volunteer Code of Conduct at the
          top of the page.
        </p>
        <hr />
        <p>
          If you have already requested an exception, you can submit your application by clicking "
          <strong>Submit Anyway</strong>".
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" id="submitAnyway" onClick={() => onClose(true)}>
          Submit Anyway
        </Button>{' '}
        <Button color="secondary" id="cancel" onClick={() => onClose(false)} outline>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
