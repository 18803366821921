import React, { Component, FC, FormEvent } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { Department, VolunteerDepartmentModel } from '../../../../shared/volunteer';
import { MaterialIconText } from '../../../components/MaterialIconText';
import { classNames } from '../../../utils';

const departmentTypes = ['Experience', 'Interest', 'Avoid'];

interface VolunteerDepartmentsProps {
  readonly departments: Department[];
  readonly volunteerDepartments?: VolunteerDepartmentModel[];
}

type VolunteerDepartmentsState = Record<string, number[]>;

// eslint-disable-next-line react/require-optimization
export class VolunteerDepartments extends Component<
  VolunteerDepartmentsProps,
  VolunteerDepartmentsState
> {
  public constructor(props: VolunteerDepartmentsProps) {
    super(props);

    const { volunteerDepartments } = props;
    const state: Record<string, number[]> = {};

    for (const depType of departmentTypes) {
      state[depType] = (volunteerDepartments ?? [])
        .filter((volDep) => (volDep.states as string[]).includes(depType.toLowerCase()))
        .map((volDep) => volDep.id);
    }

    this.state = {
      ...state,
    };
  }

  public override render(): JSX.Element {
    const { departments } = this.props;
    return (
      <Row className="department-select">
        <Col className="margin-bottom-10 department-select-header" lg={6} xs={12}>
          <strong>Department Name</strong>
        </Col>
        {departmentTypes.map((type) => (
          <Col
            className="margin-bottom-10 text-center department-select-header"
            key={type}
            lg={2}
            xs={4}
          >
            <strong>{type}</strong>
          </Col>
        ))}
        {departments.map(({ name, id, publiclyVisible }) => (
          <Col className="department-select-item" id={`department-${id}`} key={id} xs={12}>
            <Row>
              <Col className="margin-bottom-10" lg={6} xs={12}>
                {name}
                {!publiclyVisible && <HiddenDepartment />}
              </Col>
              {departmentTypes.map((type) => {
                const formId = `department${type}${id}`;
                return (
                  <Col className="margin-bottom-10 text-center" key={type} lg={2} xs={4}>
                    <div
                      className={classNames(
                        {
                          negative: type === 'Avoid',
                        },
                        'custom-control',
                        'custom-checkbox',
                      )}
                    >
                      <Input
                        checked={this.state[type].includes(id)}
                        className="custom-control-input"
                        id={formId}
                        name={`department${type}[]`}
                        onChange={(event) => {
                          this.changeSelection(type, event);
                        }}
                        type="checkbox"
                        value={id}
                      />
                      <Label className="custom-control-label" for={formId} />
                    </div>
                  </Col>
                );
              })}
              <Col className="margin-bottom-10 show-mobile" xs={12} />
            </Row>
          </Col>
        ))}
      </Row>
    );
  }

  private changeSelection(type: string, event: FormEvent<HTMLInputElement>): void {
    const { state } = this;
    const value = Number.parseInt(event.currentTarget.value, 10);

    if (event.currentTarget.checked) {
      if (type === 'Avoid') {
        this.setState({
          Avoid: [...state.Avoid, value],
          Experience: state.Experience.filter((n) => n !== value),
          Interest: state.Interest.filter((n) => n !== value),
        });
      } else {
        this.setState({
          Avoid: state.Avoid.filter((n) => n !== value),
          [type]: [...state[type], value],
        });
      }
    } else {
      this.setState({
        [type]: state[type].filter((n) => n !== value),
      });
    }
  }
}

export const HiddenDepartment: FC = () => {
  return (
    <div className="small text-muted">
      <MaterialIconText name="visibility" small>
        This is a <strong>hidden</strong> department.
      </MaterialIconText>
    </div>
  );
};
