import React, { ChangeEvent, FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';
import { OptionFileInput } from '../../../shared/options';
import { noImage } from '../../../shared/orders/product';
import { CurrentUser } from '../../../shared/user/base';
import { maxProfilePictureWidth } from '../../../shared/user/update';
import { MaterialIcon } from '../../components';
import { UserAvatar } from '../../utils/UserAvatar';

interface ProfilePictureEditorProps {
  readonly inputUser: CurrentUser;
  readonly title?: string;
  readonly ignoreGravatar: boolean;
  onChange(file: OptionFileInput | null | undefined): void;
}

export const ProfilePictureEditor: FC<ProfilePictureEditorProps> = ({
  inputUser,
  title,
  ignoreGravatar,
  onChange,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const avatarEditorRef = React.useRef<AvatarEditor>(null);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [remove, setRemove] = useState(false);
  const canRemove = !inputUser.profilePictureUrl.includes('gravatar');

  const onChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const eventFiles = e.target.files;
        setNewFile(eventFiles.item(0));
      }
    },
    [setNewFile],
  );

  const onClickUpload = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const cancel = useCallback(() => {
    setNewFile(null);
    onChange(undefined);
    setRemove(false);
  }, [onChange, setNewFile]);

  const onLoadFailure = useCallback(() => {
    toast.error('Not a valid image');
    cancel();
  }, [cancel]);

  const onImageChange = useCallback(() => {
    const canvas = avatarEditorRef.current?.getImageScaledToCanvas();

    if (!canvas) {
      return;
    }

    const result = canvas.toDataURL();
    const data = result.slice(result.indexOf('base64') + 7);

    setRemove(false);
    onChange({
      contentType: 'image/png',
      fileName: 'avatar.png',
      size: result.length,
      data,
    });
  }, [avatarEditorRef]);

  const onDelete = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onChange(null);
    setRemove(true);
  }, []);

  useEffect(() => {
    cancel();
  }, [inputUser.profilePictureUrl]);

  return (
    <>
      {title && <Label for="profilePictureInput">{title}</Label>}
      <div className="file-upload">
        <input
          accept="image/*"
          className="file-upload-input"
          id="profilePictureInput"
          onChange={onChangeFile}
          ref={inputRef}
          type="file"
        />
      </div>
      {newFile ? (
        <div>
          <AvatarEditor
            border={0}
            borderRadius={maxProfilePictureWidth / 2}
            height={maxProfilePictureWidth}
            image={newFile}
            onImageChange={onImageChange}
            onImageReady={onImageChange}
            onLoadFailure={onLoadFailure}
            ref={avatarEditorRef}
            width={maxProfilePictureWidth}
          />
          <br />
          <button onClick={cancel} type="button">
            Cancel
          </button>
        </div>
      ) : (
        <DefaultProfilePicture
          canRemove={canRemove}
          ignoreGravatar={ignoreGravatar}
          onClickUpload={onClickUpload}
          onDelete={onDelete}
          remove={remove}
          user={inputUser}
        />
      )}
    </>
  );
};

function getProfilePic(profilePic: string, ignoreGravatar: boolean) {
  if (profilePic.includes('gravatar') && ignoreGravatar) {
    return noImage;
  }

  return profilePic;
}

interface DefaultProfilePictureProps {
  readonly canRemove: boolean;
  readonly remove: boolean;
  readonly user: CurrentUser;
  readonly ignoreGravatar: boolean;
  readonly onDelete: MouseEventHandler<HTMLSpanElement>;
  onClickUpload(): void;
}

const DefaultProfilePicture: React.FC<DefaultProfilePictureProps> = ({
  canRemove,
  remove,
  user,
  ignoreGravatar,
  onDelete,
  onClickUpload,
}) => {
  const profilePic = getProfilePic(user.profilePictureUrl, ignoreGravatar);

  return (
    <div
      className="avatar"
      id="uploadProfilePic"
      onClick={onClickUpload}
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      <div className="overlay" style={{ fontWeight: 'bold', paddingTop: '10px' }}>
        <div>
          Click
          <br />
          to
          <br />
          update
        </div>
        {canRemove && !remove && (
          <div className="profilePictureDeleteButton">
            <MaterialIcon name="delete" onClick={onDelete} />
          </div>
        )}
      </div>
      {remove ? (
        <UserAvatar size="small" url={noImage} />
      ) : (
        <UserAvatar size="small" url={profilePic} />
      )}
    </div>
  );
};
