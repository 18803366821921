import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { MaterialIcon } from '../../components';
import { RegistrationInfo } from '../../models/RegistrationInfo';
import { displayName, useBoolState, useConvention } from '../../utils';
import { captureError } from '../../utils/errorHandling';
import { RaffleRegState } from './raffleregstate';

import './listitem.scss';

interface RegistrationListItemProps {
  readonly conRegistration: RegistrationInfo;
  redirectFn(url: string): void;
  refresh(): void;
}

export const RegistrationListItem: FC<RegistrationListItemProps> = ({
  conRegistration: reg,
  redirectFn,
  refresh,
}) => {
  const [showTransfer, enableShowTransfer, disableShowTransfer] = useBoolState(false);
  const { enableRegTransfer } = useConvention();

  return (
    <Card>
      <CardBody>
        <div
          style={{
            minWidth: '200px',
            position: 'absolute',
            right: '15px',
            textAlign: 'right',
            top: '15px',
          }}
        >
          <UncontrolledDropdown className="mw-100">
            {(enableRegTransfer || !reg.paidOrderItem) && (
              <DropdownToggle id={`regDropdown${reg.id}`} tag="a">
                <MaterialIcon className="action-more-options clickable" name="more_horiz" />
              </DropdownToggle>
            )}
            <DropdownMenu right>
              {enableRegTransfer && (
                <DropdownItem className="registration-action-transfer" onClick={enableShowTransfer}>
                  Transfer Registration
                </DropdownItem>
              )}
              {!reg.paidOrderItem && (
                <>
                  <hr />
                  <DropdownItem
                    className="text-danger registration-action-delete"
                    onClick={async () =>
                      await api.deleteRegistration(reg.id).then(refresh).catch(captureError)
                    }
                  >
                    Delete Registration
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <h4 style={{ marginRight: '30px' }}>{reg.badgeName}</h4>
        <div className="float-right">
          <Button
            color="primary"
            id={`editRegistration${reg.id}`}
            onClick={() => {
              redirectFn('/event/register/edit');
            }}
            outline
          >
            Modify Registration
          </Button>
        </div>
        <div>
          <Badge color="primary" pill style={{ padding: '5px 10px', marginRight: '5px' }}>
            {displayName(reg.attendanceType)}
          </Badge>
          {reg.paidOrderItem ? (
            <Badge className="reg-state-paid" color="success" pill>
              Paid
            </Badge>
          ) : (
            <Badge className="reg-state-unpaid" color="secondary" pill>
              Unpaid
            </Badge>
          )}
        </div>
        {reg.attendanceType.raffleId && !reg.paidOrderItem && reg.raffle && (
          <RaffleRegState raffle={reg.raffle} />
        )}
      </CardBody>
      {showTransfer && (
        <TransferRegistrationComponent
          close={disableShowTransfer}
          conRegistration={reg}
          refresh={refresh}
        />
      )}
    </Card>
  );
};

interface TransferRegistrationComponentProps {
  readonly conRegistration: RegistrationInfo;
  close(): void;
  refresh(): void;
}

const TransferRegistrationComponent: FC<TransferRegistrationComponentProps> = ({
  conRegistration: reg,
  close,
  refresh,
}) => {
  const { id, badgePrinted } = reg;

  if (badgePrinted && badgePrinted !== null) {
    return (
      <div className="transfer-registration">
        <hr />
        <CardBody>
          <MaterialIcon className="pointer float-right" name="close" onClick={close} />
          <h5>Transfer Registration</h5>
          <div className="reg-transfer-error reg-transfer-already-printed">
            <div>
              <MaterialIcon medium name="front_hand" type="danger" />
            </div>
            <div>
              <p>
                <strong>This registration cannot be transferred</strong>, as the badge has already
                been printed.
              </p>
              <p>If you have any questions, please contact the registration team.</p>
            </div>
          </div>
        </CardBody>
      </div>
    );
  }

  return (
    <div className="transfer-registration">
      <hr />
      <CardBody>
        <MaterialIcon className="pointer float-right" name="close" onClick={close} />
        <h5>Transfer Registration</h5>
        <p>
          You can only transfer a registration to an existing user with a verified email address.
        </p>
        <p>
          This action is <strong>PERMANENT</strong> and can't be undone. You will no longer be
          registered for this event and will be unable to edit this registration.
        </p>
        <hr />
        <JSONForm
          method="post"
          onSuccess={() => {
            toast.success('Registration has been transferred!');
            close();
            refresh();
          }}
          path={`/api/registrations/${id}/transfer`}
        >
          <Row>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="email">Target Email</Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email of Intended Recipient"
                  type="email"
                />
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input id="password" name="password" type="password" />
              </FormGroup>
            </Col>
          </Row>
          <Button color="danger" id="transferButton">
            Transfer Registration
          </Button>
        </JSONForm>
      </CardBody>
    </div>
  );
};
