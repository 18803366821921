import { OptionFileInput } from '../options';

export const maxProfilePictureSize = 512 * 1024;
export const maxProfilePictureWidth = 256;

export interface UpdateUserPayload {
  firstName?: string;
  lastName?: string;
  preferredName?: string | null;
  phone?: string;

  addressLine1?: string;
  addressLine2?: string | null;
  addressCity?: string;
  addressCountry?: string;
  addressState?: string;
  addressZipcode?: string;

  profilePicture?: OptionFileInput | null;

  bornAt?: Date | null;

  password?: string;
  currentPassword?: string;
  email?: string;
  resetCode?: string | null;
}
