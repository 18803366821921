import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { ChildRegistration } from '../models';
import { useConvention } from '../utils';
import { ElementListItem } from './ElementList';
import { ElementList, HotkeyButton, MaterialIcon, TermsLoader } from '.';

const convertToElements = (children: ChildRegistration[]): ElementListItem[] => {
  return children.map((child) => ({
    id: child.id,
    name: `${child.badgeName}${child.species ? ` (${child.species})` : ''}`,
  }));
};

interface ChildRegistrationProps {
  readonly hideWarning?: boolean;
  readonly compact?: boolean;
  readonly useModal?: boolean;
  readonly items: ChildRegistration[];
  readonly registrationId: number;
  onUpdate(): void;
}

export const ChildRegistrations: FC<ChildRegistrationProps> = ({
  items,
  compact,
  useModal,
  hideWarning,
  registrationId,
  onUpdate,
}) => {
  const onDelete = useCallback(async (id: number) => {
    await api.deleteChildRegistration(id);
    toast.success('Child registration has been removed.');
    onUpdate();
  }, []);

  const onAdd = useCallback(async () => {
    toast.success('New child registration has been created!');
    onUpdate();
  }, []);

  return (
    <Row className="justify-content-center" id="childRegistration">
      {!hideWarning && (
        <Col className="margin-bottom-10" xs={12}>
          <Card color="info" outline>
            <CardBody>
              <CardTitle>Child Badge Terms</CardTitle>
              <Row>
                <Col className="text-center p-0" lg={2} xs={12}>
                  <MaterialIcon large name="info" type="info" />
                </Col>
                <Col className="p-0" lg={10} xs={12}>
                  <TermsLoader name="childBadgeTerms" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )}
      <Col className="margin-bottom-10" lg={compact ? 12 : 6} xs={12}>
        <ElementList elements={convertToElements(items)} onDelete={onDelete} />
      </Col>
      <Col className="margin-bottom-10" lg={compact ? 12 : 6} xs={12}>
        {useModal ? (
          <AddChildModal onAdd={onAdd} registrationId={registrationId} />
        ) : (
          <AddChildInline onAdd={onAdd} registrationId={registrationId} />
        )}
      </Col>
    </Row>
  );
};

interface AddChildProps {
  readonly registrationId: number;
  onAdd(): void;
}

const AddChildInline: FC<AddChildProps> = ({ registrationId, onAdd }) => {
  const convention = useConvention();

  return (
    <JSONForm
      method="post"
      onSuccess={onAdd}
      path={`/api/registrations/${registrationId}/children`}
    >
      <Card>
        <CardBody>
          <CardTitle>Add New Child</CardTitle>
          <Row>
            <Col md={6} xs={12}>
              <FormGroup>
                <Label for="badgeName">Badge Name</Label>
                <Input id="badgeName" name="badgeName" />
              </FormGroup>
            </Col>
            {convention.childSubLabel ? (
              <Col md={6} xs={12}>
                <FormGroup>
                  <Label for="species">
                    {convention.childSubLabel} <small>(Optional)</small>
                  </Label>
                  <Input id="species" name="species" />
                </FormGroup>
              </Col>
            ) : (
              <Input id="species" name="species" type="hidden" value="" />
            )}
            <Col className="margin-top-10" lg={12} xs={12}>
              <Button block color="primary" id="submitForm" type="submit">
                Add Child
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </JSONForm>
  );
};

const AddChildModal: FC<AddChildProps> = ({ registrationId, onAdd: baseOnAdd }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onAdd = useCallback(async () => {
    setIsOpen(false);
    baseOnAdd();
  }, [baseOnAdd]);

  return (
    <>
      <HotkeyButton
        actionText="add child badge"
        block
        color="secondary"
        hotkey="c"
        onClick={async () => setIsOpen(true)}
        outline
      >
        Add new child badge
      </HotkeyButton>
      <Modal
        className="modal-large child-registration-modal hide-print"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
      >
        <ModalHeader toggle={() => setIsOpen(false)}>Add child badge</ModalHeader>
        <ModalBody>
          <AddChildInline onAdd={onAdd} registrationId={registrationId} />
        </ModalBody>
      </Modal>
    </>
  );
};
