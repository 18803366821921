import React from 'react';

type Size = 'medium' | 'small';

export const UserAvatar = ({
  url,
  size,
}: {
  readonly url: string;
  readonly size?: Size;
}): JSX.Element => {
  return (
    <img
      className={size ? `avatar-${size}` : ''}
      loading="lazy"
      referrerPolicy="no-referrer"
      src={url}
    />
  );
};
