import React, { FC, ReactNode, useCallback } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { PermissionName } from '../../shared/permissions';
import { ConventionSetting } from '../models';
import { checkPermission, useConfig, useConvention, useFetcher, useQuery, useUser } from '../utils';
import { LoadingState } from '../utils/LoadingState';
import { AccessDenied } from '.';

interface UserStateProps {
  readonly children?: ReactNode;
  readonly requireLoggedOut?: boolean;
}

interface UserRouteProps {
  readonly requirePermissions?: PermissionName[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly component?: React.ComponentType<any>;
  readonly render?: () => React.ReactNode;
  readonly path?: string | readonly string[] | undefined;
  readonly exact?: boolean | undefined;
  readonly noRedirect?: boolean;
  readonly matchAny?: boolean;
}

export const UserRoute: FC<UserRouteProps> = ({
  requirePermissions,
  component,
  exact,
  path,
  render,
  noRedirect,
  matchAny,
}) => {
  const user = useUser();

  if (!user) {
    return <LoginRedirect noRedirect={noRedirect} />;
  }

  if (requirePermissions && !checkPermission(user, requirePermissions, matchAny)) {
    return (
      <Container className="justify-content-center">
        <AccessDenied />
      </Container>
    );
  }

  return <Route component={component} exact={exact} path={path} render={render} />;
};

export const UserStateComponent: FC<UserStateProps> = (props) => {
  const { children, requireLoggedOut } = props;

  const user = useUser();
  if (requireLoggedOut && user) {
    return <RedirectAfterLogin />;
  }

  return (
    <Container className="justify-content-center">
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </Container>
  );
};

export const RedirectAfterLogin: FC = () => {
  const config = useConfig();
  const params = useQuery();
  const redirect = params.get('redirect');
  const extParams = params.get('params') ?? '';
  const conventionId = Number.parseInt(params.get('conventionId') ?? '', 10);
  const path = redirect ? `${redirect}${decodeURIComponent(extParams)}` : '/';

  for (const c of config.conventions) {
    if (conventionId === c.id) {
      return <ConventionRedirect convention={c} extParams={extParams} redirect={redirect} />;
    }
  }

  // For redirect scenarios where the user is going to be redirected to an external website
  if (path.includes('api/')) {
    window.location.href = path;
    return null;
  }

  return <Redirect to={path} />;
};

interface ConventionRedirectProps {
  readonly convention: ConventionSetting;
  readonly redirect: string | null;
  readonly extParams: string;
}

export const ConventionRedirect: FC<ConventionRedirectProps> = ({
  convention,
  redirect,
  extParams,
}) => {
  const redirectRequest = useFetcher(async () => {
    return await api.getRedirectRequest(convention.id);
  }, [convention.id]);

  const user = useUser();

  if (redirectRequest.state === LoadingState.Loading) {
    return <>Redirecting user to {convention.longName}</>;
  }

  if (redirectRequest.state === LoadingState.Error) {
    return <>Error while redirecting user. Please contact support.</>;
  }

  return (
    <HiddenRedirectForm
      host={convention.domain}
      params={extParams}
      redirect={redirect ?? '/'}
      token={redirectRequest.data!.token}
      userId={user!.id}
    />
  );
};

interface HiddenRedirectFormProps {
  readonly host: string;
  readonly token: string;
  readonly userId: number;
  readonly redirect: string;
  readonly params: string;
}

const HiddenRedirectForm: FC<HiddenRedirectFormProps> = ({
  host,
  token,
  userId,
  params,
  redirect,
}) => {
  const url = `${location.protocol}//${host}/api/login/from_redirect`;
  if (params !== '') {
    redirect += `?${params}`;
  }

  const submit = useCallback((form: HTMLFormElement) => {
    form.submit();
  }, []);

  return (
    <form action={url} method="post" ref={submit}>
      <input name="token" type="hidden" value={token} />
      <input name="userId" type="hidden" value={userId.toString()} />
      <input name="redirect" type="hidden" value={redirect} />
    </form>
  );
};

export const LoginRedirect: FC<{ readonly noRedirect?: boolean }> = ({ noRedirect }) => {
  const { conventions, activeConventionId } = useConfig();
  const location = useLocation();
  const convention = useConvention();

  const { domain } = conventions.find((t) => t.id === activeConventionId)!;
  const params = location.search.length > 0 ? `&params=${encodeURIComponent(location.search)}` : '';
  const redirect = noRedirect ? '/' : `${location.pathname}${params}`;
  const conventionId = convention.id === activeConventionId ? '' : `&conventionId=${convention.id}`;

  window.location.replace(
    `${window.location.protocol}//${domain}/login?redirect=${redirect}${conventionId}`,
  );

  return null;
};
