import React, { Component, Fragment } from 'react';
import { classNames } from '../utils';
import { MaterialIcon } from '.';

export enum WizardStatus {
  NotStarted,
  InProgress,
  Pending,
  Completed,
  Error,
}

export interface WizardItem {
  status: WizardStatus;
  text: string;
}

interface WizardProps {
  readonly className?: string;
  readonly items: WizardItem[];
}

// eslint-disable-next-line react/require-optimization
export class WizardComponent extends Component<WizardProps> {
  public override render(): JSX.Element {
    const { className, items } = this.props;

    return (
      <div className={`wizard text-center ${className!}`}>
        {items.map((v, i) => this.renderWizardStep(i, v))}
      </div>
    );
  }

  private renderWizardStep(id: number, { status, text }: WizardItem): JSX.Element {
    return (
      <Fragment key={id}>
        {id > 0 && <div className="wizard-divider" />}
        <div
          className={classNames(
            {
              active: status === WizardStatus.InProgress,
              complete: status === WizardStatus.Completed,
              error: status === WizardStatus.Error,
              loading: status === WizardStatus.Pending,
            },
            'wizard-step',
          )}
        >
          <div className="wizard-step-number">
            <span>{this.renderWizardNumber(id, status)}</span>
          </div>
          <div className="wizard-step-description">
            <span>{text}</span>
          </div>
        </div>
      </Fragment>
    );
  }

  private renderWizardNumber(id: number, status: WizardStatus): JSX.Element | number {
    if (status === WizardStatus.Completed) {
      return <MaterialIcon name="check" />;
    }

    if (status === WizardStatus.Error) {
      return <MaterialIcon name="close" />;
    }

    return id + 1;
  }
}
