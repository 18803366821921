import React, { ReactNode } from 'react';
import { MessageCard, PageHeader } from '../../components';
import { isLogicError } from '../../utils';
import { LogicError } from '../../utils/errorHandling';

const RegistrationClosed = () => (
  <MessageCard icon="remove_circle" id="registrationClosed" level="danger">
    Registration for the convention is currently closed.
  </MessageCard>
);

export const registrationClosedHandler = (err: Error): ReactNode =>
  isLogicError(err, LogicError.RegistrationClosed) && (
    <>
      <PageHeader>Event Registration</PageHeader>
      <RegistrationClosed />
    </>
  );
