import React, {
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Loading, LoadingError } from '../components';
import { LoadingState } from '../utils/LoadingState';
import { captureError } from '../utils/errorHandling';

type LoadingDisplay = () => JSX.Element | null;
type ErrorDisplay = (err: Error) => ReactNode;

interface LoadingComponentProps<T, R> {
  readonly passback?: R;
  readonly children: (data: T, refresh: (passback?: R) => void) => JSX.Element | null;
  readonly errorDisplay?: ErrorDisplay;
  readonly loadingDisplay?: LoadingDisplay;
  readonly inline?: boolean;
  readonly noLoadAfterFirstRender?: boolean;
  readonly refreshRef?: MutableRefObject<(() => void) | null>;
  dataFetcher(input: R): Promise<T>;
}

export const LoadingWrapper = <T, R>({
  children,
  dataFetcher,
  refreshRef,
  passback: initialPassback,
  inline,
  errorDisplay,
  noLoadAfterFirstRender = true,
  loadingDisplay,
}: LoadingComponentProps<T, R>): ReactElement | null => {
  const [state, setState] = useState(LoadingState.Loading);
  const [data, setData] = useState<T>();
  const [passback, setPassback] = useState<R | undefined>(initialPassback);
  const [error, setError] = useState<Error | undefined>(undefined);
  const cb = useCallback((passB?: R) => {
    if (passB) {
      setPassback(passB);
    }

    setState(LoadingState.Loading);
  }, []);

  useEffect(() => {
    if (refreshRef) {
      refreshRef.current = cb;
    }
  }, [refreshRef]);

  useEffect(() => {
    setPassback(initialPassback);
    setState(LoadingState.Loading);
  }, [initialPassback]);

  useEffect(() => {
    if (state !== LoadingState.Loading) {
      return;
    }

    dataFetcher(passback!).then(
      (newData) => {
        unstable_batchedUpdates(() => {
          setData(newData);
          setError(undefined);
          setState(LoadingState.Done);
        });
      },
      (error_: Error) => {
        unstable_batchedUpdates(() => {
          setState(LoadingState.Error);
          setError(error_);
        });
      },
    );
  }, [passback, state]);

  const forceOldRender = noLoadAfterFirstRender && data;
  if (state === LoadingState.Loading && !forceOldRender) {
    if (loadingDisplay) {
      return loadingDisplay();
    }

    return <Loading inline={inline} />;
  }

  if (state === LoadingState.Done || (state === LoadingState.Loading && forceOldRender && data)) {
    return children(data!, cb);
  }

  if (state === LoadingState.Error) {
    if (errorDisplay && error) {
      const out = errorDisplay(error);
      if (out === null) {
        return null;
      }

      if (out !== undefined && out !== false) {
        return <>{out}</>;
      }

      // Only handle errors that were NOT handled.
      captureError(error);
    }

    return (
      <LoadingError
        errorText="Failed to load"
        retryCallback={() => {
          setState(LoadingState.Loading);
        }}
      />
    );
  }

  throw new Error('bad state');
};
