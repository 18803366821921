import React, { FC } from 'react';
import { MaterialIcon, MaterialIconProps } from './';

export const MaterialIconText: FC<MaterialIconProps> = ({ children, ...props }) => {
  return (
    <div className="material-icon-text">
      <MaterialIcon {...props} />
      <div>{children}</div>
    </div>
  );
};
