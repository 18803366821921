import React, { FC } from 'react';
import { Label } from 'reactstrap';
import { InlineHelp } from '.';

export const PlatformFeeHelp: FC = () => (
  <InlineHelp>
    <Label>Platform Fee Information</Label>
    <p>
      By default, our platform fees are absorbed into the cost of each product and are paid by the
      event, but as the organizer, you can choose to pass these fees to your attendees.
    </p>
    <Label>Example</Label>
    <ul>
      <li>
        If you absorb the platform fees on a $50.00 registration, you will receive $50.00 minus your
        agreed platform fee.
      </li>
      <li>
        If you choose to pass the fees to your attendees, the attendee will be charged $50.00 plus
        your agreed platform fee and you will receive $50.00.
      </li>
    </ul>
  </InlineHelp>
);
