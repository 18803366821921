import { SelectBlocks } from '@conventioncatcorp/common-fe/dist/components';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import {
  Badge,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import { Option, OptionDataValue } from '../../shared/options';
import { ProductOptionInput } from '../../shared/orders';
import { classNames, setElementInArray } from '../utils';
import { cToUsdStrPref } from '../utils/cToUsdStr';
import { OptionFileComponent } from './option/OptionFileComponent';
import { OptionPhoneComponent } from './option/OptionPhoneComponent';

interface ProductOptionViewerProps {
  readonly disabled?: boolean;
  readonly option: ProductOptionInput;
  readonly defaultValue?: OptionDataValue;
  readonly value?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

export const ProductOptionViewer: FC<ProductOptionViewerProps> = ({
  disabled,
  option,
  onChange,
  defaultValue,
  value,
}) => {
  return (
    <FormGroup>
      <Label>
        {option.name} {option.required && <Badge color="danger">Required</Badge>}
      </Label>
      <ProductOptionRenderer
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        option={option}
        required={option.required}
        value={value}
      />
      {option.description && <FormText color="muted">{option.description}</FormText>}
    </FormGroup>
  );
};

interface ProductOptionRendererProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  readonly required: boolean;
  readonly value?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

export const ProductOptionRenderer: FC<ProductOptionRendererProps> = (props) => {
  const { disabled, option, required, onChange, defaultValue, value } = props;

  if (option.type === 'select') {
    return <POSingleSelect {...props} />;
  }

  if (option.type === 'multi') {
    return <POMultiSelect {...props} />;
  }

  if (option.type === 'text') {
    return <OptionText {...props} />;
  }

  if (option.type === 'file') {
    return <OptionFileComponent {...props} />;
  }

  if (option.type === 'phone') {
    return <OptionPhoneComponent {...props} />;
  }

  return (
    <InputGroup>
      {option.type === 'currency' && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>$</InputGroupText>
        </InputGroupAddon>
      )}
      <Input
        defaultValue={defaultValue?.toString() ?? '0'}
        disabled={disabled}
        id={`option${option.id}`}
        max={option.max ?? undefined}
        min={option.min}
        name={`options[${option.id}]`}
        onChange={(e) => {
          const v = e.currentTarget.value;
          onChange?.(option.id, v === '' ? null : v);
        }}
        required={required}
        step={option.type === 'currency' ? 0.01 : undefined}
        type="number"
        value={value as string}
      />
    </InputGroup>
  );
};

interface OptionTextProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  readonly required: boolean;
  readonly value?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

const OptionText: FC<OptionTextProps> = ({
  disabled,
  option,
  required,
  defaultValue,
  value,
  onChange,
}) => {
  const onChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.currentTarget.value;
      onChange?.(option.id, v === '' ? null : v);
    },
    [onChange, option],
  );

  const maxLength = option.max && option.max > option.min ? option.max : undefined;

  return (
    <InputGroup>
      <Input
        defaultValue={defaultValue?.toString() ?? ''}
        disabled={disabled}
        id={`option${option.id}`}
        maxLength={maxLength}
        minLength={option.min}
        name={`options[${option.id}]`}
        onChange={onChangeCallback}
        required={required}
        type={option.style === 'textarea' ? 'textarea' : 'text'}
        value={value as string}
      />
    </InputGroup>
  );
};

interface POSingleSelectProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  readonly required: boolean;
  readonly value?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

const POSingleSelect: FC<POSingleSelectProps> = ({
  disabled,
  option,
  required,
  onChange,
  defaultValue,
  value,
}) => {
  if (option.style === 'radio') {
    return (
      <POSingleRadio
        defaultValue={defaultValue ?? value}
        disabled={disabled}
        onChange={onChange}
        option={option}
      />
    );
  }

  return (
    <POSingleSelectDropdown
      defaultValue={defaultValue ?? value}
      disabled={disabled}
      onChange={onChange}
      option={option}
      required={required}
    />
  );
};

interface POSingleRadioProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

const POSingleRadio: FC<POSingleRadioProps> = ({ disabled, option, defaultValue, onChange }) => {
  const name = `options[${option.id}]`;

  const onChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      onChange?.(option.id, value === '' ? null : value);
    },
    [onChange, option],
  );

  return (
    <FormGroup>
      {(option.selectValues ?? []).map((opt) => {
        const id = `option${option.id}-${opt.value}`;
        return (
          <div className="custom-control custom-radio margin-top-10" key={opt.value}>
            <Input
              className="custom-control-input"
              defaultChecked={defaultValue?.toString() === opt.value}
              disabled={disabled}
              id={id}
              name={name}
              onChange={onChangeCallback}
              type="radio"
              value={opt.value}
            />
            <Label className="custom-control-label" for={id}>
              {opt.displayName ?? opt.value}
            </Label>
          </div>
        );
      })}
    </FormGroup>
  );
};

interface POSingleSelectDropdownProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  readonly required: boolean;
  onChange?(id: number, option: OptionDataValue): void;
}

const POSingleSelectDropdown: FC<POSingleSelectDropdownProps> = ({
  disabled,
  option,
  required,
  onChange,
  defaultValue,
}) => {
  const name = `options[${option.id}]`;

  const callback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      onChange?.(option.id, value === '' ? null : value);
    },
    [option, onChange],
  );

  return (
    <FormGroup>
      <Input
        data-coerce={false}
        defaultValue={defaultValue?.toString() ?? ''}
        disabled={disabled}
        id={`option${option.id}`}
        name={disabled ? undefined : name}
        onChange={callback}
        required={required}
        type="select"
      >
        {required ? (
          <option disabled value="">
            (Required) Select One
          </option>
        ) : (
          <option value="">None</option>
        )}
        {(option.selectValues ?? []).map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.displayName ?? opt.value}{' '}
            {opt.priceModifier > 0 && `(${cToUsdStrPref(opt.priceModifier)})`}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

interface POMultiSelectProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

const POMultiSelect: FC<POMultiSelectProps> = ({ disabled, option, onChange, defaultValue }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    Array.isArray(defaultValue) ? (defaultValue as string[]) : [],
  );

  useEffect(() => {
    onChange?.(option.id, selectedOptions);
  }, [selectedOptions, onChange, option.id]);

  return (
    <FormGroup id={`option${option.id}`}>
      {option.style === 'simple' ? (
        <MultipleSelectCheckboxes
          disabled={disabled}
          option={option}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      ) : (
        <MultipleSelectBlocks
          disabled={disabled}
          option={option}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      )}
      {selectedOptions.map((id) => (
        <Input
          data-coerce={false}
          key={`${option.id}-${id}`}
          name={`options[${option.id}][]`}
          type="hidden"
          value={id}
        />
      ))}
    </FormGroup>
  );
};

interface MultipleSelectCheckboxesProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly selectedOptions: string[];
  setSelectedOptions(options: string[]): void;
}

const MultipleSelectCheckboxes: FC<MultipleSelectCheckboxesProps> = ({
  option,
  selectedOptions,
  setSelectedOptions,
  disabled,
}) => {
  const selectValues = option.selectValues ?? [];

  const changeCallback = useCallback(
    (key: string, checked: boolean) => {
      setSelectedOptions(setElementInArray(selectedOptions, key, checked));
    },
    [selectedOptions, setSelectedOptions],
  );

  return (
    <>
      {selectValues.map((value) => {
        const id = `optionValue${value.value}`;
        const name = value.displayName ?? value.value;
        const modifier =
          value.priceModifier === 0 ? '' : ` (${cToUsdStrPref(value.priceModifier)})`;

        const selected = selectedOptions.includes(value.value);

        return (
          <div className="custom-control custom-checkbox margin-top-10" key={id}>
            <Input
              checked={selected}
              className={classNames({ selected }, 'custom-control-input')}
              disabled={disabled}
              id={id}
              onChange={(e) => changeCallback(value.value, e.target.checked)}
              type="checkbox"
            />
            <Label className="custom-control-label" for={id}>
              {`${name}${modifier}`}
            </Label>
          </div>
        );
      })}
    </>
  );
};

interface MultipleSelectBlocksProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly selectedOptions: string[];
  setSelectedOptions(option: string[]): void;
}

const MultipleSelectBlocks: FC<MultipleSelectBlocksProps> = ({
  option,
  selectedOptions,
  setSelectedOptions,
  disabled,
}) => {
  const selectValues = option.selectValues ?? [];

  return (
    <SelectBlocks
      allowMultiple
      defaultSelectedId={selectedOptions.map((t) => selectValues.findIndex((u) => u.value === t))}
      disabled={disabled}
      items={selectValues.map(({ displayName, value, priceModifier }) => {
        const name = displayName ?? value;
        const modifier = priceModifier === 0 ? '' : ` (${cToUsdStrPref(priceModifier)})`;
        return { name: `${name}${modifier}`, id: `optionValue${value}` };
      })}
      onChange={(idxs) => {
        setSelectedOptions(idxs.map((id) => selectValues[id].value));
      }}
      textOnly
    />
  );
};
