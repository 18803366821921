import React, { FC } from 'react';
import { SettingTermKeys } from '../../shared/settings';
import { LoadingWrapper } from '../utils/LoadingWrapper';
import { LazyMarkdown } from '.';

export const TermsLoader: FC<{ readonly name: SettingTermKeys }> = ({ name }) => (
  <LoadingWrapper dataFetcher={async () => await api.retrieveTermsOfAttendance(name)}>
    {(terms) => <LazyMarkdown source={terms.text} />}
  </LoadingWrapper>
);
