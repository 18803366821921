import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { noImage, ProductAddonModel } from '../../shared/orders';
import { displayName, truncateText } from '../utils';
import { cToUsdStrPref } from '../utils/cToUsdStr';
import { ActionButton, LazyMarkdown } from '.';

interface RelatedProductProps {
  readonly addon: ProductAddonModel;
}

export const RelatedProduct: FC<RelatedProductProps> = ({ addon }) => {
  const image = addon.imageId ? `/api/products/${addon.id}/images/${addon.imageId}` : noImage;

  return (
    <Col className="text-center" lg={3} xs={12}>
      <Link to={`/store/product/${addon.id}`}>
        <img
          id={`addon${addon.id}`}
          src={image}
          style={{ maxWidth: '75%', maxHeight: 'auto', borderRadius: '10px', margin: '0 auto' }}
        />
        <div>{addon.name}</div>
      </Link>
      <UncontrolledPopover placement="right" target={`addon${addon.id}`} trigger="hover">
        <PopoverHeader>
          <Link to={`/store/product/${addon.id}`}>{displayName(addon)}</Link>
        </PopoverHeader>
        <PopoverBody>
          <h5>{cToUsdStrPref(addon.price)}</h5>
          {addon.description && <LazyMarkdown source={truncateText(addon.description)} />}
        </PopoverBody>
        <PopoverHeader>
          <ActionButton
            action={`/api/products/${addon.id}/add`}
            block
            color="success"
            method="post"
            onSuccess={() => {
              /* NO-OP */
            }}
            payload={{ quantity: 1 }}
          >
            Add to Cart
          </ActionButton>
        </PopoverHeader>
      </UncontrolledPopover>
    </Col>
  );
};
