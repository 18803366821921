import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { useConvention } from '../utils';
import { DateTime } from './Date';
import { InlineHelp } from '.';

interface Props {
  readonly minAge?: number;
  readonly maxAge?: number;
  readonly title?: string;
  readonly audience?: string;
  readonly idPrefix: string;
}

export const AgeRestrictedProductBadge: FC<Props> = ({
  audience = 'You',
  title = 'Age Restricted Product',
  minAge,
  maxAge,
  idPrefix,
}) => {
  const { startAt, timeZone } = useConvention();
  return (
    <Badge className="with-inline-icon" color="danger" id={`${idPrefix}AgeRestricted`} pill>
      {title}&nbsp;
      <InlineHelp>
        {minAge && `${audience} must be at least ${minAge} `}
        {maxAge && `${minAge ? 'and' : `${audience} must be`} no older than ${maxAge} `}
        on <DateTime timeZone={timeZone} value={startAt} /> to purchase this product.
      </InlineHelp>
    </Badge>
  );
};
