/* eslint-disable import/no-unused-modules */
import React, { FC } from 'react';
import { Input, Label } from 'reactstrap';
import { OrderItem } from '../../../../shared/orders';
import { displayName, toggleElementInArray } from '../../../utils';

interface FulfillmentListProps {
  readonly items: OrderItem[];
  readonly selected: number[];
  setSelected(items: number[]): void;
}

export const FulfillmentList: FC<FulfillmentListProps> = ({ items, selected, setSelected }) => {
  return (
    <div>
      {items.map((item) => {
        const name = `fulfillItem-${item.id}`;
        return (
          <div className="custom-control custom-checkbox margin-top-10" key={item.id}>
            <Input
              checked={selected.includes(item.id)}
              className="custom-control-input"
              disabled={!!item.dispatchedOn}
              id={name}
              name={name}
              onChange={() => setSelected(toggleElementInArray(selected, item.id))}
              type="checkbox"
            />
            <Label className="custom-control-label" for={name}>
              {`x${item.quantity} - `}
              {displayName(item.product)}
              {item.dispatchedOn && <span>(Delivered)</span>}
            </Label>
          </div>
        );
      })}
    </div>
  );
};
