import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { SocialLink, SocialLinkEndpoint } from '../../../models';
import { classNames, useFetcher, useUser } from '../../../utils';
import { LoadingState } from '../../../utils/LoadingState';
import { captureError } from '../../../utils/errorHandling';

async function initOAuthLogin(endpoint: SocialLinkEndpoint): Promise<{ id: string; name: string }> {
  const popup = window.open(
    `${endpoint.endpoints.auth}?client_id=${endpoint.client_id}&redirect_url=${location.origin}/neosvrauth&response_type=code&scope=email profile`,
    '_blank',
    'scrollbars=yes,resize=no,width=500,height=700',
  );

  setTimeout(() => {
    popup?.focus();
  }, 100);

  let closedCheck: number;
  return await new Promise<{ id: string; name: string }>((resolve, reject) => {
    if (!popup) {
      const error = 'Failed to open authentication dialog, check popup and adblocker';
      reject(new Error(error));
      return;
    }

    const listener = async (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      try {
        const { code } = event.data as { code: string };

        if (!code) {
          return;
        }

        window.removeEventListener('message', listener, false);
        clearInterval(closedCheck);
        setTimeout(() => {
          popup.close();
        }, 100);

        const result = await api.loginSocialLink(code);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    };

    window.addEventListener('message', listener, false);
    closedCheck = setInterval(() => {
      if (popup.closed) {
        window.removeEventListener('message', listener, false);
        clearInterval(closedCheck);
        toast.error('Authentication cancelled!');
        reject(new Error('Authentication cancelled!'));
      }
    }, 250);
  });
}

export const NeosVRLoginButton: FC<{ readonly target: string; readonly fullText?: boolean }> = ({
  target,
  fullText = true,
}) => {
  const user = useUser();
  const [refreshToken, setRefreshToken] = useState(0);
  const [saving, setSaving] = useState(false);
  const [oauthToken, setOauthToken] = useState<{ id: string; name: string } | undefined>(undefined);
  const socialLink = useFetcher(async () => {
    return await api.getSocialLink(target);
  }, [target]);

  const oauthInfo = useFetcher<SocialLink | null>(async () => {
    if (!user) {
      return null;
    }

    return await api.getSocialLinkUser(user.id, target);
  }, [target, refreshToken]);

  async function initLogin(): Promise<void> {
    setSaving(true);
    try {
      const resultId = await initOAuthLogin(socialLink.data!);
      setOauthToken(resultId);
    } catch (error) {
      if (error) {
        captureError(error as Error);
      }
    }

    setRefreshToken(refreshToken + 1);
    setSaving(false);
  }

  async function logout(): Promise<void> {
    setSaving(true);

    try {
      if (oauthToken) {
        await api.deleteSocialLink(oauthToken.id);
      } else if (oauthInfo.data) {
        await api.deleteSocialLink(oauthInfo.data.id);
      }
    } catch {
      toast.error('Social unlink failed');
    }

    setRefreshToken(refreshToken + 1);
    setOauthToken(undefined);
    setSaving(false);
  }

  if (
    socialLink.state === LoadingState.Loading ||
    oauthInfo.state === LoadingState.Loading ||
    saving
  ) {
    return (
      <Button block color="secondary" disabled onClick={initLogin}>
        Loading...
      </Button>
    );
  }

  const socialLinkNameIcon = fullText ? (
    <span className={classNames({}, 'social-link-name', `social-link-${target}`)}>NeosVR</span>
  ) : (
    ''
  );

  // If the user already has a related account
  if (oauthInfo.state === LoadingState.Done && oauthInfo.data) {
    return (
      <div className="social-link-logged-in">
        <p>
          {socialLinkNameIcon}Logged in as <strong>{oauthInfo.data.externalName}</strong>
        </p>
        <Button block color="secondary" onClick={logout}>
          Disconnect
        </Button>
      </div>
    );
  }

  // For scenarios in which the user is creating a new acount and a user does not yet exist
  if (oauthToken) {
    return (
      <div className="social-link-logged-in">
        <input name={`externalOauth[${target}]`} type="hidden" value={oauthToken.id} />
        <p>
          {socialLinkNameIcon}Logged in as <strong>{oauthToken.name}</strong>
        </p>
        <Button block color="secondary" onClick={logout}>
          Disconnect
        </Button>
      </div>
    );
  }

  return (
    <Button block color="primary" onClick={initLogin}>
      {fullText ? (
        <>
          Login with{' '}
          <span className={classNames({}, 'social-link-name', `social-link-${target}`)}>Neos</span>
        </>
      ) : (
        'Connect'
      )}
    </Button>
  );
};
