export enum LogicError {
  Test = -1,
  None = 0,
  RecordIsReferenced = 1,
  CannotChangeDealerStatus,
  UserWasAlreadyAdded,
  CannotDeleteApplication,
  CardDeclined,
  EmailAlreadyInUse,
  CannotAddCompensationToOwn,
  FailedToPerformCashierPayment,
  AttendeeHasErrors,
  UserAlreadyHasRole,
  UserAlreadyAssignedToDepartment,
  AssistanceRequestAlreadySubmitted,
  AssistanceRequestNotFound,
  UserAlreadyCompensated,
  UserHasNotRegistered,
  CannotTargetSelf,
  IncorrectPassword,
  PaymentFailed,
  ChildLimitExceeded,
  InvalidAttendanceType,
  RegistrationIsFull,
  ProductNotAvailable,
  BadgeArtLimitReached,
  InvalidBadgeArt,
  InvalidConvention,
  EmergencyContactRequired,
  RegistrationClosed,
  ChildrenNotAllowed,
  TooManyChildren,
  ParentCannotVolunteer,
  OAuthMissingToken,
  OAuthInvalidScope,
  OAuthInvalidRedirectURI,
  DealerAlreadyAdded,
  InvalidPaymentProcessor,
  CannotUpdateDateOfBirth,
  NotDepartmentLead,
  DuplicateOrderNumber,
  GatewayOperationNotSupported,
  CannotRedeemVoucherNegative,
  InvalidVoucher,
  GrantWithRefIdAlreadyExists,
  RegistrationCategoryAlreadyExists,
  RefundQuantityExceedsRemaining,
  InvalidRefundRequestStatus,
  DataOutOfSync,
  RefundOperationFailed,
  InvalidRefundAmount,
  VoucherAlreadyRedeemed,
  OrderNotPaid,
  VoucherCodeExists,
  CannotDeletePaidFee,
  NoRefundItemsProvided,
  EventStoreCannotUseCategory,
  OrderCannotBeCancelled,
  InsufficientStock,
  SecondFactorPhoneInUse,
  InvalidFactorIdOrVerification,
  MissingRequiredProductOption,
  UnknownProductOption,
  MustProvideSelectOption,
  InternalOption,
  AgeRestrictedProduct,
  RoleRestrictedProduct,
  ProductNoMultipleTax,
  WrongPlatformFeeAssignment,
  RaffleAlreadyClosed,
  ProductInAnotherRaffle,
  RaffleTicketRescinded,
  AlreadyReedmedError,
  RegistrationNotDeletable,
  GeoCodingError,
  TooFrequentEmailChange,
  DealerAreaClosed,
  VolunteerOptionLocked,
  NoRegistraionGrantTransfer,
  TargetEmailNotFound,
  TargetRegistrationHasGrant,
  VolunteerAlreadyAssigned,
  DigitalGrantNotOwnedError,
  OrderIsPaid,
  NotAVendor,
  VendorIsNotApproved,
  SessionAlreadyVerified,
  DateOfBirthMissing,
  IdentityInputMissing,
  RequiredIdentityMissing,
  AgeVerification,
  CanNotUpdateVerifiedInformation,
  IdentitySessionAlreadyClaimed,
  IdentitySessionNotVerified,
  CannotOversellProduct,
  OrderAlreadyPaid,
  TicketAlreadyBought,
  NoTicketGroupsAvailable,
  ProductStockTooSmall,
  TooManyTickets,
  ProductRequiresGrant,
  VolunteerClosed,
  ProductVolumeError,
  AddonsCanNotHaveAddonsError,
  AddonItselfError,
  AddonRegistrationError,
  UserDoesNotHaveRole,
  ExportRequestAlreadyCreated,
  ExportIsExpired,
  ExportRequestAlreadyApproved,
  ExportRequestIsRejected,
  ExportRequestNotApproved,
  DirectApprovalNotAllowed,
  ProductAlreadyInOrder,
  BadPaginationToken,
  AddonAlreadyExistsError,
  CannotRemoveAddonFromCart,
  ExportUserApprovedSelf,
  ApplicationNotGranted,
  InvalidOptionValue,
  InternalRegistrationFlag,
  VoucherProductRestricted,
  EmptyCart,
  OutstandingBalance,
  CategoryAssignedToDealerArea,
  DiscourseSignatureError,
  DiscourseMissingNonce,
  OptionIncorrectSource,
  RequestEntityTooLarge,
  CantTransferBadgePrinted,
  ProductNotFound,
  InvalidImage,
  ImageFormatNotSupported,
  ProductGrantQuantityLimit,
  RefundNoAction,
  NoDeleteRegistrationCategory,
  TransferDisabled,
  VolunteerFelonyRestriction,
  VolunteerFelonyRestrictionBypass,
  VolunteerRequiresFelonyBypass,
  VolunteerFelonyAlreadyBypassed,
  VoucherCannotBeUsedWithOtherVouchers,
  InvalidEmailDomain,
  InvalidEmailSender,
  OrderGrantError,
  InternalRole,
  UserMissingPermissions,
  RefundsDiabled,
  VoucherExpired,
}
