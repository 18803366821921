import { classNames } from '@conventioncatcorp/common-fe';
import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children?: ReactNode;
  readonly className?: string[];
}

const emptyArray: string[] = [];

export const PageHeader: FC<Props> = ({ children, className = emptyArray }: Props) => {
  return (
    <div className={classNames({}, 'page-header', ...className)}>
      <h1>{children}</h1>
    </div>
  );
};
