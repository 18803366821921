import React, { FC, useCallback } from 'react';
import { Pagination, PaginationItem, PaginationLink, PaginationLinkProps } from 'reactstrap';

interface PaginatorProps {
  readonly page: number;
  readonly totalPages: number;
  readonly range?: number;
  onChange?(page: number): void;
  getHref?(page: number): string;
}

export interface PaginationItemLinkProps extends PaginationLinkProps {
  readonly active?: boolean;
  readonly disabled?: boolean;
  readonly idx: number;
  getHref?(page: number): string;
}

const PaginationItemLink: FC<PaginationItemLinkProps> = ({
  disabled,
  active,
  getHref,
  idx,
  ...props
}) => (
  <PaginationItem active={active} disabled={disabled}>
    <PaginationLink
      {...props}
      data-idx={idx}
      href={getHref ? getHref(idx) : undefined}
      type="button"
    />
  </PaginationItem>
);

export const PaginationBar: FC<PaginatorProps> = ({ page, totalPages, onChange, getHref }) => {
  const maxPageIdx = totalPages - 1;
  const clickFn = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      if (onChange) {
        onChange(Number.parseInt(ev.currentTarget.dataset.idx!, 10));
      }
    },
    [onChange],
  );

  const itemLinkProps = {
    getHref,
    onClick: clickFn,
  };

  const out: JSX.Element[] = [
    <PaginationItemLink first idx={0} key="--" {...itemLinkProps} disabled={page === 0} />,
    <PaginationItemLink
      idx={Math.max(0, page - 1)}
      key="-"
      previous
      {...itemLinkProps}
      disabled={page === 0}
    />,
  ];

  const pageStart = Math.max(page - 10, 0);
  const pageEnd = Math.min(page + 10, totalPages);

  if (pageStart !== 0) {
    out.push(
      <PaginationLink disabled key="-...">
        &#8943;
      </PaginationLink>,
    );
  }

  for (let i = pageStart; i < pageEnd; i++) {
    out.push(
      <PaginationItemLink active={i === page} idx={i} key={i} {...itemLinkProps}>
        {i + 1}
      </PaginationItemLink>,
    );
  }

  if (pageEnd !== totalPages) {
    out.push(
      <PaginationItemLink disabled idx={-1} key="+...">
        &#8943;
      </PaginationItemLink>,
    );
  }

  out.push(
    <PaginationItemLink
      disabled={page === maxPageIdx}
      idx={Math.min(page + 1, totalPages)}
      key="+"
      next
      {...itemLinkProps}
    />,
    <PaginationItemLink
      disabled={page === maxPageIdx}
      idx={maxPageIdx}
      key="++"
      last
      {...itemLinkProps}
    />,
  );

  return <Pagination>{out}</Pagination>;
};
