import React, { FC } from 'react';
import { PageHeader, TermsLoader, UserStateComponent } from '../../components';

export const VendorPolicies: FC = () => (
  <UserStateComponent>
    <div id="vendorTerms">
      <PageHeader>Vendor Policy</PageHeader>
      <TermsLoader name="vendor" />
    </div>
  </UserStateComponent>
);
