import { OptionDataPayload } from '../options';

export type DesignerNodeTypes = 'image' | 'node' | 'root' | 'text';

interface DesignerNodeBase {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: DesignerNodeTypes;
}

export interface DesignerNodeImage extends DesignerNodeBase {
  type: 'image';
  url?: string;
  source: 'badgeDesign' | 'product' | 'profilePic' | 'url';
  size: 'auto' | 'contain' | 'cover';
  repeat: 'no-repeat' | 'repeat-x' | 'repeat-y' | 'repeat';
  circle?: boolean;
  ignoreEmpty?: boolean;
  position:
    | 'center bottom'
    | 'center center'
    | 'center top'
    | 'left bottom'
    | 'left center'
    | 'left top'
    | 'right bottom'
    | 'right center'
    | 'right top';
}

export interface DesignerNodeText extends DesignerNodeBase {
  type: 'text';
  fontSize: number;
  placeholderText?: string;
  horizontalBinding: 'left' | 'right';
  verticalBinding: 'bottom' | 'top';
  textAlign: 'center' | 'left' | 'right';
  textTransform: 'capitalize' | 'lowercase' | 'none' | 'uppercase';
  fontFamily: string;
  customFontFamily?: string;
  autoResize: boolean;
  growText: boolean;
  shrinkText: boolean;
  growTextMin: number;
  growTextMax: number;
  variableType: string;
  variableValue: string;
  specialDisplayValues?: string[];
  fontWeight: 'bold' | 'bolder' | 'lighter' | 'normal';
  color: string;
  prefixPadding?: NodePrefixPadding;
}

export interface NodePrefixPadding {
  length: number;
  character: string;
}

export interface DesignerNodeNode extends DesignerNodeBase {
  type: 'node';
  children: string[];
  rotate?: boolean;
  conditional?: NodeConditional;
}

export interface NodeConditional {
  debug: boolean;
  invert: boolean;
  minor: boolean;
  underage: boolean;
  dealer: boolean;
  volunteer: boolean;
  vendorAssistant: boolean;
  productId?: number;
  roleId?: number;
}

export interface DesignerNodeRoot extends DesignerNodeNode {
  xstart: number;
  ystart: number;
  displayName: string;
  xspacing: number;
  yspacing: number;
  preprintedCanvas?: PreprintedCanvasData;
}

export interface PreprintedCanvasData {
  preview: boolean;
  sampleBadgeArtId: number;
  width: number;
  height: number;
  fromBottom: number;
}

export type DesignerNode =
  | DesignerNodeImage
  | DesignerNodeNode
  | DesignerNodeRoot
  | DesignerNodeText;

export interface BadgeDesign {
  createdAt: Date;
  updatedAt: Date;
  id: number;
  content: DesignerNode[];
  name: string;
}

export interface BadgeData {
  registrationId: number;
  badgeArt?: string;
  profilePictureUrl?: string;
  productArt?: string;
  isVounteer: boolean;
  isVendor: boolean;
  isMinor: boolean;
  isVendorAssistant: boolean;
  isUnderage: boolean;
  isGuardian: boolean;
  isStaff: boolean;
  options?: OptionDataPayload;
  data: Record<string, Record<string, Date | string[] | number | string | null>>;
  roleIds: number[];
}

export interface BadgeDataRequest {
  designId?: number;
  userIds: number[];
}

export const badgeVariables: Record<string, string[]> = {
  registration: ['attendanceType', 'badgeArt', 'badgeId', 'badgeName', 'species'],
  special: [],
  user: [
    'id',
    'username',
    'email',
    'firstName',
    'lastName',
    'preferredName',
    'bornAt',
    'addressCity',
    'addressCountry',
    'addressState',
    'addressCityState',
  ],
  option: [],
};

export const badgeDefaultSpecial = [
  'registration',
  'dealer',
  'staff',
  'volunteer',
  'minor',
  'guardian',
];
