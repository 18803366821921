import { Payment } from '../../shared/orders/model';
import { ProductDiscount, ProductModel } from '../../shared/orders/product';
import { Surcharge } from './product';
import { Ticket } from './ticket';
import { User } from './user';
import { OptionOrderItem } from '.';

export type OrderStatus = 'paid' | 'unpaid';

export interface Order {
  id: number;
  status: OrderStatus;
  userId: number;
  fees?: Fee[];
  orderItems?: OrderItem[];
  vouchers?: OrderVoucher[];
  payments?: Payment[];
  refunds?: OrderRefund[];
  tickets?: Ticket[];
  paidAt?: Date;
  user?: User;
  deletedAt: Date | null;
}

export interface Fee {
  amount: number;
  createdAt: Date;
  deletedAt?: Date;
  id: number;
  order?: Order;
  orderId: number;
  issuerId?: number;
  issuer?: User;
  reason?: string;
  user?: User;
}

export interface OrderItem {
  id: number;
  orderId: number;
  productId: number;
  quantity: number;
  referenceId?: number;
  cancelDate?: string;
  dispatchDate?: string;
  product?: Pick<ProductModel, 'displayName' | 'name'>;
  options?: OptionOrderItem[];
  paidPrice?: number;
  price?: number;
  surcharges?: Surcharge[];
  discounts?: ProductDiscount[];
  unitPrice: number;
  order?: Order;
}

export type RefundStatus = 'approved' | 'denied' | 'pending';
export type RefundRequestReason = 'accident' | 'changedMind' | 'damaged' | 'other' | 'unableToUse';

export interface RequestReason {
  description: string;
  key: string;
}

export const refundRequestReasons: RequestReason[] = [
  {
    description: 'I purchased this by accident.',
    key: 'accident',
  },
  {
    description: "I can no longer / don't want to attend.",
    key: 'unableToUse',
  },
  {
    description: 'I have changed my mind',
    key: 'changedMind',
  },
  {
    description: 'Item is damaged or not as described.',
    key: 'damaged',
  },
  {
    description: 'Other',
    key: 'other',
  },
];

export function getRefundRequestReason(reasonKey: string): string {
  const reason = refundRequestReasons.find(({ key }) => key === reasonKey);
  return reason?.description ?? 'Unknown';
}

export interface OrderRefund {
  amountRefunded?: number;
  id: number;
  orderId: number;
  status: RefundStatus;
  refundItems: OrderRefundItem[];
  requestReason: RefundRequestReason;
  requestAddInfo?: string;
  order?: Order;
  createdAt: Date;
}

export interface OrderRefundItem {
  orderRefundId: number;
  orderItemId: number;
  quantity: number;
  orderItem?: OrderItem;
}

export interface OrderVoucher {
  id: number;
  amount: number;
  code: string;
  displayName?: string;
}
