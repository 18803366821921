import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useInputState } from '../utils';
import { NewPasswordInput } from './NewPasswordInput';

interface PasswordChangeInputProps {
  readonly passwordScore: number;
  readonly isNew?: boolean;
  readonly sm?: number;
  readonly md?: number;
  onChange?(value: string): void;
}

const errMsg = 'Passwords do not match.';

export const PasswordChangeInput: FC<PasswordChangeInputProps> = ({
  passwordScore,
  isNew,
  sm,
  md,
  onChange,
}) => {
  // TODO: This is annoying..
  const ref = useRef<HTMLInputElement>(null);
  const [pw, setPW] = useState('' as string);
  const [pwconf, setPWConf] = useInputState('' as string);
  const smHalf = sm ? sm / 2 : sm;
  const mdHalf = md ? md / 2 : md;
  const matchError = pw.length > 0 && pw !== pwconf;
  useEffect(() => {
    if (!matchError && onChange) {
      onChange(pw);
    }

    if (matchError) {
      ref.current!.setCustomValidity(errMsg);
    } else if (pw.length > 0 && pwconf === '') {
      ref.current!.setCustomValidity('Confirmation is required');
    } else {
      ref.current!.setCustomValidity('');
    }
  }, [pw, pwconf]);

  return (
    <>
      <FormGroup row>
        <Label for="password" md={mdHalf} sm={smHalf}>
          {isNew ? 'New' : ''} Password
        </Label>
        <Col md={md} sm={sm}>
          <NewPasswordInput minLength={6} minScore={passwordScore} onChange={setPW} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="confirmPassword" md={mdHalf} sm={smHalf}>
          Confirm Password
        </Label>
        <Col md={md} sm={sm}>
          <Input
            autoComplete="new-password"
            id="confirmPassword"
            innerRef={ref}
            invalid={matchError}
            onChange={setPWConf}
            type="password"
          />
          {matchError ? (
            <FormFeedback id="passwordMismatch" invalid="true">
              Passwords do not match.
            </FormFeedback>
          ) : null}
        </Col>
      </FormGroup>
    </>
  );
};
