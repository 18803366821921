export const enum ActionType {
  LoginSuccess,
  LoginReturning,
  Logout,
  ProfileUpdate,
  SideNavToggle,
  SideNavLinkClicked,
  LoadingComplete,
  ConfigReceived,
  PreferenceUpdate,
  OnlyShowBody,
}
