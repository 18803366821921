import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface ScannerResult {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  DoB?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  country?: string;
  expireDate?: string;
  zip?: string;
}

export interface Scanner {
  info: SerialPortInfo;
  port?: SerialPort;
}

function portInfoEquals(a: SerialPortInfo, b: SerialPortInfo) {
  return a.usbVendorId === b.usbVendorId && a.usbProductId === b.usbProductId;
}

type CallbackScan = (data: ScannerResult) => void;

interface ScannerContextProps {
  scanners: Scanner[];
  scanListeners: CallbackScan[];
  setListeners: React.Dispatch<React.SetStateAction<CallbackScan[]>>;
  addScanner(port: SerialPort): void;
  removeScanner(port: SerialPortInfo): void;
}

/*
 * Sometimes the date is in MMDDYYYY format, but we want YYYYMMDD format.
 * We check if the 5 and 6th characters are greater than 12, and if so, we assume it's in MMDDYYYY format.
 */
function normalizeDate(date: string): string {
  if (!date) {
    return date;
  }

  const yearSlice = date.slice(4, 6);

  if (yearSlice > '12') {
    return date.slice(4, 8) + date.slice(0, 4);
  }

  return date;
}

function parseScannerData(data: string): ScannerResult {
  const kv: Record<string, string> = {};

  for (const entry of data.split('\n')) {
    if (entry.length < 3) {
      continue;
    }

    kv[entry.slice(0, 3)] = entry.slice(3);
  }

  return {
    firstName: kv.DAC?.trim() ?? kv.DCT?.trim(),
    middleName: kv.DAD?.trim(),
    DoB: normalizeDate(kv.DBB),
    lastName: kv.DCS?.trim() ?? kv.DAB?.trim(),
    addressLine1: kv.DAG?.trim(),
    city: kv.DAI?.trim(),
    state: kv.DAJ,
    country: kv.DCG,
    expireDate: normalizeDate(kv.DBA),
    zip: kv.DAK?.trim(),
  };
}

async function runScanner(device: Scanner, onScan: (data: ScannerResult) => void): Promise<void> {
  let data = '';
  const { port } = device;

  if (!port) {
    return;
  }

  // From the example: https://wicg.github.io/serial/#readable-attribute
  while (port.readable) {
    const reader = port.readable.getReader();

    try {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // |reader| has been canceled.
          break;
        }

        data += new TextDecoder().decode(value);

        if (value.at(-1) === 13) {
          onScan(parseScannerData(data));
          data = '';
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      reader.releaseLock();
    }
  }
}

export function useScannerResult(onScan: CallbackScan, deps: readonly unknown[]): void {
  const context = useContext(ScannerContext);

  useEffect(() => {
    if (!context) {
      return;
    }

    const { setListeners } = context;
    setListeners((listeners) => [...listeners, onScan]);

    return () => {
      setListeners((listeners) => listeners.filter((listener) => listener !== onScan));
    };
  }, deps);
}

export const ScannerContext = React.createContext<ScannerContextProps | undefined>(undefined);

export const useCreateScannerContext = (): ScannerContextProps => {
  const [scanListeners, setScanListeners] = useState<CallbackScan[]>([]);
  // Load scanners from local storage - so page can be refreshed without losing scanner state
  const [devices, setDevices] = useState<Scanner[]>(() => {
    const stored = window.localStorage.getItem('scanner');
    if (stored) {
      try {
        const parse = JSON.parse(stored) as SerialPortInfo[];
        if (Array.isArray(parse)) {
          return parse.map((info) => ({ info }));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    return [];
  });

  // Load scanner
  const addScanner = useCallback(
    async (d: SerialPort) => {
      await d.open({ baudRate: 9600 });
      const info = d.getInfo();
      const newDevice: Scanner = { port: d, info };

      // Scanner might be already present on the list - remvoe them first, then re-add them
      setDevices((scanners) => {
        const clone = scanners.filter((t) => !portInfoEquals(t.info, info));
        return [...clone, newDevice];
      });

      await runScanner(newDevice, (result) => {
        // using setScanListeners as a work-around for getting the latest listeners value
        setScanListeners((listeners) => {
          for (const event of listeners) {
            event(result);
          }

          return listeners;
        });
      });
    },
    [setDevices],
  );

  // Save device state into local storage, to be re-used after page refresh
  useEffect(() => {
    window.localStorage.setItem('scanner', JSON.stringify(devices.map((t) => t.info)));
  }, [devices]);

  // Called once during load time to connect all ports
  useEffect(() => {
    let loading = true;
    async function loadPorts() {
      const newPorts = await navigator.serial?.getPorts();
      if (loading && newPorts) {
        for (const port of newPorts) {
          try {
            await addScanner(port);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      }
    }

    void loadPorts();
    return () => {
      loading = false;
    };
  }, []);

  // Called when devices change - for connect and disconnect events after page load
  useEffect(() => {
    function deviceConnected(e: Event) {
      // Add |e.target| to the UI or automatically connect.
      const target = e.target as SerialPort;
      const info = target.getInfo();
      if (devices.some((t) => portInfoEquals(t.info, info))) {
        void addScanner(target);
      }
    }

    function deviceDisconnect(e: Event) {
      // Remove |e.target| from the UI. If the device was open the disconnection can also be observed as a stream error.
      const target = e.target as SerialPort;
      const info = target.getInfo();
      setDevices((old) =>
        old.map((t) => {
          if (portInfoEquals(t.info, info)) {
            return { ...t, port: undefined };
          }

          return t;
        }),
      );
    }

    navigator.serial?.addEventListener('connect', deviceConnected);
    navigator.serial?.addEventListener('disconnect', deviceDisconnect);

    return () => {
      navigator.serial?.removeEventListener('connect', deviceConnected);
      navigator.serial?.removeEventListener('disconnect', deviceDisconnect);
    };
  }, [devices]);

  const removeScanner = useCallback(
    (info: SerialPortInfo) => {
      try {
        for (const device of devices) {
          if (portInfoEquals(device.info, info)) {
            void device.port?.forget();
            void device.port?.close();
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      setDevices((old) => old.filter((t) => !portInfoEquals(t.info, info)));
    },
    [devices],
  );

  const scannerContext = useMemo<ScannerContextProps>(
    () => ({
      scanListeners,
      scanners: devices,
      addScanner,
      setListeners: setScanListeners,
      removeScanner,
    }),
    [devices, scanListeners, setDevices, removeScanner, addScanner],
  );

  return scannerContext;
};

export const useScannerContext = (): ScannerContextProps => {
  return useContext(ScannerContext)!;
};
