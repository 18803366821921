import React, { FC, ReactNode } from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { MaterialIcon } from './MaterialIcon';

export const MessageCard: FC<{
  readonly id?: string;
  readonly icon: string;
  readonly level: 'danger' | 'success' | 'warning';
  readonly title?: string;
  readonly children?: ReactNode;
}> = ({ id, level, icon, title, children }) => (
  <Row className="justify-content-center" id={id}>
    <Col lg={6} xs={12}>
      <Card className={level}>
        <CardBody className="text-center">
          <MaterialIcon large name={icon} type={level} />
          <CardTitle>{title}</CardTitle>
          <CardText>{children}</CardText>
        </CardBody>
      </Card>
    </Col>
  </Row>
);
