import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import {
  DealerFullModel,
  SeatingPreference,
  VendorPreferenceState,
} from '../../../../shared/dealer';
import { AccessDenied, ActionButton, MaterialIcon, UserStateComponent } from '../../../components';
import { useFetcher } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';

import './VendorSeating.scss';

interface StateStyle {
  icon: string;
  type: string;
  message: string;
  color: string;
}

interface VendorSeatingComponentProps {
  readonly dealer?: DealerFullModel;
}

export const VendorSeatingComponent: FC<VendorSeatingComponentProps> = ({ dealer }) => {
  return (
    <UserStateComponent>
      {dealer ? <VendorSeatingComponentInner dealer={dealer} /> : <AccessDenied />}
    </UserStateComponent>
  );
};

interface VendorSeatingComponentInnerProps {
  readonly dealer: DealerFullModel;
}

const VendorSeatingComponentInner: FC<VendorSeatingComponentInnerProps> = ({ dealer }) => {
  const request = useFetcher(async () => {
    return await api.getDealerSeatingPreferences(dealer.id);
  }, [dealer.id]);

  return (
    <Row className="justify-content-center" id="dealerSeatingForm">
      <Col className="margin-bottom-10 justify-content-left" lg={3} xs={12}>
        <Link className="btn btn-primary btn-block" to="/vendor">
          Back to Application
        </Link>
      </Col>
      <Col lg={9} xs={12} />
      <Col lg={4} xs={12}>
        <Card className="margin-bottom-10">
          <CardBody>
            <CardTitle>How it Works</CardTitle>
            <p>
              Your unique vendor ID can be given to other vendors that you would like to sit with.
            </p>
            <p>
              When another vendor enters your ID, they will appear below as a seating request. You
              can then approve or decline the request.
            </p>
            <p>
              While every effort will be made to honor to vendor preferences, it may not be possible
              to honor all submitted requests.
            </p>
            <hr />
            <FormGroup>
              <Label for="inviteCode">Your Vendor ID</Label>
              <Input id="inviteCode" readOnly value={dealer.publicID} />
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
      <Col lg={8} xs={12}>
        {request.complete && <AddVendor dealer={dealer} reload={request.refresh} />}
        {request.complete && (
          <VendorSeatingRequests
            dealer={dealer}
            reload={request.refresh}
            seatingPreferences={request.data!}
          />
        )}
      </Col>
    </Row>
  );
};

interface AddVendorProps {
  readonly dealer: DealerFullModel;
  reload(): void;
}

const AddVendor: FC<AddVendorProps> = ({ dealer, reload }) => {
  const onSuccess = useCallback(async () => {
    reload();
    toast.success('We have added your seating preference and it is now pending approval.');
  }, [reload]);

  return (
    <Card className="margin-bottom-10">
      <CardBody>
        <CardTitle>Add a Vendor</CardTitle>
        <p>
          If another vendor has provided you with their Vendor ID, you can enter it here to submit
          your preference to sit with them for review with your application.
        </p>
        <hr />
        <JSONForm
          method="post"
          onSuccess={onSuccess}
          path={`/api/dealers/${dealer.id}/seatingPreferences`}
        >
          <FormGroup row>
            <Label for="token" lg={2} md={4} sm={12}>
              Vendor ID
            </Label>
            <Col className="margin-bottom-10" md={8} sm={12}>
              <Input id="token" name="token" />
            </Col>
            <Col lg={2} sm={12}>
              <Button block color="primary" id="addSeatingPreference" type="submit">
                Add
              </Button>
            </Col>
          </FormGroup>
        </JSONForm>
      </CardBody>
    </Card>
  );
};

interface ExistingRequestsProps {
  readonly dealer: DealerFullModel;
  readonly seatingPreferences: SeatingPreference[];
  reload(): void;
}

export const VendorSeatingRequests: FC<ExistingRequestsProps> = ({
  dealer,
  seatingPreferences,
  reload,
}) => {
  // Sort by own requests first, then by approved, then by requested
  const sortedSeatingPreferences = seatingPreferences.sort((a, b) => {
    if (a.requester.id === dealer.id && b.requester.id !== dealer.id) {
      return -1;
    }

    if (a.requester.id !== dealer.id && b.requester.id === dealer.id) {
      return 1;
    }

    if (a.status === 'approved' && b.status !== 'approved') {
      return -1;
    }

    if (a.status !== 'approved' && b.status === 'approved') {
      return 1;
    }

    if (a.status === 'requested' && b.status !== 'requested') {
      return -1;
    }

    if (a.status !== 'requested' && b.status === 'requested') {
      return 1;
    }

    return 0;
  });

  return (
    <Card>
      <CardBody>
        <CardTitle>Seating Requests</CardTitle>
        {sortedSeatingPreferences.length === 0 && (
          <p className="text-muted">No existing requests.</p>
        )}
        {sortedSeatingPreferences.length > 0 && (
          <Row className="justify-content-center">
            <Table>
              <tr>
                <th>Dealer</th>
                <th>Status</th>
                <th style={{ maxWidth: '250px' }} />
              </tr>
              {sortedSeatingPreferences.map((pref) => {
                return (
                  <DealerPreferenceRow
                    dealer={dealer}
                    key={`${pref.requester.id}-${pref.target.id}`}
                    pref={pref}
                    reload={reload}
                  />
                );
              })}
            </Table>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

interface DealerPreferenceRowProps {
  readonly dealer: DealerFullModel;
  readonly pref: SeatingPreference;
  reload(): void;
}

const DealerPreferenceRow: FC<DealerPreferenceRowProps> = ({ dealer, pref, reload }) => {
  const isOutgoingRequest = pref.requester.id === dealer.id;
  const { name, id } = isOutgoingRequest ? pref.target : pref.requester;
  const { color, icon, message } = getStateStyle(pref.status);

  return (
    <tr className="dealer-preference-row">
      <td>
        <div>
          <MaterialIcon name={isOutgoingRequest ? 'call_made' : 'call_received'} />
          {name}
        </div>
      </td>
      <td className="dealer-preference-status" id={`status-${id}-${pref.status}`}>
        <Badge color={color}>
          <MaterialIcon name={icon} small />
          <span>{message}</span>
        </Badge>
      </td>
      <td className="dealer-preference-actions">
        {pref.status === 'requested' && !isOutgoingRequest && (
          <ActionButton
            action={`/api/dealers/${dealer.id}/seatingPreferences/update`}
            color="primary"
            id={`request-${id}-approve`}
            method="post"
            onFailure={captureError}
            onSuccess={() => {
              toast.success('Seating preference request approved!');
              reload();
            }}
            payload={{ target: id, status: 'approve' }}
          >
            Approve
          </ActionButton>
        )}
        <ActionButton
          action={`/api/dealers/${dealer.id}/seatingPreferences/update`}
          color="danger"
          id={`request-${id}-delete`}
          method="post"
          onFailure={captureError}
          onSuccess={() => {
            toast.success(
              `Seating preference request ${isOutgoingRequest ? 'cancelled' : 'declined'}`,
            );

            reload();
          }}
          payload={{ target: id, status: 'reject' }}
        >
          {isOutgoingRequest ? 'Cancel' : 'Decline'}
        </ActionButton>
      </td>
    </tr>
  );
};

function getStateStyle(state: VendorPreferenceState): StateStyle {
  switch (state) {
    case 'requested': {
      return {
        icon: 'hourglass_empty',
        type: 'warning',
        message: 'Pending approval',
        color: 'warning',
      };
    }

    case 'approved': {
      return {
        icon: 'check',
        type: 'success',
        message: 'Approved',
        color: 'success',
      };
    }

    case 'rejected': {
      return {
        icon: 'close',
        type: 'danger',
        message: 'Rejected',
        color: 'danger',
      };
    }
  }
}
