import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Navbar, Row } from 'reactstrap';
import { ActionType } from '../services';
import { useGlobalState, useUser } from '../utils';
import { MaterialIcon } from '.';

import './Header.scss';

export const Header: FC = () => {
  const user = useUser();
  const bodyOnly = useGlobalState((s) => s.bodyOnly);

  const dispatch = useDispatch();
  const toggleSideNav = useCallback(() => dispatch({ type: ActionType.SideNavToggle }), [dispatch]);

  if (bodyOnly) {
    return null;
  }

  return (
    <>
      <Navbar className="header" color="light" light>
        {user ? (
          <Button className="toggle-btn" color="primary" onClick={toggleSideNav}>
            <MaterialIcon name="menu" />
            Open Menu
          </Button>
        ) : null}
      </Navbar>
      <div className="header-spacer" />
    </>
  );
};

export const SupportAgentBanner: FC = () => {
  const user = useUser();

  if (!user?.supportAgent) {
    return null;
  }

  return (
    <div className="support-glass-break-banner">
      <Row>
        <Col className="banner-message" xs={12}>
          <MaterialIcon name="support_agent" small type="danger" />
          <div>
            <strong>Logged in as ConCat Support</strong>: {user.supportAgent.name} (
            {user.supportAgent.email}) &mdash; Break Glass Reason: {user.supportAgent.reason}
          </div>
        </Col>
      </Row>
    </div>
  );
};
