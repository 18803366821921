import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { ProductModel } from '../../shared/orders';
import { AgeRestrictedProductBadge } from '.';

interface ProductBadgesProps {
  readonly product: ProductModel;
  readonly includeCategory?: boolean;
  readonly float?: boolean;
}

export const ProductBadges: FC<ProductBadgesProps> = ({ float, includeCategory, product }) => {
  const badges: JSX.Element[] = [];
  const idPrefix = `product${product.id}Info`;

  if (includeCategory) {
    badges.push(
      <Badge color="info" id={`${idPrefix}Category`} pill>
        {product.category.name}
      </Badge>,
    );
  }

  if (product.minAge || product.maxAge) {
    badges.push(
      <AgeRestrictedProductBadge
        idPrefix={idPrefix}
        maxAge={product.maxAge}
        minAge={product.minAge}
      />,
    );
  }

  return (
    <div className="badge-list compact" style={{ float: float ? 'right' : 'none' }}>
      {badges}
    </div>
  );
};
