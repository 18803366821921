import React, { FC } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { ProductModel, ProductVisibility } from '../../shared/orders';
import { RaffleWithUserDetails } from '../../shared/orders/raffle';
import { RegTypeSelect } from './RegTypeSelect';

interface ConventionRegTypeFormProps {
  readonly attendanceType?: ProductModel;
  readonly attendanceTypes: ProductModel[];
  readonly paidAttendanceType?: ProductModel;
  readonly raffle?: RaffleWithUserDetails;
  onSelect(productId: number): void;
}

export const RegTypeSelectList: FC<ConventionRegTypeFormProps> = ({
  attendanceType,
  attendanceTypes,
  paidAttendanceType,
  raffle,
  onSelect,
}) => {
  attendanceTypes = attendanceTypes.filter((at) => {
    if (
      attendanceType &&
      attendanceType.visibility !== ProductVisibility.PUBLIC &&
      attendanceType.id !== at.id
    ) {
      return false;
    }

    if (paidAttendanceType?.id === at.id) {
      return true;
    }

    if (at.requiredGrants.length > 0) {
      return at.requiredGrants.some((t) => t.productId === paidAttendanceType?.id);
    }

    const isSelectable = paidAttendanceType?.upgrades.some(({ id }) => id === at.id);
    return !paidAttendanceType || !attendanceType || attendanceType.id === at.id || isSelectable;
  });

  const selectedTypeId = attendanceType ? attendanceType.id : paidAttendanceType?.id ?? 0;

  return (
    <Col xs={12}>
      <Row className="justify-content-center" id="registrationTypeSelect">
        <Col lg={10} xs={12}>
          <Row>
            {attendanceTypes.map((at) => (
              <RegTypeSelect
                isPaid={at.id === paidAttendanceType?.id}
                isSelected={at.id === selectedTypeId}
                key={at.id}
                onSelect={onSelect}
                product={at}
                raffle={raffle}
              />
            ))}
            {attendanceType && attendanceType.visibility !== ProductVisibility.PUBLIC && (
              <Col className="margin-bottom-10" id="attendanceTypeSpecial" lg={6} xs={12}>
                <Alert color="warning">
                  You are currently assigned a special attendance type which cannot be changed.
                  Please contact the registration team for more information.
                </Alert>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
