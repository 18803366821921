import React, { FC, useMemo } from 'react';
import { ElementSelector, SearchProvider } from './ElementSelector';
import { ElementSelectorProps } from '.';

export interface UserEntry {
  id: number;
  firstName: string;
  lastName: string;
  preferredName?: string;
}

type UserSelectorProps = Partial<ElementSelectorProps<UserEntry>>;

const displayFormat = (u: UserEntry) =>
  // Use || for not catching empty strings
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  `#${u.id} - ${u.preferredName || `${u.firstName} ${u.lastName}`}`;

export const UserSelector: FC<UserSelectorProps> = (props) => {
  const searchProvider: SearchProvider<UserEntry> = useMemo(
    () => ({
      ids: api.getUsersByIds.bind(api),
      text: api.searchUsers.bind(api),
    }),
    [],
  );

  return <ElementSelector<UserEntry> {...{ ...props, displayFormat, searchProvider }} />;
};
