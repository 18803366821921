import { ListResponse } from '@conventioncatcorp/common-fe/dist/APIClient';
import React, { MutableRefObject, ReactElement, useState } from 'react';
import { CardBody } from 'reactstrap';
import { LoadingWrapper } from '../utils/LoadingWrapper';
import { PaginationBar } from './PaginationBar';

interface Props<T> {
  readonly refreshRef?: MutableRefObject<(() => void) | null>;
  readonly pageSize: number;
  readonly noResultsText?: string;
  renderer(item: T, refresh: () => void): JSX.Element;
  dataFetcher(page: number): Promise<ListResponse<T>>;
}

export const PaginatedDataView = <T extends object>({
  dataFetcher,
  refreshRef,
  pageSize,
  noResultsText = 'No results found.',
  renderer,
}: Props<T>): ReactElement | null => {
  const [page, setPage] = useState(0);

  return (
    <LoadingWrapper<ListResponse<T>, number>
      dataFetcher={dataFetcher}
      inline
      passback={page}
      refreshRef={refreshRef}
    >
      {({ items, totalCount }, refresh) => {
        return (
          <>
            {items.length === 0 ? (
              <CardBody>{noResultsText}</CardBody>
            ) : (
              <PaginationBar
                onChange={setPage}
                page={page}
                totalPages={Math.ceil(totalCount / pageSize)}
              />
            )}
            {items.map((item) => renderer(item, refresh))}
          </>
        );
      }}
    </LoadingWrapper>
  );
};
