import { classNames } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface LinkButtonProps {
  readonly id?: string;
  readonly className?: string;
  readonly to: string;
  readonly outline?: boolean;
  readonly color?: 'primary' | 'secondary';
  readonly block?: boolean;
}

export const LinkButton: FC<LinkButtonProps> = ({
  id,
  to,
  children,
  block,
  outline,
  color = 'primary',
  className = '',
}) => {
  const btnOutlineColor = `btn${outline ? '-outline' : ''}-${color}`;

  return (
    <Link
      className={`${classNames({ 'btn-block': block }, `btn ${btnOutlineColor}`)} ${className}`}
      id={id}
      to={to}
    >
      {children}
    </Link>
  );
};
