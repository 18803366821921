import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { OAuthApp, OAuthAuthorization } from '../../../APIClient';
import { Loading, MaterialIcon, UserStateComponent } from '../../../components';
import { ActionType } from '../../../services';
import { isLogicError, isResourceError, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError, LogicError } from '../../../utils/errorHandling';
import { Authorized, Denied, OAuthPermission, OAuthState } from './components';

export const OAuthAuthorize: React.FC = () => {
  const location = useLocation();

  const retrieveAppData = async (): Promise<OAuthApp> => {
    const params = new URLSearchParams(location.search);
    const clientId = params.get('client_id');
    const responseType = params.get('response_type');
    const scope = params.get('scope');
    const redirectUri = params.get('redirect_uri');

    const missing_fields = [] as string[];
    if (!clientId) {
      missing_fields.push('client_id');
    }

    if (!responseType) {
      missing_fields.push('response_type');
    }

    if (!scope) {
      missing_fields.push('scope');
    }

    if (!redirectUri) {
      missing_fields.push('redirect_uri');
    }

    if (missing_fields.length > 0) {
      throw new Error(`Missing fields: ${missing_fields.join(', ')}`);
    }

    try {
      const authInfo = await api.getOAuthApplicationAuthorizationInfo(
        clientId!,
        scope!,
        redirectUri!,
      );

      return authInfo;
    } catch (error) {
      if (
        isLogicError(error, LogicError.OAuthInvalidScope) ||
        isLogicError(error, LogicError.UserMissingPermissions)
      ) {
        // Get the logic errors and throw them to display them to the user
        throw new Error(
          error.apiResponse.errors.logic!.reduce(
            (acc, curr) => `${acc}${acc.length > 0 ? ',' : ''} ${curr.message}`,
            '',
          ),
        );
      }

      if (isResourceError(error, 'OAuthApplication')) {
        throw new Error('Invalid or disabled client_id');
      }

      throw error;
    }
  };

  return (
    <UserStateComponent>
      <LoadingWrapper<OAuthApp, void>
        dataFetcher={async () => await retrieveAppData()}
        errorDisplay={(err) => (
          <CardBody className="text-center">
            <MaterialIcon className="override" large name="error" type="danger" />
            <h4>Invalid Request</h4>
            <div>This authorization request is malformed or the application has been disabled.</div>
            <div className="text-muted mt-2">
              <small>{err.message}</small>
            </div>
          </CardBody>
        )}
        inline
      >
        {(app) => <OAuthAppInfo app={app} />}
      </LoadingWrapper>
    </UserStateComponent>
  );
};

interface OAuthAppInfoProps {
  readonly app: OAuthApp;
}

const OAuthAppInfo: React.FC<OAuthAppInfoProps> = ({ app }) => {
  const { name, scopeInfo, userAlreadyAuthorized } = app;
  const [authToken, setAuthToken] = useState<OAuthAuthorization | null>(null);
  const [authStatus, setAuthStatus] = useState<OAuthState>(
    userAlreadyAuthorized ? OAuthState.PriorAuthorization : OAuthState.Pending,
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const user = useUser()!;
  const hasElevatedPermission = scopeInfo?.some((scope) => scope.name.startsWith('admin:'));

  const authorizeApp = useCallback(async () => {
    try {
      const params = new URLSearchParams(location.search);
      const responseType = params.get('response_type');
      const scope = params.get('scope');

      const token = await api.authorizeOAuthApplication(
        app.clientId,
        params.get('redirect_uri')!,
        responseType!,
        scope!,
      );

      setAuthToken(token);
      setAuthStatus(OAuthState.Authorized);
    } catch (error) {
      captureError(error as Error);
    }
  }, [app, scopeInfo]);

  const denyApp = useCallback(async () => {
    setAuthStatus(OAuthState.Denied);
  }, []);

  useEffect(() => {
    dispatch({ type: ActionType.OnlyShowBody });

    if (app.userAlreadyAuthorized) {
      authorizeApp().catch((error) => captureError(error as Error));
    }
  }, []);

  if (authStatus === OAuthState.Authorized) {
    return <Authorized app={app} authorization={authToken!} />;
  }

  if (authStatus === OAuthState.Denied) {
    return <Denied app={app} />;
  }

  if (authStatus === OAuthState.PriorAuthorization) {
    return <Loading inline />;
  }

  return (
    <Row>
      <Col className="margin-center margin-top-10 oauth" lg={8} md={10} s={12} xl={6}>
        <div className="appicon">
          <img src="/api/logo" style={{ maxWidth: '128px' }} />
        </div>
        <h4 className="text-center">Authorize {app.name}</h4>
        {app.description && <p className="text-center">{app.description}</p>}
        <Card>
          <CardBody className="oauthHeader">
            <div className="oauth-access-info">
              <h3>{name}</h3>
              <span>
                wants to access your account, <strong>{user.username}</strong>, and needs your
                permission to:
              </span>
            </div>
          </CardBody>
          <CardBody>
            <div className="permission">
              <MaterialIcon medium name="vpn_key" />
              <div className="permission-info">
                <div className="permissionHeader">Sign In Automatically</div>
                <div>
                  Signing in with your ConCat account will automatically sign you in to this app.
                </div>
              </div>
            </div>
            {(scopeInfo ?? []).map((scope) => (
              <OAuthPermission key={scope.name} scope={scope} />
            ))}
          </CardBody>
          <CardFooter>
            <div>
              <small>
                Accepting these permissions means that you allow this app to use your data as
                specified in their Terms of Service and Privacy Policy.
              </small>
            </div>
            <br />
            <Button
              block
              color={hasElevatedPermission ? 'danger' : 'success'}
              onClick={authorizeApp}
            >
              Authorize Application
            </Button>
            <Button block color="secondary" onClick={denyApp} outline>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};
