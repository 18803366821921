import React, { FC } from 'react';
import { UserStateComponent } from '../../../components';
import { isLogicError, useQuery, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { LogicError } from '../../../utils/errorHandling';

export const VendorAssistantConfirmComponent: FC = () => {
  const user = useUser();
  const code = useQuery().get('code');

  return (
    <UserStateComponent>
      {code === null ? (
        <div id="noCodeFound">No code present in the URL.</div>
      ) : (
        <LoadingWrapper<void, void>
          dataFetcher={async () => await api.confirmAssistant(user!.id, code)}
          errorDisplay={(err) =>
            isLogicError(err, LogicError.AssistanceRequestNotFound) && (
              <div id="assistantFail">
                <h3>Invalid Confirmation Code</h3>
                <p>The confirmation code provided is invalid or may already have been used.</p>
                <p>If you are sure this code should be valid, pelase contact dealers@concat.app.</p>
              </div>
            )
          }
          inline
        >
          {() => (
            <div id="assistantSuccess">
              <h3>Confirmed!</h3>
              <p>Thank you for confirming that you will be assiting in the Dealer's Den.</p>
              <p>You can now close this window.</p>
            </div>
          )}
        </LoadingWrapper>
      )}
    </UserStateComponent>
  );
};
