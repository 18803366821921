import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { DealerAssistance, DealerFullModel } from '../../../../shared/dealer';
import { Loading, MaterialIcon, UserStateComponent } from '../../../components';
import { renderName } from '../../../utils';

interface VendorAssistantComponentProps {
  readonly dealer: DealerFullModel | undefined;
}

interface VendorAssistantComponentState {
  assistants: DealerAssistance[] | null;
}

export class VendorAssistantComponent extends Component<
  VendorAssistantComponentProps,
  VendorAssistantComponentState
> {
  public constructor(props: VendorAssistantComponentProps) {
    super(props);

    this.state = {
      assistants: null,
    };
  }

  public override render(): JSX.Element {
    return (
      <UserStateComponent>
        {this.getBody()}
        <Row className="justify-content-center">
          <Col lg={6} xs={12}>
            <Link to="/vendor">
              <Button block color="primary" style={{ marginTop: '20px' }} type="submit">
                Go back
              </Button>
            </Link>
          </Col>
        </Row>
      </UserStateComponent>
    );
  }

  public override async componentDidMount(): Promise<void> {
    await this.fetchAssistants();
  }

  private getBody(): JSX.Element {
    const { dealer } = this.props;

    if (!dealer?.applications.some((t) => t.status === 'approved')) {
      return this.unapprovedBody();
    }

    return this.showForm(dealer);
  }

  private async fetchAssistants(): Promise<void> {
    const { dealer } = this.props;

    if (!dealer) {
      return;
    }

    this.setState({
      assistants: await api.getDealerAssistants(dealer.id),
    });
  }

  private unapprovedBody(): JSX.Element {
    return (
      <Row className="justify-content-center" id="vendorUnapproved">
        <Col lg={6} xs={12}>
          <Card className="warning">
            <CardBody className="text-center">
              <MaterialIcon large name="assignment_late" type="warning" />
              <CardText tag="div">
                <p>
                  As your vendor application has not yet been approved, you can't access this
                  feature.
                </p>
                <p>Please check back later.</p>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  private async successToast(): Promise<void> {
    await this.fetchAssistants();
    toast.success(
      'We have sent your assistant request. They receive an email asking them to confirm your request.',
    );
  }

  private async assistantRemovedToast(): Promise<void> {
    await this.fetchAssistants();
    toast.success('Assistant removed.');
  }

  private showForm(dealer: DealerFullModel): JSX.Element {
    return (
      <Row className="justify-content-center" id="dealerForm">
        <Col lg={3} xs={12}>
          <Card>
            <CardBody>
              <CardTitle>Add Assistant</CardTitle>
              <p>
                You can nominate up to two other attendees to assist you at the convention. If you
                require the assistance of more than two attendees, please email the Dealer's Den
                lead.
              </p>
              <p>
                When you add a new assistant, they will receive an email asking them to confirm they
                will be helping you at the convention. Once confirmed, they'll be all set!
              </p>
              <hr />
              <JSONForm
                method="post"
                onSuccess={async () => await this.successToast()}
                path={`/api/dealers/${dealer.id}/assistants`}
              >
                <FormGroup>
                  <Label for="username">Assistant Username</Label>
                  <Input id="username" name="username" />
                  <FormText color="muted">
                    Your assistant can find their username at the top of the left side bar once
                    they've logged in.
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Button block color="primary" id="submitForm" type="submit">
                    Add Assistant
                  </Button>
                </FormGroup>
              </JSONForm>
            </CardBody>
          </Card>
        </Col>
        <Col lg={7} xs={12}>
          <Card className="margin-bottom-10">
            <CardBody>
              <CardTitle>Current Assistants</CardTitle>
              {this.renderCurrentAssistantsTable(dealer)}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  private renderCurrentAssistantsTable(dealer: DealerFullModel): JSX.Element {
    if (this.state.assistants === null) {
      return <Loading inline />;
    }

    if (this.state.assistants.length === 0) {
      return <p className="text-muted">You don't have any assistants.</p>;
    }

    return (
      <Col xs={12}>
        {this.state.assistants.map((assistant) => (
          <Col
            className="clearfix-after"
            id={`assistant${assistant.userId}`}
            key={assistant.userId}
            xs={12}
          >
            <div className="float-right">
              <JSONForm
                method="delete"
                onSuccess={async () => await this.assistantRemovedToast()}
                path={`/api/dealers/${dealer.id}/assistants/${assistant.userId}`}
              >
                <Button className="action-delete" color="danger" type="submit">
                  <MaterialIcon name="delete" />
                </Button>
              </JSONForm>
            </div>
            <h4>{renderName(assistant.user)}</h4>
            <div>
              {assistant.accepted
                ? 'Accepted'
                : 'Pending Email Confirmation. Please ask your assistant to check their email.'}
            </div>
            <hr />
          </Col>
        ))}
      </Col>
    );
  }
}
