import React, { FC, useCallback } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FormNodeTermsAgreementModel } from '../../../../shared/kiosk';
import { LazyMarkdown } from '../../LazyMarkdown';
import { TermsLoader } from '../../TermsLoader';

interface FormNodeTermsAgreementProps extends FormNodeTermsAgreementModel {
  readonly checked: boolean;
  setChecked(checked: boolean): void;
}

export const FormNodeTermsAgreement: FC<FormNodeTermsAgreementProps> = ({
  id,
  markdown,
  termsKey,
  checked,
  setChecked,
}) => {
  const inputId = `agreement-checkbox-${id}`;
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
    },
    [setChecked],
  );

  return (
    <div className="form-node form-node-terms-agreement" id={`agreement-${id}`}>
      <div className="terms-container">
        <TermsLoader name={termsKey} />
      </div>
      <FormGroup>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={checked}
            className="custom-control-input"
            id={inputId}
            onChange={onChange}
            required
            type="checkbox"
          />
          <Label className="custom-control-label" for={inputId}>
            <LazyMarkdown source={markdown} />
          </Label>
        </div>
      </FormGroup>
    </div>
  );
};
