import React, { FC, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, FormGroup, Input, Label } from 'reactstrap';
import { UserSearchUser } from '../../shared/user/search';
import { ScannerResult, useScannerResult } from '../containers/cashier/scanner';
import { UserBadges } from '../containers/housekeeping/attendee/utils';
import { renderName, useCheckboxState, useFetcher, useInputState, useQuery } from '../utils';
import { LoadingState } from '../utils/LoadingState';
import { useDebounceValue } from '../utils/debounce';
import { Loading } from './Loading';
import { UserProfilePicture } from './UserProfilePicture';

import './AttendeeSearch.scss';

interface UserSearchProps {
  readonly hideHelp?: boolean;
  readonly redirectUrl?: string;
  readonly redirectOnSingle?: boolean;
  readonly showBornAt?: boolean;
}

export const SearchComponent: FC<UserSearchProps> = ({
  hideHelp,
  redirectUrl,
  redirectOnSingle,
  showBornAt,
}) => {
  const history = useHistory();
  const [autoNavigate, setAutoNavigate] = useCheckboxState(true);
  const [term, setTerm, setTermStr] = useInputState(useQuery().get('term') ?? '');
  const [scannerResult, setScannerResult] = useState<ScannerResult | undefined>();

  const searchTerm = useDebounceValue(term, 300);
  const results = useFetcher(async () => {
    const users = searchTerm === '' ? [] : await api.searchUsers(searchTerm);

    const urlParams = new URLSearchParams(history.location.search);
    urlParams.set('term', searchTerm);
    history.push({ search: urlParams.toString() });

    return users;
  }, [searchTerm]);

  useScannerResult(
    (result) => {
      let parts = `@ANSI;DBA${result.expireDate!};DBB${result.DoB!};DCS${result.lastName!}`;

      if (result.middleName) {
        parts += `;DAD${result.middleName}`;
      }

      if (result.firstName) {
        parts += `;DAC${result.firstName}`;
      }

      setTermStr(parts);
      setScannerResult(result);
    },
    [setTermStr],
  );

  const searchUI = (
    <Input
      autoFocus
      id="searchBox"
      onChange={setTerm}
      placeholder="Search users..."
      type="search"
      value={term}
    />
  );

  return (
    <>
      <Col xs={12}>
        <FormGroup>
          {redirectOnSingle ? (
            <div>
              {searchUI}
              <div className="search-options">
                <Input
                  checked={autoNavigate}
                  id="autoNavigate"
                  name="autoNavigate"
                  onChange={setAutoNavigate}
                  type="checkbox"
                />
                <Label for="autoNavigate">Enable auto navigate</Label>
              </div>
            </div>
          ) : (
            searchUI
          )}
        </FormGroup>
      </Col>
      <Col xs={12}>
        <Card>
          <CardBody>
            {results.state === LoadingState.Loading && <Loading inline />}
            {results.state === LoadingState.Done && (
              <SearchResults
                hideHelp={hideHelp}
                redirectOnSingle={redirectOnSingle && autoNavigate}
                redirectUrl={redirectUrl}
                scannerResult={scannerResult}
                searchText={searchTerm}
                showBornAt={showBornAt}
                users={results.data!}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

interface SearchResultsProps {
  readonly hideHelp?: boolean;
  readonly users: UserSearchUser[];
  readonly redirectUrl?: string;
  readonly redirectOnSingle?: boolean;
  readonly showBornAt?: boolean;
  readonly searchText: string;
  readonly scannerResult?: ScannerResult;
}

const SearchResults: FC<SearchResultsProps> = ({
  hideHelp,
  users,
  redirectUrl,
  redirectOnSingle,
  searchText,
  showBornAt,
  scannerResult,
}) => {
  function getURL(id: number): string {
    let url = `${redirectUrl ?? '/housekeeping/attendees/user'}/${id}`;

    if (searchText.startsWith('@ANSI')) {
      url += `?barcode=${searchText}`;
    }

    return url;
  }

  if (users.length === 0) {
    return (
      <SearchHelp hideHelp={hideHelp} noResults={searchText !== ''} scannerResult={scannerResult} />
    );
  }

  if (users.length === 1 && redirectOnSingle) {
    return (
      <>
        <Redirect to={getURL(users[0].id)} />
        <h3>One Result Found</h3>
        <p>Redirecting...</p>
      </>
    );
  }

  return (
    <>
      {users.map((user) => (
        <div className="search-item" id={`viewUser${user.id}`} key={user.id}>
          <Link to={getURL(user.id)}>
            <Col className="userListItem clearfix-after" xs={12}>
              <UserProfilePicture url={user.profilePictureUrl} />
              <div>
                {renderName(user)}&nbsp;
                <UserBadges showBornAt={showBornAt} user={user} />
              </div>
              <div>{user.registration?.badgeName}</div>
            </Col>
          </Link>
          <hr />
        </div>
      ))}
    </>
  );
};

interface SearchHelpProps {
  readonly hideHelp?: boolean;
  readonly noResults: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly scannerResult?: ScannerResult;
}

const SearchHelp: FC<SearchHelpProps> = ({ hideHelp, noResults }) => {
  if (hideHelp) {
    return <NoResults noResults={noResults} />;
  }

  return (
    <>
      <h3>Search Parameters</h3>
      {noResults && <p>No results found.</p>}
      <p>
        To search for an attendee, <strong>scan the barcode on their ID</strong>, or enter one of
        the following:
      </p>
      <ul>
        <li>Badge ID</li>
        <li>Username</li>
        <li>First Name, Last Name, and / or Preferred Name</li>
        <li>Phone Number</li>
        <li>Email Address</li>
      </ul>
    </>
  );
};

const NoResults: FC<{ readonly noResults: boolean }> = ({ noResults }) => {
  return (
    <div className="search-empty-state">
      {noResults ? (
        <h4>No results found.</h4>
      ) : (
        <h4>Scan an ID barcode, or begin typing to search.</h4>
      )}
    </div>
  );
};
