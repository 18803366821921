import React, { FC } from 'react';
import { PageHeader, TermsLoader, UserStateComponent } from '../../components';

export const Privacy: FC = () => (
  <UserStateComponent>
    <div id="conPrivacy">
      <PageHeader>Privacy Policy</PageHeader>
      <TermsLoader name="privacy" />
    </div>
  </UserStateComponent>
);
