import React, { FC } from 'react';
import { PageHeader, TermsLoader, UserStateComponent } from '../../components';

export const TermsComponent: FC = () => (
  <UserStateComponent>
    <div id="conTerms">
      <PageHeader>Convention Terms and Conditions</PageHeader>
      <TermsLoader name="terms" />
    </div>
  </UserStateComponent>
);
