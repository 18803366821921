/* eslint-disable import/no-unused-modules */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { RegistrationOptionInput } from '../../../shared/registration/model';
import { UserRegistration } from '../../../shared/user/base';
import { CashierCheck, CashierStatus, UndeliveredProducts } from '../../../shared/user/cashier';
import { ActionButton, MaterialIcon } from '../../components';
import { FulfillmentList } from '../../modules/order/fulfillment/FulfillmentList';
import { displayName, useConvention } from '../../utils';
import { cToUsdStrPref } from '../../utils/cToUsdStr';
import { CashierContext, getReceiptPrinter } from './cashiercontext';
import { RenderMessages } from './messages';
import { PrinterButton } from './printButton';
import { ReceiptPrinterBase } from './printers/PrinterBase';

enum CashierStatusColor {
  danger = 'danger',
  warning = 'warning',
  success = 'success',
}

function transformStatus(status: CashierStatus): {
  color: CashierStatusColor;
  icon: string;
  text: string;
  explanation?: string;
} {
  switch (status) {
    case 'error': {
      return {
        color: CashierStatusColor.danger,
        icon: 'error_outline',
        text: 'Check-In Blocked',
        explanation: 'One or more errors must be resolved before check-in can proceed.',
      };
    }

    case 'warning': {
      return {
        color: CashierStatusColor.warning,
        icon: 'warning',
        text: 'Check-In Warning',
        explanation: 'One or more concerns may need to be addressed before the badge is printed.',
      };
    }

    case 'complete': {
      return {
        color: CashierStatusColor.success,
        icon: 'check_circle_outline',
        text: 'Check-In Complete',
      };
    }

    default: {
      return {
        color: CashierStatusColor.success,
        icon: 'radio_button_unchecked',
        text: 'Check-In OK',
        explanation: 'No concerns or issues were found. Badge is ready to print!',
      };
    }
  }
}

interface AttendeeStatusProps {
  readonly profile: CashierCheck;
  reload(): void;
  openUpdateFromScannerModal?(): void;
}

export const AttendeeStatus: FC<AttendeeStatusProps> = ({
  profile,
  reload,
  openUpdateFromScannerModal,
}) => {
  const cashierSettings = useContext(CashierContext);
  const { longName, venue } = useConvention();
  const { color, icon, text, explanation } = transformStatus(profile.status);

  const receiptAndReload = useCallback(() => {
    const printer = getReceiptPrinter(cashierSettings);
    if (printer === undefined) {
      reload();
      return;
    }

    // TODO make this dynamic and move it someplace better.

    // ReceiptLine formatted document
    const receipt = `
^^^CASH PAYMENT
${longName}
${venue}
{b:line}
Level | ${profile.registration?.attendanceType.displayName}
Payment | ${cToUsdStrPref(profile.dueBalance)}
Reg ID | ${profile.user.id}
-
Your registered email is
${profile.user.email}
{b:none}

Thank you for registering!


=
{drawer:kick}
`;

    printer.print(receipt).catch((error: Error) => {
      toast.error(error.message);
    });

    reload();
  }, [cashierSettings, profile, longName, venue, reload]);

  return (
    <Card className="margin-bottom-10">
      <CardBody className={`text-center cashier-current-status state-${profile.status}`}>
        <div className="cashier-status-header">
          <MaterialIcon large name={icon} type={color} />
          <div>
            <h4>
              <strong>{text}</strong>
            </h4>
            {explanation && <p>{explanation}</p>}
          </div>
        </div>
        <div className="text-left">
          {profile.status === 'complete' ? (
            <>
              <p>Badge has been queued to print. No further action is required!</p>
              <p>
                Press <strong>ESC</strong> to return to the user search page, or press{' '}
                <strong>SPACE BAR</strong> 3 times to re-print the badge for this attendee.
              </p>
            </>
          ) : (
            <>
              <RenderMessages messages={profile.errors} title="Error(s)" type="danger" />
              <RenderMessages
                licenseWarnings={profile.licenseWarnings}
                messages={profile.warnings}
                openUpdateFromScannerModal={openUpdateFromScannerModal}
                title="Warning(s)"
                type="warning"
              />
              <RenderMessages messages={profile.info} title="Info" type="info" />
            </>
          )}
          <RegistrationOptionList
            regOptions={profile.regOptions}
            registration={profile.registration}
          />
        </div>
      </CardBody>
      <CardFooter>
        {profile.dueBalance > 0 && (
          <PaymentButtons
            dueBalance={profile.dueBalance}
            receiptAndReload={receiptAndReload}
            reload={reload}
            userId={profile.user.id}
          />
        )}
        {profile.dueBalance === 0 && <ActionButtons profile={profile} reload={reload} />}
      </CardFooter>
    </Card>
  );
};

interface UndeliveredProductsListProps {
  readonly userId: number;
  readonly products: UndeliveredProducts[];
  refresh(): void;
}

export const UndeliveredProductsList: FC<UndeliveredProductsListProps> = ({
  userId,
  products,
  refresh,
}) => {
  const cashierSettings = useContext(CashierContext);
  const [selected, setSelected] = useState<number[]>([]);
  const onSuccess = useCallback(() => {
    refresh();
    setSelected([]);
  }, [refresh, setSelected]);

  const [receiptPrinter, setReceiptPrinter] = useState<ReceiptPrinterBase | undefined>();

  const findReceiptPrinter = useCallback(() => {
    const printer = getReceiptPrinter(cashierSettings);
    setReceiptPrinter(printer);
  }, [cashierSettings]);

  useEffect(() => {
    for (const m of cashierSettings.receiptPrinterManagers) {
      m.addEventListener('connectedDevice', findReceiptPrinter);
      m.addEventListener('disconnectedDevice', findReceiptPrinter);
    }

    findReceiptPrinter();

    return () => {
      for (const m of cashierSettings.receiptPrinterManagers) {
        m.removeEventListener('connectedDevice', findReceiptPrinter);
        m.removeEventListener('disconnectedDevice', findReceiptPrinter);
      }
    };
  }, [cashierSettings]);

  const printPickList = useCallback(() => {
    if (receiptPrinter === undefined || products.length === 0) {
      return;
    }

    // TODO make this dynamic and move it someplace better.

    // ReceiptLine formatted document
    const receipt = `
^^^PICK LIST
^^^Badge # ${userId}

{width:4 *}
QTY| ITEM |
-
${products
  .filter((p) => !p.dispatchedOn)
  .map((p) => `${p.quantity} |${displayName(p.product)}`)
  .join('\n\n')}


=
`;

    receiptPrinter.print(receipt).catch((error: Error) => {
      toast.error(error.message);
    });
  }, [userId, products, receiptPrinter, refresh, setSelected]);

  if (products.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <div className="cashier-status-header">
          <div>
            <h4>
              <strong>Undelivered products</strong>
            </h4>
          </div>
        </div>
        <FulfillmentList items={products} selected={selected} setSelected={setSelected} />
      </CardBody>
      <CardFooter>
        {receiptPrinter && (
          <Button
            color="primary"
            key="printPickList"
            onClick={printPickList}
            style={{ float: 'left' }}
          >
            Print Pick List
          </Button>
        )}
        <ActionButton
          action={`/api/users/${userId}/fulfill`}
          color="success"
          disabled={selected.length === 0}
          method="patch"
          onSuccess={onSuccess}
          payload={{ orderItemIds: selected }}
          style={{ float: 'right' }}
        >
          Mark as delivered
        </ActionButton>
      </CardFooter>
    </Card>
  );
};

interface RegistrationOptionListProps {
  readonly registration?: UserRegistration;
  readonly regOptions: RegistrationOptionInput[];
}

const RegistrationOptionList: FC<RegistrationOptionListProps> = ({ registration, regOptions }) => {
  if (!registration) {
    return null;
  }

  const options = Object.entries(registration.options).filter(([key]) =>
    regOptions.some((t) => t.id.toString() === key),
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <div>
      <p style={{ marginBottom: 0 }}>
        {registration.paidOrderItem
          ? displayName(registration.paidOrderItem)
          : displayName(registration.attendanceType)}{' '}
        options
      </p>
      <ul>
        {options.map(([key, value]) => {
          const option = regOptions.find((t) => t.id.toString() === key);

          if (!option) {
            return null;
          }

          return (
            <li key={key}>
              <strong>{option.name}</strong>: {value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface PaymentButtonsProps {
  readonly dueBalance: number;
  readonly userId: number;
  receiptAndReload(): void;
  reload(): void;
}

const PaymentButtons: React.FC<PaymentButtonsProps> = ({
  dueBalance,
  userId,
  receiptAndReload,
  reload,
}) => {
  const cashierSettings = useContext(CashierContext);

  if (!cashierSettings.config.enablePayments) {
    return (
      <div className="cashier-payment-notenabled">
        <MaterialIcon name="front_hand" type="danger" />
        <div>
          <div>
            <strong>This registration station is not configured to accept payments.</strong>
          </div>
          <div>Please direct the attendee to another registration station.</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <h4 className="text-center margin-bottom-10">
        Payment of {cToUsdStrPref(dueBalance)} Required
      </h4>
      <ActionButton
        action="/api/pay/gateways/cash"
        block
        className="margin-bottom-10"
        color="primary"
        id="cashPayment"
        method="post"
        onSuccess={receiptAndReload}
        payload={{ token: 'none', targetUserId: userId }}
      >
        <MaterialIcon name="local_atm" /> Cash Payment
      </ActionButton>
      <ActionButton
        action="/api/pay/gateways/card"
        block
        color="primary"
        id="cardPayment"
        method="post"
        onSuccess={reload}
        payload={{ token: 'none', targetUserId: userId }}
      >
        <MaterialIcon name="credit_card" /> Card Payment
      </ActionButton>
    </>
  );
};

interface ActionButtonsProps {
  readonly profile: CashierCheck;
  reload(): void;
}

const ActionButtons: FC<ActionButtonsProps> = ({ profile, reload }) => {
  const cashierSettings = useContext(CashierContext);
  const { badge, errors, registration, status, warnings, cashierCheckIn } = profile;

  if (!badge) return null;

  if (status === 'error' && badge.extraLabels.length === 0) {
    const wasNoBarcodeScanned = errors.find((s) => s.includes('No ID barcode has been scanned'));
    if (wasNoBarcodeScanned) {
      return (
        <div className="cashier-idscan-error">
          <MaterialIcon name="front_hand" type="danger" />
          <div>
            <div>
              <strong>This registration station requires an ID barcode to be scanned.</strong>
            </div>
            <div>
              Please return to the search page and scan an ID, or direct the attendee to another
              registration station.
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  if (
    !cashierSettings.config.enablePrintingStaffBadges &&
    warnings.includes('User is a volunteer.')
  ) {
    return (
      <div className="cashier-volunteer-notenabled">
        <MaterialIcon name="front_hand" type="danger" />
        <div>
          <div>
            <strong>
              This registration station is not configured to print staff / volunteer badges.
            </strong>
          </div>
          <div>Please direct the attendee to another registration station.</div>
        </div>
      </div>
    );
  }

  return (
    <Row className="cashier-action-buttons">
      <PrinterButton
        badge={badge}
        hotkey={' '}
        isReprint={!!registration?.badgePrinted}
        reload={reload}
        requireExtraConfirmation={status !== 'ok'}
      />
      {cashierCheckIn && <CheckInButton reg={registration} reload={reload} />}
    </Row>
  );
};

interface CheckInButtonProps {
  readonly reg?: UserRegistration;
  reload(): void;
}

const CheckInButton: React.FC<CheckInButtonProps> = ({ reg, reload }) => {
  const [loading, setLoading] = useState(false);
  const alreadyChecked = !!reg?.paidOrderItem?.dispatchDate;

  const checkInBadge = useCallback(async () => {
    setLoading(true);
    const barcode = new URLSearchParams(location.search).get('barcode') ?? undefined;

    try {
      await api.markRegistrationBadgeAsPrinted(reg!.id, !alreadyChecked, barcode);
      reload();
    } catch (error) {
      toast.error((error as Error).message);
    }

    setLoading(false);
  }, [reg]);

  return (
    <Col lg={12}>
      <Button
        actionText="print badge"
        block
        color={alreadyChecked ? 'danger' : 'primary'}
        disabled={loading || !reg}
        id="printBadge"
        onClick={checkInBadge}
        outline={alreadyChecked}
      >
        {alreadyChecked ? 'Remove Check-in' : 'Check-in Registration'}
      </Button>
    </Col>
  );
};
