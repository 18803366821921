import { createStore } from 'redux';
import { captureError } from '../utils/errorHandling';
import { ActionType } from './ActionTypes';
import { Preferences, rootReducer } from './RootReducer';

export const store = createStore(rootReducer);

const lsPreferences = localStorage.getItem('preferences');

if (lsPreferences) {
  try {
    const preferences = JSON.parse(lsPreferences) as Preferences;

    store.dispatch({
      preferences,
      type: ActionType.PreferenceUpdate,
    });
  } catch (error) {
    captureError(error as Error);
    localStorage.removeItem('preferences');
  }
}
