import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { RegistrationOptionInput, RegistrationUpsert } from '../../../shared/registration/model';
import { generateRandomName } from '../../utils/nameGenerator';

interface RandomizeBadgeInfoButtonProps {
  readonly options: RegistrationOptionInput[];
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

export const RandomizeBadgeInfoButton: FC<RandomizeBadgeInfoButtonProps> = ({
  options,
  registration,
  setRegistration,
}) => {
  const speciesOption = options.find((t) => t.fromRegistration && t.type === 'text');
  const randomizeBadgeInfo = useCallback(() => {
    const species = (registration.options[speciesOption!.id.toString()] as string) ?? '';
    const random = generateRandomName({ badgeName: registration.badgeName, species });
    setRegistration((old) => ({
      ...old,
      badgeName: random.badgeName,
      options: speciesOption
        ? {
            ...old.options,
            [speciesOption.id]: random.species,
          }
        : old.options,
    }));
  }, [setRegistration, speciesOption, registration]);

  if (!speciesOption) {
    return null;
  }

  return (
    <FormGroup>
      <Label for="randomBadgeInfo">Randomize</Label>
      <Button
        className="btn-block"
        color="primary"
        id="randomBadgeInfo"
        onClick={randomizeBadgeInfo}
        outline
      >
        <MaterialIcon className="align-middle" name="shuffle" />
      </Button>
    </FormGroup>
  );
};
