import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { OAuthApp } from '../../../../APIClient';

interface AuthorizedProps {
  readonly app: OAuthApp;
}

export const Denied: React.FC<AuthorizedProps> = ({ app }) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(location.search);
      const redirectUri = params.get('redirect_uri')!;

      window.location.replace(`${redirectUri}?error=access_denied&state=${params.get('state')!}`);
    }, 2000);
  }, []);

  return (
    <Row>
      <Col className="margin-center margin-top-10 oauth" lg={8} md={10} s={12} xl={6}>
        <div className="appicon">
          <img src="/api/logo" style={{ maxWidth: '128px' }} />
        </div>
        <h4 className="text-center">Authorize {app.name}</h4>
        {app.description && <p className="text-center">{app.description}</p>}
        <Card>
          <CardBody className="text-center">
            <MaterialIcon className="override" large name="dangerous" type="danger" />
            <h4>Authorization Denied</h4>
            <p>
              You have refused access to <strong>{app.name}</strong>.
            </p>
            <p>Just a moment while we redirect you...</p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
