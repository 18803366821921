/* eslint-disable import/no-unused-modules */

import { UserReferenceModel } from '../user/base';
import { ProductModel } from './product';

export type ValidCriterionName =
  | 'addressDistance'
  | 'composite'
  | 'cryptoRandom'
  | 'points'
  | 'prevYearAttendance'
  | 'value';

export type BaseCriterionOptions = Record<string, unknown>;

export interface BaseCriterion {
  name: ValidCriterionName;
  displayName?: string;
  options?: BaseCriterionOptions;
}

export type ValidCriterions =
  | AddressDistanceCriteron
  | CompositeCriterion
  | CryptoRandomCriterion
  | PointsCriterion
  | PreviousYearAttendanceCriterion
  | ValueCriterion;

export type CompositeOperation = 'add' | 'max' | 'min' | 'multiply' | 'pow';

export interface CompositeCriterionOptions extends BaseCriterionOptions {
  composites: ValidCriterions[];
  operator: CompositeOperation;
}

export interface CompositeCriterion extends BaseCriterion {
  name: 'composite';
  options: CompositeCriterionOptions;
}

export interface PreviousYearAttendanceCriterionOptions extends BaseCriterionOptions {
  previousYears: number;
}

export interface PreviousYearAttendanceCriterion extends BaseCriterion {
  name: 'prevYearAttendance';
  options: PreviousYearAttendanceCriterionOptions;
}

export interface CryptoRandomCriterion extends BaseCriterion {
  name: 'cryptoRandom';
}

interface UserLocation {
  latitude: number;
  longitude: number;
}

export interface AddressDistanceCriteronOptions extends BaseCriterionOptions {
  origin: UserLocation;
  preferFar?: boolean;
}

export interface AddressDistanceCriteron extends BaseCriterion {
  name: 'addressDistance';
  options: AddressDistanceCriteronOptions;
}

export interface PointsCriterion extends BaseCriterion {
  name: 'points';
  options: {};
}

export interface ValueCriterionOptions extends BaseCriterionOptions {
  value: number;
}

export interface ValueCriterion extends BaseCriterion {
  name: 'value';
  options: ValueCriterionOptions;
}

export type RaffleProduct = Pick<ProductModel, 'description' | 'displayName' | 'id' | 'name'>;
export type RaffleStatus = 'closed' | 'drawn' | 'open';

interface RaffleText {
  enteredTitle: string;
  enteredSubtext: string;
  notEnteredText: string;
  rescindedText: string;
  winnerText: string;
  waitingOpenText: string;
  waitingDrawnText: string;
  waitingClosedText: string;
}

export interface RaffleWithUserDetails extends RaffleText {
  id: number;
  name: string;
  status: RaffleStatus;
  roleOverride: boolean;
  overflowEntries: boolean;
  ticket?: {
    id: number;
    createdAt: Date;
    status: RaffleTicketStatus;
    points: number;
  };
}

export interface Raffle extends RaffleText {
  id: number;
  criterion: CompositeCriterion;
  name: string;
  status: 'closed' | 'drawn' | 'open';
  rolesOverride: number[];
  overflowEntries: boolean;
  tickets: RaffleTicket[];
  products: RaffleProduct[];
}

export interface RaffleUpsert {
  criterion: ValidCriterions | undefined;
  productIds: number[];
  rolesOverride: number[];
}

export const raffleTicketStatus = [
  'deleted',
  'redeemed',
  'rescinded',
  'waiting',
  'winner',
] as const;

export type RaffleTicketStatus = (typeof raffleTicketStatus)[number];

export interface RaffleTicket {
  id: number;
  userId: number;
  raffleId: number;
  points: number;
  createdAt: Date;
  status: RaffleTicketStatus;
  user: UserReferenceModel;
}

export interface TicketWeight {
  userId: number;
  weight: number;
  geoLocation?: {
    latitude: number;
    longitude: number;
  };
  previousYearAttendance?: {
    startDate: Date;
  }[];
  warnings?: string[];
}

export interface RunRaffleResults {
  winners: number[];
  weights: TicketWeight[];
  testRun?: boolean;
}
