import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { OptionDataPayload, OptionDataValue } from '../../../shared/options';
import { ProductOptionInput } from '../../../shared/orders';
import { RegistrationUpsert } from '../../../shared/registration/model';
import { ProductOptionViewer } from '../../components';

interface ProductOptionsViewerProps {
  readonly options: ProductOptionInput[];
  readonly isPaidAttendance?: boolean;
  readonly values: OptionDataPayload;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

export const ProductOptionViewerList: FC<ProductOptionsViewerProps> = ({
  setRegistration,
  isPaidAttendance,
  options,
  values,
}) => {
  return (
    <Row>
      {options.map((o) => {
        return (
          <Col key={o.id} lg={6} xs={12}>
            <RegistrationOptionRenderer
              isPaidAttendance={isPaidAttendance}
              option={o}
              setRegistration={setRegistration}
              values={values}
            />
          </Col>
        );
      })}
    </Row>
  );
};

interface RegistrationOptionRendererProps {
  readonly option: ProductOptionInput;
  readonly values: OptionDataPayload;
  readonly isPaidAttendance?: boolean;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

export const RegistrationOptionRenderer: FC<RegistrationOptionRendererProps> = ({
  option,
  values,
  setRegistration,
  isPaidAttendance,
}) => {
  const onOptionChange = useCallback(
    (id: number, value: OptionDataValue) => {
      setRegistration((old) => ({
        ...old,
        options: {
          ...old.options,
          [id.toString()]: value,
        },
      }));
    },
    [setRegistration],
  );

  let disabled = false;
  if (isPaidAttendance) {
    if (option.type === 'currency') {
      disabled = true;
    }

    if (option.type === 'select' || option.type === 'multi') {
      disabled = !!option.selectValues?.some((t) => t.priceModifier > 0);
    }
  }

  return (
    <ProductOptionViewer
      disabled={disabled}
      onChange={onOptionChange}
      option={option}
      value={values[option.id.toString()] ?? undefined}
    />
  );
};
