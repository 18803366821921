import React, { CSSProperties, FC } from 'react';
import { classNames } from '../utils';

export type IconColor = 'danger' | 'info' | 'muted' | 'primary' | 'success' | 'warning' | 'white';

export interface MaterialIconProps {
  readonly name: string;
  readonly large?: boolean;
  readonly medium?: boolean;
  readonly small?: boolean;
  readonly xsmall?: boolean;
  readonly type?: IconColor;
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly onClick?: React.MouseEventHandler<HTMLSpanElement>;
  readonly id?: string;
}

export const MaterialIcon: FC<MaterialIconProps> = ({
  name,
  large,
  medium,
  small,
  xsmall,
  type,
  className,
  style,
  onClick,
  id,
}) => (
  <span
    className={classNames(
      {
        large: !!large,
        medium: !!medium,
        small: !!small,
        xsmall: !!xsmall,
        [`text-${type!}`]: !!type,
      },
      className ?? '',
      'material-icons',
    )}
    id={id}
    onClick={onClick}
    style={style}
  >
    {name}
  </span>
);
