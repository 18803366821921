import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { MaterialIcon, PageHeader, UserStateComponent } from '../../components';
import { isResourceError } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { getConventionIdParam } from './getConventionIdParam';

interface ConfirmComponentProps {
  readonly match: {
    params: {
      code?: string;
    };
  };
}

export class ConfirmComponent extends Component<ConfirmComponentProps> {
  public override render(): JSX.Element {
    return (
      <UserStateComponent requireLoggedOut>
        <PageHeader>Account Registration</PageHeader>
        <Row className="justify-content-center">
          <Col lg={6} xs={12}>
            <Card>
              <CardBody className="text-center">
                <LoadingWrapper<void, void>
                  dataFetcher={async () =>
                    await api.confirmAccount(this.props.match.params.code ?? '')
                  }
                  errorDisplay={(err) => isResourceError(err, 'User') && this.showError()}
                  loadingDisplay={() => this.showLoading()}
                >
                  {() => this.showActivated()}
                </LoadingWrapper>
                <hr />
                <Link className="btn btn-link btn-block" to={`/login${getConventionIdParam()}`}>
                  Go back to Login
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </UserStateComponent>
    );
  }

  private showLoading(): JSX.Element {
    return (
      <div id="confirmLoading">
        <MaterialIcon className="margin-bottom-10" large name="security" type="muted" />
        <CardTitle>Just a Moment...</CardTitle>
        <p>We're trying to activate your account. This may take a few moments.</p>
      </div>
    );
  }

  private showActivated(): JSX.Element {
    return (
      <div id="confirmActivated">
        <MaterialIcon className="margin-bottom-10" large name="verified_user" type="success" />
        <CardTitle>Account Activated!</CardTitle>
        <p>Thanks for confirming your e-mail address!</p>
        <p>Your account is active and you can now log in.</p>
      </div>
    );
  }

  private showError(): JSX.Element {
    return (
      <div id="confirmError">
        <MaterialIcon className="margin-bottom-10" large name="warning" type="warning" />
        <CardTitle>Error During Activation</CardTitle>
        <p>
          Sorry, but the code you provided could not be matched to an account pending verification.
        </p>
        <p>
          Try copying the activation link from your mail client and pasting it into the address bar.
        </p>
      </div>
    );
  }
}
