import React, { FC, useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Order, Product } from '../../../shared/orders/model';
import { LazyMarkdown, Loading, PageHeader, UserStateComponent } from '../../components';
import { DateTime } from '../../components/Date';
import { ConventionSetting } from '../../models';
import { Ticket } from '../../models/ticket';
import { DateRange, displayName, isAccessError, useConvention, useUser } from '../../utils';
import { captureError } from '../../utils/errorHandling';

import './tickets.scss';

type OrderTicketsProps = RouteComponentProps<{ id: string }>;

export const OrderTickets: FC<OrderTicketsProps> = ({ match: { params } }) => {
  const orderId = Number(params.id);
  const [order, setOrder] = useState<Order | null>(null);
  const history = useHistory();

  useEffect(() => {
    api
      .getOrderById(orderId)
      .then(setOrder)
      .catch((error: Error) => {
        if (isAccessError(error)) {
          // User tried to access a ticket they don't have permission for, redirect to the order list
          history.push('/account/orders');
          return;
        }

        captureError(error);
      });
  }, [history, orderId]);

  return (
    <UserStateComponent>
      {order ? <OrderTicketInfo order={order} /> : <Loading inline />}
    </UserStateComponent>
  );
};

function getAllTicketIds(order: Order): string[] {
  return order.orderItems.filter((t) => t.ticket).map((t) => t.ticket!.id);
}

interface OrderTicketInfoProps {
  readonly order: Order;
}

const OrderTicketInfo: FC<OrderTicketInfoProps> = ({ order }) => {
  const [ticketElements, setTicketElements] = useState<JSX.Element[]>([]);
  const [loaded, setLoaded] = useState(false);

  const user = useUser()!;
  const convention = useConvention();

  const didRetrieveAllTickets = ticketElements.length === getAllTicketIds(order).length;
  const products = order.orderItems.map((item) => item.product);

  const header = <TicketHeader convention={convention} />;

  useEffect(() => {
    const ticketIds = getAllTicketIds(order);
    const promises = ticketIds.map(async (id) => await api.getTicketById(id));

    Promise.allSettled(promises)
      .then((results) => {
        const tickets = results
          .filter((r) => r.status === 'fulfilled')
          .map((r) => (r as PromiseFulfilledResult<Ticket>).value);

        setTicketElements(
          tickets.map((ticket) => (
            <TicketElement
              key={ticket.id}
              product={products.find(({ id }) => id === ticket.paidOrderItem.productId)!}
              ticket={ticket}
              timeZone={convention.timeZone}
            />
          )),
        );
      })
      .finally(() => {
        setLoaded(true);
      })
      .catch((error) => captureError(error as Error));
  }, [order, setTicketElements]);

  useEffect(() => {
    render(
      <>
        {header}
        {ticketElements}
      </>,
      document.getElementById('printArea'),
    );
  }, [ticketElements]);

  const print = useCallback(() => {
    window.print();
  }, []);

  const isOwnOrder = user.id === order.userId;

  return (
    <div id="order-tickets">
      <PageHeader>Order #{order.id} &mdash; Tickets</PageHeader>
      {!loaded && <Loading inline />}
      <Row className="justify-content-center">
        {!isOwnOrder && (
          <Col id="tickets-impersonated" lg={10} xs={12}>
            <Alert className="text-center ticket-impersonated" color="light">
              <strong>
                You are in staff mode. You are viewing the tickets of UID {order.userId}.
              </strong>
            </Alert>
          </Col>
        )}
        {loaded && !didRetrieveAllTickets && (
          <Col id="tickets-not-retrieved" lg={8} xs={12}>
            <Alert color="warning">
              Some tickets could not be retrieved. Please try again later, or contact support for
              assistance.
            </Alert>
          </Col>
        )}
        {loaded && (
          <>
            <Col id="tickets-print-info" lg={8} xs={12}>
              <Alert color="info">
                <strong>Please print these tickets and bring them to the event</strong>&nbsp; You
                can also retrieve them or screenshot them via this page on your phone.
              </Alert>
            </Col>
            <Col className="mt-1" lg={2} xs={12}>
              <Button block color="primary" onClick={print}>
                Print
              </Button>
            </Col>
            <Col className="d-block d-sm-none mb-3" xs={12} />
          </>
        )}
        <Col id="tickets-info" lg={10} xs={12}>
          {header}
          {ticketElements}
        </Col>
      </Row>
    </div>
  );
};

interface TicketElementProps {
  readonly ticket: Ticket;
  readonly product: Product;
  readonly timeZone: string;
}

const TicketElement = ({ ticket, product, timeZone }: TicketElementProps): JSX.Element => {
  const { validFrom, validUntil } = ticket.ticketGroup;
  const barcode = `/api/tickets/${ticket.id}/image`;

  return (
    <Card className="mb-3 order-ticket">
      <CardBody>
        <Row>
          <Col className="text-center ticket-barcode" lg={2} xs={12}>
            {ticket.scannedAt && <span className="ticket-barcode-redeemed">REDEEMED</span>}
            <img alt={`Barcode for ${ticket.id}`} src={barcode} />
          </Col>
          <Col className="d-block d-sm-none mb-4" xs={12} />
          <Col lg={8} xs={12}>
            <h3 className="ticket-product-name">{displayName(product)}</h3>
            <h5 className="ticket-group-name">{displayName(ticket.ticketGroup)}</h5>
            <p>
              <LazyMarkdown source={ticket.ticketGroup.additionalInfo} />
            </p>
            <p>
              {validFrom && (
                <>
                  <strong>Valid From</strong>: <DateTime timeZone={timeZone} value={validFrom} />
                  <br />
                </>
              )}
              {validUntil && (
                <>
                  <strong>Valid Until</strong>: <DateTime timeZone={timeZone} value={validUntil} />
                </>
              )}
            </p>
          </Col>
          <Col lg={2} xs={12}>
            <p>
              <strong>Issued To</strong> #{ticket.userId}
              <br />
              {ticket.scannedAt && (
                <>
                  <strong>Scanned At</strong>: {ticket.scannedAt.toLocaleString()}
                  <br />
                </>
              )}
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

interface TicketHeaderProps {
  readonly convention: ConventionSetting;
}

const TicketHeader = ({
  convention: { longName, venue, startAt, endAt, timeZone },
}: TicketHeaderProps) => {
  return (
    <Card className="mb-3">
      <CardBody>
        <Row>
          <Col className="text-center" lg={2} xs={12}>
            <img alt={longName} src="/api/logo" style={{ maxWidth: '64px' }} />
          </Col>
          <Col className="d-block d-sm-none mb-4" xs={12} />
          <Col lg={8} xs={12}>
            <h3>{longName}</h3>
            <div>{venue}</div>
            <div>
              <DateRange end={endAt} start={startAt} timeZone={timeZone} />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
