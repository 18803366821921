import React, { PropsWithChildren } from 'react';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { MaterialIcon } from '.';

let inlineHelpId = 1;

export interface InlineHelpProps {
  readonly href?: string;
}

export const InlineHelp = ({ href, children }: PropsWithChildren<InlineHelpProps>): JSX.Element => {
  const key = `help-${inlineHelpId++}`;

  if (href) {
    return (
      <span className="inline-help">
        {' '}
        (
        <a href={href} rel="noreferrer" target="_blank">
          {children ?? 'Help'}
        </a>
        )
      </span>
    );
  }

  return (
    <>
      <span className="inline-icon" id={key}>
        <MaterialIcon name="info" small />
      </span>
      <UncontrolledPopover placement="bottom" target={key} trigger="hover">
        <PopoverBody>{children}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
