/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, PureComponent, ReactNode } from 'react';

const FONT_SIZE_VARIATION_DECREASE_FACTOR = 0.5;

// Taken from https://github.com/Splact/react-occamy-text

interface Props {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly grow: boolean;
  readonly maxFontSize: number;
  readonly maxFontSizeVariation: number;
  readonly maxHeight?: number;
  readonly minFontSize: number;
  readonly minFontSizeVariation: number;
  readonly shrink: boolean;
  readonly style: CSSProperties;
}

export class OccamyText extends PureComponent<Props> {
  public static defaultProps = {
    grow: true,
    maxFontSize: 96,
    maxFontSizeVariation: 8,
    minFontSize: 4,
    minFontSizeVariation: 0.3,
    shrink: true,
    style: {},
  };

  private maxHeight: number;
  private maxWidth: number;
  private fontSize: number;
  private initialFontSize: number;
  private lineHeight: number | string | null;
  private variationStrategy: number | null;
  private isVariationStrategyChanged: boolean;
  private isImperfectionReached: boolean;
  private wrapper?: HTMLElement | null;
  private content?: HTMLElement | null;

  public constructor(props: Props) {
    super(props);

    this.maxHeight = Number.POSITIVE_INFINITY;
    this.maxWidth = Number.POSITIVE_INFINITY;
    this.fontSize = -1;
    this.initialFontSize = -1;
    this.lineHeight = null;
    this.variationStrategy = null;
    this.isVariationStrategyChanged = false;
    this.isImperfectionReached = false;
  }

  public override render(): ReactNode {
    const {
      children,
      className,
      style,
      grow: _,
      maxFontSize: __,
      maxFontSizeVariation: ___,
      maxHeight: ____,
      minFontSize: _____,
      minFontSizeVariation: ______,
      shrink: _______,
      ...props
    } = this.props;

    const wrapperClasses = className ? `${className} occamy-text` : 'occamy-text';
    const wrapperStyle = {
      ...style,
      height: '100%',
    };

    const contentStyle = {
      fontSize: this.fontSize ? `${this.fontSize}px` : undefined,
      lineHeight: this.lineHeight ? `${this.lineHeight}em` : undefined,
    };

    return (
      <div
        className={wrapperClasses}
        ref={(r) => (this.wrapper = r)}
        style={wrapperStyle}
        {...props}
      >
        <div className="occamy-text--content" ref={(r) => (this.content = r)} style={contentStyle}>
          {children}
        </div>
      </div>
    );
  }

  public override componentDidMount(): void {
    if (this.props.maxHeight) {
      this.maxHeight = this.props.maxHeight;
    }

    if (!this.content) {
      return;
    }

    if (this.wrapper) {
      if (!this.props.maxHeight) {
        this.maxHeight = this.wrapper.offsetHeight;
      }

      this.maxWidth = this.wrapper.offsetWidth;
    }

    this.fontSize = Number.parseInt(
      window.getComputedStyle(this.content).getPropertyValue('font-size'),
      10,
    );

    this.initialFontSize = this.fontSize;
    const lineHeight = window.getComputedStyle(this.content).getPropertyValue('line-height');
    const computedLineHeight = Number.parseInt(lineHeight, 10);
    this.lineHeight = computedLineHeight > 0 ? computedLineHeight / this.fontSize : lineHeight;

    this.setRightFontSize();
  }

  public override componentDidUpdate(): void {
    if (this.props.maxHeight) {
      this.maxHeight = this.props.maxHeight;
    }

    if (!this.props.maxHeight && this.wrapper) {
      this.maxHeight = this.wrapper.offsetHeight;
      this.maxWidth = this.wrapper.offsetWidth;
      this.setRightFontSize();
    }
  }

  private readonly getSizeDiff = () => {
    if (!this.content) {
      return {
        axis: 'x',
        value: 0,
      };
    }

    const x = this.content.scrollWidth - this.maxWidth;
    const y = this.content.offsetHeight - this.maxHeight;

    if (x > 0 && x > y) {
      return {
        axis: 'x',
        value: x,
      };
    }

    return {
      axis: 'y',
      value: y,
    };
  };

  private readonly getFontSizeVariation = (diff: { value: number; axis: string }) => {
    const variationStrategy = (diff.value > 0 && -1) || (diff.value < 0 && 1) || 0;

    if (!variationStrategy) {
      return 0;
    }

    if (this.variationStrategy === null) {
      this.variationStrategy = variationStrategy;
    } else if (this.variationStrategy === variationStrategy) {
      this.isVariationStrategyChanged = false;
    } else {
      // variation changed
      if (this.isVariationStrategyChanged) {
        // already changed in a previous iteration: we need to stop iterations
        this.isImperfectionReached = true;
      } else {
        this.isVariationStrategyChanged = true;
      }

      this.variationStrategy = variationStrategy;
    }

    const diffRatio = Math.abs(diff.value) / (diff.axis === 'x' ? this.maxWidth : this.maxHeight);
    let fontSizeVariation = diffRatio * this.fontSize * FONT_SIZE_VARIATION_DECREASE_FACTOR;

    if (fontSizeVariation < this.props.minFontSizeVariation) {
      fontSizeVariation = this.props.minFontSizeVariation;
    } else if (fontSizeVariation > this.props.maxFontSizeVariation) {
      fontSizeVariation = this.props.maxFontSizeVariation;
    }

    return fontSizeVariation * this.variationStrategy;
  };

  private readonly setRightFontSize = () => {
    if (!this.content) {
      return false;
    }

    let diff = this.getSizeDiff();
    this.isVariationStrategyChanged = false;
    this.isImperfectionReached = false;
    let fontSizeVariation = this.getFontSizeVariation(diff);
    let nextFontSize = this.fontSize + fontSizeVariation;

    // while the text is higher then its wrapper (or maxHeight props)
    while (
      diff.value !== 0 &&
      (!this.isImperfectionReached || diff.value > 0) &&
      this.fontSize > this.props.minFontSize &&
      this.fontSize < this.props.maxFontSize &&
      (this.props.shrink || nextFontSize >= this.initialFontSize) &&
      (this.props.grow || nextFontSize <= this.initialFontSize)
    ) {
      // set a new fontSize
      this.fontSize += fontSizeVariation;
      this.content.setAttribute(
        'style',
        `font-size: ${this.fontSize}px; line-height: ${this.lineHeight!}`,
      );

      diff = this.getSizeDiff();
      fontSizeVariation = this.getFontSizeVariation(diff);
      nextFontSize = this.fontSize + fontSizeVariation;
      // counter++;
    }

    return true;
  };
}
