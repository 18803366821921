import { TreeElement } from '@conventioncatcorp/common-fe';
import { RaffleWithUserDetails, ValidCriterions } from '../../shared/orders/raffle';

export const transformCriterionToTitle = (criterion: ValidCriterions): TreeElement => {
  let name = 'Unknown';
  let children: TreeElement[] | undefined;
  const attributes: {
    canDelete: boolean;
    canEdit: boolean;
    minChildren?: number;
  } = { canDelete: true, canEdit: true };

  switch (criterion.name) {
    case 'addressDistance': {
      name = `Address Distance (Prefer ${criterion.options.preferFar ? 'Far' : 'Closer'})`;
      break;
    }

    case 'composite': {
      let operatorTitle: string;
      switch (criterion.options.operator) {
        case 'max': {
          operatorTitle = 'Max Operation';
          break;
        }

        case 'min': {
          operatorTitle = 'Min Operation';
          break;
        }

        case 'multiply': {
          operatorTitle = 'Multiply Operation';
          break;
        }

        case 'pow': {
          operatorTitle = 'Power Operation';
          break;
        }

        case 'add': {
          operatorTitle = 'Addition Operation';
          break;
        }
      }

      attributes.minChildren = 2;
      name = operatorTitle;
      children = criterion.options.composites.map((child) => transformCriterionToTitle(child));

      break;
    }

    case 'prevYearAttendance': {
      name = `Previous Years Attendance (${criterion.options.previousYears} Year(s) Max)`;
      break;
    }

    case 'value': {
      name = `Static Value: ${criterion.options.value}`;
      break;
    }

    case 'cryptoRandom': {
      name = 'Random Number';
      break;
    }

    case 'points': {
      name = 'Ticket Points';
      break;
    }
  }

  return {
    ...attributes,
    children,
    title: criterion.displayName ? `${criterion.displayName} (${name})` : name,
  };
};

export function isRaffleClosed(
  userRaffle?: RaffleWithUserDetails,
  raffle?: Pick<RaffleWithUserDetails, 'id' | 'roleOverride' | 'status'>,
): boolean {
  if (userRaffle && userRaffle?.id === raffle?.id) {
    const ticketStatus = userRaffle.ticket?.status;
    if (ticketStatus === 'winner' || ticketStatus === 'redeemed') {
      return false;
    }
  }

  if (!raffle) {
    return false;
  }

  if (raffle.roleOverride) {
    return false;
  }

  return raffle.status !== 'open';
}
