/* eslint-disable react/no-object-type-as-default-prop */
import React, { ChangeEvent, ClipboardEvent, useCallback, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Input } from 'reactstrap';
import { displayName, useBoolState, useTimeoutCreator } from '../utils';
import { debounce } from '../utils/debounce';
import { captureError } from '../utils/errorHandling';
import { Chip } from './Chip';
import { MaterialIcon } from '.';

export interface Element {
  id: number;
  name?: string;
  displayName?: string | null;
}

export interface SearchProvider<T extends Element> {
  text(txt: string): Promise<T[]>;
  ids(ids: number[]): Promise<T[]>;
}

interface AutocompleteRowProps<T> {
  readonly element: T;
  addFromSearch(el: T): void;
  displayFormat(el: T): string;
}

const AutocompleteRow = <T extends Element>({
  element,
  addFromSearch,
  displayFormat,
}: AutocompleteRowProps<T>) => {
  const onClick = useCallback(() => {
    addFromSearch(element);
  }, [addFromSearch, element]);

  return (
    <div className="autocomplete-option" id={`item${element.id}`} onClick={onClick}>
      {displayFormat(element)}
    </div>
  );
};

interface AutoCompleteProps<T> {
  readonly createElementLabel?: string;
  readonly unselected: T[];
  onCreateClicked?(): void;
  displayFormat(el: T): string;
  addFromSearch(el: T): void;
}

const AutoComplete = <T extends Element>({
  onCreateClicked,
  addFromSearch,
  unselected,
  displayFormat,
  createElementLabel = '',
}: AutoCompleteProps<T>) => {
  const autoCompleteElements: JSX.Element[] = unselected.map((element) => (
    <AutocompleteRow
      addFromSearch={addFromSearch}
      displayFormat={displayFormat}
      element={element}
      key={element.id}
    />
  ));

  if (onCreateClicked) {
    autoCompleteElements.push(
      <div
        className="autocomplete-create autocomplete-option"
        key="createOption"
        onClick={onCreateClicked}
        style={{ borderTop: '1px solid #ccc' }}
      >
        <MaterialIcon name="create" small style={{ float: 'left', marginRight: '4px' }} />
        <div>Create New {createElementLabel}</div>
      </div>,
    );
  }

  if (autoCompleteElements.length === 0) {
    return null;
  }

  return <div className="autocomplete">{autoCompleteElements}</div>;
};

export interface ElementSelectorProps<T extends Element> {
  readonly createElementLabel?: string;
  readonly defaultSelected?: T[];
  readonly disabled?: boolean;
  readonly searchPlaceholder?: string;
  readonly items?: T[];
  readonly id?: string;
  readonly maxItems?: number;
  readonly searchProvider: SearchProvider<T>;
  onCreateClicked?(): void;
  selectionChanged?(elements: T[]): void;
  selectionIdsChanged?(elements: number[]): void;
  singleSelectionIdChanged?(id?: number): void;
  displayFormat?(el: T): string;
}

const defaultFormatter = (el: Element) => `${displayName(el)} (ID: ${el.id})`;

export const ElementSelector = <T extends Element>({
  createElementLabel = '',
  defaultSelected = [],
  disabled,
  searchPlaceholder = 'Search text, or paste list of ids...',
  items = [],
  id,
  maxItems = 9999,
  searchProvider,
  onCreateClicked,
  selectionChanged,
  selectionIdsChanged,
  singleSelectionIdChanged,
  displayFormat = defaultFormatter,
}: ElementSelectorProps<T>): JSX.Element => {
  const timeoutCreator = useTimeoutCreator();
  const [searchText, setSearchText] = useState('');
  const [elements, setElements] = useState(items);
  const [inFocus, enableInFocus, disableInFocus] = useBoolState(false);
  const [selected, setSelected] = useState(defaultSelected);

  const selectionChangedInner = useCallback(
    (newSelection: T[]) => {
      setSelected(newSelection);
      if (selectionChanged) {
        selectionChanged(newSelection);
      }

      if (selectionIdsChanged) {
        selectionIdsChanged(newSelection.map((el) => el.id));
      }

      if (singleSelectionIdChanged && newSelection.length >= 1) {
        singleSelectionIdChanged(newSelection[0]?.id);
      }
    },
    [selectionChanged, selectionIdsChanged, singleSelectionIdChanged],
  );

  const debounceSearch = useMemo(
    () =>
      debounce((newSearchText: string) => {
        if (newSearchText.length > 0) {
          searchProvider.text(newSearchText).then((f) => {
            enableInFocus();
            setElements(f);
          }, captureError);
        }
      }, 350),
    [enableInFocus, searchProvider],
  );

  const onSearchPaste = useCallback(
    async (e: ClipboardEvent<HTMLInputElement>): Promise<void> => {
      const str = e.clipboardData.getData('text/plain');
      const split = str.split(/[ ,]/);
      if (split.length === 0) {
        return;
      }

      const ids = split.map((v) => Number.parseInt(v, 10));
      if (!ids.some((v) => Number.isInteger(v) && v > 0)) {
        return;
      }

      if (
        !confirm('It appears you are pasting a list of ids, would you like to add all of them?')
      ) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      const toAdd = (await searchProvider.ids(ids)).filter(({ id: elId }) =>
        elements.every((el) => el.id !== elId),
      );

      if (toAdd.length !== ids.length) {
        alert(
          `Warning, not all ids seem to be invalid: ${ids
            .filter((elId) => !toAdd.some((u) => u.id === elId))
            .join(',')}`,
        );
      }

      const newSelected = toAdd
        .filter((u) => !selected.some((u2) => u.id === u2.id))
        .concat(selected);

      unstable_batchedUpdates(() => {
        setElements([]);
        setSearchText('');
        selectionChangedInner(newSelected);
      });
    },
    [elements, selected, selectionChangedInner, searchProvider],
  );

  const onSearchChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSearchText = e.currentTarget.value;

      setElements(newSearchText.length === 0 ? [] : elements);
      setSearchText(newSearchText);
      debounceSearch(newSearchText);
    },
    [elements, debounceSearch],
  );

  const removeSelected = useCallback(
    (elId: number) => {
      selectionChangedInner(selected.filter((u) => u.id !== elId));
    },
    [selected, selectionChangedInner],
  );

  const addFromSearch = useCallback(
    (el: T) => {
      const oldSelected = selected;
      if (oldSelected.some((u) => u.id === el.id)) {
        return;
      }

      const add = elements.find((u) => u.id === el.id);
      if (!add) {
        return;
      }

      setElements([]);
      disableInFocus();
      setSearchText('');
      selectionChangedInner(oldSelected.concat(add));
    },
    [elements, selected, disableInFocus, selectionChangedInner],
  );

  const onBlur = useCallback(() => {
    timeoutCreator(disableInFocus, 175);
  }, [timeoutCreator, disableInFocus]);

  const unselected = useMemo(
    () => elements.filter(({ id: elementId }) => !selected.some((s) => s.id === elementId)),
    [selected, elements],
  );

  return (
    <>
      <Input
        autoComplete="off"
        disabled={disabled ?? selected.length >= maxItems}
        id={`${id!}Search`}
        invalid={disabled}
        onBlur={onBlur}
        onChange={onSearchChanged}
        onFocus={enableInFocus}
        onPaste={onSearchPaste}
        placeholder={searchPlaceholder}
        type="search"
        value={searchText}
      />
      {inFocus ? (
        <AutoComplete
          addFromSearch={addFromSearch}
          createElementLabel={createElementLabel}
          displayFormat={displayFormat}
          onCreateClicked={onCreateClicked}
          unselected={unselected}
        />
      ) : null}
      <span>
        {selected.map((u) => (
          <Chip id={`${id!}Chip${u.id}`} key={u.id} onDismiss={removeSelected} value={u.id}>
            {displayFormat(u)}
          </Chip>
        ))}
      </span>
    </>
  );
};
