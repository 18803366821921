/* eslint-disable import/no-unused-modules */
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormNodeType, FormPageModel, FormSectionModel } from '../../../shared/kiosk';
import { ProductModel, ProductOptionInput } from '../../../shared/orders';
import {
  ConRegistrationForm,
  RegistrationFlagModel,
  RegistrationUpsert,
} from '../../../shared/registration/model';
import { CurrentUser } from '../../../shared/user/base';
import { AttendanceFlags, LazyMarkdown } from '../../components';
import { FormNodeLinkTermsAgreement } from '../../components/formElements/models/FormNodeLinkTermsAgreement';
import { FormNodeMarkdown } from '../../components/formElements/models/FormNodeMarkdown';
import { FormNodeTermsAgreement } from '../../components/formElements/models/FormNodeTermsAgreement';
import { ProfilePictureEditor } from '../../containers/account/profilePictureEditor';
import { RegistrationInfo } from '../../models';
import { ActionType, store } from '../../services';
import { displayName, useForm, useInputModel, useObject, useUser } from '../../utils';
import { cleanupOptions } from '../../utils/options';
import { getProductPrice, renderPrice } from '../../utils/product';
import { BadgePreview } from './BadgePreview';
import { EmergencyContactInner } from './EmergencyContactInfo';
import { ProductOptionViewerList, RegistrationOptionRenderer } from './ProductOptionViewerList';
import { RandomizeBadgeInfoButton } from './RandomizeBadge';

interface ConventionFormProps {
  readonly attendanceType: ProductModel;
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly regForm: ConRegistrationForm;
  onSuccess(): void;
  onReset(): void;
}

export const RegistrationDynamicInfoForm: FC<ConventionFormProps> = ({
  registration: conreg,
  attendanceType,
  user,
  regForm,
  onSuccess,
  onReset,
}) => {
  const isPaidAttendance = !!conreg?.paidOrderItem;
  const productPrice = getProductPrice(attendanceType);
  const [policyChecked, setPolicyChecked] = useState(!!conreg);
  const [registration, setRegistration] = useState<RegistrationUpsert>(() => ({
    attendanceTypeId: attendanceType.id,
    badgeName: conreg?.badgeName ?? '',
    emergencyContactName1: conreg?.emergencyContactName1 ?? null,
    emergencyContactName2: conreg?.emergencyContactName2 ?? null,
    emergencyContactPhone1: conreg?.emergencyContactPhone1 ?? null,
    emergencyContactPhone2: conreg?.emergencyContactPhone2 ?? null,
    flags: conreg?.flags ?? [],
    options: cleanupOptions([...regForm.options, ...attendanceType.options], conreg?.options ?? {}),
    badgeArtId: conreg?.badgeArtId,
  }));

  const otherOptions = useMemo(() => {
    let other: ProductOptionInput[] = [...regForm.options, ...attendanceType.options];

    for (const section of regForm.formLayout!.sections) {
      for (const node of section.nodes) {
        if (node.type === 'option') {
          other = other.filter((t) => t.id !== node.optionId);
        }
      }
    }

    return other;
  }, [regForm.options, regForm.formLayout, attendanceType.options]);

  const form = useForm(async () => {
    const hasTerms = regForm.formLayout!.sections.some((section) => {
      return section.nodes.some((node) => {
        return node.type === 'termsAgreement' || node.type === 'linkTermsAgreement';
      });
    });

    if (hasTerms && !policyChecked) {
      toast.error("You must accept the convention's policy");
      return;
    }

    if (conreg) {
      await api.updateRegistration(conreg.id, registration);
    } else {
      await api.createRegistration(user.id, registration);
    }

    // For updating the profile picture, if it has changed
    const activeUser = await api.getActiveUser();
    store.dispatch({
      type: ActionType.ProfileUpdate,
      user: activeUser,
    });

    onSuccess();
  }, [conreg, user.id, registration, onSuccess, regForm, policyChecked]);

  return (
    <form onSubmit={form.onSubmit}>
      <Row className="justify-content-center">
        <Col lg={10} xs={12}>
          <Row id="registrationForm">
            <Col className="margin-bottom-10" lg={12} xs={12}>
              <Card className="productItem markdown-container">
                <div className="productInfo">
                  <h5>{displayName(attendanceType)}</h5>
                  <LazyMarkdown
                    source={attendanceType.description ?? '*No Description Provided*'}
                  />
                </div>
                <div className="productPurchaseBar" id="productChangeBar">
                  {!isPaidAttendance && renderPrice(productPrice)}
                  <Button color="white" onClick={onReset} outline type="button">
                    Change
                  </Button>
                </div>
              </Card>
            </Col>
            <Col xs={12} />
            <RegistrationPageRenderer
              attendanceType={attendanceType}
              isPaidAttendance={isPaidAttendance}
              otherOptions={otherOptions}
              page={regForm.formLayout!}
              policyChecked={policyChecked}
              regForm={regForm}
              registration={registration}
              setPolicyChecked={setPolicyChecked}
              setRegistration={setRegistration}
            />
          </Row>
        </Col>
        <Col className="margin-top-10" lg={6} xs={12}>
          <Button block color="primary" id="submitForm" type="submit">
            {conreg ? 'Update Registration' : 'Register'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

interface RegistrationPageRendererProps {
  readonly page: FormPageModel;
  readonly regForm: ConRegistrationForm;
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
  readonly isPaidAttendance?: boolean;
  readonly otherOptions: ProductOptionInput[];
  readonly attendanceType: ProductModel;
  readonly policyChecked: boolean;
  setPolicyChecked(checked: boolean): void;
}

const RegistrationPageRenderer: FC<RegistrationPageRendererProps> = ({
  page,
  regForm,
  registration,
  setRegistration,
  isPaidAttendance,
  attendanceType,
  otherOptions,
  policyChecked,
  setPolicyChecked,
}) => {
  return (
    <>
      {page.sections.map((section) => {
        return (
          <RegistrationSectionRenderer
            attendanceType={attendanceType}
            isPaidAttendance={isPaidAttendance}
            key={section.id}
            otherOptions={otherOptions}
            policyChecked={policyChecked}
            regForm={regForm}
            registration={registration}
            section={section}
            setPolicyChecked={setPolicyChecked}
            setRegistration={setRegistration}
          />
        );
      })}
    </>
  );
};

interface RegistrationSectionRendererProps {
  readonly section: FormSectionModel;
  readonly regForm: ConRegistrationForm;
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
  readonly isPaidAttendance?: boolean;
  readonly otherOptions: ProductOptionInput[];
  readonly attendanceType: ProductModel;
  readonly policyChecked: boolean;
  setPolicyChecked(checked: boolean): void;
}

const RegistrationSectionRenderer: FC<RegistrationSectionRendererProps> = ({
  section,
  regForm,
  registration,
  setRegistration,
  isPaidAttendance,
  attendanceType,
  otherOptions,
  policyChecked,
  setPolicyChecked,
}) => {
  return (
    <Col
      className="margin-bottom-10"
      id="badgeDesign"
      lg={section.size === 'large' ? 12 : 6}
      xs={12}
    >
      <Card>
        <CardHeader>{section.name}</CardHeader>
        <CardBody>
          {section.nodes.map((node) => {
            return (
              <RegistrationNodeRenderer
                attendanceType={attendanceType}
                isPaidAttendance={isPaidAttendance}
                key={node.id}
                node={node}
                otherOptions={otherOptions}
                policyChecked={policyChecked}
                regForm={regForm}
                registration={registration}
                setPolicyChecked={setPolicyChecked}
                setRegistration={setRegistration}
              />
            );
          })}
        </CardBody>
      </Card>
    </Col>
  );
};

interface RegistrationNodeRendererProps {
  readonly node: FormNodeType;
  readonly regForm: ConRegistrationForm;
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
  readonly isPaidAttendance?: boolean;
  readonly attendanceType: ProductModel;
  readonly otherOptions: ProductOptionInput[];
  readonly policyChecked: boolean;
  setPolicyChecked(checked: boolean): void;
}

const RegistrationNodeRenderer: FC<RegistrationNodeRendererProps> = ({
  node,
  regForm,
  registration,
  setRegistration,
  isPaidAttendance,
  attendanceType,
  otherOptions,
  policyChecked,
  setPolicyChecked,
}) => {
  switch (node.type) {
    case 'text': {
      return <>{node.text}</>;
    }

    case 'randomizeButton': {
      return (
        <RandomizeBadgeInfoButton
          options={regForm.options}
          registration={registration}
          setRegistration={setRegistration}
        />
      );
    }

    case 'option': {
      const option = regForm.options.find((t) => t.id === node.optionId);

      if (!option) {
        if (node.optionId === 'badgeName') {
          return <BadgeNameInput registration={registration} setRegistration={setRegistration} />;
        }

        return null;
      }

      return (
        <RegistrationOptionRenderer
          isPaidAttendance={isPaidAttendance}
          option={option}
          setRegistration={setRegistration}
          values={registration.options}
        />
      );
    }

    case 'profilePicture': {
      return (
        <ProfilePictureInput
          ignoreGravatar={node.ignoreGravatar ?? false}
          setRegistration={setRegistration}
          title={node.title}
        />
      );
    }

    case 'divider': {
      return <hr />;
    }

    case 'badgePreview': {
      return (
        <BadgePreview
          attendanceType={attendanceType}
          badgeDesign={regForm.badgeDesign}
          options={regForm.options}
          registration={registration}
        />
      );
    }

    case 'emergencyContacts': {
      return (
        <EmergencyContactInner registration={registration} setRegistration={setRegistration} />
      );
    }

    case 'otherOptions': {
      return (
        <ProductOptionViewerList
          isPaidAttendance={isPaidAttendance}
          options={otherOptions}
          setRegistration={setRegistration}
          values={registration.options}
        />
      );
    }

    case 'attendanceFlags': {
      return (
        <AttendanceFlagsRenderer
          flags={regForm.flags}
          registration={registration}
          setRegistration={setRegistration}
        />
      );
    }

    case 'attendanceType': {
      throw new Error('Not implemented yet: "attendanceType" case');
    }

    case 'login': {
      throw new Error('Not implemented yet: "login" case');
    }

    case 'cart': {
      throw new Error('Not implemented yet: "cart" case');
    }

    case 'linkTermsAgreement': {
      const linkTermsNode = node;
      return (
        <FormNodeLinkTermsAgreement
          {...linkTermsNode}
          checked={policyChecked}
          setChecked={setPolicyChecked}
        />
      );
    }

    case 'markdown': {
      const markdownNode = node;
      return <FormNodeMarkdown {...markdownNode} />;
    }

    case 'pii': {
      throw new Error('Not implemented yet: "pii" case');
    }

    case 'subheader': {
      throw new Error('Not implemented yet: "subheader" case');
    }

    case 'termsAgreement': {
      const termsNode = node;
      return (
        <FormNodeTermsAgreement
          {...termsNode}
          checked={policyChecked}
          setChecked={setPolicyChecked}
        />
      );
    }

    case 'oauthLogin': {
      throw new Error('Not implemented yet: "oauthLogin" case');
    }

    case 'submit': {
      throw new Error('Not implemented yet: "submit" case');
    }

    default: {
      return <i>UnhandledNode: {JSON.stringify(node)}</i>;
    }
  }
};

interface BadgeNameInputProps {
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

const BadgeNameInput: FC<BadgeNameInputProps> = ({ registration, setRegistration }) => {
  const setBadgeNameEV = useInputModel(setRegistration, 'badgeName');

  return (
    <FormGroup>
      <Label for="badgeName">Badge Name</Label>
      <Input
        id="badgeName"
        maxLength={32}
        name="badgeName"
        onChange={setBadgeNameEV}
        value={registration.badgeName}
      />
    </FormGroup>
  );
};

interface AttendanceFlagsRendererProps {
  readonly flags: RegistrationFlagModel[];
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

const AttendanceFlagsRenderer: FC<AttendanceFlagsRendererProps> = ({
  flags,
  registration,
  setRegistration,
}) => {
  const [enabledFlags, setEnabledFlags] = useObject(registration, setRegistration, 'flags');

  return (
    <FormGroup>
      <AttendanceFlags enabledFlags={enabledFlags} flags={flags} onUpdate={setEnabledFlags} />
    </FormGroup>
  );
};

interface ProfilePictureInputProps {
  readonly title?: string;
  readonly ignoreGravatar: boolean;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

const ProfilePictureInput: FC<ProfilePictureInputProps> = ({
  title,
  ignoreGravatar,
  setRegistration,
}) => {
  const user = useUser()!;

  return (
    <Row className="justify-content-center text-center">
      <Col lg={12} xs={12}>
        <ProfilePictureEditor
          ignoreGravatar={ignoreGravatar}
          inputUser={user}
          onChange={(file) => {
            setRegistration((old) => ({ ...old, profilePicture: file }));
          }}
          title={title}
        />
      </Col>
    </Row>
  );
};
