/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { ComponentType, createElement, FC, lazy, ReactElement, Suspense } from 'react';

type ReactFunctionCompability<T> = (opts: T) => ReactElement;

/**
 * Creates a lazy loaded component with loading from the provided resolver.
 */
export function makeLazyComponent<P extends object>(
  provider: () => Promise<
    | ComponentType<P>
    | ReactFunctionCompability<P>
    | {
        default: ComponentType<P> | ReactFunctionCompability<P>;
      }
  >,
): FC<P> {
  const lazyComponent = lazy(async () => {
    const resolved = await provider();
    return 'default' in resolved
      ? resolved
      : {
          default: resolved,
        };
  });

  const res: FC<P> = (outerProps) => {
    const { children, ...props } = outerProps;
    return (
      <Suspense fallback="Loading...">
        {/* CAST: Not sure how to otherwise do this */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {createElement(lazyComponent, props as any, children)}
      </Suspense>
    );
  };

  res.displayName = 'LazyWrapper';
  return res;
}
