import React, { PropsWithChildren, useCallback } from 'react';
import { MaterialIcon } from './MaterialIcon';

interface ChipProps<T = undefined> {
  readonly id: string;
  readonly value: T;
  onDismiss?(value: T): void;
}

export const Chip = <T = undefined,>({
  onDismiss,
  children,
  id,
  value,
}: PropsWithChildren<ChipProps<T>>): JSX.Element => {
  const onDismissInner = useCallback(() => {
    onDismiss!(value);
  }, [value]);

  return (
    <div className="chip" id={id}>
      {children}
      {onDismiss ? (
        <span className="chip-dismiss" onClick={onDismissInner}>
          <MaterialIcon name="close" small />
        </span>
      ) : null}
    </div>
  );
};
