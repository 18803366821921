import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col } from 'reactstrap';
import { MaterialIcon } from './MaterialIcon';

interface InfoCardProps {
  readonly className?: string;
  readonly heading: JSX.Element | string;
  readonly text: string[];
  readonly id?: string;
  readonly icon?: string;
  readonly largeText?: boolean;
  readonly status?: 'danger' | 'info' | 'success' | 'warning';
  readonly button?: {
    status?: string;
    to: string;
    text: string;
  };
}

export const InfoCard: FC<InfoCardProps> = ({
  className,
  status,
  id,
  heading,
  icon,
  button,
  text,
  largeText,
}) => {
  let renderedText: React.ReactNode = text.map((s) => <p key={s}>{s}</p>);

  if (!largeText) {
    renderedText = <small>{renderedText}</small>;
  }

  return (
    <Col className={className} lg={3} md={6} xs={12}>
      <Card className="info-card" id={id}>
        <CardHeader className={`card-color ${status!}`} />
        <CardBody className="text-center">
          {icon && <MaterialIcon medium name={icon} />}
          <h4>{heading}</h4>
          {renderedText}
        </CardBody>
        {button && (
          <CardHeader>
            <CardTitle tag="h3">
              <Link
                className={`btn btn-block ${button.status ? `btn-${button.status}` : ''}`}
                to={button.to}
              >
                {button.text}
              </Link>
            </CardTitle>
          </CardHeader>
        )}
      </Card>
    </Col>
  );
};
