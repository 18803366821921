import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col } from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { useConfig } from '../../../utils';
import { getConventionIdParam } from '../getConventionIdParam';

export const RegisterComplete: FC = () => {
  const config = useConfig();
  return (
    <Col lg={6} xs={12}>
      <Card>
        <CardBody className="text-center">
          <div id="regComplete">
            <MaterialIcon className="margin-bottom-10" large name="email" type="muted" />
            <CardTitle>Confirm your email address!</CardTitle>
            <hr />
            <p>In order to finalize your registration, you must confirm your e-mail address.</p>
            <p>
              Please check your e-mail, in a few moments you should receive a confirmation code.
              Follow the instructions in the e-mail to confirm your registration.
            </p>
            <p>
              If you do not confirm your registration within a few days, it may be cancelled. If you
              do not receive any e-mail from us within a few hours, then contact{' '}
              {config.contact.email.registration} for help.
            </p>
            <p>
              <strong>IMPORTANT</strong> You should ensure that you check your Spam or Junk mail
              folders before contacting the registration team for help, as our e-mails may be
              wrongly marked as junk.
            </p>
            <hr />
            <Link
              className="btn btn-link btn-block"
              id="backToLogin"
              to={`/login${getConventionIdParam()}`}
            >
              Go back to Login
            </Link>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
