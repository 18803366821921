export interface OptionOrderItem {
  optionId: number;
  orderItemId: number;
  value: string;
}

interface OptionType {
  description: string;
  name: string;
  currency?: boolean;
  // Hide experimental option types from being selected
  hidden?: boolean;
}

export const validOptionTypes: Record<string, OptionType> = {
  select: {
    description: 'Allows selecting a single option from a pre-defined list.',
    name: 'Single Select',
  },
  multi: {
    description: 'Allows selecting multiple options from a pre-defined list.',
    name: 'Multi Select',
  },
  text: {
    description: 'Allows the user to type arbitrary text.',
    name: 'Freetext Field',
  },
  number: {
    description: 'Allows the user to select a number (integer).',
    name: 'Number',
  },
  currency: {
    description: 'Allows the user to enter a currency value.',
    name: 'Currency',
    currency: true,
  },
  file: {
    description: 'Allows the user to upload files.',
    name: 'Files',
    hidden: true,
  },
};
