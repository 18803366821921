import React, { FC, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Order, ProductModel, ProductVisibility } from '../../shared/orders';
import { displayName, Fetcher, isResourceError, useFetcher, useUser } from '../utils';
import { captureError } from '../utils/errorHandling';
import { MaterialIcon, RelatedProduct } from '.';

interface SecondaryAction {
  text: string;
  onClick(product: ProductModel): void;
}

interface PageTextProps {
  readonly id: string;
  readonly title: string;
  readonly subtext: JSX.Element;
  readonly secondaryAction?: SecondaryAction;
  readonly orderComplete?: boolean;
}

interface AddedToCartProps extends PageTextProps {
  readonly product: ProductModel;
}

export const AddedToCart: FC<AddedToCartProps> = ({
  product,
  id,
  title,
  subtext,
  secondaryAction,
  orderComplete,
}) => {
  const relatedProducts = product.addons.filter(
    ({ isFree, visibility }) => !isFree && visibility === ProductVisibility.PUBLIC,
  );

  const onClick = useCallback(() => secondaryAction?.onClick(product), [secondaryAction, product]);

  return (
    <Row className="justify-content-center" id={id}>
      <Col className="margin-bottom-10" id="addedToCart" lg={10} xs={12}>
        <Card className="margin-bottom-10">
          <CardBody className="text-center">
            <MaterialIcon large name="check_circle" type="success" />
            <h3 className="text-success">{title}</h3>
            {subtext}
            <Row className="justify-content-center">
              {!orderComplete && (
                <Col className="margin-bottom-10" md={3} xs={12}>
                  <Link className="button-link" to="/cart">
                    <Button block color="primary" id="viewCart">
                      View Cart
                    </Button>
                  </Link>
                </Col>
              )}
              {secondaryAction && (
                <Col className="margin-bottom-10" md={3} xs={12}>
                  <Button block color="secondary" onClick={onClick} outline>
                    {secondaryAction.text}
                  </Button>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
      {relatedProducts.length > 0 && (
        <Col id="relatedProducts" lg={10} xs={12}>
          <Card>
            <CardBody>
              <h4>Product Addons</h4>
              <hr />
              <Row>
                {relatedProducts.map((addon) => (
                  <RelatedProduct addon={addon} key={addon.id} />
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
};

interface AddedToCartCheckProps {
  readonly product: ProductModel;
  readonly secondaryAction?: SecondaryAction;
}

export const AddedToCartCheck: FC<AddedToCartCheckProps> = (props) => {
  const user = useUser();

  const cartState = useFetcher<Order | undefined>(async () => {
    // Check to see if an order exists
    try {
      return await api.getActiveOrder(user!.id, false);
    } catch (error) {
      if (!isResourceError(error, 'Order')) {
        // If it's not an Order ResourceError, then there's a bigger issue
        captureError(error as Error);
      }
    }

    return undefined;
  }, []);

  if (!cartState.complete) {
    return <Fetcher result={cartState} />;
  }

  const order = cartState.data;

  if (!order || order.orderItems.length === 0) {
    return <AddedToCartOrderComplete {...props} />;
  }

  return (
    <AddedToCart
      {...props}
      id="productAddedToCart"
      subtext={<p>{displayName(props.product)} has been added to your cart.</p>}
      title="Product Added to Cart!"
    />
  );
};

interface AddedToCartOrderCompleteProps {
  readonly product: ProductModel;
}

export const AddedToCartOrderComplete: React.FC<AddedToCartOrderCompleteProps> = (props) => {
  const history = useHistory();

  return (
    <AddedToCart
      {...props}
      id="productAddedToCart"
      orderComplete
      secondaryAction={{
        onClick: () => {
          history.push('/account/orders');
        },
        text: 'View Order History',
      }}
      subtext={
        <>
          <p>Your order for {displayName(props.product)} has been completed.</p>
          <p>You can view your order history and manage your orders from the Order History page.</p>
        </>
      }
      title="Order Completed!"
    />
  );
};
