import { classNames } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import { LazyMarkdown } from '../../LazyMarkdown';

import './FormAlert.scss';

interface Props {
  readonly type: 'error' | 'info' | 'success' | 'warning';
  readonly markdown: string;
}

export const FormAlert: FC<Props> = ({ type, markdown }) => {
  return (
    <div className={classNames({}, `form-alert-${type}`, 'form-alert')}>
      <LazyMarkdown source={markdown} />
    </div>
  );
};
