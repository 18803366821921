import React, { FC } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { MaterialIcon } from '../../../components';

export const DealersClosed: FC = () => {
  return (
    <Row className="justify-content-center" id="dealerClosed">
      <Col lg={6} xs={12}>
        <Card className="danger">
          <CardBody className="text-center">
            <MaterialIcon large name="remove_circle" type="danger" />
            <CardText tag="div">
              <p>We are not currently accepting applications.</p>
              <p>Please check back later.</p>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
