import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { CurrentUser } from '../../../shared/user/base';
import { UpdateUserPayload } from '../../../shared/user/update';
import {
  CountrySelector,
  PageHeader,
  PhoneInput,
  RegionSelector,
  UserStateComponent,
} from '../../components';
import { ActionType, store } from '../../services';
import { Fetcher, InputString, InputStringNull, useFetcher, useForm } from '../../utils';
import { ProfilePictureEditor } from './profilePictureEditor';

export const EditPersonalComponent: FC = () => {
  const fetch = useFetcher(async () => {
    return await api.getActiveUser();
  });

  if (!fetch.data) {
    return <Fetcher result={fetch} />;
  }

  return <EditPersonalComponentInner refresh={fetch.refresh} user={fetch.data} />;
};

interface EditPersonalComponentProps {
  readonly user: CurrentUser;
  refresh(): void;
}

// eslint-disable-next-line max-lines-per-function
export const EditPersonalComponentInner: FC<EditPersonalComponentProps> = ({
  user: inputUser,
  refresh,
}) => {
  const [user, setUser] = useState<UpdateUserPayload>({
    firstName: inputUser.firstName,
    lastName: inputUser.lastName,
    preferredName: inputUser.preferredName,
    bornAt: inputUser.bornAt,
    phone: inputUser.phone,
    addressCity: inputUser.addressCity,
    addressCountry: inputUser.addressCountry,
    addressLine1: inputUser.addressLine1,
    addressLine2: inputUser.addressLine2,
    addressState: inputUser.addressState,
    addressZipcode: inputUser.addressZipcode,
  });

  const form = useForm(async () => {
    const { profilePictureUrl } = await api.updateUser(inputUser.id, user);
    toast.success('Your personal information has been updated successfully.');

    store.dispatch({
      type: ActionType.ProfileUpdate,
      user: { ...inputUser, user, profilePictureUrl },
    });

    refresh();
  }, [user, inputUser]);

  const updateCountry = useCallback(
    (val: string) => {
      setUser((old) => ({ ...old, addressCountry: val }));
    },
    [setUser],
  );

  const updateState = useCallback(
    (val: string) => {
      setUser((old) => ({ ...old, addressState: val }));
    },
    [setUser],
  );

  const updatePhone = useCallback(
    (val: string) => {
      setUser((old) => ({ ...old, phone: val }));
    },
    [setUser],
  );

  const updateDoB = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = new Date(e.target.value);
      if (!Number.isNaN(date.getTime())) {
        setUser((old) => ({ ...old, bornAt: date }));
      }
    },
    [setUser],
  );

  const warnDob = useCallback(
    () =>
      toast.warn('If you need to change your date of birth, please contact the registration team.'),
    [],
  );

  return (
    <UserStateComponent>
      <PageHeader>Edit Personal Details</PageHeader>
      <Row className="justify-content-center">
        <Col lg={6} xs={12}>
          <Card>
            <CardBody className="text-center" id="personalForm">
              <form onSubmit={form.onSubmit}>
                <Row className="justify-content-center">
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="firstName">First Name</Label>
                      <InputString
                        disabled={inputUser.identityVerified}
                        name="firstName"
                        object={user}
                        setObject={setUser}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="lastName">Last Name</Label>
                      <InputString
                        disabled={inputUser.identityVerified}
                        name="lastName"
                        object={user}
                        setObject={setUser}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <FormText color="muted">
                    These must be your legal first and last names, as it appears on your government
                    issued ID.
                  </FormText>
                </FormGroup>
                <Row className="justify-content-center clearfix-after">
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="preferredName">Preferred Name</Label>
                      <InputStringNull name="preferredName" object={user} setObject={setUser} />
                      <FormText color="muted">
                        (Optional) If you have a name that you would prefer we use, please let us
                        know here.
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="bornAt">Date of Birth</Label>
                      <Input
                        defaultValue={user.bornAt?.toJSON().slice(0, 10) ?? ''}
                        id="bornAt"
                        name="bornAt"
                        onChange={updateDoB}
                        onClick={user.bornAt ? warnDob : undefined}
                        readOnly={!!inputUser.bornAt}
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row className="justify-content-center">
                  <Col lg={6} xs={12}>
                    <ProfilePictureEditor
                      ignoreGravatar={false}
                      inputUser={inputUser}
                      onChange={(file) => {
                        setUser((old) => ({ ...old, profilePicture: file }));
                      }}
                      title="Profile Picture"
                    />
                  </Col>
                </Row>
                <hr />
                <FormGroup>
                  <Label for="addressLine1">Address Line 1</Label>
                  <InputString
                    autoComplete="address-line1"
                    name="addressLine1"
                    object={user}
                    placeholder="Example: 1 Magical Lane"
                    setObject={setUser}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="addressLine2">Address Line 2</Label>
                  <InputStringNull
                    autoComplete="address-line2"
                    name="addressLine2"
                    object={user}
                    placeholder="Example: Apt 42"
                    setObject={setUser}
                  />
                  <FormText color="muted">(Optional)</FormText>
                </FormGroup>
                <Row className="justify-content-center">
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="addressCity">City</Label>
                      <InputString
                        autoComplete="address-level2"
                        name="addressCity"
                        object={user}
                        placeholder="Example: Reno"
                        setObject={setUser}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="addressZipcode">Zip / Postal Code</Label>
                      <InputString
                        autoComplete="postal-code"
                        name="addressZipcode"
                        object={user}
                        placeholder="Example: 89595"
                        setObject={setUser}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="addressCity">Country</Label>
                      <CountrySelector
                        autoComplete="country-name"
                        classes="form-control"
                        id="addressCountry"
                        name="addressCountry"
                        onChange={updateCountry}
                        value={user.addressCountry ?? ''}
                        // Note: React types not compatible with country selector
                        valueType="short"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} xs={12}>
                    <FormGroup>
                      <Label for="addressZipcode">State / Province</Label>
                      <RegionSelector
                        autoComplete="address-level1"
                        classes="form-control"
                        country={user.addressCountry ?? ''}
                        countryValueType="short"
                        data-coerce={false}
                        disableWhenEmpty
                        id="addressState"
                        name="addressState"
                        onChange={updateState}
                        value={user.addressState ?? ''}
                        valueType="short"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="phone">Phone Number</Label>
                  <PhoneInput name="phone" onChange={updatePhone} value={user.phone ?? ''} />
                </FormGroup>
                <hr />
                <Row className="justify-content-center">
                  <Col className="margin-top-10" lg={6} xs={12}>
                    <Button
                      block
                      color="primary"
                      disabled={form.saving}
                      id="updatePersonalDetails"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
