import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DealerFullModel } from '../../../../shared/dealer';

export const DealerButtons: FC<{
  readonly dealer: DealerFullModel;
  readonly enableDealerHelpers: boolean;
}> = ({ dealer, enableDealerHelpers }) => {
  return (
    <div className="margin-bottom-10">
      <Link to="/vendor/edit">
        <Button color="primary" id="editDealer" style={{ marginRight: '10px' }}>
          Edit Dealer
        </Button>
      </Link>
      {dealer.applications.some((t) => t.status === 'approved') && (
        <>
          <Link to="/vendor/seating">
            <Button color="secondary" id="seatingPreferences" style={{ marginRight: '10px' }}>
              Seating Preferences
            </Button>
          </Link>
          {enableDealerHelpers && (
            <Link to="/vendor/assistants">
              <Button color="secondary" id="manageAssistants">
                Manage Assistants
              </Button>
            </Link>
          )}
        </>
      )}
    </div>
  );
};
