import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { passwordScore } from '../../../shared/user/password';
import { PageHeader, PasswordChangeInput, UserStateComponent } from '../../components';
import { useFetcher, useInputState, useUser } from '../../utils';

export const EditAccountComponent: FC = () => {
  const user = useUser()!;
  const [email, setEmail] = useInputState(user.email);
  const currentPasswordInput = useRef<HTMLInputElement>(null);

  const pendingEmailChangeRequest = useFetcher(async () => {
    return await api.pendingEmailChange(user.id);
  }, []);

  const onSubmit = useCallback(() => {
    toast.success('Your account information has been updated successfully.');
    currentPasswordInput.current!.value = '';
    pendingEmailChangeRequest.refresh();
  }, [currentPasswordInput, pendingEmailChangeRequest.refresh]);

  const pendingEmailChange = pendingEmailChangeRequest?.data?.pending;

  return (
    <UserStateComponent>
      <PageHeader>Edit Account Details</PageHeader>
      <Row className="justify-content-center">
        <Col lg={6} xs={12}>
          <Card>
            <CardBody className="text-center" id="accountForm">
              <JSONForm method="patch" onSuccess={onSubmit} path={`/api/users/${user.id}`}>
                <FormGroup row>
                  <Label for="currentPassword" md={4} sm={12}>
                    Current Password
                  </Label>
                  <Col md={8} sm={12}>
                    <Input
                      autoComplete="password"
                      id="currentPassword"
                      innerRef={currentPasswordInput}
                      name="currentPassword"
                      type="password"
                    />
                    <FormText color="muted">
                      To make any changes to your account, you will need to provide your current
                      password.
                    </FormText>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label for="email" md={4} sm={12}>
                    Email Address
                  </Label>
                  <Col md={8} sm={12}>
                    <Input
                      autoComplete="email"
                      defaultValue={user.email}
                      disabled={pendingEmailChange}
                      id="email"
                      name="email"
                      onChange={setEmail}
                      type="email"
                    />
                    {user.email !== email && (
                      <Alert className="mt-2" color="warning">
                        <strong>Note</strong>: You will need to verify your new email before changes
                        take effect.
                      </Alert>
                    )}
                    {pendingEmailChange && (
                      <Alert className="mt-2" color="warning" id="pendingEmailChange">
                        <strong>Note</strong>: An email change request has already been made in the
                        past 24 hours. Please check your email inbox.
                      </Alert>
                    )}
                  </Col>
                </FormGroup>
                <div className="custom-control custom-checkbox margin-top-10 left-align">
                  <Input
                    className="custom-control-input"
                    defaultChecked={user.newsletter}
                    id="newsletter"
                    name="newsletter"
                    type="checkbox"
                  />
                  <Label className="custom-control-label" for="newsletter">
                    I want to receive the convention e-mail newsletter.
                  </Label>
                </div>
                <hr />
                <PasswordChangeInput md={8} passwordScore={passwordScore} sm={12} />
                <hr />
                <Row className="justify-content-center">
                  <Col className="margin-top-10" lg={6} xs={12}>
                    <Button block color="primary" id="updateAccountDetails" type="submit">
                      Update
                    </Button>
                  </Col>
                </Row>
              </JSONForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
