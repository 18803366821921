import { Option, OptionDataPayload } from '../../shared/options';

export function cleanupOptions(options: Option[], data: OptionDataPayload): OptionDataPayload {
  const result: OptionDataPayload = {};

  for (const option of options) {
    const id = option.id.toString();

    if (id in data) {
      result[id] = data[id];
    }
  }

  return result;
}
