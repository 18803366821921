import React, { FC, useEffect, useState } from 'react';

interface DateTimeProps {
  readonly value: Date | string;
  readonly timeZone?: string;
}

/** Retreive the date part of a datetime object */
export function getDate(date: Date): string {
  return date.toISOString().slice(0, 10);
}

export const DateTime: FC<DateTimeProps> = ({ value, timeZone }) => {
  const date = new Date(value);
  const isoTime = date.toISOString();
  const formatOpts: Intl.DateTimeFormatOptions = {};
  if (timeZone) {
    formatOpts.timeZone = timeZone;
    formatOpts.timeZoneName = 'short';
  }

  return (
    <time dateTime={isoTime} title={isoTime}>
      {date.toLocaleString('en-US', formatOpts)}
    </time>
  );
};

interface DateTProps {
  readonly value: Date | string;
  readonly options?: Intl.DateTimeFormatOptions;
}

export const DateT: FC<DateTProps> = ({ value, options }) => {
  const translated = new Date(value);
  const timeDiff = translated.getTimezoneOffset() * 60_000;
  const adjustedDate = new Date(translated.valueOf() + timeDiff);
  return (
    <time dateTime={adjustedDate.toString()} title={adjustedDate.toString()}>
      {adjustedDate.toLocaleDateString('en-US', options)}
    </time>
  );
};

export const LiveDateTime: FC<{ readonly timeZone?: string }> = ({ timeZone }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 1000);

    return () => clearInterval(interval);
  }, []);

  return <DateTime timeZone={timeZone} value={currentDate} />;
};
