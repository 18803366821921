import React, { FC } from 'react';
import { FormNodeMarkdownModel } from '../../../../shared/kiosk';
import { LazyMarkdown } from '../../LazyMarkdown';

export const FormNodeMarkdown: FC<FormNodeMarkdownModel> = ({ id, markdown }) => {
  return (
    <div className="form-node form-node-markdown" id={`node-${id}`}>
      <div>
        <LazyMarkdown source={markdown} />
      </div>
    </div>
  );
};
