import React, { Component } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { MaterialIcon, UserStateComponent } from '.';

interface PIIAccessWarningProps<T> {
  readonly ep: string;
  children(data: T): JSX.Element;
}

interface PIIAccessWarningState<T> {
  data?: T;
}

export class PIIAccessWarning<T extends object> extends Component<
  PIIAccessWarningProps<T>,
  PIIAccessWarningState<T>
> {
  public override state: PIIAccessWarningState<T> = {};

  public override render(): JSX.Element {
    const { children } = this.props;
    const { data } = this.state;

    if (data) {
      return children(data);
    }

    return (
      <UserStateComponent>
        <Row className="justify-content-center">
          <Col className="margin-bottom-10" xs={12}>
            <Card className="warning">
              <CardBody className="text-center">
                <MaterialIcon large name="warning" type="warning" />
                <CardTitle>PII Access Warning</CardTitle>
                <CardText>
                  You are attempting to access sensitive Personally Identifiable Information (PII).
                </CardText>
                <CardText>
                  You are required to confirm that this access is compliant with the convention
                  privacy policy and that you have a legitimate reason to access this information
                  before continuing.
                </CardText>
                <CardText>This access will be logged for auditing purposes.</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} xs={12}>
            <Button
              block
              color="danger"
              id="accessEmergencyContactsBtn"
              onClick={async () => await this.requestPII()}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </UserStateComponent>
    );
  }

  private async requestPII(): Promise<void> {
    const { ep } = this.props;

    this.setState({
      data: await api.request<T>({ url: ep }),
    });
  }
}
