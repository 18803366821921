import React from 'react';
import { ScopeInfo } from '../../../../APIClient';
import { MaterialIcon } from '../../../../components';
import { classNames } from '../../../../utils';

interface OAuthPermissionProps {
  readonly scope: ScopeInfo;
}

export const OAuthPermission: React.FC<OAuthPermissionProps> = ({
  scope: { description, icon, name },
}) => {
  const isElevated = name.startsWith('admin:');
  const classes = classNames({ 'permission-elevated': isElevated }, 'permission');

  return (
    <div className={classes} key={icon}>
      <MaterialIcon medium name={icon} />
      <div className="permission-info">
        <div className="permissionHeader">
          {isElevated && <MaterialIcon name="warning" small type="danger" />}
          {description}
        </div>
        {isElevated && (
          <div>
            This is an <strong>administrative</strong> permission.
          </div>
        )}
      </div>
    </div>
  );
};
