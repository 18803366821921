import React, { FC, ReactNode } from 'react';
import { classNames } from '../utils';

import './Loading.scss';

interface LoadingProps {
  readonly hideLoader?: boolean;
  readonly hideText?: boolean;
  readonly inline?: boolean;
  readonly children?: ReactNode;
}

export const Loading: FC<LoadingProps> = ({ children, inline, hideLoader, hideText }) => {
  const textColorClassNames = classNames({ 'text-light': !inline, 'text-dark': !!inline });

  return (
    <div
      className={classNames({
        'inline-loading': !inline,
        loading: true,
        noselect: true,
      })}
    >
      <div>
        {!hideLoader && (
          <div className={classNames({}, textColorClassNames, 'spinner-border')}>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {!hideText && <div className={textColorClassNames}>{children ?? <p>Loading...</p>}</div>}
      </div>
    </div>
  );
};
