import React, { FC, useCallback, useEffect, useState } from 'react';
import { BaseUser, UserRegistration } from '../../../shared/user/base';
import { ScannedUserInformation } from '../../../shared/user/cashier';
import { ProfileEditModal } from '../housekeeping/attendee/profileEditModal';
import { RegistrationEditModal } from '../housekeeping/attendee/registrationEditModal';

export type AttendeeInformationModalState =
  | { type: 'reg' }
  | { type: 'user'; scannedInformation?: ScannedUserInformation }
  | null;

interface AttendeeInformationProps {
  readonly modalState: AttendeeInformationModalState;
  readonly user: BaseUser;
  readonly registration?: UserRegistration;
  readonly bannedWords: string[];
  readonly isMinor: boolean;
  reload(): void;
  onModalClose(): void;
}

export const AttendeeInformation: FC<AttendeeInformationProps> = ({
  modalState,
  user,
  bannedWords,
  reload,
  isMinor,
  registration,
  onModalClose,
}) => {
  const [modal, setModal] = useState<AttendeeInformationModalState>(null);
  const close = useCallback(() => {
    setModal(null);
    onModalClose();
  }, [setModal, onModalClose]);

  const complete = useCallback(() => {
    setModal(null);
    onModalClose();
    reload();
  }, [setModal, reload]);

  useEffect(() => {
    setModal(modalState);
  }, [modalState]);

  if (!modal) {
    return null;
  }

  return (
    <>
      {modal.type === 'reg' && (
        <RegistrationEditModal
          bannedWords={bannedWords}
          close={close}
          complete={complete}
          registration={registration}
          userId={user.id}
        />
      )}
      {modal.type === 'user' && (
        <ProfileEditModal
          close={close}
          complete={complete}
          isMinor={isMinor}
          scannedUserInformation={modal.scannedInformation}
          user={user}
        />
      )}
    </>
  );
};
