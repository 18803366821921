import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LiveDateTime, MaterialIcon } from '../../components';
import { useUser } from '../../utils';
import { UserAvatar } from '../../utils/UserAvatar';
import { CashierContext, getBadgePrinter } from './cashiercontext';
import { PrinterModal } from './printerModal';
import { BadgePrinterBase } from './printers/PrinterBase';
import { LabelPrinterManager } from './printers/ZebraPrinter';
import { CashierSettings } from './settingsModal';
import './header.scss';

export const Header: React.FC = () => {
  const user = useUser()!;
  const cashierSettings = useContext(CashierContext);
  const [printerModalOpen, setPrinterModalOpen] = useState(false);

  const closePrintModal = useCallback(() => {
    setPrinterModalOpen(false);
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: true,
    });
  }, [cashierSettings]);

  const openPrintModal = useCallback(() => {
    setPrinterModalOpen(true);
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: false,
    });
  }, [cashierSettings]);

  return (
    <header className="cashier-header">
      <div className="cashier-header-info">
        <div className="cashier-header-title">Registration Station</div>
        <PrinterStatus open={openPrintModal} />
        {printerModalOpen && <PrinterModal close={closePrintModal} settings={cashierSettings} />}
      </div>
      <div className="cashier-menu">
        <div className="cashier-menu-item">
          <CashierSettings />
        </div>
      </div>
      <div className="cashier-info">
        <div className="cashier-info-username">{user.username}</div>
        <div className="cashier-info-date">
          <LiveDateTime />
        </div>
      </div>
      <div className="cashier-avatar">
        <UserAvatar url={user.profilePictureUrl} />
      </div>
    </header>
  );
};

interface PrinterStatusProps {
  readonly open: () => void;
}

const PrinterStatus: React.FC<PrinterStatusProps> = ({ open }) => {
  const context = useContext(CashierContext);
  const { config } = context;
  const [printers, setPrinters] = useState<{
    primary: BadgePrinterBase | undefined;
    secondary: BadgePrinterBase | undefined;
  }>({ primary: undefined, secondary: undefined });

  const refreshPrinters = useCallback(() => {
    const primary = getBadgePrinter(context, config.primaryPrinter);
    const secondary =
      config.secondaryPrinter === undefined
        ? undefined
        : getBadgePrinter(context, config.secondaryPrinter);

    setPrinters({ primary, secondary });
  }, [context]);

  useEffect(() => {
    for (const m of context.printerManagers) {
      if (m instanceof LabelPrinterManager) {
        m.addEventListener('connectedDevice', refreshPrinters);
        m.addEventListener('disconnectedDevice', refreshPrinters);
      }
    }

    return () => {
      for (const m of context.printerManagers) {
        if (m instanceof LabelPrinterManager) {
          m.removeEventListener('connectedDevice', refreshPrinters);
          m.removeEventListener('disconnectedDevice', refreshPrinters);
        }
      }
    };
  }, [context]);

  // Page load
  useEffect(refreshPrinters, [context]);

  if (printers.primary === undefined && printers.secondary === undefined) {
    return (
      <div className="cashier-printer-info" onClick={open}>
        <MaterialIcon name="print" type="danger" />
        <strong className="text-danger">No Printer Selected!</strong>
        <MaterialIcon name="arrow_drop_down" />
      </div>
    );
  }

  return (
    <div className="cashier-printer-info" onClick={open}>
      <MaterialIcon name="print" />
      <span className={printers.primary === undefined ? 'text-danger' : ''}>
        Primary: {printers.primary === undefined ? 'None selected' : printers.primary.name}
      </span>
      {printers.secondary && <span>, Secondary: {printers.secondary.name}</span>}
      <MaterialIcon name="arrow_drop_down" />
    </div>
  );
};
